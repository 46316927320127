export default {
  LOAD_LIST_SOCIAL_SECURITY(state, data) {
    state.listSocialSecurity = data
  },
  PREPARE_TO_EDIT_SOCIAL_SECURITY(state, data) {
    state.socialSecurity = data
  },
  RESET_SOCIAL_SECURITY(state) {
    state.socialSecurity = {
      id: 0,
      codigo: null,
      ativo: true,
      nome: '',
      grupoPrevidenciaSocialId: null,
      grupoPrevidenciaSocialNome: '',
    }
  },
  SET_LIST_COMBO_SOCIAL_SECURITY_GROUP(state, data) {
    state.comboSocialSecurityGroup = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
}
