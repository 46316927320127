/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      { title: 'Administrativo', route: { name: 'administrativeSettings' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Profissões', route: { name: 'professionsSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Natureza Fiscal', route: { name: 'fiscalNatureSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Funções', route: { name: 'functionsSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Tipos de Cadastro', route: { name: 'registrationTypeSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Cargos Diretivos', route: { name: 'executivePositionsSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Financeiro', route: { name: 'financialSettings' }, icon: 'GridIcon', isBookmarked: false },
      { title: 'Bancos', route: { name: 'banksSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Contas Bancárias', route: { name: 'banksAccountSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Tipos de Contas', route: { name: 'accountTypeSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Plano de Contas', route: { name: 'accountPlanSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Tipos de Pagamentos', route: { name: 'typeOfPaymentSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Tipos de Recebimentos', route: { name: 'typeOfReceiptSettings' }, icon: 'FileTextIcon', isBookmarked: false },
      { title: 'Formas de Pagamentos', route: { name: 'paymentMethodsSettings' }, icon: 'FileTextIcon', isBookmarked: false },
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        name: 'Rena Brant',
        email: 'nephrod@preany.co.uk',
        time: '21/05/2019',
      },
    ],
  },
}
/* eslint-enable */
