import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/ordem/status_os${queryUrl}`)
  },
  insert(data) {
    return axios.post('/ordem/status_os/', data)
  },
  edit(data) {
    return axios.put(`/ordem/status_os/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/ordem/status_os/${id}`)
  },
  getById(id) {
    return axios.get(`/ordem/status_os/${id}`)
  }
}
