export default [
  {
    path: '/modules/financial/importPage',
    redirect: '/modules/financial/files',
    name: 'importPageNeos',
    component: () =>
      import('@/views/modules/financial/files/AccountsFiles.vue'),
    meta: {
      action: 'ROLE_FINAN_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Arquivos',
      breadcrumb: [
        {
          text: 'Financeiro'
        }
      ]
    },
    children: [
      {
        path: '/neos',
        name: 'importPageNeos',
        component: () =>
          import(
            '@/views/modules/financial/files/grid/AccountFilesNeosGrid.vue'
          ),
        meta: {
          pageTitle: 'Arquivos',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Financeiro | Arquivos'
            }
          ]
        }
      },
      {
        path: '/inss',
        name: 'importPageInss',
        component: () =>
          import(
            '@/views/modules/financial/files/grid/AccountFilesInssGrid.vue'
          ),
        meta: {
          pageTitle: 'Arquivos',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Financeiro | Arquivos'
            }
          ]
        }
      }
    ]
  }
]
