var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Valor","label-for":"v-valor"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valor","raw":true,"options":_vm.options.number,"placeholder":"valor","data-cy":"valor","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", _vm._n($$v))},expression:"form.valor"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo de Atendimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Atendimento","label-for":"v-tipoAtendimento"}},[_c('v-select',{attrs:{"reduce":function (comboTiposAtendimento) { return comboTiposAtendimento.id; },"label":"nome","options":_vm.comboTiposAtendimento,"state":errors.length > 0 ? false : null,"data-cy":"tipo","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.tipoAtendimento.id),callback:function ($$v) {_vm.$set(_vm.form.tipoAtendimento, "id", $$v)},expression:"form.tipoAtendimento.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-tipoAtendimento"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.especialidade.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }