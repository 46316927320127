export default {
  LOAD_LIST_ENDERECOS(state, data) {
    state.listaEnderecos = data
  },
  PREPARE_TO_EDIT_ENDERECOS(state, data) {
    state.endereco = data
  },
  RESET_ENDERECOS(state) {
    state.endereco = {
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      cidade: null,
      complemento: null,
      ativo: true,
      registro: {
        id: null,
        nome: null,
      },
      uf: {
        id: null,
        nome: null,
        sigla: null,
      },
      tipoEndereco: {
        id: null,
        nome: null,
      },
    }
  },
}
