import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/funcoes${queryUrl}`)
  },
  insertNewFunction(data) {
    return axios.post('/administrativo/funcoes', data)
  },
  editFunction(data) {
    return axios.put(`/administrativo/funcoes/${data.id}`, data)
  },
  deleteFunction(id) {
    return axios.delete(`/administrativo/funcoes/${id}`)
  },
}
