<template>
  <div>
    <div v-if="showForm">
      <b-card :title="titleForm">
        <b-form @submit.prevent>
          <b-spinner
            v-if="loading"
            class="float-right"
            label="Floated Right"
          />
          <b-row>
            <!-- first name -->
            <b-col cols="12">
              <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-col>
            <!-- submit and reset -->
            <b-col
              cols="12"
              class="mt-3"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </div>
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button
          variant="primary"
          @click="addArquivo()"
        >
          Incluir
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="listaDocumentacoes"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="(props.column.field === 'principal') || (props.column.field === 'whatsapp')">
            <span>
              {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="downloadDocument(props.row)">
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span>Abrir</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteDocument(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Mostrando 1 a
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
  </div>
</template>
<script>
import store from '@/store/index'
import {
  BDropdown, BDropdownItem, BFormSelect, BPagination,
  BFormFile, BRow, BCol, BForm, BButton, BCard, BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BFormFile,
    BRow,
    BCol,
    BForm,
    BCard,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      titleForm: 'Envio de Documentos',
      loading: false,
      showForm: false,
      file: null,
      listaDocumentacoes: [],
      columns: [
        {
          label: 'Descrição',
          field: 'nome',
        },
        {
          label: 'Ações',
          field: 'action',
        },
      ],
      pageLength: 10,
      dir: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadDocumentation()
  },
  methods: {
    downloadDocument(data) {
      this.$store.dispatch('associantesAdministrativeModule/downloadDocument', data.id).then(response => {
        window.open(response.data, '_blank')
      })
    },
    deleteDocument(data) {
      this.$swal({
        title: 'Remover o Documento?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('associantesAdministrativeModule/deleteDocument', data.id).then(() => {
            this.loadDocumentation()
            this.showMessageSuccess('Remover', 'Documento removido com sucesso!')
          }).catch(err => {
            this.MensagemError('Falha ao remover o Documento', err.message)
            this.loadDocumentation()
          })
        }
      })
    },
    addArquivo() {
      this.file = null
      this.showForm = true
    },
    save() {
      this.loading = true
      const objectSend = {
        id: this.$route.params.id,
        file: this.file,
      }
      this.$store.dispatch('othersAdministrativeModule/sendFiles', objectSend).then(response => {
        this.loadDocumentation()
        this.cancel()
        this.loading = false
      }).catch(err => {
        console.error('ERRRO', err)
        this.loading = false
      })
    },
    cancel() {
      this.file = null
      this.showForm = false
    },
    loadDocumentation() {
      this.$store.dispatch('othersAdministrativeModule/loadDocumentations', this.$route.params.id).then(response => {
        this.listaDocumentacoes = response.data.content
      }).catch(err => {
        console.error('ERRRO', err)
      })
    },
  },
}
</script>
