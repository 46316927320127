import unidadeAtendimentoService from '@/api/attendance/unidadeAtendimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => unidadeAtendimentoService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_UNIDADE_ATENDIMENTO', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewUnidadeAtendimento({ commit }, data) {
    return unidadeAtendimentoService.insertNewUnidadeAtendimento(data).then(result => result).catch(error => error)
  },
  prepareToEditUnidadeAtendimento({ commit }, data) {
    commit('PREPARE_TO_EDIT_UNIDADE_ATENDIMENTO', data)
  },
  editUnidadeAtendimento({ commit }, data) {
    return unidadeAtendimentoService.editUnidadeAtendimento(data).then(result => result).catch(error => error)
  },
  resetUnidadeAtendimento({ commit }) {
    commit('RESET_UNIDADE_ATENDIMENTO')
  },
  delete({ commit }, id) {
    return unidadeAtendimentoService.deleteUnidadeAtendimento(id).then(result => result).catch(error => error)
  },
}
