var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[(!this.$route.params.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"cpf do Registro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF do Registro","label-for":"v-cpf-registro"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-cpf-registro","raw":false,"placeholder":"Busca registro pelo cpf","options":_vm.maskCpf,"disabled":!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                  !_vm.verificarPermissao('ROLE_CORE_INSERIR')},model:{value:(_vm.cpf),callback:function ($$v) {_vm.cpf=$$v},expression:"cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3706972797)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Nome","label-for":"v-nome"}},[_c('b-form-input',{attrs:{"id":"v-nome","placeholder":"Nome","readonly":""},model:{value:(_vm.nomeRegistroEncontrado),callback:function ($$v) {_vm.nomeRegistroEncontrado=$$v},expression:"nomeRegistroEncontrado"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data Início","label-for":"v-dataInicio"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                !_vm.verificarPermissao('ROLE_CORE_INSERIR')},model:{value:(_vm.form.dataInicio),callback:function ($$v) {_vm.$set(_vm.form, "dataInicio", $$v)},expression:"form.dataInicio"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data Término","label-for":"v-dataTermino"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                !_vm.verificarPermissao('ROLE_CORE_INSERIR')},model:{value:(_vm.form.dataTermino),callback:function ($$v) {_vm.$set(_vm.form, "dataTermino", $$v)},expression:"form.dataTermino"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"cargo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cargo","label-for":"v-cargo"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (comboCargo) { return comboCargo.id; },"label":"nome","options":_vm.comboCargo,"disabled":!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                  !_vm.verificarPermissao('ROLE_CORE_INSERIR')},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') && _vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_CORE_INSERIR') && _vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_CORE_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_CORE_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }