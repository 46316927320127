import clientService from '@/api/client/clientService'

export default {
  load({ commit }) {
    return clientService.load().then(result => result).catch(error => error)
  },
  edit({ commit }, data) {
    return clientService.edit(data).then(result => result).catch(error => error)
  },
}
