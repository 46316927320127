import api from '@/api/convenio/convenioServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => api.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insert({ commit }, data) {
    return api.insert(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return api.edit(data).then(result => result).catch(error => error)
  },
  reset({ commit }) {
    commit('RESET')
  },
  getById({ commit }, id) {
    return new Promise((resolve, reject) => api.getById(id).then(result => {
      commit('PREPARE_TO_EDIT', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  async searchRegistrosPessoaJuridica({ commit }, search) {
    try {
      const result = await api.searchRegistrosPessoaJuridica(search)
      return result
    } catch (error) {
      return error
    }
  },
}
