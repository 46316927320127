import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/tipos_registros${queryUrl}`)
  },
  insertNewRegistrationType(data) {
    return axios.post('/core/tipos_registros/', data)
  },
  editRegistrationType(data) {
    return axios.put(`/core/tipos_registros/${data.id}`, data)
  },
  deleteRegistrationType(id) {
    return axios.delete(`/core/tipos_registros/${id}`)
  },
  loadCombo() {
    return axios.get('/core/tipos/lista')
  },
}
