export default {
  listaExames: [],
  exame: {
    id: null,
    descricao: '',
    especialidade: {
      id: null,
    },
    observacao: '',
    ativo: true,
  },
}
