export default {
  LOAD_LIST_SCAFFOLD(state, data) {
    state.listaScaffolds = data
  },
  PREPARE_TO_EDIT_SCAFFOLD(state, data) {
    state.scaffold = data
  },
  RESET_SCAFFOLD(state) {
    state.scaffold = {
      field1: '',
      field2: '',
      field3: '',
      field4: '',
    }
  },
}
