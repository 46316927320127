<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col v-if="!this.$route.params.id" cols="12">
            <validation-provider
              #default="{ errors }"
              name="cpf do Registro"
              rules="required"
            >
              <b-form-group label="CPF do Registro" label-for="v-cpf-registro">
                <cleave
                  id="v-cpf-registro"
                  v-model="cpf"
                  class="form-control"
                  :class="[errors.length > 0 ? isValid : '']"
                  :raw="false"
                  placeholder="Busca registro pelo cpf"
                  :options="maskCpf"
                  :disabled="
                    !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                    !verificarPermissao('ROLE_CORE_INSERIR')
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- first obs -->
          <b-col cols="12" lg="12">
            <b-form-group label="Nome" label-for="v-nome">
              <b-form-input
                id="v-nome"
                v-model="nomeRegistroEncontrado"
                placeholder="Nome"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Data dataInicio -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Data Início" label-for="v-dataInicio">
              <flat-pickr
                v-model="form.dataInicio"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                  !verificarPermissao('ROLE_CORE_INSERIR')
                "
              />
            </b-form-group>
          </b-col>

          <!-- Data dataTermino -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Data Término" label-for="v-dataTermino">
              <flat-pickr
                v-model="form.dataTermino"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                  !verificarPermissao('ROLE_CORE_INSERIR')
                "
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="cargo"
              rules="required"
            >
              <b-form-group label="Cargo" label-for="v-cargo">
                <v-select
                  v-model="form.tipo"
                  :reduce="(comboCargo) => comboCargo.id"
                  label="nome"
                  :options="comboCargo"
                  :class="[errors.length > 0 ? isValid : '']"
                  :disabled="
                    !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                    !verificarPermissao('ROLE_CORE_INSERIR')
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-if="form.id" cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_CORE_ATUALIZAR') && form.id) ||
                  (!verificarPermissao('ROLE_CORE_INSERIR') && form.id)
                "
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_CORE_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_CORE_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    Cleave,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Diretoria',
      maskCpf: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true,
        onValueChanged: this.getRegistroByCpf.bind(this)
      },
      required,
      cpf: '',
      nomeRegistroEncontrado: '',
      form: {
        id: null,
        cargo: {
          id: null
        },
        dataInicio: '',
        dataTermino: '',
        ativo: false,
        registro: {
          id: null
        }
      },
      comboCargo: []
    }
  },
  mounted() {
    this.userPlace = JSON.parse(JSON.stringify(this.user))
    this.$store.dispatch('usersSettingAccessModuleModule/loadComboRoles')
  },
  methods: {
    async getRegistroByCpf() {
      if (this.cpf && this.cpf.length === 14) {
        const registroEncontrado = await this.$store.dispatch(
          'pagamentoRecebimentoFinancialModule/getAssocianteForCpf',
          this.cpf
        )
        if (registroEncontrado.response) {
          this.MensagemError(
            'Falha na busca',
            `${registroEncontrado.response.data.message}`
          )
          return
        }
        this.form.registro.id = registroEncontrado.data.id
        this.nomeRegistroEncontrado = registroEncontrado.data.nome
      }
    },
    resetForm() {
      this.cpf = ''
      this.nomeRegistroEncontrado = ''
      this.form = {
        id: null,
        cargo: {
          id: null
        },
        dataInicio: '',
        dataTermino: '',
        ativo: false,
        registro: {
          id: null
        }
      }
    },
    cancel() {
      this.$emit('cancelar')
      this.resetForm()
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.userPlace.id) {
            this.$store
              .dispatch('diretoriaSettingsModule/editUser', this.userPlace)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch(
                'usersSettingAccessModuleModule/insertNewUser',
                this.userPlace
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
