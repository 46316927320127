import administrativeSettings from './administrativeSettings'
import finalcialSettings from './financialSettings'
import attendanceSettings from './attendanceSettings'
import accessSettings from './accessSettings'
import profileSettings from './profileSettings'
import clientSettings from './clientSettings'
import protocolSettings from './protocolSettings'
import ordemServicoSettings from './ordemServicoSettings'

export default [
  ...administrativeSettings,
  ...finalcialSettings,
  ...attendanceSettings,
  ...accessSettings,
  ...clientSettings,
  ...protocolSettings,
  ...ordemServicoSettings,
  ...profileSettings
]
