<template>
  <div v-if="loading" class="clearfix">
    <b-spinner class="float-right" label="Floated Right" />
  </div>
  <div v-else>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Atendimentos </span>
        </template>
        <app-collapse type="margin">
          <app-collapse-item title="Atendimentos" :isVisible="true">
            <FormDadosAtendimentoVue @get-grupos="getGrupos" />
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab v-for="item in listaGrupos" :key="item.id">
        <template #title>
          <feather-icon icon="CheckIcon" />
          <span>{{ item.grupo }}</span>
          &nbsp;
          <b-spinner
            v-if="loadingCampos"
            class="float-right"
            label="Floated Right"
            small
          />
        </template>
        <app-collapse
          v-for="procedimento in item.procedimentos"
          :key="procedimento.id"
          type="margin"
        >
          <app-collapse-item :isVisible="true" :title="procedimento.nome">
            <b-row
              v-if="
                item.procedimentos.length === 0 ||
                procedimento.campo_adicional.length === 0
              "
            >
              <b-col> Nenhum campo configurado </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                :sm="campo.tipo === TYPES_FIELDS.TEXTO ? '12' : '6'"
                v-for="campo in procedimento.campo_adicional"
                :key="campo.id"
              >
                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.STRING"
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="text"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.TEXTO"
                  :label="campo.nome"
                >
                  <wysiwyg
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    :class="{ disabled: disableForm }"
                  />
                </b-form-group>

                <b-form-group
                  v-if="
                    campo.tipo === TYPES_FIELDS.INTEGER ||
                    campo.tipo === TYPES_FIELDS.DECIMAL
                  "
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="number"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.DATA"
                  :label="campo.nome"
                >
                  <flat-pickr
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="fieldDate"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                    }"
                    :readonly="disableForm"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.BOOLEAN"
                  :label="campo.nome"
                >
                  <b-form-checkbox
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="check-button"
                    :value="'true'"
                    :unchecked-value="'false'"
                    :disabled="disableForm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loadingCampos"
          :permission-insert="verificarPermissao('ROLE_ATEND_INSERIR')"
          :permission-edit="verificarPermissao('ROLE_ATEND_ATUALIZAR')"
          @save="salvarCamposAdicionais"
          @edit="editar"
          @cancel="cancelar"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormTextarea
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'
import FormDadosAtendimentoVue from './FormDadosAtendimento.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BSpinner,
    FormDadosAtendimentoVue,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    ButtonsFormVue,
    BFormCheckbox,
    BFormTextarea
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoModule', {
      atendimento: (state) => state.atendimento
    })
  },
  data() {
    return {
      collapseType: 'default',
      listaGrupos: [],
      loading: false,
      loadingCampos: false,
      disableForm: true,
      TYPES_FIELDS: {
        STRING: 0,
        BOOLEAN: 1,
        DECIMAL: 2,
        INTEGER: 3,
        DATA: 4,
        TEXTO: 5
      }
    }
  },
  methods: {
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        numeroAtendimento: objectSave.numeroAtendimento,
        dataAtendimento: objectSave.dataAtendimento,
        observacao: objectSave.observacao,
        situacao: objectSave.situacao.id,
        dataCancelamento: objectSave.dataCancelamento,
        profissional: { id: objectSave.profissional.id },
        profissionalDescricao: objectSave.profissional.nome,
        especialidade: { id: objectSave.especialidade.id },
        especialidadeDescricao: objectSave.especialidade
          ? objectSave.especialidade.descricao
          : '',
        registro: objectSave.registro.id,
        solicitanteDescricao: objectSave.registro.nome,
        unidadeAtendimento: objectSave.unidadeAtendimento.id,
        convenio: objectSave.convenio.id,
        tipoAtendimento: objectSave.tipoAtendimento.id,
        tipoAtendimentoDescricao: objectSave.tipoAtendimento
          ? objectSave.tipoAtendimento.descricao
          : '',
        horaInicial: objectSave.horaInicial,
        horaAgenda: objectSave.horaAgenda,
        horaFinal: objectSave.horaFinal,
        campoAtendimento: objectSave.campoAtendimento
      }
      return obj
    },
    salvarCamposAdicionais() {
      this.loadingCampos = true
      let listaSalvarCampos = []
      this.listaGrupos.forEach((grupo) => {
        grupo.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            listaSalvarCampos.push({
              atendimento: this.$route.params.id,
              campoAdicional: campo.id,
              valor: campo.valor ? campo.valor : null
            })
          })
        })
      })
      this.$store
        .dispatch('atendimentoModule/editCamposAdicionais', listaSalvarCampos)
        .then((response) => {
          if (
            response.response &&
            response.response.data &&
            response.response.data.error
          ) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loadingCampos = false
            return
          }
          this.showMessageSuccess('Editar', 'Editado com sucesso!')
          this.cancelar()
        })
    },
    salvar() {
      const objSave = this.prepareForSave(this.atendimento)
      this.listaGrupos.forEach((procedimento) => {
        procedimento.campoAdicionais.forEach((campo) => {
          objSave.campoAtendimento.push({
            ativo: campo.ativo,
            descricao: campo.nome,
            grupo: campo.grupo,
            id: campo.id,
            nome: campo.nome,
            procedimento: procedimento.descricao
          })
        })
      })
      try {
        this.$store
          .dispatch('atendimentoModule/edit', objSave)
          .then((response) => {
            if (
              response.response &&
              response.response.data &&
              response.response.data.error
            ) {
              this.MensagemError('Editar', 'Falha ao editar!')
              return
            }
            this.showMessageSuccess('Editar', 'Editado com sucesso!')
            this.cancelar()
            this.loadCamposAdicionais()
          })
        return
      } catch (error) {
        this.MensagemError('Editar', 'Falha ao editar!')
      }
    },
    async editar() {
      this.disableForm = false
      this.aplicarEstiloDisabilitadoEmCampoData()
    },
    cancelar() {
      this.disableForm = true
      this.loadingCampos = false
      this.aplicarEstiloDisabilitadoEmCampoData()
    },
    loadListaGrupos(atendimento) {
      this.listaGrupos = []
      atendimento.atendimento.grupo_procedimento.forEach((grupo) => {
        if (grupo.id !== 0) {
          this.listaGrupos.push({
            id: grupo.id,
            grupo: grupo.nome ? grupo.nome : 'Padrão',
            procedimentos: grupo.procedimento
          })
        }
      })
      this.listaGrupos.forEach((item) => {
        item.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            campo.valor =
              campo.campo_atendimento.length > 0
                ? campo.campo_atendimento[0]?.valor
                : null
          })
        })
      })
    },
    montarAbas() {
      this.$store
        .dispatch('atendimentoModule/montarTela', this.$route.params.id)
        .then((response) => {
          this.loadListaGrupos(response.data)
        })
    },
    getGrupos() {
      this.loading = true
      this.montarAbas()
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
<style>
.teeth {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid lightblue;
}
.disabled {
  pointer-events: none;
  opacity: 0.5; /* Você pode adicionar um efeito visual para indicar que o editor está desabilitado */
}
</style>
