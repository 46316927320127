<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>

          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descrição"
              rules="required"
            >
              <b-form-group
                label="Descrição"
                label-for="v-description"
              >
                <b-form-input
                  id="v-description"
                  v-model="situacaoAtendimentoPlace.descricao"
                  :state="errors.length > 0 ? false:null"
                  placeholder="descrição"
                  data-cy="description"
                />
                <small
                  data-cy="v-description"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            v-if="situacaoAtendimentoPlace.id"
            cols="12"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="situacaoAtendimentoPlace.ativo"
                :checked="situacaoAtendimentoPlace.ativo"
                name="check-button"
                switch
                inline
              >
                {{ situacaoAtendimentoPlace.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              data-cy="save"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              data-cy="cancel"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('situacaoAtendimentoSettingsModule', {
      situacaoAtendimento: state => state.situacaoAtendimento,
    }),
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      titleForm: 'Cadastro de Situação de Atendimento',
      required,
      situacaoAtendimentoPlace: {
        descricao: '',
        ativo: true,
      },
    }
  },
  mounted() {
    this.situacaoAtendimentoPlace = JSON.parse(JSON.stringify(this.situacaoAtendimento))
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('situacaoAtendimentoSettingsModule/resetSituacaoAtendimento')
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.situacaoAtendimentoPlace.id) {
            this.$store.dispatch('situacaoAtendimentoSettingsModule/editSituacaoAtendimento', this.situacaoAtendimentoPlace).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.data.error)
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          } else {
            this.$store.dispatch('situacaoAtendimentoSettingsModule/insertNewSituacaoAtendimento', this.situacaoAtendimentoPlace).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Inserir', response.response.data.error)
                return
              }
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.cleanObjeto()
              this.$refs.form.reset()
            })
          }
        }
      })
    },
    cleanObjeto() {
      this.situacaoAtendimentoPlace = {
        descricao: '',
        ativo: true,
      }
    },
  },
}
</script>
