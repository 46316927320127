export default {
  listaConvenios: [],
  convenio: {
    id: null,
    descricao: '',
    dataInicio: '',
    dataTermino: '',
    ativo: true,
    responavelNome: '',
    responavelCpf: '',
    responavelRg: '',
    responavelQualificacao: '',
    responavelEstadoCivil: {
      id: null,
      nome: null,
    },
    responavelNacionalidade: {
      id: null,
      nome: null,
    },
    responavelProfissao: '',
    responavelCargo: '',
    responavelTecnico: '',
    registro: {
      id: null,
    },
    percentualDesconto: 0,
    tipoProtocolo: null,
    itemProtocolo: []
  },
}
