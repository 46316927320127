import naturalnessServices from '@/api/administrative/naturalnessServices'

export default {
  loadTable({ commit }, pageConfig) {
    return naturalnessServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_NATURALNESS', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewNaturalness({ commit }, data) {
    return naturalnessServices.insertNewNaturalness(data).then(result => result).catch(error => error)
  },
  prepareToEditNaturalness({ commit }, data) {
    commit('PREPARE_TO_EDIT_NATURALNESS', data)
  },
  editNaturalness({ commit }, data) {
    return naturalnessServices.editNaturalness(data).then(result => result).catch(error => error)
  },
  resetNaturalness({ commit }) {
    commit('RESET_NATURALNESS')
  },
  delete({ commit }, id) {
    return naturalnessServices.deleteNaturalness(id).then(result => result).catch(error => error)
  },
}
