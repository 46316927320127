import genderServices from '@/api/administrative/genderServices'

export default {
  loadTable({ commit }, pageConfig) {
    return genderServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_GENDERS', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewGender({ commit }, data) {
    return genderServices.insertNewGender(data).then(result => result).catch(error => error)
  },
  prepareToEditGender({ commit }, data) {
    commit('PREPARE_TO_EDIT_GENDER', data)
  },
  editGender({ commit }, data) {
    return genderServices.editGender(data).then(result => result).catch(error => error)
  },
  resetGender({ commit }) {
    commit('RESET_GENDER')
  },
  delete({ commit }, id) {
    return genderServices.deleteGender(id).then(result => result).catch(error => error)
  },
}
