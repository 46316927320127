export default [
  {
    path: '/modules/protocol',
    redirect: '/modules/protocol/protocol',
    name: 'protocol',
    component: () => import('@/views/modules/protocol/Protocol.vue'),
    meta: {
      action: 'ROLE_PROT_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Protocolo',
      breadcrumb: [
        {
          text: 'protocolo'
        }
      ]
    },
    children: [
      {
        path: 'protocol',
        name: 'protocol',
        component: () =>
          import('@/views/modules/protocol/grid/ProtocolGrid.vue'),
        meta: {
          pageTitle: 'Protocolos',
          action: 'ROLE_PROT_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Protocolo | Protocolos'
            }
          ]
        }
      }
    ]
  }
]
