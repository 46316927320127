export default {
  LOAD_LIST_FONES(state, data) {
    state.listaFones = data
  },
  PREPARE_TO_EDIT_FONE(state, data) {
    state.fone = data
  },
  RESET_FONE(state) {
    state.fone = {
      descricao: '',
      numero: '',
      tipo: null,
      principal: false,
      whatsapp: false,
      ativo: true,
      registro: {
        id: null,
        nome: '',
      },
    }
  },
  SET_LIST_COMBO_TIPO_FONE(state, data) {
    state.comboTipoFone = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
}
