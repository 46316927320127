import api from '@/api/attendance/grupoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      api
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insert({ commit }, data) {
    return api
      .insert(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return api
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  reset({ commit }) {
    commit('RESET')
  },
  delete({ commit }, id) {
    return api
      .delete(id)
      .then((result) => result)
      .catch((error) => error)
  }
}
