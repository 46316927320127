import functionsServices from '@/api/administrative/functionsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return functionsServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_FUNCTIONS', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewFunction({ commit }, data) {
    return functionsServices.insertNewFunction(data).then(result => result).catch(error => error)
  },
  prepareToEditFunction({ commit }, data) {
    commit('PREPARE_TO_EDIT_FUNCTION', data)
  },
  editFunction({ commit }, data) {
    return functionsServices.editFunction(data).then(result => result).catch(error => error)
  },
  resetFunction({ commit }) {
    commit('RESET_FUNCTION')
  },
  delete({ commit }, id) {
    return functionsServices.deleteFunction(id).then(result => result).catch(error => error)
  },
}
