export default {
  listItemProtocol: [],
  itemProtocol: {
    protocolo: {
      id: null
    },
    item: {
      id: null
    },
    entregue: false
  },
}
