export default [
  {
    path: '/settings/financial',
    redirect: '/settings/financial/banksSettings',
    name: 'financialSettings',
    component: () => import('@/views/settings/financial/FinancialSettings.vue'),
    meta: {
      action: 'ROLE_FINAN_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Financeiro',
      breadcrumb: [
        {
          text: 'Cadastros Módulo Financeiro'
        }
      ]
    },
    children: [
      {
        path: 'banksSettings',
        name: 'banksSettings',
        component: () => import('@/views/settings/financial/BankSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Bancos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Bancos'
            }
          ]
        }
      },
      {
        path: 'banksAccountSettings',
        name: 'banksAccountSettings',
        component: () =>
          import('@/views/settings/financial/BankAccountSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Contas',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Contas'
            }
          ]
        }
      },
      {
        path: 'accountTypeSettings',
        name: 'accountTypeSettings',
        component: () =>
          import('@/views/settings/financial/AccountTypeSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Tipos de Contas',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Tipos de Contas'
            }
          ]
        }
      },
      {
        path: 'accountPlanSettings',
        name: 'accountPlanSettings',
        component: () =>
          import('@/views/settings/financial/AccountPlanSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Plano de Contas',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Plano de Contas'
            }
          ]
        }
      },
      {
        path: 'typeOfPaymentReceiptSettings',
        name: 'typeOfPaymentReceiptSettings',
        component: () =>
          import('@/views/settings/financial/TypeOfPaymentReceiptSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Tipos de Pagamentos / Recebimentos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Tipos de Pagamentos / Recebimentos'
            }
          ]
        }
      },
      {
        path: 'paymentMethodsSettings',
        name: 'paymentMethodsSettings',
        component: () =>
          import('@/views/settings/financial/PaymentMethodsSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Formas de Pagamentos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Formas de Pagamentos'
            }
          ]
        }
      },
      {
        path: 'contributionRange',
        name: 'contributionRange',
        component: () =>
          import('@/views/settings/financial/ContributionRangeSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Formas de Pagamentos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Formas de Pagamentos'
            }
          ]
        }
      },
      {
        path: 'paremater',
        name: 'paremater',
        component: () =>
          import('@/views/settings/financial/ParameterSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Parametros',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Parametros'
            }
          ]
        }
      },
      {
        path: 'taxaSettings',
        name: 'taxaSettings',
        component: () => import('@/views/settings/financial/TaxaSetting.vue'),
        meta: {
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Taxas',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Taxas'
            }
          ]
        }
      }
    ]
  }
]
