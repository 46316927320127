<template>
  <div>
    <b-card v-if="showForm">
      <validation-observer ref="form">
        <b-form @submit.prevent>
          <b-row>
            <!-- first name -->
            <b-col cols="12" lg="4">
              <validation-provider
                #default="{ errors }"
                name="nº protocolo"
                rules="required"
              >
                <b-form-group label="Nº protocolo" label-for="v-numProtocolo">
                  <b-form-input
                    id="v-numProtocolo"
                    v-model="form.numeroProtocolo"
                    :state="errors.length > 0 ? false : null"
                    placeholder="descrição"
                  />
                  <small
                    data-cy="v-numProtocolo-validate"
                    class="text-danger"
                    >{{ errors[0] }}</small
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12" lg="8">
              <b-form-group label="Nome" label-for="v-nome">
                <b-form-input
                  id="v-nome"
                  v-model="nomeRegistroEncontrado"
                  placeholder="nome registro"
                  disabled
                />
              </b-form-group>
            </b-col>

            <!-- Data Adesao -->
            <b-col cols="12" lg="6">
              <b-form-group label="Data Protocolo" label-for="v-dataProtocolo">
                <flat-pickr
                  v-model="form.dataProtocolo"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- Data dataVencimento -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="Data Vencimento"
                label-for="v-dataVencimento"
              >
                <flat-pickr
                  v-model="form.dataVencimento"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  }"
                />
              </b-form-group>
            </b-col>

            <!--Nacionalidade -->
            <b-col cols="12" lg="6">
              <b-form-group label="Tipo Protocolo" label-for="v-tipo">
                <v-select
                  v-model="form.tipo"
                  label="descricao"
                  :options="comboTipoProtocolo"
                />
              </b-form-group>
            </b-col>

            <!--Nacionalidade -->
            <b-col cols="12" lg="6">
              <b-form-group label="Meio Protocolo" label-for="v-meio">
                <v-select
                  v-model="form.meio"
                  label="descricao"
                  :options="comboMeioProtocolo"
                />
              </b-form-group>
            </b-col>

            <b-col v-if="form.id" cols="12">
              <b-form-group label="Ativo" label-for="v-check-button">
                <b-form-checkbox
                  v-model="form.ativo"
                  :checked="form.ativo"
                  name="check-button"
                  switch
                  inline
                >
                  {{ form.ativo ? 'Sim' : 'Não' }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                data-cy="save"
                @click="save"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                data-cy="cancel"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button
          v-if="verificarPermissao('ROLE_CORE_INSERIR')"
          class="mr-1"
          variant="primary"
          @click="add()"
        >
          Incluir
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="listProtocol"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'ativo'">
            <span>
              {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="edit(props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="verificarPermissao('ROLE_CORE_DELETAR')"
                @click="remove(props.row)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Mostrando 1 a </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import mixinsGlobal from '@/mixins'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('associantesAdministrativeModule', {
      fullAssociante: (state) => state.fullAssociante
    }),
    ...mapState('protocolSettingsModule', {
      protocol: (state) => state.protocol
    }),
    ...mapState('combosModule', {
      comboTipoProtocolo: (state) => state.comboTipoProtocolo,
      comboMeioProtocolo: (state) => state.comboMeioProtocolo
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  async mounted() {
    await this.$store.dispatch('combosModule/loadComboTipoProtocolo')
    await this.$store.dispatch('combosModule/loadComboMeioProtocolo')
    this.nomeRegistroEncontrado = ''
    this.form = JSON.parse(JSON.stringify(this.protocol))
    this.loadList()
  },
  data() {
    return {
      cpf: '',
      nomeRegistroEncontrado: '',
      form: {
        numeroProtocolo: '',
        dataProtocolo: null,
        dataVencimento: null,
        tipo: null,
        meio: null,
        registro: null,
        ativo: true
      },
      showForm: false,
      listProtocol: [],
      columns: [
        {
          label: 'Id',
          field: 'id'
        },
        {
          label: 'Nº Protocolo',
          field: 'numeroProtocolo'
        },
        {
          label: 'Nome',
          field: 'registro.nome'
        },
        {
          label: 'Data Protocolo',
          field: 'dataProtocolo',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Tipo',
          field: 'tipo.descricao'
        },
        {
          label: 'Meio Protocolo',
          field: 'meio.descricao'
        },
        {
          label: 'Ativo',
          field: 'ativo'
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      pageLength: 10,
      dir: false
    }
  },
  methods: {
    loadList() {
      this.$store
        .dispatch(
          'protocolSettingsModule/getProtocolByIdRegister',
          this.$route.params.id
        )
        .then((response) => {
          this.listProtocol = response.data.content
        })
    },
    edit(data) {
      this.form = data
      this.form.registro = { id: this.fullAssociante.id }
      this.nomeRegistroEncontrado = this.fullAssociante.nome
      this.showForm = true
    },
    add() {
      this.cancel()
      this.form = JSON.parse(JSON.stringify(this.protocol))
      this.form.registro = { id: this.fullAssociante.id }
      this.nomeRegistroEncontrado = this.fullAssociante.nome
      this.showForm = true
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            this.$store
              .dispatch('protocolSettingsModule/edit', this.form)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
                this.loadList()
              })
          } else {
            this.$store
              .dispatch('protocolSettingsModule/insert', this.form)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cancel()
                this.loadList()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cancel() {
      this.cpf = ''
      this.nomeRegistroEncontrado = ''
      this.showForm = false
      this.form = {
        numeroProtocolo: '',
        dataProtocolo: null,
        dataVencimento: null,
        tipo: null,
        meio: null,
        registro: { id: this.fullAssociante.id },
        ativo: true
      }
    },
    async remove(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('protocolSettingsModule/delete', data.id)
            .then((response) => {
              if (response.data.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.data.message
                )
                return
              }
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.loadList()
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.loadList()
            })
        }
      })
    }
  }
}
</script>
