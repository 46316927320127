export default [
  {
    path: '/modules/financial/pagamento',
    redirect: '/modules/financial/pagamento',
    name: 'pagamento',
    component: () =>
      import('@/views/modules/financial/pagamento/Pagamento.vue'),
    meta: {
      action: 'ROLE_FINAN_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Contas a Pagar',
      breadcrumb: [
        {
          text: 'Financeiro'
        }
      ]
    },
    children: [
      {
        path: 'pagamento',
        name: 'pagamento',
        component: () =>
          import('@/views/modules/financial/pagamento/grid/PagamentoGrid.vue'),
        meta: {
          pageTitle: 'Contas a Pagar',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Financeiro | Contas a Pagar'
            }
          ]
        }
      },
      {
        path: 'editPaymentAccount/:id',
        name: 'editPaymentAccount',
        component: () =>
          import('@/views/modules/financial/pagamento/form/PagamentoForm.vue'),
        meta: {
          pageTitle: 'Editando Contas a Pagar',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Modulo Financeiro | Editando Contas a Pagar'
            }
          ]
        }
      }
    ]
  }
]
