var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"v-nome"}},[_c('b-form-input',{attrs:{"id":"v-nome","state":errors.length > 0 ? false : null,"placeholder":"nome","data-cy":"nome","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-nome"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Percentual de Juros","label-for":"v-percentual"}},[_c('b-form-input',{attrs:{"id":"v-percentual","placeholder":"percentual","data-cy":"percentual","type":"number","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.percentualJuros),callback:function ($$v) {_vm.$set(_vm.form, "percentualJuros", $$v)},expression:"form.percentualJuros"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Adesão","label-for":"v-adesao"}},[_c('b-form-input',{attrs:{"id":"v-adesao","placeholder":"adesao","data-cy":"adesao","type":"number","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.adesao),callback:function ($$v) {_vm.$set(_vm.form, "adesao", $$v)},expression:"form.adesao"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Margem","label-for":"v-margem"}},[_c('b-form-input',{attrs:{"id":"v-margem","placeholder":"margem","data-cy":"margem","type":"number","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.margem),callback:function ($$v) {_vm.$set(_vm.form, "margem", $$v)},expression:"form.margem"}})],1)],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ativo ?"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }