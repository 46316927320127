export default {
  listaCamposAdicionais: [],
  campoAdicional: {
    nome: '',
    descricao: '',
    procedimento: {
      id: null
    },
    ativo: true
  }
}
