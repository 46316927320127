export default {
  listaProfissoes: [],
  profissao: {
    ativo: true,
    cbo: '',
    id: 0,
    nome: '',
    originalIndex: 0,
    vgt_id: 0,
  },
}
