import profissionalService from '@/api/attendance/profissionalServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      profissionalService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_PROFISSIONAL', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNew({ commit }, data) {
    return profissionalService
      .insertNew(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetObject({ commit }) {
    commit('RESET_PROFISSIONAL')
  },
  prepareToEditProfissional({ commit }, data) {
    commit('PREPARE_TO_EDIT_PROFISSIONAL', data)
  },
  edit({ commit }, data) {
    return profissionalService
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return profissionalService
      .delete(id)
      .then((result) => result)
      .catch((error) => error)
  },
  async searchProfessional({ commit }, search) {
    try {
      const result = await profissionalService.searchProfessional(search)
      return result
    } catch (error) {
      return error
    }
  },
  getById({ commit }, id) {
    return profissionalService
      .getById(id)
      .then((result) => result)
      .catch((error) => error)
  }
}
