var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"v-nome"}},[_c('b-form-input',{attrs:{"id":"v-nome","state":errors.length > 0 ? false : null,"placeholder":"nome","data-cy":"nome","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.nome),callback:function ($$v) {_vm.$set(_vm.form, "nome", $$v)},expression:"form.nome"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-nome"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('validation-provider',{attrs:{"name":"Procedimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Procedimento","label-for":"v-procedimento"}},[_c('v-select',{attrs:{"reduce":function (comboProcedimentos) { return comboProcedimentos.id; },"label":"nome","options":_vm.comboProcedimentos,"state":errors.length > 0 ? false : null,"data-cy":"tipo","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.procedimento.id),callback:function ($$v) {_vm.$set(_vm.form.procedimento, "id", $$v)},expression:"form.procedimento.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-procedimento"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"12"}},[_c('validation-provider',{attrs:{"name":"Tipo Campo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo Campo","label-for":"v-tipo"}},[_c('v-select',{attrs:{"options":_vm.comboTipoCampo,"reduce":function (comboProcedimentos) { return comboProcedimentos.value; },"state":errors.length > 0 ? false : null,"data-cy":"tipo","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-tipo"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }