<template>
  <div>
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <fieldset :disabled="disableForm">
          <b-row>
            <!-- matricula -->
            <b-col cols="12" lg="4">
              <b-form-group label="Matrícula" label-for="v-matricula">
                <b-form-input
                  id="v-matricula"
                  v-model="associanteInforPersonal.matricula"
                  placeholder="matricula"
                />
              </b-form-group>
            </b-col>

            <!-- nome -->
            <b-col cols="12" lg="8">
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome" label-for="v-nome">
                  <b-form-input
                    id="v-nome"
                    v-model="associanteInforPersonal.nome"
                    :state="errors.length > 0 ? false : null"
                    placeholder="nome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Genero -->
            <b-col ols="12" lg="6">
              <b-form-group label="Gênero" label-for="v-genero">
                <v-select
                  v-model="associanteInforPersonal.sexo"
                  :disabled="disableForm"
                  :reduce="(comboGeneros) => comboGeneros.id"
                  label="nome"
                  :options="comboGeneros"
                />
              </b-form-group>
            </b-col>

            <!-- Data Nascimento -->
            <b-col cols="12" lg="6">
              <b-form-group label="Data Nascimento" label-for="v-genero">
                <flat-pickr
                  v-model="associanteInforPersonal.dataNascimento"
                  name="fieldDate"
                  class="form-control"
                  :readonly="disableForm"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- Estado Civil -->
            <b-col cols="12" lg="6">
              <b-form-group label="Estado Civil" label-for="v-estadoCivil">
                <v-select
                  v-model="associanteInforPersonal.estadoCivil"
                  :disabled="disableForm"
                  label="nome"
                  :options="comboEstadoCivil"
                />
              </b-form-group>
            </b-col>

            <!--Profissao -->
            <b-col cols="12" lg="6">
              <b-form-group label="Profissão" label-for="v-profissao">
                <v-select
                  v-model="associanteInforPersonal.profissao"
                  :disabled="disableForm"
                  label="nome"
                  :options="comboProfissoes"
                />
              </b-form-group>
            </b-col>

            <!-- nomeMae -->
            <b-col cols="12" lg="12">
              <b-form-group label="Nome da Mãe" label-for="v-nomeMae">
                <b-form-input
                  id="v-nomeMae"
                  v-model="associanteInforPersonal.nomeMae"
                  placeholder="nome da mãe"
                />
              </b-form-group>
            </b-col>
            <!-- Nome Pai -->
            <b-col cols="12" lg="12">
              <b-form-group label="Nome do Pai" label-for="v-nomePai">
                <b-form-input
                  id="v-nomePai"
                  v-model="associanteInforPersonal.nomePai"
                  placeholder="nome do pai"
                />
              </b-form-group>
            </b-col>

            <!-- Nome Social -->
            <b-col cols="12" lg="12">
              <b-form-group label="Nome Social" label-for="v-nomeSocial">
                <b-form-input
                  id="v-nomeSocial"
                  v-model="associanteInforPersonal.nomeSocial"
                  placeholder="nome social"
                />
              </b-form-group>
            </b-col>

            <!-- email -->
            <b-col cols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-group label="E-mail" label-for="v-email">
                  <b-form-input
                    id="v-email"
                    v-model="associanteInforPersonal.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--Naturalidade -->
            <b-col cols="12" lg="6">
              <b-form-group label="Naturalidade" label-for="v-naturalidade">
                <v-select
                  v-model="associanteInforPersonal.naturalidade"
                  :disabled="disableForm"
                  label="nome"
                  :options="comboNaturalidades"
                />
              </b-form-group>
            </b-col>

            <!--Nacionalidade -->
            <b-col cols="12" lg="6">
              <b-form-group label="Nacionalidade" label-for="v-nacionalidade">
                <v-select
                  v-model="associanteInforPersonal.nacionalidade"
                  :disabled="disableForm"
                  label="nome"
                  :options="comboNacionalidades"
                />
              </b-form-group>
            </b-col>

            <!-- Data Adesao -->
            <b-col cols="12" lg="12">
              <b-form-group label="Data Adesão" label-for="v-dataAdesao">
                <flat-pickr
                  v-model="associanteInforPersonal.dataAdesao"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                  }"
                />
              </b-form-group>
            </b-col>

            <!-- first name -->
            <b-col cols="12">
              <label for="textarea-default">Observações</label>
              <b-form-textarea
                id="textarea-default"
                v-model="associanteInforPersonal.observacao"
                placeholder="Textarea"
                rows="3"
              />
            </b-col>
          </b-row>
        </fieldset>
        <b-row class="mt-1">
          <!-- submit and reset -->
          <b-col v-if="disableForm" cols="12">
            <b-button
              v-if="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="edit"
            >
              Editar
            </b-button>
          </b-col>
          <b-col v-else cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  watch: {
    disableForm() {
      this.aplicarEstiloDisabilitadoEmCampoData()
    }
  },
  computed: {
    ...mapState('associantesAdministrativeModule', {
      fullAssociante: (state) => state.fullAssociante,
      comboGeneros: (state) => state.comboGeneros,
      comboEstadoCivil: (state) => state.comboEstadoCivil,
      comboProfissoes: (state) => state.comboProfissoes,
      comboNaturalidades: (state) => state.comboNaturalidades,
      comboNacionalidades: (state) => state.comboNacionalidades,
      comboTypesRegister: (state) => state.comboTypesRegister
    })
  },
  data() {
    return {
      required,
      associanteInforPersonal: {
        id: null,
        matricula: '',
        nome: '',
        email: '',
        nomeMae: '',
        nomePai: '',
        dataNascimento: '',
        situacaoRegistro: '',
        sexo: null,
        estadoCivil: null,
        profissao: null,
        naturalidade: null,
        nacionalidade: null,
        tipoRegistro: null,
        dataAdesao: null,
        observacao: '',
        nomeSocial: ''
      },
      disableForm: true
    }
  },
  mounted() {
    this.loadDadosPerssoais(this.fullAssociante)
    this.aplicarEstiloDisabilitadoEmCampoData()
  },
  methods: {
    loadDadosPerssoais(data) {
      this.associanteInforPersonal = {
        id: data.id,
        matricula: data.matricula,
        nome: data.nome,
        nomeMae: data.nomeMae,
        nomePai: data.nomePai,
        dataNascimento: `${data.dataNascimento}00:00:01`,
        email: data.email,
        sexo: data.sexo.id,
        estadoCivil: data.estadoCivil,
        profissao: data.profissao,
        naturalidade: data.naturalidade,
        nacionalidade: data.nacionalidade,
        tipoRegistro: data.tipoRegistro,
        situacaoRegistro: data.situacaoRegistro,
        dataAdesao: `${data.dataAdesao}00:00:01`,
        observacao: data.observacao,
        nomeSocial: data.nomeSocial
      }
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const data = JSON.parse(JSON.stringify(this.associanteInforPersonal))
          data.estadoCivil = data.estadoCivil.id
          data.situacaoRegistro = data.situacaoRegistro.id
          data.naturalidade = data.naturalidade.id
          data.nacionalidade = data.nacionalidade.id
          data.profissao = data.profissao.id
          data.tipoRegistro = data.tipoRegistro.id
          this.$store
            .dispatch(
              'associantesAdministrativeModule/editAssocianteDadosPessoais',
              data
            )
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  'Falha ao Editar',
                  response.response.data.error
                )
                return
              }
              this.$store
                .dispatch(
                  'associantesAdministrativeModule/prepareToEditFullAssociante',
                  response.data
                )
                .then(() => {
                  this.showMessageSuccess('Editar', 'Editado com sucesso!')
                  this.$refs.form.reset()
                  this.cancel()
                  this.loadDadosPerssoais(response.data)
                })
            })
        }
      })
    },
    edit() {
      this.disableForm = false
    },
    cancel() {
      this.disableForm = true
      this.loadDadosPerssoais(this.fullAssociante)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
