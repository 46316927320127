export default {
  LOAD_LIST_TYPES_OF_PAYMENTS_RECEIPT(state, data) {
    state.listTypesOfPaymentReceipts = data
  },
  PREPARE_TO_EDIT_TYPE_OF_PAYMENT_RECEIPT(state, data) {
    state.typeOfPaymentReceipt = data
  },
  RESET_TYPE_OF_PAYMENT_RECEIPT(state) {
    state.typeOfPaymentReceipt = {
      descricao: '',
      conta: {
        id: null,
      },
      ativo: true,
    }
  },
  SET_LIST_COMBO_CONTAS(state, data) {
    state.comboConta = data.map(item => ({
      id: item.id,
      classe: item.descricao,
    }))
  },
}
