export default {
  LOAD_LIST_CONVENIO(state, data) {
    state.listaConvenios = data
  },
  PREPARE_TO_EDIT_CONVENIO(state, data) {
    state.convenio = data
  },
  RESET_CONVENIO(state) {
    state.convenio = {
      id: null,
      descricao: '',
      dataInicio: '',
      dataTermino: '',
      ativo: true,
    }
  },
}
