export default [
  {
    path: '/settings/profile/profile',
    name: 'profileSettings',
    component: () => import('@/views/settings/profile/ProfileSettings.vue'),
    meta: {
      action: 'ROLE_PERFIL_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Perfils',
      breadcrumb: [
        {
          text: 'Configurações De Perfils'
        }
      ]
    }
  }
]
