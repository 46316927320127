<template>
  <div
    v-if="loading"
    class="clearfix"
  >
    <b-spinner
      class="float-right"
      label="Floated Right"
    />
  </div>
  <div v-else>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <app-collapse
          type="margin"
        >

          <app-collapse-item title="Informações da Empresa">
            <FormPersonalInfoVue />
          </app-collapse-item>

          <app-collapse-item title="Endereços">
            <FormPersonalEnderecosVue />
          </app-collapse-item>

          <app-collapse-item title="Fones">
            <FormFornecedorFoneVue />
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FormPersonalInfoVue from './FormFornecedorPersonalInfo.vue'
import FormPersonalEnderecosVue from './FormFornecedorEnderecos.vue'
import FormFornecedorFoneVue from './FormFornecedorFone.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    FormPersonalInfoVue,
    FormPersonalEnderecosVue,
    FormFornecedorFoneVue,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapseType: 'default',
    }
  },
}
</script>
