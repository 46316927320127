export default {
  LOAD_LIST_FORNECEDOR(state, data) {
    state.listaFornecedores = data
  },
  PREPARE_TO_EDIT_FORNECEDOR(state, data) {
    state.fullFornecedor = data
  },
  RESET_FORNECEDOR(state) {
    state.scaffold = {
      nome: '',
      cnpj: '',
      email: '',
      tipoRegistro: null,
    }
  },
  SET_LIST_COMBO_TYPES_REGISTER(state, data) {
    state.comboTypesRegister = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
}
