import sectorServices from '@/api/administrative/sectorServices'

export default {
  loadTable({ commit }, pageConfig) {
    return sectorServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_SECTOR', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewSector({ commit }, data) {
    return sectorServices.insertNewSector(data).then(result => result).catch(error => error)
  },
  prepareToEditSector({ commit }, data) {
    commit('PREPARE_TO_EDIT_SECTOR', data)
  },
  editSector({ commit }, data) {
    return sectorServices.editSector(data).then(result => result).catch(error => error)
  },
  resetSector({ commit }) {
    commit('RESET_SECTOR')
  },
  delete({ commit }, id) {
    return sectorServices.deleteSector(id).then(result => result).catch(error => error)
  },
}
