export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth'
  },
  {
    action: 'whiter',
    subject: 'Auth'
  },
  // ADMINISTRATIVO
  {
    action: 'ROLE_ADMIM_ATUALIZAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ADMIM_CONSULTAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ADMIM_DELETAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ADMIM_INSERIR',
    subject: 'ACL'
  },
  // ATENDIMENTO
  {
    action: 'ROLE_ATEND_ATUALIZAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ATEND_CONSULTAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ATEND_DELETAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_ATEND_INSERIR',
    subject: 'ACL'
  },
  // FINANCEIRO
  {
    action: 'ROLE_FINAN_ATUALIZAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_FINAN_CONSULTAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_FINAN_DELETAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_FINAN_INSERIR',
    subject: 'ACL'
  },
  // USUARIOS
  {
    action: 'ROLE_USER_ATUALIZAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_USER_CONSULTAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_USER_DELETAR',
    subject: 'ACL'
  },
  {
    action: 'ROLE_USER_INSERIR',
    subject: 'ACL'
  },
  // CORE
  {
    subject: 'ACL',
    authority: 'ROLE_CORE_ATUALIZAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_CORE_CONSULTAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_CORE_DELETAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_CORE_INSERIR'
  },
  // DOWNLOAD
  {
    subject: 'ACL',
    authority: 'ROLE_DOWNLOAD'
  },
  // NEOS
  {
    subject: 'ACL',
    authority: 'ROLE_NEOS'
  },
  // PERFIL
  {
    subject: 'ACL',
    authority: 'ROLE_PERFIL_ATUALIZAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_PERFIL_CONSULTAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_PERFIL_DELETAR'
  },
  {
    subject: 'ACL',
    authority: 'ROLE_PERFIL_INSERIR'
  },
  // PROFISSIONAL
  {
    subject: 'ACL',
    authority: 'ROLE_PROFISSIONAL'
  },
  // RELATORIO
  {
    subject: 'ACL',
    authority: 'ROLE_RELATORIO_CONSULTAR'
  }
]

export const _ = undefined
