import axios from '@axios'
import axiosViaCEp from 'axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/funcionarios${queryUrl}`)
  },
  insertNewEmployee(data) {
    return axios.post('/administrativo/funcionarios', data)
  },
  editEmployee(data) {
    return axios.put(`/administrativo/funcionarios/${data.id}`, data)
  },
  deleteEmployee(id) {
    return axios.put(`/administrativo/funcionarios/${id}`)
  },
  getEmployeeID(id) {
    return axios.get(`/administrativo/funcionarios/${id}`)
  },
  loadCombo() {
    return axios.get('/core/tipos_registros/lista')
  },
  loadComboGenero() {
    return axios.get('/core/sexos/lista')
  },
  loadComboEstadoCivil() {
    return axios.get('/core/estatos_civis/lista')
  },
  loadComboProfissoes() {
    return axios.get('/core/profissoes/lista')
  },
  loadComboNaturalidades() {
    return axios.get('/core/naturalidades/lista')
  },
  loadComboNacionalidades() {
    return axios.get('/core/nacionalidades/lista')
  },
  loadComboUfs() {
    return axios.get('/core/ufs/lista')
  },
  loadComboPrevidenciaSocial() {
    return axios.get('/core/previdencias_sociais/lista')
  },
  loadComboTipoEndereco() {
    return axios.get('/core/tipo_enderecos/lista')
  },
  editEmployeeDadosPessoais(data) {
    return axios.put(
      `/administrativo/funcionarios/dados_pessoais/${data.id}`,
      data
    )
  },
  editEmployeeDocumentation(data) {
    return axios.put(
      `/administrativo/funcionarios/documentacao/${data.id}`,
      data
    )
  },
  getEnderecoViaCep(cep) {
    return axiosViaCEp({
      method: 'get',
      url: `https://viacep.com.br/ws/${cep}/json/`
    })
  },
  inserirNovoEndereco(data) {
    return axios.post('/core/enderecos/', data)
  },
  editEndereco(data) {
    return axios.put(`/core/enderecos/${data.id}`, data)
  },
  loadComboFuncoes() {
    return axios.get('/administrativo/funcoes?page=0&size=1000&sort=nome,desc')
  },
  loadComboSetores() {
    return axios.get('/administrativo/setores?page=0&size=1000&sort=nome,desc')
  },
  loadComboSituacaoRegistro() {
    return axios.get('/core/situacoes_registros/lista')
  },
  setHistoricoSituacao(data) {
    return axios.post('/core/historico_situacao', data)
  },
  getHistoricoSituacao(id) {
    return axios.get(`/core/historico_situacao/${id}`)
  },
  editEmployeeDadosFuncionario(data) {
    return axios.put(`/administrativo/funcionarios/dados/${data.id}`, data)
  },
  sendNewPhoto(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/fotos/${object.id}`, dataForm)
  },
  deletePhoto(id) {
    return axios.get(`/administrativo/fotos/${id}`)
  },
  loadEnderecosPorRegistro(id) {
    return axios.get(`/core/enderecos/registro/${id}`)
  },
  deleteEndereco(id) {
    return axios.delete(`/core/enderecos/${id}`)
  },
  getListDepedentEmployee(id) {
    return axios.get(`/administrativo/funcionarios/dependentes/${id}`)
  },
  downloadDocument(id) {
    return axios.get(`/administrativo/documentos/download/${id}`)
  },
  deleteDocument(id) {
    return axios.delete(`/administrativo/documentos/${id}`)
  },
  getHistoricoTipoRegistro(id) {
    return axios.get(`/core/historico_tipo/${id}`)
  },
  setMudarTipoRegistro(data) {
    return axios.post('/core/historico_tipo', data)
  },
  loadCards() {
    return axios.get('/administrativo/funcionarios/card_situacao')
  },
  getHistoricoFaixaContribuicao(id) {
    return axios.get(`/core/historico_contribuicao/${id}`)
  },
  setMudarFaixaContribuica(data) {
    return axios.post('/core/historico_contribuicao', data)
  },
  delete(id) {
    return axios.delete(`/administrativo/funcionarios/${id}`)
  }
}
