export default {
  LOAD_LIST_REGISTRATION_TYPE(state, data) {
    state.listRegistrationsTypes = data
  },
  PREPARE_TO_EDIT_REGISTRATION_TYPE(state, data) {
    state.registrationType = data
  },
  RESET_REGISTRATION_TYPE(state) {
    state.registrationType = {
      nome: '',
      valor: '',
      ativo: true,
      tipo: null,
    }
  },
  SET_LIST_COMBO_TYPE(state, data) {
    state.comboTypes = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
}
