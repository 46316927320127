export default [
  {
    path: '/settings/attendance',
    name: 'attendanceSettings',
    redirect: '/settings/attendance/conselhoSettings',
    component: () =>
      import('@/views/settings/attendance/AttendanceSettings.vue'),
    meta: {
      action: 'ROLE_ATEND_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Cadastro Atendimentos',
      breadcrumb: [
        {
          text: 'Atendimento'
        }
      ]
    },
    children: [
      {
        path: 'covenioSettings',
        name: 'covenioSettings',
        component: () =>
          import('@/views/settings/attendance/ConvenioSetting.vue'),
        meta: {
          pageTitle: 'Convênios',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Convênios'
            }
          ]
        }
      },
      {
        path: 'conselhoSettings',
        name: 'conselhoSettings',
        component: () =>
          import('@/views/settings/attendance/ConselhoSettings.vue'),
        meta: {
          pageTitle: 'Conselhos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Conselhos'
            }
          ]
        }
      },
      {
        path: 'especialidadeSettings',
        name: 'especialidadeSettings',
        component: () =>
          import('@/views/settings/attendance/EspecialidadeSetting.vue'),
        meta: {
          pageTitle: 'Especialidades',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Especialidades'
            }
          ]
        }
      },
      {
        path: 'exameSettings',
        name: 'exameSettings',
        component: () => import('@/views/settings/attendance/ExameSetting.vue'),
        meta: {
          pageTitle: 'Exames',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Exames'
            }
          ]
        }
      },
      {
        path: 'feriadoSettings',
        name: 'feriadoSettings',
        component: () =>
          import('@/views/settings/attendance/FeriadoSetting.vue'),
        meta: {
          pageTitle: 'Feriados',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Feriados'
            }
          ]
        }
      },
      {
        path: 'situacaoAtendimentoSettings',
        name: 'situacaoAtendimentoSettings',
        component: () =>
          import('@/views/settings/attendance/SituacaoAtendimentoSetting.vue'),
        meta: {
          pageTitle: 'Situação Atendimento',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Situação Atendimento'
            }
          ]
        }
      },
      {
        path: 'tipoAtendimentoSettings',
        name: 'tipoAtendimentoSettings',
        component: () =>
          import('@/views/settings/attendance/TipoAtendimentoSetting.vue'),
        meta: {
          pageTitle: 'Tipos Atendimento',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Tipos Atendimento'
            }
          ]
        }
      },
      {
        path: 'unidadeAtendimentoSettings',
        name: 'unidadeAtendimentoSettings',
        component: () =>
          import('@/views/settings/attendance/UnidadeAtendimentoSetting.vue'),
        meta: {
          pageTitle: 'Unidades Atendimento',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Unidades Atendimento'
            }
          ]
        }
      },
      {
        path: 'profissionalAtendimentoSettings',
        name: 'profissionalAtendimentoSettings',
        component: () =>
          import('@/views/settings/attendance/ProfissionalSetting.vue'),
        meta: {
          pageTitle: 'Profissionais',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Profissionais'
            }
          ]
        }
      },
      {
        path: 'atendimentosettings',
        name: 'atendimentosettings',
        component: () =>
          import('@/views/settings/attendance/ProfissionalSetting.vue'),
        meta: {
          pageTitle: 'Atendimentos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Atendimentos'
            }
          ]
        }
      },
      {
        path: 'doencasCidSettings',
        name: 'doencasCidSettings',
        component: () =>
          import('@/views/settings/attendance/DoencasCidSetting.vue'),
        meta: {
          pageTitle: 'Doenças CID',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Doenças CID'
            }
          ]
        }
      },
      {
        path: 'parematerAtedimento',
        name: 'parematerAtedimento',
        component: () =>
          import('@/views/settings/attendance/ParameterSetting.vue'),
        meta: {
          pageTitle: 'Parametros',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Parametros'
            }
          ]
        }
      },
      {
        path: 'camposAdicionaisSettings',
        name: 'camposAdicionaisSettings',
        component: () =>
          import('@/views/settings/attendance/CamposAdicionaisSetting.vue'),
        meta: {
          pageTitle: 'Campos Adicionais',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Campos Adicionais'
            }
          ]
        }
      },
      {
        path: 'procedimentoSettings',
        name: 'procedimentoSettings',
        component: () =>
          import('@/views/settings/attendance/ProcedimentosSetting.vue'),
        meta: {
          pageTitle: 'Procedimentos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Procedimentos'
            }
          ]
        }
      },
      {
        path: 'grupoSettings',
        name: 'grupoSettings',
        component: () =>
          import('@/views/settings/attendance/GruposSettings.vue'),
        meta: {
          pageTitle: 'Grupos',
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Atendimento | Grupos'
            }
          ]
        }
      }
    ]
  }
]
