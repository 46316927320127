var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"observacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Profissional","label-for":"v-profissional"}},[_c('v-select',{attrs:{"label":"nome","filterable":false,"options":_vm.comboProfissionals,"selected":_vm.form.registro,"disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.nome)+" ")])]}}],null,true),model:{value:(_vm.form.registro),callback:function ($$v) {_vm.$set(_vm.form, "registro", $$v)},expression:"form.registro"}},[_c('template',{slot:"no-options"},[_vm._v(" Pesquisa profissional por nome, cpf ou matrícula ")])],2),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-profissional"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Data Inicio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Inicio","label-for":"v-dataInicio"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                },"disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.dataInicio),callback:function ($$v) {_vm.$set(_vm.form, "dataInicio", $$v)},expression:"form.dataInicio"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-profissional"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Data Termino","label-for":"v-dataTermino"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.dataTermino),callback:function ($$v) {_vm.$set(_vm.form, "dataTermino", $$v)},expression:"form.dataTermino"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"Especialidades","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Especialidades","label-for":"v-especialidades"}},[_c('v-select',{attrs:{"label":"nome","options":_vm.comboEspecialidades,"state":errors.length > 0 ? false : null,"data-cy":"tipo","multiple":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.especialidades),callback:function ($$v) {_vm.$set(_vm.form, "especialidades", $$v)},expression:"form.especialidades"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-especialidades"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Conselho","label-for":"v-conselho"}},[_c('v-select',{attrs:{"label":"descricao","options":_vm.comboConselhos,"data-cy":"tipo","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.conselho),callback:function ($$v) {_vm.$set(_vm.form, "conselho", $$v)},expression:"form.conselho"}})],1)],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }