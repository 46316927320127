import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/convenio/convenios${queryUrl}`)
  },
  insert(data) {
    return axios.post('/convenio/convenios/', data)
  },
  edit(data) {
    return axios.put(`/convenio/convenios/${data.id}`, data)
  },
  delete(id) {
    return axios.put(`/convenio/convenios/${id}`)
  },
  getById(id) {
    return axios.get(`/convenio/convenios/${id}`)
  },
  searchRegistrosPessoaJuridica(name) {
    const endpoint = `/convenio/profissionais/registro?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  }
}
