import socialSecurityServices from '@/api/administrative/socialSecurityServices'

export default {
  loadTable({ commit }, pageConfig) {
    return socialSecurityServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_SOCIAL_SECURITY', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewSocialSecurity({ commit }, data) {
    return socialSecurityServices.insertNewSocialSecurity(data).then(result => result).catch(error => error)
  },
  prepareToEditSocialSecurity({ commit }, data) {
    commit('PREPARE_TO_EDIT_SOCIAL_SECURITY', data)
  },
  editSocialSecurity({ commit }, data) {
    return socialSecurityServices.editSocialSecurity(data).then(result => result).catch(error => error)
  },
  resetSocialSecurity({ commit }) {
    commit('RESET_SOCIAL_SECURITY')
  },
  delete({ commit }, id) {
    return socialSecurityServices.deleteSocialSecurity(id).then(result => result).catch(error => error)
  },
  loadComboSocialSecurityGroup({ commit }) {
    return new Promise((resolve, reject) => socialSecurityServices.loadComboSocialSecurityGroup().then(result => {
      commit('SET_LIST_COMBO_SOCIAL_SECURITY_GROUP', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
}
