export default {
  listaProcedimentos: [],
  procedimento: {
    id: null,
    descricao: '',
    especialidade: { id: null },
    grupo: { id: null },
    ativo: true,
    exame: false
  }
}
