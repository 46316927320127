export default {
  listItem: [],
  item: {
    descricao: '',
    tipo: {
      id: null
    },
    ativo: true
  },
}
