<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="(card, index) in cards"
        :key="index"
        :md="cards.lenght"
        :lg="cards.lenght"
        :cols="cards.lenght"
      >
        <statistic-card-with-line-chart
          icon="UserIcon"
          :statistic="card.valor"
          :statistic-title="card.descricao"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import StatisticCardWithLineChart from '@core/components/statistics-cards/StatisticCardWithLineChart.vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  name: 'Cards',
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    StatisticCardWithLineChart,
  },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
  },
}

</script>
