<template>
  <b-row>
    <div v-if="loading" class="clearfix">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listaUsers"
        sort-name="firstName"
        :title-table="$t('Users')"
        module="usersSettingAccessModuleModule"
        :show-btn-add="verificarPermissao('ROLE_USER_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_USER_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <form-user v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormUser from './forms/FormUser.vue'

export default {
  name: 'Users',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormUser,
    BSpinner
  },
  computed: {
    ...mapState('usersSettingAccessModuleModule', {
      listaUsers: (state) => state.listaUsers
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      loading: false,
      columns: [
        {
          label: this.$t('id'),
          field: 'id'
        },
        {
          label: 'Nome',
          field: 'firstName'
        },
        {
          label: 'Sobre Nome',
          field: 'lastName'
        },
        {
          label: 'email',
          field: 'email'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    async edit(data) {
      this.loading = true
      await this.$store.dispatch(
        'usersSettingAccessModuleModule/getById',
        data.id
      )
      this.showForm = true
      this.loading = false
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
