export default {
  LOAD_LIST_PARAMETER(state, data) {
    state.listParameters = data
  },
  PREPARE_TO_EDIT_PARAMETER(state, data) {
    state.parameter = data
  },
  RESET_PARAMETER(state) {
    state.parameter = {
      tipo: '',
      lista: true,
      nome: '',
      valor: '',
      comentario: '',
      ativo: true,
    }
  },
}
