export default {
  LOAD_LIST_FUNCTIONS(state, data) {
    state.listFunctions = data
  },
  PREPARE_TO_EDIT_FUNCTION(state, data) {
    state.function = data
  },
  RESET_FUNCTION(state) {
    state.function = {
      ativo: true,
      nome: '',
    }
  },
}
