<template>
  <b-row>
    <b-col cols="12">
      <div v-if="showGrid">
        <b-card title="Arquivos Neos">
          <b-button
            class="mb-3"
            variant="primary"
            @click="importarNovoArquivo()"
          >
            Importar arquivo
          </b-button>
          <b-button
            class="mb-3 ml-1"
            variant="success"
            @click="exportarNovoArquivo()"
          >
            Exportar arquivo
          </b-button>

          <b-button
            class="mb-3 ml-1"
            variant="outline-primary"
            @click="printReport('neos_emprestimos_mes')"
          >
            Relatório de Empréstimos

            <feather-icon
              icon="PrinterIcon"
              size="18"
            />
          </b-button>

          <b-button
            class="mb-3 ml-1"
            variant="outline-primary"
            @click="printReport('neos_agregados_mes')"
          >
            Relatório de Agregados
            <feather-icon
              icon="PrinterIcon"
              size="18"
            />
          </b-button>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Pesquisar</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="pesquisa"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <!-- Principal -->
          <vue-good-table
            mode="remote"
            :columns="columnsFiles"
            :rows="listaHistorico"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            @on-row-click="getFileByID"
            @on-sort-change="onSortChange"
          >
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="tableConfigs.size"
                    :options="[tableConfigs.size]"
                    class="mx-1"
                    @input="handleChangePage"
                  />
                  <span class="text-nowrap">
                    of {{ tableConfigs.totalPages }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="tableConfigs.totalElements"
                    :per-page="tableConfigs.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </div>
      <div v-else>
        <b-card
          v-if="!showForm"
          title="Arquivo de Retorno NEOS"
        >
          <validation-observer ref="form">
            <b-form @submit.prevent>
              <b-spinner
                v-if="loading"
                class="float-right"
                label="Floated Right"
              />
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  :md="tipo === 1 ? '6' : '12'"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo"
                    rules="required"
                  >
                    <b-form-group
                      label="Tipo"
                      label-for="v-tipo"
                    >
                      <v-select
                        v-model="tipo"
                        :reduce="(comboTipo) => comboTipo.id"
                        label="nome"
                        :options="comboTipo"
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                        @input="getEmprestimo"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!--  TIPO EMPRESTIMO -->
                <b-col
                  v-if="tipo === 1"
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de Empréstimo"
                    rules="required"
                  >
                    <b-form-group
                      label="Tipo de Empréstimo"
                      label-for="v-tipoPagamento"
                    >
                      <v-select
                        v-model="tipoEmprestimo"
                        :reduce="
                          (tipoFiltradoPorEmprestimos) =>
                            tipoFiltradoPorEmprestimos.id
                        "
                        label="nome"
                        :options="tipoFiltradoPorEmprestimos"
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- first name -->
                <b-col cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Arquivo"
                    rules="required"
                  >
                    <b-form-group
                      label="Arquivo"
                      label-for="v-file"
                    >
                      <b-form-file
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        :state="errors.length > 0 ? false : null"
                        :class="[errors.length > 0 ? isValid : '']"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- submit and reset -->
                <b-col
                  cols="12"
                  class="mt-3"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="importFile"
                  >
                    Importar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                    @click="cancelar"
                  >
                    Cancelar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
      <div v-if="showForm">
        <b-card title="Dados Importados">
          <b-spinner
            v-if="loading"
            class="float-right"
            label="Floated Right"
          />
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="mb-3 ml-1"
            variant="primary"
            :disabled="loading"
            @click="processarArquivo"
          >
            Processar
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="mb-3 ml-1"
            type="reset"
            variant="warning"
            @click="cancelar"
          >
            Sair
          </b-button>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Pesquisar</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="pesquisa"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <!-- Internomente -->
          <vue-good-table
            :columns="columns"
            :rows="listaConteudo"
            :rtl="direction"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
          />
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormFile,
  BForm,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import download from 'downloadjs'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import _ from 'lodash'

export default {
  name: 'ImportExport',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BForm,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    vSelect,
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboTipoPagamento: state => state.comboTipoPagamento,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  data() {
    return {
      required,
      totalRecords: 0,
      isLoading: false,
      searchTerm: '',
      isValid: 'is-invalid',
      tipo: null,
      tipoEmprestimo: null,
      comboTipo: [
        {
          id: 6,
          nome: '9509 - Mensalidades Sócio',
        },
        {
          id: 13,
          nome: '9555 - Mensalidades Agregados',
        },
        {
          id: 1,
          nome: '9505 - Empréstimos',
        },
        {
          id: 9991,
          nome: 'Consistência de Folha',
        },
      ],
      loading: false,
      showForm: false,
      file: null,
      listaConteudo: [],
      showGrid: true,
      columns: [
        {
          label: 'Matrícula',
          field: 'matricula',
        },
        {
          label: 'CPF',
          field: 'cpf',
        },
        {
          label: 'Rubrica',
          field: 'rubrica',
        },
        {
          label: 'Valor',
          field: 'valor',
          formatFn: this.formatCurrencyBrazil,
          sortable: false,
        },
        {
          label: 'Ano',
          field: 'ano',
        },
        {
          label: 'Mês',
          field: 'mes',
        },
        {
          label: 'Observações',
          field: 'obs',
        },
      ],
      listaHistorico: [],
      columnsFiles: [
        {
          label: 'Arquivo',
          field: 'nomeArquivo',
        },
        {
          label: 'Data Importação',
          field: 'dataImportacao',
          formatFn: this.formatDateGrid,
        },
      ],
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0,
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'id',
        order: 'asc',
      },
      pageLength: 10,
      dir: false,
      listaCodigosParametroEmprestimos: [],
      tipoFiltradoPorEmprestimos: [],
      fileId: null,
    }
  },
  methods: {
    async getEmprestimo() {
      this.tipoEmprestimo = null
      this.tipoFiltradoPorEmprestimos = []
      const listaTiposParcelamentos = await this.$store.dispatch(
        'parcelamentoFinananceiroModule/getParametrosParcelamento',
      )
      this.listaCodigosParametroEmprestimos = listaTiposParcelamentos.data.valor.split(',')
      this.comboTipoPagamento.forEach(tipo => {
        this.listaCodigosParametroEmprestimos.forEach(codigo => {
          if (parseInt(codigo) === parseInt(tipo.id)) {
            this.tipoFiltradoPorEmprestimos.push(tipo)
          }
        })
      })
    },
    processarArquivo() {
      this.loading = true
      this.$store
        .dispatch('filesFinancialModule/processarArquivo', this.fileId)
        .then(response => {
          if (response.response && response.response.data.error) {
            this.MensagemError(response.response.data.message)
            this.loading = false
            return
          }
          this.showMessageSuccess(
            'Processamento',
            'arquivo processado com sucesso!',
          )
          this.loading = false
        })
        .catch(err => {
          this.MensagemError('Erro ao processar o arquivo!')
          this.loading = false
        })
    },
    printReport(reportName) {
      this.report = {}
      this.report = {
        nome: reportName,
        valor: [this.$route.params.id],
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then(response => {
          this.openNewAbaBase64(response.data)
        })
    },
    gerarNomeArquivoExportacao() {
      const date = new Date()
      const currentYear = date.getFullYear().toString().substr(-2)
      let currentMonth = date.getMonth() + 1
      if (currentMonth < 10) {
        currentMonth = `0${currentMonth}`
      }
      return `WFOL${currentMonth}${currentYear}.dat`
    },
    async exportarNovoArquivo() {
      this.loading = true
      this.$store
        .dispatch('filesFinancialModule/exportNeos')
        .then(response => {
          const nomeArquivo = this.gerarNomeArquivoExportacao()
          download(response.data, nomeArquivo)

          this.showMessageSuccess(
            'Exportado',
            'arquivo exportado com sucesso!',
          )
          this.cancelar()
        })
        .catch(err => {
          console.error('ERRRO', err)
          this.loading = false
        })
    },
    async importFile() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true
          const tipoEnviado = this.tipo === 1 ? this.tipoEmprestimo : this.tipo
          this.$store
            .dispatch('filesFinancialModule/importNeos', {
              file: this.file,
              tipo: tipoEnviado,
            })
            .then(response => {
              if (response.response && response.response.data.error) {
                this.MensagemError(response.response.data.message)
                this.loading = false
                return
              }
              this.showMessageSuccess(
                'Importado',
                'arquivo importado com sucesso!',
              )
              this.loaddHistoricoFilesNeos()
              this.cancelar()
            })
            .catch(err => {
              console.error('ERRRO', err)
              this.loading = false
              this.loaddHistoricoFilesNeos()
            })
        }
      })
    },
    loaddHistoricoFilesNeos() {
      this.isLoading = true
      this.$store
        .dispatch('filesFinancialModule/loadHistoricoArquivos', this.pageConfig)
        .then(response => {
          this.pageConfig.page = response.data.pageable.pageNumber
          this.tableConfigs.last = response.data.last
          this.tableConfigs.totalPages = response.data.totalPages
          this.tableConfigs.totalElements = response.data.totalElements
          this.tableConfigs.size = response.data.size
          this.tableConfigs.number = response.data.number
          this.listaHistorico = response.data.content
          this.isLoading = false
        })
    },
    importarNovoArquivo() {
      this.file = null
      this.tipo = []
      this.tipoEmprestimo = []
      this.showGrid = false
      this.loading = false
    },
    async cancelar() {
      this.file = null
      this.tipo = []
      this.tipoEmprestimo = []
      this.showGrid = true
      this.showForm = false
    },
    getFileByID(params) {
      this.fileId = null
      this.$store
        .dispatch('filesFinancialModule/getFileById', params.row.id)
        .then(response => {
          this.fileId = response.data.id
          this.listaConteudo = response.data.itens
          this.cancelar()
          this.loading = false
          this.showForm = true
          this.showGrid = false
        })
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.loaddHistoricoFilesNeos()
    },
    handleSearch: _.debounce(function (searching) {
      this.pageConfigdefault()
      if (searching) {
        this.pageConfig.filter = `nome=${searching}`
      }
      this.loaddHistoricoFilesNeos()
    }, 300),
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc',
      }
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      this.loaddHistoricoFilesNeos()
    },
  },
  async mounted() {
    this.loaddHistoricoFilesNeos()
    await this.$store.dispatch('combosModule/loadComboTipoPagamento')
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
