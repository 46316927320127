export default {
  LOAD_LIST_USERS(state, data) {
    state.listaUsers = data
  },
  PREPARE_TO_EDIT_USER(state, data) {
    state.user = data
    state.user.perfil = state.user.perfil.id
  },
  RESET_USER(state) {
    state.user = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      perfil: ''
    }
  },
  SET_SYSTEM_PERMISSION(state, data) {
    state.systemPermission = data
  }
}
