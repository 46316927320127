<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listBanks"
        :title-table="$t('Banks')"
        module="bankSettingsModule"
        sort-name="descricao"
        :show-btn-add="verificarPermissao('ROLE_FINAN_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_FINAN_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <FormBank v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormBank from './forms/FormBank.vue'

export default {
  name: 'Bank',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormBank
  },
  computed: {
    ...mapState('bankSettingsModule', {
      listBanks: (state) => state.listBanks
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      columns: [
        {
          label: this.$t('id'),
          field: 'id'
        },
        {
          label: this.$t('Description'),
          field: 'descricao'
        },
        {
          label: 'Número',
          field: 'numero'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ]
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch('bankSettingsModule/prepareToEditBank', data)
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
