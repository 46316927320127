var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('b-row',[(_vm.objectPlace.id)?_c('b-col',{attrs:{"sm":"4","offset-lg":"10","offset-md":"8","offset-sm":"6"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":_vm.print}},[_vm._v(" Recibo ")])],1):_vm._e()],1),_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"observacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Registro","label-for":"v-registro"}},[_c('v-select',{attrs:{"label":"nome","filterable":false,"options":_vm.comboRegistros,"selected":_vm.objectPlace.registro,"disabled":_vm.disabledSearch ||
                  (!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.objectPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.objectPlace.id)},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.nome)+" ")])]}}],null,true),model:{value:(_vm.objectPlace.registro),callback:function ($$v) {_vm.$set(_vm.objectPlace, "registro", $$v)},expression:"objectPlace.registro"}},[_c('template',{slot:"no-options"},[_vm._v(" Pesquisa registro por nome, cpf ou matrícula ")])],2),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-registro"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descricao","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"descricao","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.objectPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.descricao),callback:function ($$v) {_vm.$set(_vm.objectPlace, "descricao", $$v)},expression:"objectPlace.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Repetições do Lançamento","label-for":"v-repeticoes"}},[_c('b-form-input',{attrs:{"id":"v-repeticoes","type":"number","placeholder":"repeticoes","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.qtoRepetido),callback:function ($$v) {_vm.$set(_vm.objectPlace, "qtoRepetido", $$v)},expression:"objectPlace.qtoRepetido"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data Lançamento","label-for":"v-dataLancamento"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.dataLancamento),callback:function ($$v) {_vm.$set(_vm.objectPlace, "dataLancamento", $$v)},expression:"objectPlace.dataLancamento"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data Vencimento","label-for":"v-dataVencimento"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.dataVencimento),callback:function ($$v) {_vm.$set(_vm.objectPlace, "dataVencimento", $$v)},expression:"objectPlace.dataVencimento"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Data Quitação","label-for":"v-dataQuitacao"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.dataQuitacao),callback:function ($$v) {_vm.$set(_vm.objectPlace, "dataQuitacao", $$v)},expression:"objectPlace.dataQuitacao"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Valor","label-for":"v-Valor"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valor","raw":true,"options":_vm.options.number,"placeholder":"Valor","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.valor),callback:function ($$v) {_vm.$set(_vm.objectPlace, "valor", _vm._n($$v))},expression:"objectPlace.valor"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Desconto","label-for":"v-desconto"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-desconto","raw":true,"options":_vm.options.number,"placeholder":"desconto","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.desconto),callback:function ($$v) {_vm.$set(_vm.objectPlace, "desconto", _vm._n($$v))},expression:"objectPlace.desconto"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Juros","label-for":"v-juros"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-juros","raw":true,"options":_vm.options.number,"placeholder":"sjuros","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.juro),callback:function ($$v) {_vm.$set(_vm.objectPlace, "juro", _vm._n($$v))},expression:"objectPlace.juro"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Multa","label-for":"v-multa"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-multa","raw":true,"options":_vm.options.number,"placeholder":"multa","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.multa),callback:function ($$v) {_vm.$set(_vm.objectPlace, "multa", _vm._n($$v))},expression:"objectPlace.multa"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Valor Total","label-for":"v-valorTotal"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valorTotal","raw":true,"options":_vm.options.number,"placeholder":"valor total","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.valorTotal),callback:function ($$v) {_vm.$set(_vm.objectPlace, "valorTotal", _vm._n($$v))},expression:"objectPlace.valorTotal"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Valor Quitação","label-for":"v-valorQuitacao"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valorQuitacao","raw":true,"options":_vm.options.number,"placeholder":"valor quitação","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.valorQuitado),callback:function ($$v) {_vm.$set(_vm.objectPlace, "valorQuitado", _vm._n($$v))},expression:"objectPlace.valorQuitado"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"tipo de pagamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Pagamento","label-for":"v-tipoPagamento"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (comboType) { return comboType.id; },"label":"nome","options":_vm.comboType,"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.objectPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.tipo),callback:function ($$v) {_vm.$set(_vm.objectPlace, "tipo", $$v)},expression:"objectPlace.tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"forma pagamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Forma Pagamento","label-for":"v-formaPagamento"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (comboPayment) { return comboPayment.id; },"label":"nome","options":_vm.comboPayment,"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.objectPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.formaPagamento),callback:function ($$v) {_vm.$set(_vm.objectPlace, "formaPagamento", $$v)},expression:"objectPlace.formaPagamento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('validation-provider',{attrs:{"name":"sitiacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Situação","label-for":"v-situacao"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (comboSituation) { return comboSituation.id; },"label":"nome","options":_vm.comboSituation,"disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.objectPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.situacao),callback:function ($$v) {_vm.$set(_vm.objectPlace, "situacao", $$v)},expression:"objectPlace.situacao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('b-form-group',{attrs:{"label":"Observação","label-for":"v-obs"}},[_c('b-form-input',{attrs:{"id":"v-obs","placeholder":"observação","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                  !!_vm.objectPlace.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.objectPlace.id)},model:{value:(_vm.objectPlace.observacao),callback:function ($$v) {_vm.$set(_vm.objectPlace, "observacao", $$v)},expression:"objectPlace.observacao"}})],1)],1),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }