export default {
  listProtocol: [],
  protocol: {
    numeroProtocolo: '',
    dataProtocolo: null,
    dataVencimento: null,
    tipo: null,
    meio: null,
    registro: null,
    ativo: true,
  },
}
