import situacaoAtendimentoService from '@/api/attendance/situacaoAtendimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => situacaoAtendimentoService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_SITUACAO_ATENDIMENTO', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewSituacaoAtendimento({ commit }, data) {
    return situacaoAtendimentoService.insertNewSituacaoAtendimento(data).then(result => result).catch(error => error)
  },
  prepareToEditSituacaoAtendimento({ commit }, data) {
    commit('PREPARE_TO_EDIT_SITUACAO_ATENDIMENTO', data)
  },
  editSituacaoAtendimento({ commit }, data) {
    return situacaoAtendimentoService.editSituacaoAtendimento(data).then(result => result).catch(error => error)
  },
  resetSituacaoAtendimento({ commit }) {
    commit('RESET_SITUACAO_ATENDIMENTO')
  },
  delete({ commit }, id) {
    return situacaoAtendimentoService.deleteSituacaoAtendimento(id).then(result => result).catch(error => error)
  },
}
