var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"data","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"data","label-for":"v-data"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"v-data","state":errors.length > 0 ? false : null,"name":"fieldDate","config":{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                },"disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.data),callback:function ($$v) {_vm.$set(_vm.form, "data", $$v)},expression:"form.data"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-data"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Fixo (Repetir Anualmente)","label-for":"v-fixo"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.fixo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.fixo),callback:function ($$v) {_vm.$set(_vm.form, "fixo", $$v)},expression:"form.fixo"}},[_vm._v(" "+_vm._s(_vm.form.fixo ? 'Sim' : 'Não')+" ")])],1)],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Ativo","label-for":"v-ativo"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_ATEND_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_ATEND_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ATEND_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }