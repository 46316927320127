import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/grupos_previdencias${queryUrl}`)
  },
  insertNewSocialSecurityGroup(data) {
    return axios.post('/core/grupos_previdencias', data)
  },
  editSocialSecurityGroup(data) {
    return axios.put(`/core/grupos_previdencias/${data.id}`, data)
  },
  deleteSocialSecurityGroup(id) {
    return axios.delete(`/core/grupos_previdencias/${id}`)
  },
}
