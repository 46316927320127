export default {
  listaDoencas: [],
  doenca: {
    id: null,
    descricao: '',
    especialidade: {
      id: null,
    },
    cat: '',
    ativo: true,
  },
}
