export default {
  LOAD_LIST(state, data) {
    state.listaProcedimentos = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.procedimento = data
  },
  RESET(state) {
    state.procedimento = {
      id: null,
      descricao: '',
      especialidade: { id: null },
      grupo: { id: null },
      ativo: true,
      exame: false
    }
  }
}
