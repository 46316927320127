import bankAccountService from '@/api/financial/bankAccountsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => bankAccountService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_BANKS_ACCOUNT', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewBankAccount({ commit }, data) {
    return bankAccountService.insertNewBankAccount(data).then(result => result).catch(error => error)
  },
  prepareToEditBankAccount({ commit }, data) {
    commit('PREPARE_TO_EDIT_BANK_ACCOUNT', data)
  },
  editBankAccount({ commit }, data) {
    return bankAccountService.editBankAccount(data).then(result => result).catch(error => error)
  },
  resetBankAccount({ commit }) {
    commit('RESET_BANK_ACCOUNT')
  },
  loadComboPlanoContas({ commit }) {
    return new Promise((resolve, reject) => bankAccountService.loadComboPlanoContas().then(result => {
      commit('SET_LIST_COMBO_PLANO_CONTAS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboTipoConta({ commit }) {
    return new Promise((resolve, reject) => bankAccountService.loadComboTipoConta().then(result => {
      commit('SET_LIST_COMBO_TIPO_CONTAS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboBanks({ commit }) {
    return new Promise((resolve, reject) => bankAccountService.loadComboBanks().then(result => {
      commit('SET_LIST_COMBO_BANCOS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  delete({ commit }, id) {
    return bankAccountService.deleteBankAccount(id).then(result => result).catch(error => error)
  },
}
