export default [
  {
    path: '/settings/client',
    name: 'settings-client',
    component: () => import('@/views/settings/client/Form.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Configurações Cliente'
        }
      ]
    }
  },
  {
    path: '/settings/diretoria',
    name: 'settings-diretoria',
    component: () =>
      import('@/views/settings/directoria/DiretoriaSettings.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Diretoria',
      breadcrumb: [
        {
          text: 'Configurações Diretoria'
        }
      ]
    }
  }
]
