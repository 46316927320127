export default {
  listaConvenios: [],
  convenio: {
    id: null,
    descricao: '',
    dataInicio: '',
    dataTermino: '',
    ativo: true,
  },
}
