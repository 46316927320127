var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.disableForm)?_c('div',[_c('div',{staticClass:"custom-search d-flex mb-2"},[_c('b-button',{staticClass:"justify-content-start",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.inserirHistorico()}}},[_vm._v(" Alterar Tipo de Registro ")]),_c('b-button',{staticClass:"justify-content-start ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.migrarAssociados()}}},[_vm._v(" Migrar Para Sócio ")]),_c('b-button',{staticClass:"justify-content-start ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.MigrarDepedentes()}}},[_vm._v(" Migrar Para Depedente ")]),_c('b-button',{staticClass:"justify-content-start ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.MigrarAgregados()}}},[_vm._v(" Migrar Para Agregado ")])],1),_c('div',{staticClass:"custom-search d-flex justify-content-end mb-2"},[_c('b-badge',{staticStyle:{"font-size":"16px"},attrs:{"variant":"primary"}},[_vm._v(" Registro Atual: "+_vm._s(_vm.registroAtual)+" ")])],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"justify-content-center mb-2"},[_c('h4',[_vm._v("Histórico do Tipo de Registro")])])])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.listaHistorico,"rtl":_vm.direction,"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength
      }}})],1):_c('div',[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[(_vm.type === 'setMudarTipoRegistroDependenteAgregado')?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"cpf Associado","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF do Associado","label-for":"v-cpf-associado"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-cpf-associado","raw":false,"placeholder":"cpf do associado","options":_vm.maskCpf,"readonly":_vm.ehAssociadoHabilitado()},model:{value:(_vm.TipoRegistroPlace.cpf),callback:function ($$v) {_vm.$set(_vm.TipoRegistroPlace, "cpf", $$v)},expression:"TipoRegistroPlace.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4224852084)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"descrição"},model:{value:(_vm.TipoRegistroPlace.descricao),callback:function ($$v) {_vm.$set(_vm.TipoRegistroPlace, "descricao", $$v)},expression:"TipoRegistroPlace.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"ols":"12","lg":"12"}},[_c('validation-provider',{attrs:{"name":"tipoRegistro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo Registro","label-for":"v-tipoRegistro"}},[_c('v-select',{attrs:{"disabled":_vm.disableForm,"label":"nome","state":errors.length > 0 ? false : null,"options":_vm.comboTypesRegister},model:{value:(_vm.TipoRegistroPlace.tipoRegistro),callback:function ($$v) {_vm.$set(_vm.TipoRegistroPlace, "tipoRegistro", $$v)},expression:"TipoRegistroPlace.tipoRegistro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }