import executivePositionsServices from '@/api/administrative/executivePositionsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return executivePositionsServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_EXECUTIVE_POSITION', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewExecutivePositions({ commit }, data) {
    return executivePositionsServices.insertNewExecutiePosition(data).then(result => result).catch(error => error)
  },
  prepareToEditExecutivePositions({ commit }, data) {
    commit('PREPARE_TO_EDIT_EXECUTIVE_POSITION', data)
  },
  editExecutivePositions({ commit }, data) {
    return executivePositionsServices.editExecutiePosition(data).then(result => result).catch(error => error)
  },
  resetExecutivePositions({ commit }) {
    commit('RESET_EXECUTIVE_POSITION')
  },
  delete({ commit }, id) {
    return executivePositionsServices.deleteExecutiePosition(id).then(result => result).catch(error => error)
  },
}
