<template>
  <b-row>
    <b-col cols="12" sm="12" md="12" lg="2">
      <div v-for="(opcao, index) in opcoes" :key="index">
        <b-button
          v-if="verificarPermissao(opcao.action)"
          class="mb-1"
          block
          variant="gradient-primary"
          :to="opcao.route"
          exact
          :data-cy="opcao.route.name"
        >
          {{ $t(opcao.title) }}
        </b-button>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10">
      <router-view />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Executive Positions',
          route: { name: 'executivePositionsSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Civil Status',
          route: { name: 'civilStatusSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Functions',
          route: { name: 'functionsSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Gender',
          route: { name: 'gendersSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Social Security Group',
          route: { name: 'socialSecurityGroupSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Nationality',
          route: { name: 'nationalitySettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Naturalness',
          route: { name: 'naturalnessSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Fiscal Nature',
          route: { name: 'fiscalNatureSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Social Security',
          route: { name: 'socialSecuritySettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Professions',
          route: { name: 'professionsSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Sector',
          route: { name: 'sectorSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Record Situations',
          route: { name: 'recordSituationsSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Phone Type',
          route: { name: 'phoneTypeSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Registration Type',
          route: { name: 'registrationTypeSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Address Type',
          route: { name: 'addresstypeSettings' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Parametros',
          route: { name: 'parematerAdministrative' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        }
      ],
      permissionUser: undefined
    }
  }
}
</script>

mensagem mudar botão
