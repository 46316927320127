import axios from '@axios'

const baseUrlEntidade = '/administrativo/fornecedores'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`${baseUrlEntidade}${queryUrl}`)
  },
  insertNewFornecedor(data) {
    return axios.post(baseUrlEntidade, data)
  },
  editFornecedor(data) {
    return axios.put(`${baseUrlEntidade}/${data.id}`, data)
  },
  deleteFornecedor(id) {
    return axios.delete(`${baseUrlEntidade}/${id}`)
  },
  loadCombo() {
    return axios.get(`${baseUrlEntidade}/tipo_registro`)
  },
  loadCards() {
    return axios.get(`${baseUrlEntidade}/card_situacao`)
  },
  getFornecedorID(id) {
    return axios.get(`${baseUrlEntidade}/${id}`)
  },
  sendNewPhoto(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/fotos/${object.id}`, dataForm)
  },
  deletePhoto(id) {
    return axios.get(`/administrativo/fotos/${id}`)
  },
  loadDocumentations(id) {
    return axios.get(`/administrativo/documentos/registro/${id}`)
  },
  sendFiles(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/documentos/${object.id}`, dataForm)
  },
  downloadDocument(id) {
    return axios.get(`/administrativo/documentos/download/${id}`)
  },
  deleteDocument(id) {
    return axios.delete(`/administrativo/documentos/${id}`)
  },
  delete(id) {
    return axios.delete(`${baseUrlEntidade}/${id}`)
  }
}
