export default [
  {
    path: '/modules/financial/emprestimo',
    redirect: '/modules/financial/emprestimo',
    name: 'emprestimo',
    component: () =>
      import('@/views/modules/financial/emprestimo/Emprestimo.vue'),
    meta: {
      action: 'ROLE_FINAN_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Emprestimos',
      breadcrumb: [
        {
          text: 'Administrative'
        }
      ]
    },
    children: [
      {
        path: 'emprestimo',
        name: 'emprestimo',
        component: () =>
          import(
            '@/views/modules/financial/emprestimo/grid/EmprestimoGrid.vue'
          ),
        meta: {
          pageTitle: 'Emprestimos',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Financeiro | Emprestimos'
            }
          ]
        }
      },
      {
        path: 'editemprestimo/:id',
        name: 'editemprestimo',
        component: () =>
          import(
            '@/views/modules/financial/emprestimo/form/EmprestimoForm.vue'
          ),
        meta: {
          pageTitle: 'Editando Emprestimos',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Modulo Financeiro | Editando Emprestimos'
            }
          ]
        }
      }
    ]
  }
]
