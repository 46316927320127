import agendaServices from '@/api/atendimentoModule/agendaServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => agendaServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insert({ commit }, data) {
    return agendaServices.insert(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return agendaServices.edit(data).then(result => result).catch(error => error)
  },
  reset({ commit }) {
    commit('RESET')
  },
  getById({ commit }, id) {
    return new Promise((resolve, reject) => agendaServices.getById(id).then(result => {
      commit('PREPARE_TO_EDIT', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  async searchProfessional({ commit }, search) {
    try {
      const result = await agendaServices.searchProfessional(search)
      return result
    } catch (error) {
      return error
    }
  },
}
