export default {
  listaProfissionals: [],
  profissional: {
    id: null,
    registro: {
      id: null
    },
    dataInicio: '',
    dataTermino: '',
    numeroRegistroProfissional: '',
    conselho: {
      id: null
    },
    especialidades: [],
    ativo: true,
    doencasCid: ''
  }
}
