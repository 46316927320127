var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"ano","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Ano","label-for":"v-ano"}},[_c('b-form-input',{attrs:{"id":"v-ano","placeholder":"Ano","state":errors.length > 0 ? false : null,"data-cy":"ano","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.ano),callback:function ($$v) {_vm.$set(_vm.form, "ano", $$v)},expression:"form.ano"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-ano"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Valor","label-for":"v-valor"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valor","raw":true,"options":_vm.options.number,"placeholder":"valor","data-cy":"valor","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", _vm._n($$v))},expression:"form.valor"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-valor"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"vencimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vencimento","label-for":"v-vencimento"}},[_c('b-form-input',{attrs:{"id":"v-vencimento","placeholder":"vencimento","state":errors.length > 0 ? false : null,"data-cy":"vencimento","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.vencimento),callback:function ($$v) {_vm.$set(_vm.form, "vencimento", $$v)},expression:"form.vencimento"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-vencimento"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"recorrencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Recorrência","label-for":"v-recorrencia"}},[_c('b-form-input',{attrs:{"id":"v-recorrencia","placeholder":"recorrencia","state":errors.length > 0 ? false : null,"data-cy":"recorrencia","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.recorrencia),callback:function ($$v) {_vm.$set(_vm.form, "recorrencia", $$v)},expression:"form.recorrencia"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-recorrencia"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Juros","label-for":"v-juros"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-juros","raw":true,"options":_vm.options.percent,"placeholder":"Juros","data-cy":"juros","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.juros),callback:function ($$v) {_vm.$set(_vm.form, "juros", _vm._n($$v))},expression:"form.juros"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Multa","label-for":"v-multa"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-multa","raw":true,"options":_vm.options.percent,"placeholder":"Multa","data-cy":"multa","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.multa),callback:function ($$v) {_vm.$set(_vm.form, "multa", _vm._n($$v))},expression:"form.multa"}})],1)],1),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }