import protocolTypeServices from '@/api/protocol/protocolTypeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return protocolTypeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ADDRESS_TYPE', result.data.content)
      return result
    }).catch(err => err)
  },
  insert({ commit }, data) {
    return protocolTypeServices.insert(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return protocolTypeServices.edit(data).then(result => result).catch(error => error)
  },
  delete({ commit }, id) {
    return protocolTypeServices.delete(id).then(result => result).catch(error => error)
  },
}
