import reportServices from '@/api/report/reportServices'

export default {
  listagemRelatorios({ commit }, id) {
    return reportServices.listagemRelatorios(id).then(result => result).catch(error => error)
  },
  printReport({ commit }, report) {
    return reportServices.printReport(report)
  },
  getReport({ commit }) {
    return reportServices.getReport().then(result => result).catch(error => error)
  },
  listagemGeral({ commit }, pageConfig) {
    if (pageConfig.module === 'administrative') {
      pageConfig.module = 'administrativo'
    } else if (pageConfig.module === 'financial') {
      pageConfig.module = 'financeiro'
    } else if (pageConfig.module === 'attendance') {
      pageConfig.module = 'atendimento'
    } else if (pageConfig.module === 'reportPageProtocol') {
      pageConfig.module = 'protocolo'
    }
    return reportServices.listagemGeral(pageConfig).then(result => result).catch(error => error)
  },
}
