export default {
  LOAD_LIST(state, data) {
    state.listTaxas = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.taxa = data
  },
  RESET(state) {
    state.taxa = {
      nome: '',
      percentualJuros: null,
      adesao: null,
      margem: null,
      ativo: true,
    }
  },
}
