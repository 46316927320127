import civilStatusServices from '@/api/administrative/civilStatusServices'

export default {
  loadTable({ commit }, pageConfig) {
    return civilStatusServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_CIVIL_STATUS', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewCivilStatus({ commit }, data) {
    return civilStatusServices.insertNewCivilStatus(data).then(result => result).catch(error => error)
  },
  prepareToEditCivilStatus({ commit }, data) {
    commit('PREPARE_TO_EDIT_CIVIL_STATUS', data)
  },
  editCivilStatus({ commit }, data) {
    return civilStatusServices.editCivilStatus(data).then(result => result).catch(error => error)
  },
  resetCivilStatus({ commit }) {
    commit('RESET_CIVIL_STATUS')
  },
  delete({ commit }, id) {
    return civilStatusServices.deleteCivilStatus(id).then(result => result).catch(error => error)
  },
}
