import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryFilter.order = 'desc'
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/agendas${queryUrl}`)
  },
  insert(data) {
    return axios.post('/atendimento/agendas', data)
  },
  edit(data) {
    return axios.put(`/atendimento/agendas/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/atendimento/agendas/${id}`)
  },
  getById(id) {
    return axios.get(`/atendimento/agendas/${id}`)
  },
  getProfissional(id) {
    return axios.get(`/atendimento/agendas/${id}/profissionais`)
  },
  searchProfessional(name) {
    const endpoint = `/atendimento/agendas/pesquisar_profissional?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
}
