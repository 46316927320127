import { render, staticRenderFns } from "./AccountsFiles.vue?vue&type=template&id=6786eeb4"
import script from "./AccountsFiles.vue?vue&type=script&lang=js"
export * from "./AccountsFiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports