export default [
  {
    path: '/modules/administrative/juridicPerson',
    redirect: '/modules/administrative/juridicPerson/fornecedor',
    name: 'juridicPersonPage',
    component: () =>
      import('@/views/modules/administrative/juridicPerson/JuridicPerson.vue'),
    meta: {
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Pessoa Juridica',
      breadcrumb: [
        {
          text: 'Administrative'
        }
      ]
    },
    children: [
      {
        path: 'fornecedor',
        name: 'fornecedor',
        component: () =>
          import(
            '@/views/modules/administrative/juridicPerson/fornecedor/grid/Fornecedor.vue'
          ),
        meta: {
          pageTitle: 'Fornecedor',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Fornecedor'
            }
          ]
        }
      }
    ]
  }
]
