var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descricao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"descrição"},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Tipo Protocolo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo Protocolo","label-for":"v-tipoProtocolo"}},[_c('v-select',{attrs:{"reduce":function (comboTipoProtocolo) { return comboTipoProtocolo.id; },"label":"descricao","options":_vm.comboTipoProtocolo,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.tipoProtocolo),callback:function ($$v) {_vm.$set(_vm.form, "tipoProtocolo", $$v)},expression:"form.tipoProtocolo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status","label-for":"v-status"}},[_c('v-select',{attrs:{"reduce":function (comboStatus) { return comboStatus.id; },"label":"nome","options":_vm.comboStatus,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Data Início","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Início","label-for":"v-dataInicio"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                }},model:{value:(_vm.form.dataInicio),callback:function ($$v) {_vm.$set(_vm.form, "dataInicio", $$v)},expression:"form.dataInicio"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-dataInicio-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":"Data Término","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Data Término","label-for":"v-dataFim"}},[_c('flat-pickr',{attrs:{"name":"fieldDate","config":{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d',
                }},model:{value:(_vm.form.dataTermino),callback:function ($$v) {_vm.$set(_vm.form, "dataTermino", $$v)},expression:"form.dataTermino"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-dataFim-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"10"}},[_c('validation-provider',{attrs:{"name":"observacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Solicitante","label-for":"v-registro"}},[_c('v-select',{attrs:{"label":"nome","filterable":false,"options":_vm.comboRegistros,"selected":_vm.form.registro},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.nome)+" ")])]}}],null,true),model:{value:(_vm.form.registro),callback:function ($$v) {_vm.$set(_vm.form, "registro", $$v)},expression:"form.registro"}},[_c('template',{slot:"no-options"},[_vm._v(" Pesquisa registro por nome, cpf ou matrícula ")])],2),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-registro"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Garantia"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.garantia,"name":"check-button","switch":"","inline":""},model:{value:(_vm.form.garantia),callback:function ($$v) {_vm.$set(_vm.form, "garantia", $$v)},expression:"form.garantia"}},[_vm._v(" "+_vm._s(_vm.form.garantia ? 'Sim' : 'Não')+" ")])],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"valor","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Valor","label-for":"v-valor"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"valor","raw":true,"options":_vm.options.number,"placeholder":"valor"},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", _vm._n($$v))},expression:"form.valor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Valor Desconto","label-for":"v-valorDesconto"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"valorDesconto","raw":true,"options":_vm.options.number,"placeholder":"valor Desconto"},model:{value:(_vm.form.valorDesconto),callback:function ($$v) {_vm.$set(_vm.form, "valorDesconto", _vm._n($$v))},expression:"form.valorDesconto"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Observação","label-for":"v-observacao"}},[_c('b-form-textarea',{attrs:{"id":"v-observacao","placeholder":"Observação","rows":"2","max-rows":"6"},model:{value:(_vm.form.observacao),callback:function ($$v) {_vm.$set(_vm.form, "observacao", $$v)},expression:"form.observacao"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }