export default {
  LOAD_LIST_PROFESSION(state, data) {
    state.listaProfissoes = data
  },
  PREPARE_TO_EDIT_PROFESSION(state, data) {
    state.profissao = data
  },
  RESET_PROFESSION(state) {
    state.profissao = {
      ativo: true,
      cbo: '',
      nome: '',
    }
  },
}
