<template>
  <b-row>
    <b-col v-if="!loadingDelete" cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listSocialSecurity"
        :title-table="$t('Social Security')"
        module="socialSecuritySettingsModule"
        sort-name="nome"
        :show-btn-add="verificarPermissao('ROLE_ADMIM_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ADMIM_DELETAR')"
        @add="addNew"
        @edit="edit"
        @remove="remove"
      />
      <FormSocialSecurity v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import FormSocialSecurity from './forms/FormSocialSecurity.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    TableComponents,
    FormSocialSecurity
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('socialSecuritySettingsModule', {
      listSocialSecurity: (state) => state.listSocialSecurity
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      loadingDelete: false,
      columns: [
        {
          label: 'Descrição',
          field: 'nome'
        },
        {
          label: 'Ativo',
          field: 'ativo'
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch(
        'socialSecuritySettingsModule/prepareToEditSocialSecurity',
        data
      )
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    },
    async remove(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.loadingDelete = true
          this.$store
            .dispatch(
              'socialSecuritySettingsModule/deleteSocialSecurity',
              data.id
            )
            .then(() => {
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.loadingDelete = false
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.loadingDelete = false
            })
        }
      })
    }
  }
}
</script>
