export default {
  listaScaffolds: [],
  scaffold: {
    field1: '',
    field2: '',
    field3: '',
    field4: '',
  },
  comboScaffold: [],
}
