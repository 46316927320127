export default [
  {
    header: 'Modules',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Administrative',
    icon: 'UserCheckIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Phisical Person',
        route: 'phisicalPesonPage',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'juridic Person',
        route: 'juridicPersonPage',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Financial',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Contas a Pagar',
        route: 'financialPage',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Contas a Receber',
        route: 'financialPage',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Emprestimos',
        route: 'financialPage',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Atendimento',
    icon: 'ClockIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Atendimento',
        route: 'Atendimento',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Eventos',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Eventos',
        route: 'Eventos',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Comunicação',
    icon: 'SendIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Comunicação',
        route: 'Comunicação',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Relatórios',
    icon: 'SendIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Relatorios',
        route: 'Comunicação',
        action: 'read',
        esource: 'ACL',
      },
    ],
  },
]
