export default {
  LOAD_LIST_ACCOUNT_PLANS(state, data) {
    state.listAccountPlans = data
  },
  PREPARE_TO_EDIT_ACCOUNT_PLANS(state, data) {
    state.accountPlan = data
  },
  RESET_ACCOUNT_PLANS(state) {
    state.accountPlan = {
      classe: '',
      codNatureza: '',
      ativo: true,
    }
  },
}
