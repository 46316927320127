import axios from '@axios'
import axiosViaCEp from 'axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = '?page=0&size=10&sort=descricao,asc'
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/enderecos${queryUrl}`)
  },
  insertNewAddress(data) {
    return axios.post('/core/enderecos', data)
  },
  editAddress(data) {
    return axios.put(`/core/enderecos/${data.id}`, data)
  },
  deleteAddress(id) {
    return axios.delete(`/core/enderecos/${id}`)
  },
  getAddressByIdRegister(id) {
    return axios.get(`/core/enderecos/registro/${id}`)
  },

  getEnderecoViaCep(cep) {
    return axiosViaCEp({
      method: 'get',
      url: `https://viacep.com.br/ws/${cep}/json/`,
    })
  },
}
