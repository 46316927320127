import employeesService from '@/api/AdministrativeModule/employeesServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_EMPLOYEES', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNewEmployee({ commit }, data) {
    return employeesService
      .insertNewEmployee(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditEmployee({ commit }, data) {
    commit('PREPARE_TO_EDIT_EMPLOYEE', data)
  },
  editEmployee({ commit }, data) {
    return employeesService
      .editEmployee(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetEmployee({ commit }) {
    commit('RESET_EMPLOYEE')
  },
  getEmployeeID({ commit }, id) {
    return new Promise((resolve, reject) =>
      employeesService
        .getEmployeeID(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  prepareToEditFullEmployee({ commit }, data) {
    commit('PREPARE_TO_EDIT_FULL_EMPLOYEE', data)
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadCombo()
        .then((result) => {
          const tipoFuncionario = result.data.find(
            (item) => parseInt(item.id) === 34
          )
          commit('SET_LIST_COMBO_TYPES_REGISTER', [tipoFuncionario])
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboGenero()
        .then((result) => {
          commit('SET_LIST_COMBO_GENERO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboEstadoCivil()
        .then((result) => {
          commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboProfissoes()
        .then((result) => {
          commit('SET_LIST_COMBO_PROFISSOES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboNaturalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NATURALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboNacionalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboUfs()
        .then((result) => {
          commit('SET_LIST_COMBO_UFS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboPrevidenciaSocial()
        .then((result) => {
          commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboTipoEndereco()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboSituacaoRegistro()
        .then((result) => {
          commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  setHistoricoSituacao({ commit }, data) {
    return employeesService
      .setHistoricoSituacao(data)
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoSituacao({ commit }, id) {
    return employeesService
      .getHistoricoSituacao(id)
      .then((result) => result)
      .catch((error) => error)
  },
  editEmployeeDadosPessoais({ commit }, data) {
    return employeesService
      .editEmployeeDadosPessoais(data)
      .then((result) => result)
      .catch((error) => error)
  },
  editEmployeeDocumentation({ commit }, data) {
    return employeesService
      .editEmployeeDocumentation(data)
      .then((result) => result)
      .catch((error) => error)
  },
  getEnderecoViaCep({ commit }, data) {
    return employeesService
      .getEnderecoViaCep(data)
      .then((result) => result)
      .catch((error) => error)
  },
  inserirNovoEndereco({ commit }, data) {
    return employeesService
      .inserirNovoEndereco(data)
      .then((result) => result)
      .catch((error) => error)
  },
  editEndereco({ commit }, data) {
    return employeesService
      .editEndereco(data)
      .then((result) => result)
      .catch((error) => error)
  },
  loadComboFuncoes({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboFuncoes()
        .then((result) => {
          commit('SET_LIST_COMBO_FUNCOES', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSetores({ commit }) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadComboSetores()
        .then((result) => {
          commit('SET_LIST_COMBO_SETORES', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  editEmployeeDadosFuncionario({ commit }, data) {
    return employeesService
      .editEmployeeDadosFuncionario(data)
      .then((result) => result)
      .catch((error) => error)
  },
  sendNewPhoto({ commit }, object) {
    return employeesService
      .sendNewPhoto(object)
      .then((result) => result)
      .catch((error) => error)
  },
  deletePhoto({ commit }, id) {
    return employeesService
      .deletePhoto(id)
      .then((result) => result)
      .catch((error) => error)
  },
  loadEnderecosPorRegistro({ commit }, id) {
    return new Promise((resolve, reject) =>
      employeesService
        .loadEnderecosPorRegistro(id)
        .then((result) => {
          commit('SET_LISTA_ENDERECOS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  deleteEndereco({ commit }, id) {
    return employeesService
      .deleteEndereco(id)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditEndereco({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECO', data)
  },
  getListDepedentEmployee({ commit }, id) {
    return employeesService
      .getListDepedentEmployee(id)
      .then((result) => result)
      .catch((error) => error)
  },
  downloadDocument({ commit }, id) {
    return employeesService
      .downloadDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  deleteDocument({ commit }, id) {
    return employeesService
      .deleteDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoTipoRegistro({ commit }, id) {
    return employeesService
      .getHistoricoTipoRegistro(id)
      .then((result) => result)
      .catch((error) => error)
  },
  setMudarTipoRegistro({ commit }, data) {
    return employeesService
      .setMudarTipoRegistro(data)
      .then((result) => result)
      .catch((error) => error)
  },
  loadCards({ commit }) {
    return employeesService
      .loadCards()
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoFaixaContribuicao({ commit }, id) {
    return employeesService
      .getHistoricoFaixaContribuicao(id)
      .then((result) => result)
      .catch((error) => error)
  },
  setMudarFaixaContribuica({ commit }, data) {
    return employeesService
      .setMudarFaixaContribuica(data)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) =>
      employeesService
        .delete(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  }
}
