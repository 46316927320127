<template>
  <div>
    <b-row>
      <b-col xl="3" lg="4" md="5">
        <FormCardProfileVue :loading="loading" />
      </b-col>
      <b-col xl="9" lg="8" md="7">
        <b-tabs>
          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Pessoal</span>
            </template>
            <FormPersonalInfoVue :loading="loading" />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_FINAN_CONSULTAR')">
            <template #title>
              <feather-icon icon="DollarSignIcon" />
              <span>Financeiro</span>
            </template>
            <FormFinacialVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ATEND_CONSULTAR')">
            <template #title>
              <feather-icon icon="PhoneIcon" />
              <span>Atendimento</span>
            </template>
            <FormAttendanceVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="LinkIcon" />
              <span>Vinculos</span>
            </template>
            <FormLinkVue :loading="loading" />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_ADMIM_CONSULTAR')">
            <template #title>
              <feather-icon icon="FileTextIcon" />
              <span>Documentos</span>
            </template>
            <FormDocumentationsVue />
          </b-tab>

          <b-tab disabled>
            <template #title>
              <feather-icon icon="CalendarIcon" />
              <span>Eventos</span>
            </template>
            <FormEventsVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_CORE_CONSULTAR')">
            <template #title>
              <feather-icon icon="FileIcon" />
              <span>Protocolos</span>
            </template>
            <FormProtocolVue />
          </b-tab>

          <b-tab v-if="verificarPermissao('ROLE_RELATORIO_CONSULTAR')">
            <template #title>
              <feather-icon icon="PrinterIcon" />
              <span>Relatorios</span>
            </template>
            <FormReportsVue />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'

import FormPersonalInfoVue from '../aggregates/forms/personal/FormAggregates.vue'
import FormFinacialVue from '../aggregates/forms/finance/FormFinancial.vue'
import FormAttendanceVue from '../aggregates/forms/attendance/FormAttendance.vue'
import FormDocumentationsVue from '../aggregates/forms/documentations/FormDocumentations.vue'
import FormEventsVue from '../aggregates/forms/events/FormEvents.vue'
import FormReportsVue from '../aggregates/forms/report/GridReport.vue'
import FormLinkVue from '../aggregates/forms/link/FormLink.vue'
import FormProtocolVue from '../aggregates/forms/protocol/FormProtocol.vue'
import FormCardProfileVue from '../aggregates/forms/personal/FormCardProfileAggregate.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    FormPersonalInfoVue,
    FormFinacialVue,
    FormAttendanceVue,
    FormDocumentationsVue,
    FormEventsVue,
    FormReportsVue,
    FormCardProfileVue,
    FormLinkVue,
    FormProtocolVue
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      loading: true
    }
  },
  async mounted() {
    this.loading = true
    try {
      const response = await this.$store.dispatch(
        'aggregatesAdministrativeModule/getAggregateID',
        this.$route.params.id
      )
      const objectAggregate = response.data
      if (objectAggregate.foto) {
        objectAggregate.foto = `data:image/jpeg;base64,${objectAggregate.foto}`
      }
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/prepareToEditFullAggregate',
        objectAggregate
      )
      this.$store.dispatch(
        'aggregatesAdministrativeModule/setAssociante',
        objectAggregate.associado
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboGenero'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboEstadoCivil'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboProfissoes'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboNaturalidades'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboNacionalidades'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboTypesRegister'
      )
      await this.$store.dispatch('aggregatesAdministrativeModule/loadComboUfs')
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboPrevidenciaSocial'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboTipoEndereco'
      )
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboSituacaoRegistro'
      )
      await this.$store.dispatch('combosModule/loadComboFaixaContribuicao')
      this.loading = false
    } catch {
      this.MensagemError('Error', 'Erro ao carregar dados da tela!')
      this.loading = false
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
