import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/planos_de_contas${queryUrl}`)
  },
  insertNewAccountPlan(data) {
    return axios.post('/financeiro/planos_de_contas', data)
  },
  editAccountPlan(data) {
    return axios.put(`/financeiro/planos_de_contas/${data.id}`, data)
  },
  deleteAccountPlan(id) {
    return axios.delete(`/financeiro/planos_de_contas/${id}`)
  },
}
