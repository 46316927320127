import protocolServices from '@/api/protocol/protocolServices'

export default {
  loadTable({ commit }, pageConfig) {
    return protocolServices
      .loadTable(pageConfig)
      .then((result) => {
        commit('LOAD_LIST', result.data.content)
        return result
      })
      .catch((err) => err)
  },
  insert({ commit }, data) {
    return protocolServices
      .insert(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return protocolServices
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return protocolServices
      .delete(id)
      .then((result) => result)
      .catch((error) => error)
  },
  getProtocolByIdRegister({ commit }, id) {
    return protocolServices
      .getProtocolByIdRegister(id)
      .then((result) => result)
      .catch((err) => err)
  },
  reset({ commit }) {
    commit('RESET')
  }
}
