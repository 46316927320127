export default {
  listTaxas: [],
  taxa: {
    nome: '',
    percentualJuros: null,
    adesao: null,
    margem: null,
    ativo: true,
  },
}
