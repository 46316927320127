export default {
  LOAD_LIST_SECTOR(state, data) {
    state.listSector = data
  },
  PREPARE_TO_EDIT_SECTOR(state, data) {
    state.sector = data
  },
  RESET_SECTOR(state) {
    state.sector = {
      id: 0,
      ativo: true,
      nome: '',
    }
  },
}
