<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listaConvenios"
        sort-name="descricao"
        title-table="Convênios"
        module="convenioModule"
        @add="addNew"
        @edit="edit"
      />
      <FormConvenio
        v-else
        @cancelar="cancelar"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import FormConvenio from './form/FormConvenio.vue'

export default {
  name: 'Convenio',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormConvenio,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('convenioModule', {
      listaConvenios: state => state.listaConvenios,
    }),
  },
  data() {
    return {
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Descrição',
          field: 'descricao',
        },
        {
          label: 'Data de Inicio',
          field: 'dataInicio',
          formatFn: this.formatarData,
        },
        {
          label: 'Responsavel',
          field: 'responavelNome',
          sortable: true,
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
    }
  },
  methods: {
    formatarData(val) {
      if (val) {
        return val.replaceAll('-', '/')
      }
      return val
    },
    async addNew() {
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `/modules/convenios/edit/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    },
  },
}
</script>
