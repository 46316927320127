export default {
  LOAD_LIST_BANK(state, data) {
    state.listBanks = data
  },
  PREPARE_TO_EDIT_BANK(state, data) {
    state.bank = data
  },
  RESET_BANK(state) {
    state.bank = {
      numero: '',
      descricao: '',
      ativo: true,
    }
  },
}
