<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listaAgendamentos"
        sort-name="nome"
        title-table="Agendamentos"
        module="agendamentoModule"
        :show-btn-add="true"
        :show-action-delete="false"
        @edit="edit"
        @add="addNew"
      />
      <Form v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import Form from '../form/Form.vue'

export default {
  name: 'ContasPagar',
  components: {
    BRow,
    BCol,
    TableComponents,
    Form
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboFormaPagamento: (state) => state.comboFormaPagamento,
      comboTipoPagamento: (state) => state.comboTipoPagamento
    }),
    ...mapState('agendamentoModule', {
      listaAgendamentos: (state) => state.listaAgendamentos
    })
  },
  data() {
    return {
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Data Inicial',
          field: 'dataInicial',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Data Final',
          field: 'dataFinal',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Hora Inicial',
          field: 'horaInicial'
        },
        {
          label: 'Hora Final',
          field: 'horaFinal'
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: false
        },
        {
          label: 'Quantidade Vagas',
          field: 'qtoVagas',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Contas a Pagar'
      }
    }
  },
  methods: {
    async addNew() {
      await this.$store.dispatch('agendamentoModule/reset')
      this.showForm = true
    },
    async edit(data) {
      this.$router.push({ path: `agendamento/edit/${data.id}` })
    },
    async cancelar() {
      this.showForm = false
    }
  }
}
</script>
