export default {
  LOAD_LIST(state, data) {
    state.listProtocol = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.protocol = data
  },
  RESET(state) {
    state.protocol = {
      numeroProtocolo: '',
      dataProtocolo: null,
      dataVencimento: null,
      tipo: null,
      meio: null,
      registro: null,
      ativo: true
    }
  }
}
