import ability from '@/libs/acl/ability'

export default {
  LOAD_LIST(state, data) {
    state.listProfiles = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.profile = data
  },
  RESET(state) {
    state.profile = {
      nome: '',
      roles: []
    }
  },
  SET_SYSTEM_PERMISSION(state, data) {
    state.systemPermission = data
  }
}
