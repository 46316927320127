import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/tipos_protocolos${queryUrl}`)
  },
  insert(data) {
    return axios.post('/core/tipos_protocolos', data)
  },
  edit(data) {
    return axios.put(`/core/tipos_protocolos/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/core/tipos_protocolos/${id}`)
  },
}
