export default {
  LOAD_LIST_UNIDADE_ATENDIMENTO(state, data) {
    state.listaUnidadesAtendimento = data
  },
  PREPARE_TO_EDIT_UNIDADE_ATENDIMENTO(state, data) {
    state.unidadeAtendimento = data
  },
  RESET_UNIDADE_ATENDIMENTO(state) {
    state.unidadeAtendimento = {
      id: null,
      descricao: '',
      qtdDeSala: null,
      ativo: true,
    }
  },
}
