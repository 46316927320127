export default [
  {
    path: '/modules/report',
    redirect: '/modules/report/protocol',
    name: 'reportPageProtocol',
    component: () => import('@/views/modules/report/Report.vue'),
    meta: {
      action: 'ROLE_RELATORIO_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Relatórios',
      breadcrumb: [
        {
          text: 'protocol'
        }
      ]
    },
    children: [
      {
        path: 'reportPageProtocol',
        name: 'reportPageProtocol',
        component: () => import('@/views/modules/report/Report.vue'),
        meta: {
          pageTitle: 'Relatórios',
          action: 'ROLE_RELATORIO_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Protocolo | Relatórios'
            }
          ]
        }
      }
    ]
  }
]
