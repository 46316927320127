export default {
  listaEnderecos: [],
  endereco: {
    cep: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    complemento: null,
    ativo: true,
    registro: {
      id: null,
      nome: null,
    },
    uf: {
      id: null,
      nome: null,
      sigla: null,
    },
    tipoEndereco: {
      id: null,
      nome: null,
    },
  },
}
