<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>

          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label="Nome"
              label-for="v-nome"
            >
              <b-form-input
                id="v-nome"
                v-model="parameterPlace.nome"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <b-form-group
              label="Comentário"
              label-for="v-comentario"
            >
              <b-form-input
                id="v-comentario"
                v-model="parameterPlace.comentario"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="valor"
              rules="required"
            >
              <b-form-group
                label="Valor"
                label-for="v-valor"
              >
                <b-form-input
                  id="v-valor"
                  v-model="parameterPlace.valor"
                  :state="errors.length > 0 ? false:null"
                  placeholder="valor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('parameterAdministrativeSettingsModule', {
      parameter: state => state.parameter,
    }),
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      titleForm: 'Edição de Parametros',
      required,
      parameterPlace: {
        valor: '',
        nome: '',
      },
    }
  },
  mounted() {
    this.parameterPlace = JSON.parse(JSON.stringify(this.parameter))
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('parameterAdministrativeSettingsModule/resetParameter')
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.parameterPlace.id) {
            this.$store.dispatch('parameterAdministrativeSettingsModule/editParameter', { id: this.parameterPlace.id, valor: this.parameterPlace.valor }).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.data.error)
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          }
        }
      })
    },
    cleanObjeto() {
      this.parameterPlace = {
        valor: '',
        nome: '',
      }
    },
  },
}
</script>
