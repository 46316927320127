export default {
  LOAD_LIST_SITUACAO_ATENDIMENTO(state, data) {
    state.listasituacaoAtendimentos = data
  },
  PREPARE_TO_EDIT_SITUACAO_ATENDIMENTO(state, data) {
    state.situacaoAtendimento = data
  },
  RESET_SITUACAO_ATENDIMENTO(state) {
    state.situacaoAtendimento = {
      id: null,
      descricao: '',
      ativo: true,
    }
  },
}
