export default {
  LOAD_LIST_EXAME(state, data) {
    state.listaExames = data
  },
  PREPARE_TO_EDIT_EXAME(state, data) {
    state.exame = data
  },
  RESET_EXAME(state) {
    state.exame = {
      id: null,
      descricao: '',
      especialidade: {
        id: null,
      },
      observacao: '',
      ativo: true,
    }
  },
}
