import axios from '@axios'

export default {
  listagemRelatorios(id) {
    return axios.get(`/relatorio/registro/${id}`)
  },
  getReport() {
    return axios.get('/relatorio/')
  },
  printReport(report) {
    return axios.post('relatorio/', report)
  },
  listagemGeral(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `nome_amigavel?nome=${queryFilter.filter}&modulo=${queryFilter.module}&page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
      return axios.get(`/relatorio/${queryUrl}`)
    }
    return axios.get(`/relatorio/geral/${queryFilter.module}?${queryUrl}`)
  },

}
