export default {
  LOAD_LIST_PROFISSIONAL(state, data) {
    state.listaProfissionals = data
  },
  PREPARE_TO_EDIT_PROFISSIONAL(state, data) {
    state.profissional = data
  },
  RESET_PROFISSIONAL(state) {
    state.profissional = {
      id: null,
      registro: {
        id: null
      },
      dataInicio: '',
      dataTermino: '',
      numeroRegistroProfissional: '',
      conselho: {
        id: null
      },
      especialidades: [],
      ativo: true,
      doencasCid: ''
    }
  }
}
