export default {
  listaFeriados: [],
  feriado: {
    id: null,
    descricao: '',
    data: '',
    ativo: true,
    fixo: false
  }
}
