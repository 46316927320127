export default [
  {
    path: '/settings/administrative',
    redirect: '/settings/administrative/executivePositionsSettings',
    name: 'administrativeSettings',
    component: () =>
      import('@/views/settings/administrative/AdministrativeSettings.vue'),
    meta: {
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Administrativo',
      breadcrumb: [
        {
          text: 'Cadastros Módulo Administrativo'
        }
      ]
    },
    children: [
      {
        path: 'professionsSettings',
        name: 'professionsSettings',
        component: () =>
          import('@/views/settings/administrative/ProfessionSetting.vue'),
        meta: {
          pageTitle: 'Profissões',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Profissões'
            }
          ]
        }
      },
      {
        path: 'fiscalNatureSettings',
        name: 'fiscalNatureSettings',
        component: () =>
          import('@/views/settings/administrative/FiscalNatureSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Natureza Fiscal',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Natureza Fiscal'
            }
          ]
        }
      },
      {
        path: 'functionsSettings',
        name: 'functionsSettings',
        component: () =>
          import('@/views/settings/administrative/FunctionsSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Funções',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Funções'
            }
          ]
        }
      },
      {
        path: 'registrationTypeSettings',
        name: 'registrationTypeSettings',
        component: () =>
          import('@/views/settings/administrative/RegistrationTypeSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Tipos de Registro',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Tipos de Registro'
            }
          ]
        }
      },
      {
        path: 'executivePositionsSettings',
        name: 'executivePositionsSettings',
        component: () =>
          import(
            '@/views/settings/administrative/ExecutivePositionSetting.vue'
          ),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Cargos Diretivos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Cargos Diretivos'
            }
          ]
        }
      },
      {
        path: 'civilStatusSettings',
        name: 'civilStatusSettings',
        component: () =>
          import('@/views/settings/administrative/CivilStatusSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Estado Civil',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Estado Civil'
            }
          ]
        }
      },
      {
        path: 'nationalitySettings',
        name: 'nationalitySettings',
        component: () =>
          import('@/views/settings/administrative/NationalitySetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Nacionalidade',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Nacionalidade'
            }
          ]
        }
      },
      {
        path: 'naturalnessSettings',
        name: 'naturalnessSettings',
        component: () =>
          import('@/views/settings/administrative/NaturalnessSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Naturalidade',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Naturalidade'
            }
          ]
        }
      },
      {
        path: 'gendersSettings',
        name: 'gendersSettings',
        component: () =>
          import('@/views/settings/administrative/GenderSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Gênero',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Gênero'
            }
          ]
        }
      },
      {
        path: 'addresstypeSettings',
        name: 'addresstypeSettings',
        component: () =>
          import('@/views/settings/administrative/AddressTypeSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Tipos de Enderço',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Tipos de Endereço'
            }
          ]
        }
      },
      {
        path: 'recordSituationsSettings',
        name: 'recordSituationsSettings',
        component: () =>
          import('@/views/settings/administrative/RecordSituationsSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Situações de Registros',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Situações de Registros'
            }
          ]
        }
      },
      {
        path: 'socialSecurityGroupSettings',
        name: 'socialSecurityGroupSettings',
        component: () =>
          import(
            '@/views/settings/administrative/SocialSecurityGroupSetting.vue'
          ),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Grupo Previdência Social',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Grupo Previdência Social'
            }
          ]
        }
      },
      {
        path: 'socialSecuritySettings',
        name: 'socialSecuritySettings',
        component: () =>
          import('@/views/settings/administrative/SocialSecuritySetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Previdência Social',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Previdência Social'
            }
          ]
        }
      },
      {
        path: 'phoneTypeSettings',
        name: 'phoneTypeSettings',
        component: () =>
          import('@/views/settings/administrative/PhoneTypeSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Tipos de Telefones',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Tipos de Telefones'
            }
          ]
        }
      },
      {
        path: 'sectorSettings',
        name: 'sectorSettings',
        component: () =>
          import('@/views/settings/administrative/SectorSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Setores',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Administrativo | Setores'
            }
          ]
        }
      },
      {
        path: 'parematerAdministrative',
        name: 'parematerAdministrative',
        component: () =>
          import('@/views/settings/administrative/ParameterSetting.vue'),
        meta: {
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Parametros',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Financeiro | Parametros'
            }
          ]
        }
      }
    ]
  }
]
