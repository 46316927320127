export default {
  LOAD_LIST_CONTRIBUTION_RANGE(state, data) {
    state.listContributionRange = data
  },
  PREPARE_TO_EDIT_CONTRIBUTION_RANGE(state, data) {
    state.contributionRange = data
  },
  RESET_CONTRIBUTION_RANGE(state) {
    state.contributionRange = {
      descricao: '',
      ano: '',
      valor: '',
      vencimento: '',
      recorrencia: '',
      juros: '',
      multa: '',
      ativo: true,
    }
  },
}
