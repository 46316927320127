import exameService from '@/api/attendance/exameServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => exameService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_EXAME', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewExame({ commit }, data) {
    return exameService.insertNewExame(data).then(result => result).catch(error => error)
  },
  prepareToEditExame({ commit }, data) {
    commit('PREPARE_TO_EDIT_EXAME', data)
  },
  editExame({ commit }, data) {
    return exameService.editExame(data).then(result => result).catch(error => error)
  },
  resetExame({ commit }) {
    commit('RESET_EXAME')
  },
  delete({ commit }, id) {
    return exameService.deleteExame(id).then(result => result).catch(error => error)
  },
}
