export default {
  listaUsers: [],
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    perfil: [
      {
        id: ''
      }
    ]
  },
  systemPermission: []
}
