export default {
  LOAD_LIST_ADDRESS_TYPE(state, data) {
    state.listAddressType = data
  },
  PREPARE_TO_EDIT_ADDRESS_TYPE(state, data) {
    state.addressType = data
  },
  RESET_ADDRESS_TYPE(state) {
    state.addressType = {
      ativo: true,
      nome: '',
    }
  },
}
