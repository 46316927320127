<template>
  <img 
    style="
      margin-top: -45px;
      margin-left: -30px;
    "
    href="#" 
    alt="logo" 
    :src="imgurl" 
  />
</template>
<script>
export default {
  name: 'Logo',
  data() {
    return {
      imgurl: require('@/assets/syper_128px_without.png'),
    }
  },
}
</script>
