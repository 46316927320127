import conselhoService from '@/api/attendance/conselhoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => conselhoService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_CONSELHO', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewConselho({ commit }, data) {
    return conselhoService.insertNewConselho(data).then(result => result).catch(error => error)
  },
  prepareToEditConselho({ commit }, data) {
    commit('PREPARE_TO_EDIT_CONSELHO', data)
  },
  editConselho({ commit }, data) {
    return conselhoService.editConselho(data).then(result => result).catch(error => error)
  },
  resetConselho({ commit }) {
    commit('RESET_CONSELHO')
  },
  delete({ commit }, id) {
    return conselhoService.deleteConselho(id).then(result => result).catch(error => error)
  },
}
