import accountTypeServices from '@/api/financial/accountTypeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => accountTypeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ACCOUNT_TYPES', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewAccountType({ commit }, data) {
    return accountTypeServices.insertNewAccountType(data).then(result => result).catch(error => error)
  },
  prepareToEditAccountType({ commit }, data) {
    commit('PREPARE_TO_EDIT_ACCOUNT_TYPE', data)
  },
  editAccountType({ commit }, data) {
    return accountTypeServices.editAccountType(data).then(result => result).catch(error => error)
  },
  resetAccountType({ commit }) {
    commit('RESET_ACCOUNT_TYPE')
  },
  delete({ commit }, id) {
    return accountTypeServices.deleteAccountType(id).then(result => result).catch(error => error)
  },
}
