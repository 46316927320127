<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listTypesOfPaymentReceipts"
        :title-table="$t('Types Of Payment Receipt')"
        module="typeOfPaymentReceiptSettingsModule"
        sort-name="descricao"
        :show-btn-add="verificarPermissao('ROLE_FINAN_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_FINAN_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <FormTypeOfPaymentReceipt v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormTypeOfPaymentReceipt from './forms/FormTypeOfPaymentReceipt.vue'

export default {
  name: 'TypeOfPayment',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormTypeOfPaymentReceipt
  },
  computed: {
    ...mapState('typeOfPaymentReceiptSettingsModule', {
      listTypesOfPaymentReceipts: (state) => state.listTypesOfPaymentReceipts
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      columns: [
        {
          label: this.$t('id'),
          field: 'id'
        },
        {
          label: 'Descrição',
          field: 'descricao'
        },
        {
          label: 'Conta',
          field: 'conta.descricao'
        },
        {
          label: 'Tipo de Conta',
          field: 'conta.tipoConta.descricao'
        },
        {
          label: 'Plano de Conta',
          field: 'conta.planoDeConta.classe'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'descricao',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch(
        'typeOfPaymentReceiptSettingsModule/prepareToEditTypeOfPaymentReceipt',
        data
      )
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
