import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/tipo_enderecos${queryUrl}`)
  },
  insertNewAddressType(data) {
    return axios.post('/core/tipo_enderecos', data)
  },
  editAddressType(data) {
    return axios.put(`/core/tipo_enderecos/${data.id}`, data)
  },
  deleteAddressType(id) {
    return axios.delete(`/core/tipo_enderecos/${id}`)
  },
}
