import fornecedorService from '@/api/AdministrativeModule/fornecedorServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      fornecedorService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_FORNECEDOR', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNewFornecedor({ commit }, data) {
    return fornecedorService
      .insertNewFornecedor(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditFull({ commit }, data) {
    commit('PREPARE_TO_EDIT_FORNECEDOR', data)
  },
  editFornecedor({ commit }, data) {
    return fornecedorService
      .editFornecedor(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetFornecedor({ commit }) {
    commit('RESET_FORNECEDOR')
  },
  loadCards({ commit }) {
    return fornecedorService
      .loadCards()
      .then((result) => result)
      .catch((error) => error)
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) =>
      fornecedorService
        .loadCombo()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  getFornecedorID({ commit }, id) {
    return new Promise((resolve, reject) =>
      fornecedorService
        .getFornecedorID(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  sendNewPhoto({ commit }, object) {
    return fornecedorService
      .sendNewPhoto(object)
      .then((result) => result)
      .catch((error) => error)
  },
  deletePhoto({ commit }, id) {
    return fornecedorService
      .deletePhoto(id)
      .then((result) => result)
      .catch((error) => error)
  },
  sendFiles({ commit }, object) {
    return fornecedorService
      .sendFiles(object)
      .then((result) => result)
      .catch((error) => error)
  },
  loadDocumentations({ commit }, id) {
    return fornecedorService
      .loadDocumentations(id)
      .then((result) => result)
      .catch((error) => error)
  },
  downloadDocument({ commit }, id) {
    return fornecedorService
      .downloadDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  deleteDocument({ commit }, id) {
    return fornecedorService
      .deleteDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) =>
      fornecedorService
        .delete(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  }
}
