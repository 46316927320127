export default {
  listaOrdemServico: [],
  ordemServico: {
    id: null,
    descricao: '',
    dataInicial: '',
    dataTermino: '',
    registro: {
      id: null,
    },
    garantia: true,
    observacao: '',
    status: {
      id: null,
    },
    valor: null,
    valorDesconto: null,
    tipoProtocolo: null,
  },
}
