import parcelamentoService from '@/api/financialModule/parcelamento'

export default {
  load({ commit }, pageConfig) {
    return new Promise((resolve, reject) => parcelamentoService.load(pageConfig).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  insert({ commit }, data) {
    return parcelamentoService.insert(data).then(result => result).catch(error => error)
  },
  edit({ commit }, data) {
    return parcelamentoService.edit(data).then(result => result).catch(error => error)
  },
  getParametrosParcelamento() {
    return new Promise((resolve, reject) => parcelamentoService.getParametrosParcelamento().then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  getParcelamentoPorId({ commit }, id) {
    return new Promise((resolve, reject) => parcelamentoService.getParcelamentoPorId(id).then(result => {
      resolve(result.data)
    }).catch(err => reject(err)))
  },
  filterPayment({ commit }, pageFilter) {
    return new Promise((resolve, reject) => parcelamentoService.filter(pageFilter.pageConfig, pageFilter.filter).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
}
