<template>
  <div>
    <div
      v-if="loading"
      class="clearfix"
    >
      <b-spinner
        class="float-right"
        label="Floated Right"
      />
    </div>
    <FormPaymentReceiptAccount
      v-else
      :title-form="objectFormInsert.titleForm"
      :combo-payment="comboFormaPagamento"
      :combo-type="comboTipoPagamento"
      :combo-situation="comboSituacao"
      :object-edit="pagamentoRecebimento"
      @cancelar="cancelar"
      @save="save"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { BSpinner } from 'bootstrap-vue'
import FormPaymentReceiptAccount from '@/layouts/components/FormPaymentReceiptAccount.vue'
import mixinsGlobal from '@/mixins'

export default {
  name: 'FormContasPagar',
  components: {
    FormPaymentReceiptAccount,
    BSpinner,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboFormaPagamento: state => state.comboFormaPagamento,
      comboTipoPagamento: state => state.comboTipoPagamento,
    }),
    ...mapState('pagamentoRecebimentoFinancialModule', {
      pagamentoRecebimento: state => state.pagamentoRecebimento,
      comboSituacao: state => state.comboSituacao,
    }),
  },
  mounted() {
    this.carregarDados()
  },
  methods: {
    async carregarDados() {
      this.loading = true
      await this.$store.dispatch('combosModule/loadComboFormaPagamento')
      await this.$store.dispatch('combosModule/loadComboTipoPagamento')
      await this.$store.dispatch('pagamentoRecebimentoFinancialModule/resetFinancialAccount')
      const paymentObject = await this.$store.dispatch('pagamentoRecebimentoFinancialModule/getbyIdForEdit', this.$route.params.id)
      const objEdit = paymentObject.data
      objEdit.tipo = objEdit.tipo.id
      objEdit.formaPagamento = objEdit.formaPagamento.id
      await this.$store.dispatch('pagamentoRecebimentoFinancialModule/prepareToEditFinancialAccount', objEdit)
      this.loading = false
    },
    async cancelar() {
      await this.$store.dispatch('pagamentoRecebimentoFinancialModule/resetFinancialAccount')
      this.$router.push({ name: 'recebimento' })
      this.showForm = false
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        registro: objectSave.registro,
        descricao: objectSave.descricao,
        dataLancamento: objectSave.dataLancamento,
        dataVencimento: objectSave.dataVencimento,
        dataQuitacao: objectSave.dataQuitacao,
        valor: objectSave.valor,
        desconto: objectSave.desconto,
        juro: objectSave.juro,
        multa: objectSave.multa,
        valorTotal: objectSave.valor,
        valorQuitado: objectSave.valorQuitado,
        obs: objectSave.obs,
        tipo: {
          id: objectSave.tipo,
        },
        formaPagamento: {
          id: objectSave.formaPagamento,
        },
        situacao: objectSave.situacao,
        ativo: objectSave.ativo,
      }
      return obj
    },
    async save(data) {
      const objectSave = this.prepareForSave(data)
      this.$store.dispatch('pagamentoRecebimentoFinancialModule/editPagamentoRecebimento', objectSave).then(response => {
        if (response.response) {
          this.MensagemError(`Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`, `${response.response.data.errors[0].message}`)
          this.showForm = true
          return
        }
        this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
        this.cancelar()
      })
    },
  },
  data() {
    return {
      loading: true,
      objectFormInsert: {
        titleForm: 'Edição de Contas a Receber',
      },
    }
  },
}
</script>
