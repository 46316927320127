<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="v-description">
                <b-form-input
                  id="v-description"
                  v-model="fiscalNaturePlace.nome"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                  :disabled="
                    (!verificarPermissao('ROLE_ADMIM_ATUALIZAR') &&
                      !!fiscalNaturePlace.id) ||
                    (!verificarPermissao('ROLE_ADMIM_INSERIR') &&
                      !fiscalNaturePlace.id)
                  "
                />
                <small data-cy="v-description-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col v-if="fiscalNaturePlace.id" cols="12">
            <b-form-group>
              <b-form-checkbox
                v-model="fiscalNaturePlace.ativo"
                :checked="fiscalNaturePlace.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_ADMIM_ATUALIZAR') &&
                    !!fiscalNaturePlace.id) ||
                  (!verificarPermissao('ROLE_ADMIM_INSERIR') &&
                    !fiscalNaturePlace.id)
                "
              >
                {{ fiscalNaturePlace.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_ADMIM_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('fiscalNatureSettingsModule', {
      fiscalNature: (state) => state.fiscalNature
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Natureza Fiscal',
      required,
      fiscalNaturePlace: {
        nome: '',
        ativo: true
      }
    }
  },
  mounted() {
    this.fiscalNaturePlace = JSON.parse(JSON.stringify(this.fiscalNature))
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('fiscalNatureSettingsModule/resetFiscalNature')
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.fiscalNaturePlace.id) {
            this.$store
              .dispatch(
                'fiscalNatureSettingsModule/editFiscalNature',
                this.fiscalNaturePlace
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch(
                'fiscalNatureSettingsModule/insertNewFiscalNature',
                this.fiscalNaturePlace
              )
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.fiscalNaturePlace = {
        nome: '',
        ativo: true
      }
    }
  }
}
</script>
