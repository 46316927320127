import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryFilter.order = 'desc'
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisar?${queryFilter.filter}`
    }
    // return axios.get(`/atendimento/atendimentos${queryUrl}`)
    return axios.get(`/profissional/atendimentos${queryUrl}`)
  },
  async edit(data) {
    try {
      await this.insertCid(data.id, data.doencasCid)
      return axios.put(`/profissional/atendimentos/${data.id}`, data)
    } catch (error) {
      console.log(error)
    }
  },
  insertCid(idAtendimento, data) {
    return axios.post(
      `/profissional/doencas_cid/${idAtendimento}/adicionar`,
      data
    )
  },
  delete(id) {
    return axios.delete(`/atendimento/atendimentos/${id}`)
  },
  listaAtendimentoPorIdRegistro(id) {
    return axios.get(`/atendimento/atendimentos/${id}/registro`)
  },
  searchProfessional(name) {
    const endpoint = `/atendimento/atendimentos/pesquisar_profissional?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  searchRegistro(name) {
    const endpoint = `/atendimento/profissionais/registro?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  getAgendaProfissional(id) {
    return axios.get(`atendimento/agendamentos/${id}/profissional`)
  },
  gerarHorarioPorIdAgenda(id) {
    return axios.get(`/atendimento/agendamentos/agenda/${id}`)
  },
  getAtendimentoById(id) {
    return axios.get(`/profissional/atendimentos/${id}`)
  },
  getListaCamposAtendimentoPorIdAtendimento(id) {
    return axios.get(`/atendimento/campo_atendimentos/atendimento/${id}`)
  },
  getListaProcedimentoPorIdProcedimento(id) {
    return axios.get(`/atendimento/campos_adicionais/lista/${id}`)
  },
  getListaProcedimentosPorEspecialidade(id) {
    return axios.get(`/atendimento/procedimentos/especialidade/${id}`)
  },
  editCamposAdicionais(data) {
    return axios.post('/profissional/campo_atendimentos', data)
  },
  getListaCampoAdicionaisPorIdAtendimento(id) {
    return axios.get(`/atendimento/atendimentos/listar_campo/${id}`)
  },
  montarTela(id) {
    return axios.get(`/profissional/atendimentos/montarTela/${id}`)
  },
  getComboSituacao() {
    return axios.get('/profissional/situacao_atendimento')
  },
  carregarHistoricos(id) {
    return axios.get(`/profissional/lista_historico/${id}`)
  },
  getHistoricoById(id) {
    return axios.get(`/profissional/historico/${id}`)
  }
}
