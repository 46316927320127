export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-analytics',
    action: 'ROLE_CORE_CONSULTAR',
    resource: 'ACL'
  }
]
