<template>
  <div
    class="accordion"
    role="tablist"
  >
    <b-card
      no-body
      class="mb-2"
    >
      <b-card-header
        header-tag="header"
        class="p-1"
        role="tab"
      >
        Filtros
      </b-card-header>
      <b-collapse
        id="accordion-1"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <b-card-text>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                lg="3"
              >
                <b-form-group
                  label="Periodo Inicial"
                  label-for="v-dataInicio"
                >
                  <flat-pickr
                    v-model="filter.dataInicio"
                    name="fieldDate"
                    class="form-control"
                    :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: false, dateFormat: 'Y-m-d\\TH:i:ss\\Z' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                lg="3"
              >
                <b-form-group
                  label="Periodo Final"
                  label-for="v-dataFinal"
                >
                  <flat-pickr
                    v-model="filter.dataFinal"
                    name="fieldDate"
                    class="form-control"
                    :config="{ altInput: true, altFormat: 'd/m/Y', enableTime: false, dateFormat: 'Y-m-d\\TH:i:ss\\Z' }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="tipo === -1"
                cols="12"
                sm="6"
                lg="3"
              >
                <b-form-group
                  label="Tipo"
                  label-for="v-tipo"
                >
                  <b-form-select
                    v-model="filter.tipo"
                    :reduce="(comboTipo) => comboTipo.id"
                    text="nome"
                    :options="comboTipo"
                  />

                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                sm="6"
                :lg="(tipo > -1) ? '6' : '3'"
              >
                <b-form-group
                  label="Situação"
                  label-for="v-situacao"
                >
                  <b-form-select
                    v-model="filter.situacao"
                    :reduce="(comboSituacao) => comboSituacao.id"
                    text="nome"
                    :options="comboSituacao"
                  />

                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="filtrar"
                >
                  Filtrar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="warning"
                  @click="limpar"
                >
                  Limpar
                </b-button>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCollapse,
  BCard,
  BCardHeader,
  BFormSelect,
  BCardBody,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import mixinsGlobal from '@/mixins'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    flatPickr,
    vSelect,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BFormSelect,
  },
  mixins: [mixinsGlobal],
  props: {
    tipo: {
      type: Number,
      required: true,
    },
    registroId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      filter: {
        tipo: -1,
        dataInicio: '',
        dataFinal: '',
        situacao: -1,
        campo: '',
        pageConfig: {
          page: 0,
          size: 10,
          sort: 'dataLancamento',
          order: 'desc',
        },
      },
      comboSituacao: [
        { value: -1, text: 'Selecione...' },
        { value: 0, text: 'Aberto' },
        { value: 1, text: 'Vencido' },
        { value: 2, text: 'Quitado' },
        { value: 3, text: 'Pago a Menor' },
      ],
      comboTipo: [
        { value: -1, text: 'Selecione...' },
        { value: 0, text: 'Pagamentos' },
        { value: 1, text: 'Recebimentos' },
        { value: 2, text: 'Emprestimos' },
      ],
    }
  },
  methods: {
    async filtrar() {
      const filter = { ...this.filter }
      filter.dataInicio = this.formatDateGrid(filter.dataInicio)
      filter.dataFinal = this.formatDateGrid(filter.dataFinal)
      filter.registroId = this.registroId
      this.$emit('filtrarComponent', filter)
    },
    limpar() {
      this.filter = {
        tipo: -1,
        dataInicio: '',
        dataFinal: '',
        situacao: -1,
        pageConfig: {
          page: 0,
          size: 10,
          sort: 'dataLancamento',
          order: 'desc',
        },
      }
    },
  },
}
</script>
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
