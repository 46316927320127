export default {
  LOAD_LIST(state, data) {
    state.listaConvenios = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.convenio = data
  },
  RESET(state) {
    state.convenio = {
      id: null,
      descricao: '',
      dataInicio: '',
      dataTermino: '',
      ativo: true,
      responavelNome: '',
      responavelCpf: '',
      responavelRg: '',
      responavelQualificacao: '',
      responavelEstadoCivil: {
        id: null,
      },
      responavelNacionalidade: {
        id: null,
      },
      responavelProfissao: '',
      responavelCargo: '',
      responavelTecnico: '',
      registro: {
        id: null,
      },
      percentualDesconto: 0,
      tipoProtocolo: null,
      itemProtocolo: []
    }
  },
}
