<template>
  <div>
    <div v-if="loading" class="clearfix">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <div v-else>
      <b-card
        style="margin-top: 20%"
        img-alt="Profile Cover Photo"
        img-top
        class="card-profile"
      >
        <div class="profile-image-wrapper">
          <div
            class="profile-image p-0"
            style="padding-top: 15px; margin-top: 15px"
          >
            <b-avatar
              ref="previewEl"
              size="114"
              variant="light"
              :src="fullAssociante.foto"
            />
          </div>
          <div class="demo-inline-spacing">
            &nbsp;
            <b-button
              v-if="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle"
              variant="primary"
              @click="$refs.refInputEl.click()"
            >
              <input
                id="fotoInput"
                ref="refInputEl"
                type="file"
                class="d-none"
                @input="updateFoto"
              />
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              v-if="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
              variant="warning outline-secondary"
              class="btn-icon rounded-circle"
              @click="deleteFoto"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
        <h3>{{ fullAssociante.nome }}</h3>
        <h6 class="text-muted">Matricula: {{ fullAssociante.matricula }}</h6>
        <b-badge class="profile-badge" variant="light-primary">
          <!-- {{fullAssociante.tipoRegistro.nome}} -->
        </b-badge>
      </b-card>
      <b-card>
        <!-- about -->
        <div class="mt-2">
          <h5 class="text-capitalize mb-75">Email:</h5>
          <b-card-text>
            {{ fullAssociante.email }}
          </b-card-text>
        </div>
        <div class="mt-2">
          <h5 class="text-capitalize mb-75">Telefone:</h5>
          <b-card-text v-if="fullAssociante.fones.length > 0">
            {{ fullAssociante.fones[0].numero }}
          </b-card-text>
        </div>
        <div class="mt-2">
          <h5 class="text-capitalize mb-75">Endereço:</h5>
          <b-card-text>
            {{ showEndereco() }}
          </b-card-text>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import {
  BCard,
  BAvatar,
  BBadge,
  BCardText,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BCardText,
    BButton,
    BSpinner
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('associantesAdministrativeModule', {
      fullAssociante: (state) => state.fullAssociante
    })
  },
  methods: {
    showEndereco() {
      let endereco = ''
      if (this.fullAssociante.enderecos.length > 0) {
        endereco = `${this.fullAssociante.enderecos[0].logradouro}, ${this.fullAssociante.enderecos[0].numero}`
        if (this.fullAssociante.enderecos[0].complemento) {
          endereco = `${endereco}, ${this.fullAssociante.enderecos[0].complemento} `
        }
        endereco = `${endereco}, 
                    ${this.fullAssociante.enderecos[0].bairro}, 
                    ${this.fullAssociante.enderecos[0].cidade}/ 
                    ${this.fullAssociante.enderecos[0].uf.sigla} -
                    ${this.fullAssociante.enderecos[0].cep} `
      }
      return endereco
    },
    updateFoto(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const objectSend = {
          id: this.$route.params.id,
          file: input.files[0]
        }
        this.$store
          .dispatch('associantesAdministrativeModule/sendNewPhoto', objectSend)
          .then((response) => {
            if (response.response) {
              this.MensagemError(
                'Falha ao enviar foto',
                response.response.data.error
              )
              this.saveSucess = false
              return
            }
            this.showMessageSuccess('Editar', 'Foto enviada com sucesso!')
            const reader = new FileReader()
            reader.onload = (e) => {
              this.fullAssociante.foto = e.target.result
            }
            reader.readAsDataURL(input.files[0])
          })
          .catch((err) => {
            console.error('ERRRO', err)
            this.MensagemError('Falha ao enviar foto', err.message)
          })
      }
    },
    deleteFoto() {
      this.$store
        .dispatch(
          'associantesAdministrativeModule/deletePhoto',
          this.$route.params.id
        )
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              'Falha ao apagar foto',
              response.response.data.error
            )
            this.saveSucess = false
            return
          }
          this.showMessageSuccess('Removida', 'Foto removida com sucesso!')
          this.$refs.refInputEl.value = null
          this.fullAssociante.foto = ''
        })
    }
  },
  data() {
    return {
      saveSucess: false
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      refInputEl,
      previewEl
    }
  }
}
</script>
