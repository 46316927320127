import axios from '@axios'
import axiosViaCEp from 'axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/agregados${queryUrl}`)
  },
  insertNewAggregates(data) {
    return axios.post('/administrativo/agregados', data)
  },
  editAggregates(data) {
    return axios.put(`/administrativo/agregados/${data.id}`, data)
  },
  deleteAggregates(id) {
    return axios.put(`/administrativo/agregados/${id}`)
  },
  getAggregateID(id) {
    return axios.get(`/administrativo/agregados/${id}`)
  },
  loadCombo() {
    return axios.get('/administrativo/agregados/tipo_registro')
  },
  loadComboGenero() {
    return axios.get('/core/sexos/lista')
  },
  loadComboEstadoCivil() {
    return axios.get('/core/estatos_civis/lista')
  },
  loadComboProfissoes() {
    return axios.get('/core/profissoes/lista')
  },
  loadComboNaturalidades() {
    return axios.get('/core/naturalidades/lista')
  },
  loadComboNacionalidades() {
    return axios.get('/core/nacionalidades/lista')
  },
  loadComboUfs() {
    return axios.get('/core/ufs/lista')
  },
  loadComboPrevidenciaSocial() {
    return axios.get('/core/previdencias_sociais/lista')
  },
  loadComboTipoEndereco() {
    return axios.get('/core/tipo_enderecos/lista')
  },
  loadComboSituacaoRegistro() {
    return axios.get('/core/situacoes_registros/lista')
  },
  editAggregateDadosPessoais(data) {
    return axios.put(`/administrativo/agregados/dados_pessoais/${data.id}`, data)
  },
  editAggregateDocumentation(data) {
    return axios.put(`/administrativo/agregados/documentacao/${data.id}`, data)
  },
  getEnderecoViaCep(cep) {
    return axiosViaCEp({
      method: 'get',
      url: `https://viacep.com.br/ws/${cep}/json/`,
    })
  },
  inserirNovoEndereco(data) {
    return axios.post('/core/enderecos/', data)
  },
  editEndereco(data) {
    return axios.put(`/core/enderecos/${data.id}`, data)
  },
  getAssocianteForCpf(data) {
    return axios.get(`/administrativo/associados/cpf/${data}`)
  },
  setHistoricoSituacao(data) {
    return axios.post('/core/historico_situacao', data)
  },
  getHistoricoSituacao(id) {
    return axios.get(`/core/historico_situacao/${id}`)
  },
  loadEnderecosPorRegistro(id) {
    return axios.get(`/core/enderecos/registro/${id}`)
  },
  deleteEndereco(id) {
    return axios.delete(`/core/enderecos/${id}`)
  },
  sendNewPhoto(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/fotos/${object.id}`, dataForm)
  },
  deletePhoto(id) {
    return axios.get(`/administrativo/fotos/${id}`)
  },
  downloadDocument(id) {
    return axios.get(`/administrativo/documentos/download/${id}`)
  },
  deleteDocument(id) {
    return axios.delete(`/administrativo/documentos/${id}`)
  },
  usarEnderecoAssociado(id) {
    return axios.get(`/administrativo/agregados/endereco/${id}/true`)
  },
  getHistoricoTipoRegistro(id) {
    return axios.get(`/core/historico_tipo/${id}`)
  },
  setMudarTipoRegistro(data) {
    return axios.post('/core/historico_tipo', data)
  },
  loadCards() {
    return axios.get('/administrativo/agregados/card_situacao')
  },
  getHistoricoFaixaContribuicao(id) {
    return axios.get(`/core/historico_contribuicao/${id}`)
  },
  setMudarFaixaContribuica(data) {
    return axios.post('/core/historico_contribuicao', data)
  },
  migrarParaSocio(data) {
    const obj = {
      descricao: data.descricao,
      tipoRegistro: data.tipoRegistro.id,
    }
    return axios.post(`administrativo/agregados/tornar_associado/${data.registro.id}`, obj)
  },
}
