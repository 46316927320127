export default {
  SET_LIST_COMBO_GENERO(state, data) {
    state.comboGeneros = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_ESTADO_CIVIL(state, data) {
    state.comboEstadoCivil = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_PROFISSOES(state, data) {
    state.comboProfissoes = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_NATURALIDADES(state, data) {
    state.comboNaturalidades = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_NACIONALIDADES(state, data) {
    state.comboNacionalidades = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_TYPES_REGISTER(state, data) {
    state.comboTypesRegister = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_UFS(state, data) {
    state.comboUfs = data.map((item) => ({
      id: item.id,
      nome: item.nome,
      sigla: item.sigla
    }))
  },
  SET_LIST_COMBO_PREVIDENCIA_SOCIAL(state, data) {
    state.comboPrevidenciaSocial = data.map((item) => ({
      id: item.id,
      nome: item.nome,
      label: `${item.codigo} - ${item.nome}`
    }))
  },
  SET_LIST_COMBO_TIPO_ENDERECO(state, data) {
    state.comboTipoEndereco = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_SITUACAO_REGISTRO(state, data) {
    state.comboSituacaoRegistro = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_NATUREZA_FISCAL(state, data) {
    state.comboNaturezaFiscals = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_FORMA_PAGAMENTO(state, data) {
    state.comboFormaPagamento = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_TIPO_PAGAMENTO(state, data) {
    state.comboTipoPagamento = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_FAIXA_CONTRIBUICAO(state, data) {
    state.comboFaixaContribuicao = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_DEPEDENTES_TIPO(state, data) {
    state.comboDepedentesPorTipo = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_TAXAS(state, data) {
    state.comboTaxas = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_ESPECIALIDADES(state, data) {
    state.comboEspecialidades = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_TIPOS_ATENDIMENTO(state, data) {
    state.comboTiposAtendimento = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_TIPO_PROTOCOLO(state, data) {
    state.comboTipoProtocolo = data.map((item) => ({
      id: item.id,
      descricao: item.descricao
    }))
  },
  SET_LIST_COMBO_MEIO_PROTOCOLO(state, data) {
    state.comboMeioProtocolo = data.map((item) => ({
      id: item.id,
      descricao: item.descricao
    }))
  },
  SET_LIST_COMBO_TYPES_REGISTER(state, data) {
    state.comboTypesRegister = data.map((item) => ({
      id: item.id,
      descricao: item.nome
    }))
  },
  SET_LIST_COMBO_CONSELHOS(state, data) {
    state.comboConselhos = data.map((item) => ({
      id: item.id,
      descricao: item.descricao
    }))
  },
  SET_LIST_COMBO_UFS(state, data) {
    // @TODO implementar correções
    state.comboUfs = data.map((item) => ({
      id: item.id,
      descricao: item.sigla
    }))
  },
  SET_LIST_COMBO_CIDADES(state, data) {
    state.comboCidades = data.map((item) => ({
      id: item.nome,
      descricao: item.nome
    }))
  },
  SET_LIST_COMBO_PROCEDIMENTOS(state, data) {
    state.comboProcedimentos = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_PROTOCOLO(state, data) {
    state.comboProtocolo = data.map((item) => ({
      id: item.id,
      nome: `${item.numeroProtocolo} - ${item.tipo.descricao}`,
      tipo: item.tipo.id
    }))
  },
  SET_LIST_COMBO_ITEM(state, data) {
    state.comboItem = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_STATUS_OS(state, data) {
    state.comboStatusOs = data.map((item) => ({
      id: item.id,
      nome: item.descricao
    }))
  },
  SET_LIST_COMBO_GRUPOS(state, data) {
    state.comboGrupos = data.content.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_ROLES(state, data) {
    state.comboRoles = data.map((item) => ({
      id: item.id,
      description: item.authority
    }))
  },
  SET_LIST_COMBO_PERMISSIONS(state, data) {
    state.comboPermissions = data.map((item) => ({
      id: item.id,
      description: item.authority
    }))
  },
  SET_LIST_COMBO_PERFILS(state, data) {
    state.comboPerfils = data.map((item) => ({
      id: item.id,
      nome: item.nome
    }))
  },
  SET_LIST_COMBO_PERMISSIONS_PROFILE(state, data) {
    state.comboPermissionsProfile = data.map((item) => ({
      id: item.id,
      authority: item.authority
    }))
  },
  SET_LIST_COMBO_DOENCAS_CID(state, data) {
    state.comboDoencasCid = data.map((item) => ({
      id: item.id,
      nome: item.cat + ' - ' + item.descricao
    }))
  }
}
