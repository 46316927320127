export default {
  LOAD_LIST(state, data) {
    state.listaAgendas = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.agenda = data
  },
  RESET(state) {
    state.agenda = {
      dataInicial: '',
      dataFinal: '',
      horaInicial: '',
      horaFinal: '',
      profissional: '',
      ativo: true,
      qtoVagas: 0,
      repetir: '',
      domingo: false,
      segunda: true,
      terca: true,
      quarta: true,
      quinta: true,
      sexta: true,
      sabado: false,
      semana: 0,
    }
  },
}
