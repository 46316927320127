import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/pagamentos_recebementos/${queryFilter.tipo}/tipo${queryUrl}`)
  },
  insertNewPagamentoRecebimento(data) {
    return axios.post('/financeiro/pagamentos_recebementos/', data)
  },
  getbyIdForEdit(id) {
    return axios.get(`/financeiro/pagamentos_recebementos/${id}`)
  },
  editPagamentoRecebimento(data) {
    return axios.put(`/financeiro/pagamentos_recebementos/${data.id}`, data)
  },
  deletePagamentoRecebimento(id) {
    return axios.delete(`/financeiro/pagamentos_recebementos/${id}`)
  },
  listFinancial(configs) {
    let queryUrl = ''
    queryUrl = `?page=${configs.configsPage.page}&size=${configs.configsPage.size}&sort=${configs.configsPage.sort},${configs.configsPage.order}`
    if (configs.configsPage.filter) {
      queryUrl = `/pesquisa?${configs.configsPage.filter}`
    }
    return axios.get(`/financeiro/pagamentos_recebementos/${configs.id}/registro${queryUrl}`)
  },
  filter(pageConfig, filter) {
    let queryUrl = ''
    queryUrl = `?page=${pageConfig.page}&size=${pageConfig.size}&${pageConfig.sort},${pageConfig.order}`
    return axios.post(`financeiro/pagamentos_recebementos/filtro${queryUrl}`, filter)
  },
  searchRegistros(name) {
    const endpoint = `/atendimento/profissionais/registro?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
}
