import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/tipo_pagamento_recebimentos${queryUrl}`)
  },
  insertNewTypeOfPaymentReceipt(data) {
    return axios.post('/financeiro/tipo_pagamento_recebimentos', data)
  },
  editTypeOfPaymentReceipt(data) {
    return axios.put(`/financeiro/tipo_pagamento_recebimentos/${data.id}`, data)
  },
  deleteTypeOfPaymentReceipt(id) {
    return axios.delete(`/financeiro/tipo_pagamento_recebimentos/${id}`)
  },
  loadCombo() {
    return axios.get('/financeiro/contas/lista')
  },
}
