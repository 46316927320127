<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listOthers"
        sort-name="nome"
        title-table="Outros"
        module="othersAdministrativeModule"
        :show-btn-add="verificarPermissao('ROLE_ADMIM_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ADMIM_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <form-insert
        v-else
        :title-form="objectFormInsert.titleForm"
        :combo-type="comboTypesRegister"
        @cancelar="cancelar"
        @save="save"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import FormInsert from '@/layouts/components/FormInsert.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'

export default {
  name: 'Others',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormInsert
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('othersAdministrativeModule', {
      listOthers: (state) => state.listOthers,
      other: (state) => state.other,
      comboTypesRegister: (state) => state.comboTypesRegister
    })
  },
  mounted() {
    this.$store.dispatch('othersAdministrativeModule/loadComboTypeNotAssociate')
  },
  data() {
    return {
      showForm: false,
      columns: [
        {
          label: 'Matricula',
          field: 'matricula'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'Tipo Registro',
          field: 'tipoRegisto'
        },
        {
          label: 'Data Nascimento',
          field: 'dataNascimento',
          type: 'date',
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'CPF',
          field: 'cpf'
        },
        {
          label: 'Fone',
          field: 'fone'
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Outros'
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$router.push({ path: `editOther/${data.id}` })
    },
    cancelar() {
      this.$store.dispatch('othersAdministrativeModule/resetOther')
      this.showForm = false
    },
    save(data) {
      this.$store
        .dispatch('othersAdministrativeModule/insertNewOther', data)
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
          this.edit(response.data)
        })
    }
  }
}
</script>
