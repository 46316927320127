export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-analytics',
    action: 'read',
    resource: 'ACL',
  },
]
