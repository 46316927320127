import recordSituationsServices from '@/api/administrative/recordSituationsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return recordSituationsServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_RECORD_SITUATIONS', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewRecordSituations({ commit }, data) {
    return recordSituationsServices.insertNewRecordSituations(data).then(result => result).catch(error => error)
  },
  prepareToEditRecordSituations({ commit }, data) {
    commit('PREPARE_TO_EDIT_RECORD_SITUATIONS', data)
  },
  editRecordSituations({ commit }, data) {
    return recordSituationsServices.editRecordSituations(data).then(result => result).catch(error => error)
  },
  delete({ commit }, id) {
    return recordSituationsServices.deleteRecordSituations(id).then(result => result).catch(error => error)
  },
  resetRecordSituations({ commit }) {
    commit('RESET_RECORD_SITUATIONS')
  },
}
