export default {
  listSocialSecurity: [],
  socialSecurity: {
    id: 0,
    codigo: null,
    nome: '',
    ativo: true,
    grupoPrevidenciaSocialId: null,
    grupoPrevidenciaSocialNome: '',
  },
  comboSocialSecurityGroup: [],
}
