<template>
  <b-row>
    <b-col cols="12">
      <div v-if="showGrid">
        <b-card title="Arquivos INSS">
          <b-button
            class="mb-3"
            variant="primary"
            @click="importarNovoArquivo()"
          >
            Importar arquivo
          </b-button>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Pesquisar</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="pesquisa"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <!-- Principal -->
          <vue-good-table
            mode="remote"
            :columns="columnsFiles"
            :rows="listaHistorico"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            @on-row-click="getFileByID"
            @on-sort-change="onSortChange"
          >
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="tableConfigs.size"
                    :options="[tableConfigs.size]"
                    class="mx-1"
                    @input="handleChangePage"
                  />
                  <span class="text-nowrap">
                    of {{ tableConfigs.totalPages }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="tableConfigs.totalElements"
                    :per-page="tableConfigs.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </div>
      <div v-else>
        <b-card v-if="!showForm" title="Arquivo de Retorno INSS">
          <b-form @submit.prevent>
            <b-spinner
              v-if="loading"
              class="float-right"
              label="Floated Right"
            />
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-file
                  v-model="file"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />
              </b-col>
              <!-- submit and reset -->
              <b-col cols="12" class="mt-3">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  @click="importFile"
                >
                  Importar
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="cancelar"
                >
                  Cancelar
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </div>
      <div v-if="showForm">
        <b-card title="Dados Importados">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="mb-2"
            type="reset"
            variant="warning"
            @click="cancelar"
          >
            Sair
          </b-button>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Pesquisar</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="pesquisa"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="listaConteudo"
            :rtl="direction"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            total-rows="totalRecords"
            :is-loading.sync="isLoading"
            @on-sort-change="onSortChange"
          >
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="tableConfigs.size"
                    :options="[tableConfigs.size]"
                    class="mx-1"
                    @input="handleChangePage"
                  />
                  <span class="text-nowrap">
                    of {{ tableConfigs.totalPages }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="tableConfigs.totalElements"
                    :per-page="tableConfigs.size"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormFile,
  BForm,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect
} from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import _ from 'lodash'

export default {
  name: 'ImportExport',
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BFormFile,
    BForm,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BPagination,
    BFormSelect
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      loading: false,
      totalRecords: 0,
      isLoading: false,
      searchTerm: '',
      showForm: false,
      file: null,
      listaConteudo: [],
      showGrid: true,
      columns: [
        {
          label: 'Matrícula',
          field: 'matricula'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'N° Benefício',
          field: 'numeroBeneficio'
        },
        {
          label: 'Mês do Arquivo',
          field: 'mesArquivo'
        },
        {
          label: 'Desconto',
          field: 'desconto',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'faapipe',
          field: 'faapipe',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'aac',
          field: 'aac',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'cobap',
          field: 'cobap',
          formatFn: this.formatCurrencyBrazil,
          sortable: false
        },
        {
          label: 'Data Importacao',
          field: 'dataImportacao',
          formatFn: this.formatDateGrid,
          sortable: false
        }
      ],
      listaHistorico: [],
      columnsFiles: [
        {
          label: 'Mês',
          field: 'mes',
          type: 'date',
          dateInputFormat: 'dd-MM-yyyy',
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'Identificador',
          field: 'identificador'
        },
        {
          label: 'Data Importação',
          field: 'importacao',
          type: 'date',
          dateInputFormat: 'dd-MM-yyyy',
          dateOutputFormat: 'dd/MM/yyyy'
        }
      ],
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'id',
        order: 'asc'
      },
      pageLength: 5,
      dir: false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  methods: {
    gerarNomeArquivoExportacao() {
      const date = new Date()
      const currentYear = date.getFullYear().toString().substr(-2)
      let currentMonth = date.getMonth() + 1
      if (currentMonth < 10) {
        currentMonth = `0${currentMonth}`
      }
      return `WFOL${currentMonth}${currentYear}.dat`
    },
    async importFile() {
      this.loading = true
      this.$store
        .dispatch('filesFinancialModule/importInss', this.file)
        .then((response) => {
          this.listaConteudo = response.data
          this.showMessageSuccess('Importado', 'arquivo importado com sucesso!')
          this.cancelar()
        })
        .catch((err) => {
          console.error('ERRRO', err)
          this.loading = false
        })
    },
    loadHistoricoFiles() {
      this.loading = true
      this.$store
        .dispatch(
          'filesFinancialModule/loadHistoricoArquivosInss',
          this.pageConfig
        )
        .then((response) => {
          this.pageConfig.page = response.data.pageable.pageNumber
          this.tableConfigs.last = response.data.last
          this.tableConfigs.totalPages = response.data.totalPages
          this.tableConfigs.totalElements = response.data.totalElements
          this.tableConfigs.size = response.data.size
          this.tableConfigs.number = response.data.number
          this.listaHistorico = response.data.content
          this.loading = true
        })
    },
    importarNovoArquivo() {
      this.file = null
      this.showGrid = false
    },
    async cancelar() {
      this.file = null
      this.showGrid = true
      this.showForm = false
    },
    getFileByID(params) {
      this.pageConfig.identificador = params.row.identificador
      this.$store
        .dispatch(
          'filesFinancialModule/carregarPorIdentificador',
          this.pageConfig
        )
        .then((response) => {
          this.pageConfig.page = response.data.pageable.pageNumber
          this.tableConfigs.last = response.data.last
          this.tableConfigs.totalPages = response.data.totalPages
          this.tableConfigs.totalElements = response.data.totalElements
          this.tableConfigs.size = response.data.size
          this.tableConfigs.number = response.data.number
          this.listaConteudo = response.data.content
          this.cancelar()
          this.loading = false
          this.showForm = true
          this.showGrid = false
        })
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      if (this.pageConfig.identificador) {
        this.setParams()
        return
      }
      this.loadHistoricoFiles()
    },
    handleSearch: _.debounce(function (searching) {
      this.pageConfigdefault()
      if (searching) {
        this.pageConfig.filter = `nome=${searching}`
      }
      if (this.pageConfig.identificador) {
        this.setParams()
        return
      }
      this.loadHistoricoFiles()
    }, 300),
    pageConfigdefault() {
      this.pageConfig = {
        check: false,
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      if (this.pageConfig.identificador) {
        this.setParams()
        return
      }
      this.loadHistoricoFiles()
    },
    setParams() {
      const params = {}
      params.row = { identificador: this.pageConfig.identificador }
      this.getFileByID(params)
    }
  },
  mounted() {
    this.loadHistoricoFiles()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
