<template>
  <div>
    <div v-if="disableForm">
      <div class="custom-search d-flex mb-2">
        <b-button
          class="justify-content-start"
          variant="primary"
          @click="inserirHistorico()"
        >
          Alterar Tipo de Registro
        </b-button>
        <b-button
          class="justify-content-start ml-1"
          variant="primary"
          @click="migrarAssociados()"
        >
          Migrar Para Sócio
        </b-button>
        <b-button
          class="justify-content-start ml-1"
          variant="primary"
          @click="MigrarDepedentes()"
        >
          Migrar Para Depedente
        </b-button>
        <b-button
          class="justify-content-start ml-1"
          variant="primary"
          @click="MigrarAgregados()"
        >
          Migrar Para Agregado
        </b-button>
      </div>
      <div class="custom-search d-flex justify-content-end mb-2">
        <b-badge variant="primary" style="font-size: 16px">
          Registro Atual: {{ registroAtual }}
        </b-badge>
      </div>
      <b-row>
        <b-col md="12">
          <div class="justify-content-center mb-2">
            <h4>Histórico do Tipo de Registro</h4>
          </div>
        </b-col>
      </b-row>
      <vue-good-table
        :columns="columns"
        :rows="listaHistorico"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      />
    </div>
    <div v-else>
      <validation-observer ref="form">
        <b-form @submit.prevent>
          <b-row>
            <b-col
              cols="12"
              v-if="type === 'setMudarTipoRegistroDependenteAgregado'"
            >
              <validation-provider
                #default="{ errors }"
                name="cpf Associado"
                rules=""
              >
                <b-form-group
                  label="CPF do Associado"
                  label-for="v-cpf-associado"
                >
                  <cleave
                    id="v-cpf-associado"
                    v-model="TipoRegistroPlace.cpf"
                    class="form-control"
                    :class="[errors.length > 0 ? isValid : '']"
                    :raw="false"
                    placeholder="cpf do associado"
                    :options="maskCpf"
                    :readonly="ehAssociadoHabilitado()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- nome -->
            <b-col cols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="descricao"
                rules="required"
              >
                <b-form-group label="Descrição" label-for="v-descricao">
                  <b-form-input
                    id="v-descricao"
                    v-model="TipoRegistroPlace.descricao"
                    :state="errors.length > 0 ? false : null"
                    placeholder="descrição"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- situacaoRegistro -->
            <b-col ols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="tipoRegistro"
                rules="required"
              >
                <b-form-group label="Tipo Registro" label-for="v-tipoRegistro">
                  <v-select
                    v-model="TipoRegistroPlace.tipoRegistro"
                    :disabled="disableForm"
                    label="nome"
                    :state="errors.length > 0 ? false : null"
                    :options="comboTypesRegister"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueGoodTable,
    BBadge,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('othersAdministrativeModule', {
      fullOther: (state) => state.fullOther,
      comboTypesRegister: (state) => state.comboTypesRegister
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    this.getHistoricoTipoRegistro()
    this.registroAtual = this.fullOther.tipoRegistro.nome
  },
  methods: {
    ehAssociadoHabilitado() {
      if (this.TipoRegistroPlace.tipoRegistro.id === this.CONVIDADO_CODIGO) {
        return false
      }
      return this.ehAssociado
    },
    getHistoricoTipoRegistro() {
      this.$store
        .dispatch(
          'othersAdministrativeModule/getHistoricoTipoRegistro',
          this.$route.params.id
        )
        .then((response) => {
          this.listaHistorico = response.data
        })
    },
    inserirHistorico() {
      this.type = 'setMudarTipoRegistro'
      this.redirect = ''
      this.$store
        .dispatch('othersAdministrativeModule/loadComboTypesRegister')
        .then(() => {
          this.TipoRegistroPlace = {
            descricao: '',
            registro: {
              id: null
            },
            tipoRegistro: {
              id: null
            }
          }
          this.TipoRegistroPlace.registro.id = this.$route.params.id
          this.disableForm = false
        })
    },
    migrarAssociados() {
      this.type = 'setMudarTipoRegistroAssociado'
      this.redirect = ''
      this.$store
        .dispatch('othersAdministrativeModule/loadComboTypesRegisterAssociate')
        .then(() => {
          this.TipoRegistroPlace = {
            descricao: '',
            registro: {
              id: null
            },
            tipoRegistro: {
              id: null
            }
          }
          this.TipoRegistroPlace.registro.id = this.$route.params.id
          this.disableForm = false
        })
    },
    MigrarDepedentes() {
      this.type = 'setMudarTipoRegistroDependenteAgregado'
      this.redirect = 'dependents'
      this.$store
        .dispatch('othersAdministrativeModule/loadComboTypesRegisterDependent')
        .then(() => {
          this.TipoRegistroPlace = {
            cpf: '',
            descricao: '',
            registro: {
              id: null
            },
            tipoRegistro: {
              id: null
            }
          }
          this.TipoRegistroPlace.registro.id = this.$route.params.id
          this.disableForm = false
        })
    },
    MigrarAgregados() {
      this.type = 'setMudarTipoRegistroDependenteAgregado'
      this.redirect = 'agregados'
      this.$store
        .dispatch('othersAdministrativeModule/loadComboTypesRegisterAggregate')
        .then(() => {
          this.TipoRegistroPlace = {
            cpf: '',
            descricao: '',
            registro: {
              id: null
            },
            tipoRegistro: {
              id: null
            }
          }
          this.TipoRegistroPlace.registro.id = this.$route.params.id
          this.disableForm = false
        })
    },
    cancel() {
      this.TipoRegistroPlace = {
        descricao: '',
        registro: {
          id: null
        },
        situacaoRegistro: {
          id: null
        }
      }
      this.type = 'setMudarTipoRegistro'
      this.disableForm = true
    },
    save() {
      if (!this.TipoRegistroPlace) {
        this.MensagemError('Falha ao Editar', 'Dados n o informados')
        return
      }

      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch(
              'othersAdministrativeModule/' + this.type,
              this.TipoRegistroPlace
            )
            .then((response) => {
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              if (response.data === true) {
                this.$router.push({
                  path: '/modules/administrative/phisicalPerson/others/'
                })
                return
              }

              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.message)
                return
              }

              if (response.error) {
                this.MensagemError('Falha ao Editar', response.message)
                return
              }

              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              if (this.type === 'setMudarTipoRegistroAssociado') {
                this.$router.push({
                  path: `/modules/administrative/phisicalPerson/editAssociante/${this.TipoRegistroPlace.registro.id}`
                })
              }
              this.registroAtual = response.data.tipoRegistro.nome
              this.TipoRegistroPlace.situacaoRegistro =
                response.data.tipoRegistro
              this.getHistoricoTipoRegistro()
              this.disableForm = true
            })
            .catch((error) => {
              if (error.response) {
                this.MensagemError('Falha ao Editar', error.response.data.error)
              } else {
                this.MensagemError('Falha ao Editar', 'Erro desconhecido')
              }
            })
        }
      })
    },
    fieldFn(rowObj) {
      let data = rowObj.dataCriacao.substring(0, 10)
      data = data.toLocaleString()
      return data
    }
  },
  data() {
    return {
      type: 'setMudarTipoRegistro',
      maskCpf: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true
      },
      redirect: '',
      registroAtual: null,
      listaHistorico: [],
      required,
      disableForm: true,
      TipoRegistroPlace: {
        descricao: '',
        registro: {
          id: null
        },
        situacaoRegistro: {
          id: null
        }
      },
      columns: [
        {
          label: 'Tipo de Registro',
          field: 'tipoRegistro.nome'
        },
        {
          label: 'Descrição',
          field: 'descricao'
        },
        {
          label: 'Data',
          field: this.fieldFn,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      },
      pageLength: 10,
      dir: false
    }
  }
}
</script>
