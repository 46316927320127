export default {
  LOAD_LIST_PHONE_TYPE(state, data) {
    state.listPhoneType = data
  },
  PREPARE_TO_EDIT_PHONE_TYPE(state, data) {
    state.phoneType = data
  },
  RESET_PHONE_TYPE(state) {
    state.phoneType = {
      id: 0,
      ativo: true,
      nome: '',
    }
  },
}
