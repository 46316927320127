import axios from '@axios'
import axiosViaCEp from 'axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/outros${queryUrl}`)
  },
  insertNewOther(data) {
    return axios.post('/administrativo/outros', data)
  },
  editOther(data) {
    return axios.put(`/administrativo/outros/${data.id}`, data)
  },
  deleteOther(id) {
    return axios.put(`/administrativo/outros/${id}`)
  },
  getOtherID(id) {
    return axios.get(`/administrativo/outros/${id}`)
  },
  loadCombo() {
    return axios.get('/administrativo/outros/tipo_registro')
  },
  loadComboTypeNotAssociate() {
    return axios.get('/administrativo/outros/tipo_registro')
  },
  loadComboGenero() {
    return axios.get('/core/sexos/lista')
  },
  loadComboEstadoCivil() {
    return axios.get('/core/estatos_civis/lista')
  },
  loadComboProfissoes() {
    return axios.get('/core/profissoes/lista')
  },
  loadComboNaturalidades() {
    return axios.get('/core/naturalidades/lista')
  },
  loadComboNacionalidades() {
    return axios.get('/core/nacionalidades/lista')
  },
  loadComboUfs() {
    return axios.get('/core/ufs/lista')
  },
  loadComboPrevidenciaSocial() {
    return axios.get('/core/previdencias_sociais/lista')
  },
  loadComboTipoEndereco() {
    return axios.get('/core/tipo_enderecos/lista')
  },
  editOtherDadosPessoais(data) {
    return axios.put(`/administrativo/outros/dados_pessoais/${data.id}`, data)
  },
  editOtherDocumentation(data) {
    return axios.put(`/administrativo/outros/documentacao/${data.id}`, data)
  },
  getEnderecoViaCep(cep) {
    return axiosViaCEp({
      method: 'get',
      url: `https://viacep.com.br/ws/${cep}/json/`
    })
  },
  inserirNovoEndereco(data) {
    return axios.post('/core/enderecos/', data)
  },
  editEndereco(data) {
    return axios.put(`/core/enderecos/${data.id}`, data)
  },
  // Novos
  sendNewPhoto(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/fotos/${object.id}`, dataForm)
  },
  deletePhoto(id) {
    return axios.get(`/administrativo/fotos/${id}`)
  },
  loadComboSituacaoRegistro() {
    return axios.get('/core/situacoes_registros/lista')
  },
  setHistoricoSituacao(data) {
    return axios.post('/core/historico_situacao', data)
  },
  getHistoricoSituacao(id) {
    return axios.get(`/core/historico_situacao/${id}`)
  },
  loadEnderecosPorRegistro(id) {
    return axios.get(`/core/enderecos/registro/${id}`)
  },
  deleteEndereco(id) {
    return axios.delete(`/core/enderecos/${id}`)
  },
  getListDepedentAssociante(id) {
    return axios.get(`/administrativo/associados/dependentes/${id}`)
  },
  loadDocumentations(id) {
    return axios.get(`/administrativo/documentos/registro/${id}`)
  },
  sendFiles(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/documentos/${object.id}`, dataForm)
  },
  downloadDocument(id) {
    return axios.get(`/administrativo/documentos/download/${id}`)
  },
  deleteDocument(id) {
    return axios.delete(`/administrativo/documentos/${id}`)
  },
  getHistoricoTipoRegistro(id) {
    return axios.get(`/core/historico_tipo/${id}`)
  },
  setMudarTipoRegistro(data) {
    return axios.post('/core/historico_tipo', data)
  },
  loadCards() {
    return axios.get('/administrativo/outros/card_situacao')
  },
  getHistoricoFaixaContribuicao(id) {
    return axios.get(`/core/historico_contribuicao/${id}`)
  },
  setMudarFaixaContribuica(data) {
    return axios.post('/core/historico_contribuicao', data)
  },
  migrarAssociados(data) {
    return axios.post(`/administrativo/outros/tornar_associado/${data.id}`, {
      descricao: data.descricao,
      tipoRegistro: 1
    })
  },
  loadComboTypesRegisterAssociate() {
    return axios.get('/administrativo/associados/tipo_registro')
  },
  loadComboTypesRegisterDependent() {
    return axios.get('/administrativo/dependentes/tipo_registro')
  },
  loadComboTypesRegisterAggregate() {
    return axios.get('/administrativo/agregados/tipo_registro')
  },
  setMudarTipoRegistroAssociado(data) {
    const obj = {
      descricao: data.descricao,
      tipoRegistro: data.tipoRegistro.id
    }
    return axios.post(
      `/administrativo/outros/tornar_associado/${data.registro.id}`,
      obj
    )
  },
  setMudarTipoRegistroDependenteAgregado(data) {
    const obj = {
      cpf: data.cpf,
      descricao: data.descricao,
      tipoRegistro: data.tipoRegistro.id
    }
    return axios.post(
      `/administrativo/outros/tornar_dependente_agregado/${data.registro.id}`,
      obj
    )
  }
}
