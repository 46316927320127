import axios from '@axios'

export default {
  loadUf() {
    return axios({
      method: 'get',
      url: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados',
    })
  },
  loadMunicipiosPorUf(uf) {
    return axios({
      method: 'get',
      url: `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`,
    })
  },
}
