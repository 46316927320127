export default {
  LOAD_LIST_PAGAMENTO_RECEBIMENTO(state, data) {
    state.listaPagamentoRecebimentos = data
  },
  PREPARE_TO_EDIT_PAGAMENTO_RECEBIMENTO(state, data) {
    state.pagamentoRecebimento = data
  },
  RESET_PAGAMENTO_RECEBIMENTO(state) {
    state.pagamentoRecebimento = {
      id: '',
      registro: '',
      descricao: '',
      dataLancamento: null,
      dataVencimento: null,
      dataQuitacao: null,
      valor: 0.00,
      desconto: 0.00,
      juro: 0.00,
      multa: 0.00,
      valorTotal: 0.00,
      valorQuitado: 0.00,
      obs: null,
      tipo: null,
      formaPagamento: null,
      situacao: null,
      ativo: true,
    }
  },
}
