<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listParameters"
        title-table="Parametros"
        module="parameterFinancialSettingsModule"
        sort-name="nome"
        :show-btn-add="false"
        :show-action-delete="false"
        @add="addNew"
        @edit="edit"
      />
      <FormParameter
        v-else
        @cancelar="cancelar"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import FormParameter from './forms/FormParameter.vue'

export default {
  name: 'Bank',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormParameter,
  },
  computed: {
    ...mapState('parameterFinancialSettingsModule', {
      listParameters: state => state.listParameters,
    }),
  },
  data() {
    return {
      showForm: false,
      columns: [
        {
          label: 'Nome',
          field: 'nome',
        },
        {
          label: 'Valor',
          field: 'valor',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch('parameterFinancialSettingsModule/prepareToEditParameter', data)
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    },
  },
}
</script>
