export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL'
    }
  }
]
