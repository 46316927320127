export default {
  listBanksAccount: [],
  bankAccount: {
    descricao: '',
    planoDeConta: { id: null },
    tipoConta: { id: null },
    dataSaldoInicial: '',
    valorSaldoInicial: '',
    agencia: '',
    conta: '',
    banco: {
      id: null,
    },
    valorSaldoAtual: 0,
    ativo: true,
  },
  planoDeContaCombo: [],
  tipoContaCombo: [],
  comboBanks: [],
}
