import api from '@/api/ordemServico/statusOsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return api.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST', result.data.content)
      return result
    }).catch(err => err)
  },
  insert({ commit }, data) {
    return api.insert(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return api.edit(data).then(result => result).catch(error => error)
  },
  delete({ commit }, id) {
    return api.delete(id).then(result => result).catch(error => error)
  },
  reset({ commit }) {
    commit('RESET')
  }
}
