import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/grupo_procedimento${queryUrl}`)
  },
  insert(data) {
    return axios.post('/atendimento/grupo_procedimento', data)
  },
  edit(data) {
    return axios.put(`/atendimento/grupo_procedimento/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/atendimento/grupo_procedimento/${id}`)
  }
}
