import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = '?page=0&size=10&sort=descricao,asc'
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/fones${queryUrl}`)
  },
  insertNewFone(data) {
    return axios.post('/core/fones', data)
  },
  editFone(data) {
    return axios.put(`/core/fones/${data.id}`, data)
  },
  deleteFone(id) {
    return axios.delete(`/core/fones/${id}`)
  },
  getFoneByIdRegister(id) {
    return axios.get(`/core/fones/registro/${id}`)
  },
  loadComboTipoFone() {
    return axios.get('/core/fone_tipos/lista')
  },
}
