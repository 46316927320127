import convenioService from '@/api/attendance/convenioServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => convenioService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_CONVENIO', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewConvenio({ commit }, data) {
    return convenioService.insertNewConvenio(data).then(result => result).catch(error => error)
  },
  prepareToEditConvenio({ commit }, data) {
    commit('PREPARE_TO_EDIT_CONVENIO', data)
  },
  editConvenio({ commit }, data) {
    return convenioService.editConvenio(data).then(result => result).catch(error => error)
  },
  resetConvenio({ commit }) {
    commit('RESET_CONVENIO')
  },
  delete({ commit }, id) {
    return convenioService.deleteConvenio(id).then(result => result).catch(error => error)
  },
}
