import pagamento from './pagamento'
import recebimento from './recebimento'
import accountFiles from './accountFiles'
import emprestimo from './emprestimo'
import report from './report'

export default [
  ...pagamento,
  ...recebimento,
  ...accountFiles,
  ...emprestimo,
  ...report,
]
