import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/faixa_contribuicoes${queryUrl}`)
  },
  insertNewContributionRange(data) {
    return axios.post('/financeiro/faixa_contribuicoes', data)
  },
  editContributionRange(data) {
    return axios.put(`/financeiro/faixa_contribuicoes/${data.id}`, data)
  },
  deleteContributionRange(id) {
    return axios.delete(`/financeiro/faixa_contribuicoes/${id}`)
  },
}
