var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":(!_vm.verificarPermissao('ROLE_ADMIM_ATUALIZAR') &&
                    !!_vm.genderPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_ADMIM_INSERIR') &&
                    !_vm.genderPlace.id)},model:{value:(_vm.genderPlace.nome),callback:function ($$v) {_vm.$set(_vm.genderPlace, "nome", $$v)},expression:"genderPlace.nome"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"sigla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sigla","label-for":"v-sigla"}},[_c('b-form-input',{attrs:{"id":"v-sigla","state":errors.length > 0 ? false : null,"placeholder":"sigla","disabled":(!_vm.verificarPermissao('ROLE_ADMIM_ATUALIZAR') &&
                    !!_vm.genderPlace.id) ||
                  (!_vm.verificarPermissao('ROLE_ADMIM_INSERIR') &&
                    !_vm.genderPlace.id)},model:{value:(_vm.genderPlace.sigla),callback:function ($$v) {_vm.$set(_vm.genderPlace, "sigla", $$v)},expression:"genderPlace.sigla"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-sigla-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.genderPlace.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":_vm.genderPlace.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_ADMIM_ATUALIZAR') &&
                  !!_vm.genderPlace.id) ||
                (!_vm.verificarPermissao('ROLE_ADMIM_INSERIR') && !_vm.genderPlace.id)},model:{value:(_vm.genderPlace.ativo),callback:function ($$v) {_vm.$set(_vm.genderPlace, "ativo", $$v)},expression:"genderPlace.ativo"}},[_vm._v(" "+_vm._s(_vm.genderPlace.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_ADMIM_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_ADMIM_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }