<template>
  <div>
    <div>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button v-if="false" class="mr-1" variant="primary" @click="add()">
          Incluir
        </b-button>
      </div>
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="listaAtendimentos"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        @on-sort-change="onSortChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="edit(props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="false">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="tableConfigs.size"
                :options="[tableConfigs.size]"
                class="mx-1"
                @input="handleChangePage"
              />
              <span class="text-nowrap">
                of {{ tableConfigs.totalPages }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="tableConfigs.totalElements"
                :per-page="tableConfigs.size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoModule', {
      comboSituacao: (state) => state.comboSituacao
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    this.loadAtendimentoLista()
  },
  data() {
    return {
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'dataLancamento',
        order: 'asc'
      },
      pageLength: 10,
      isLoading: false,
      totalRecords: 0,
      searchTerm: '',
      listaAtendimentos: [],
      columns: [
        {
          label: 'Data Atendimento',
          field: 'dataAtendimento',
          formatFn: this.formatDateGrid
        },
        {
          label: 'Profissional',
          field: 'profissional.nome',
          sortable: true
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageLength: 10,
      dir: false
    }
  },
  methods: {
    handleChangePage(page) {
      this.pageConfig.page = page
      this.loadAtendimentoLista(this.module, this.pageConfig)
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.loadAtendimentoLista(this.module, this.pageConfig)
    },
    async loadAtendimentoLista() {
      this.isLoading = true
      this.$store
        .dispatch(
          'atendimentoModule/listaAtendimentoPorIdRegistro',
          this.$route.params.id
        )
        .then((result) => {
          this.pageConfig.page = result.data.pageable.pageNumber
          this.tableConfigs.last = result.data.last
          this.tableConfigs.totalPages = result.data.totalPages
          this.tableConfigs.totalElements = result.data.totalElements
          this.tableConfigs.size = result.data.size
          this.tableConfigs.number = result.data.number
          this.listaAtendimentos = result.data.content
          this.isLoading = false
        })
        .catch((err) => {
          this.MensagemError(
            'Falha ao carregar lista',
            `${err.response.data.error} / ${err.response.data.message}`
          )
          this.isLoading = false
        })
    },

    async edit(data) {
      this.$router.push({
        path: `/modules/Attendance/attendance/editAtendimento/${data.id}`
      })
      this.showForm = true
    }
  }
}
</script>
