export default [
  {
    path: '/profissional/atendimento',
    name: 'profissional-atendimento',
    component: () =>
      import('@/views/modules/profissional/GridProfissional.vue'),
    meta: {
      action: 'ROLE_PROFISSIONAL',
      resource: 'ACL'
    }
  }
]
