<template>
  <b-row>
    <b-col cols="12">
      <b-spinner
        v-if="isLoading"
        label="Carregando..."
        class="d-flex justify-content-center my-5"
        small
      />
      <TableComponents
        v-else-if="!showForm"
        :columns="columns"
        :rows="listaProfissionals"
        title-table="Profissionais"
        module="professionsAtendimentoSettingsModule"
        sort-name="registro"
        :show-btn-add="verificarPermissao('ROLE_ATEND_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ATEND_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <FormProfissional v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormProfissional from './forms/FormProfissional.vue'

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    TableComponents,
    FormProfissional
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      isLoading: true,
      columns: [
        { label: 'Id', field: 'id' },
        { label: 'Nº Registro', field: 'numeroRegistroProfissional' },
        { label: 'Nome', field: 'registro.nome' },
        { label: 'Conselho', field: 'conselho.descricao' },
        { label: 'Ativo', field: 'ativo' },
        { label: 'Action', field: 'action' }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  computed: {
    ...mapState('professionsAtendimentoSettingsModule', {
      listaProfissionals: (state) => state.listaProfissionals
    })
  },
  methods: {
    async addNew() {
      this.showForm = true
      this.isLoading = true
      try {
        await this.$store.dispatch(
          'professionsAtendimentoSettingsModule/resetObject'
        )
      } finally {
        this.isLoading = false
      }
    },
    async edit(data) {
      this.showForm = true
      this.isLoading = true
      try {
        await this.$store.dispatch(
          'professionsAtendimentoSettingsModule/resetObject'
        )
        const response = await this.$store.dispatch(
          'professionsAtendimentoSettingsModule/getById',
          data.id
        )
        const obj = {
          ...response.data,
          especialidades: response.data.especialidades.map((item) => ({
            value: item.id,
            nome: item.descricao
          }))
        }
        await this.$store.dispatch(
          'professionsAtendimentoSettingsModule/prepareToEditProfissional',
          obj
        )
      } finally {
        this.isLoading = false
      }
    },
    cancelar() {
      this.showForm = false
    }
  },
  async mounted() {
    this.isLoading = true
    try {
      await this.$store.dispatch(
        'professionsAtendimentoSettingsModule/fetchInitialData'
      )
    } finally {
      this.isLoading = false
    }
  }
}
</script>
