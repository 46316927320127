export default {
  LOAD_LIST_FISCAL_NATURE(state, data) {
    state.listfiscalNature = data
  },
  PREPARE_TO_EDIT_FISCAL_NATURE(state, data) {
    state.fiscalNature = data
  },
  RESET_FISCAL_NATURE(state) {
    state.profissao = {
      ativo: true,
      nome: '',
    }
  },
}
