import tipoAtendimentoService from '@/api/attendance/tipoAtendimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => tipoAtendimentoService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_TIPO_ATENDIMENTO', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewTipoAtendimento({ commit }, data) {
    return tipoAtendimentoService.insertNewTipoAtendimento(data).then(result => result).catch(error => error)
  },
  prepareToEditTipoAtendimento({ commit }, data) {
    commit('PREPARE_TO_EDIT_TIPO_ATENDIMENTO', data)
  },
  editTipoAtendimento({ commit }, data) {
    return tipoAtendimentoService.editTipoAtendimento(data).then(result => result).catch(error => error)
  },
  resetTipoAtendimento({ commit }) {
    commit('RESET_TIPO_ATENDIMENTO')
  },
  delete({ commit }, id) {
    return tipoAtendimentoService.deleteTipoAtendimento(id).then(result => result).catch(error => error)
  },
}
