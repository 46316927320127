<template>
  <div>
    <b-card
      style="margin-top: 20%;"
      img-alt="Profile Cover Photo"
      img-top
      class="card-profile"
    >
      <div class="profile-image-wrapper">
        <div
          class="profile-image p-0"
          style="padding-top: 15px; margin-top: 15px"
        >
          <b-avatar
            ref="previewEl"
            size="114"
            variant="light"
            :src="fullDependent.foto"
          />
        </div>
        <div class="demo-inline-spacing">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon rounded-circle"
            variant="primary"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="updateFoto"
            >
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            variant="warning outline-secondary"
            class="btn-icon rounded-circle"
            @click="deleteFoto"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
        </div>
      </div>
      <h3>{{ fullDependent.nome }}</h3>
      <h6 class="text-muted">
        Matricula: {{ fullDependent.matricula }}
      </h6>
      <b-badge
        class="profile-badge"
        variant="light-primary"
      >
      <!-- {{fullDependent.tipoRegistro.nome}} -->
      </b-badge>
    </b-card>
    <b-card>
      <!-- about -->
      <div
        class="mt-2"
      >
        <h5 class="text-capitalize mb-75">
          Email:
        </h5>
        <b-card-text>
          {{ fullDependent.email }}
        </b-card-text>
      </div>
      <div
        class="mt-2"
      >
        <h5 class="text-capitalize mb-75">
          Telefone:
        </h5>
        <b-card-text>
          {{ fullDependent.fone }}
        </b-card-text>
      </div>
      <div
        class="mt-2"
      >
        <h5 class="text-capitalize mb-75">
          Endereço:
        </h5>
        <b-card-text>
          {{ showEndereco() }}
        </b-card-text>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import {
  BCard, BAvatar, BBadge, BCardText, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BBadge,
    BAvatar,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('dependentAdministrativeModule', {
      fullDependent: state => state.fullDependent,
    }),
  },
  methods: {
    showEndereco() {
      let endereco = ''
      if (this.fullDependent.enderecos.length > 0) {
        endereco = `${this.fullDependent.enderecos[0].logradouro}, ${this.fullDependent.enderecos[0].numero}`
        if (this.fullDependent.enderecos[0].complemento) {
          endereco = `${endereco}, ${this.fullDependent.enderecos[0].complemento} `
        }
        endereco = `${endereco}, 
                    ${this.fullDependent.enderecos[0].bairro}, 
                    ${this.fullDependent.enderecos[0].cidade}/ 
                    ${this.fullDependent.enderecos[0].uf.sigla} -
                    ${this.fullDependent.enderecos[0].cep} `
      }
      return endereco
    },
    updateFoto(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const objectSend = {
          id: this.$route.params.id,
          file: input.files[0],
        }
        this.$store.dispatch('dependentAdministrativeModule/sendNewPhoto', objectSend).then(response => {
          if (response.response) {
            this.MensagemError('Falha ao enviar foto', response.response.data.error)
            this.saveSucess = false
            return
          }
          this.showMessageSuccess('Editar', 'Foto enviada com sucesso!')
          const reader = new FileReader()
          reader.onload = e => {
            this.fullDependent.foto = e.target.result
          }
          reader.readAsDataURL(input.files[0])
        }).catch(err => {
          console.error('ERRRO', err)
          this.MensagemError('Falha ao enviar foto', err.message)
        })
      }
    },
    deleteFoto() {
      this.$store.dispatch('dependentAdministrativeModule/deletePhoto', this.$route.params.id).then(response => {
        if (response.response) {
          this.MensagemError('Falha ao apagar foto', response.response.data.error)
          this.saveSucess = false
          return
        }
        this.showMessageSuccess('Removida', 'Foto removida com sucesso!')
        this.$refs.refInputEl.value = null
        this.fullDependent.foto = ''
      })
    },
  },
  data() {
    return {
      saveSucess: false,
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    return {
      refInputEl,
      previewEl,
    }
  },
}
</script>
