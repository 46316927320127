import axios from '@axios'
import axiosViaCEp from 'axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/associados${queryUrl}`)
  },
  insertNewAssociante(data) {
    return axios.post('/administrativo/associados', data)
  },
  editAssociante(data) {
    return axios.put(`/administrativo/associados/${data.id}`, data)
  },
  deleteAssociante(id) {
    return axios.delete(`/administrativo/associados/${id}`)
  },
  loadCombo() {
    return axios.get('/administrativo/associados/tipo_registro')
  },
  getAssocianteID(id) {
    return axios.get(`/administrativo/associados/${id}`)
  },
  loadComboGenero() {
    return axios.get('/core/sexos/lista')
  },
  loadComboEstadoCivil() {
    return axios.get('/core/estatos_civis/lista')
  },
  loadComboProfissoes() {
    return axios.get('/core/profissoes/lista')
  },
  loadComboNaturalidades() {
    return axios.get('/core/naturalidades/lista')
  },
  loadComboNacionalidades() {
    return axios.get('/core/nacionalidades/lista')
  },
  loadComboUfs() {
    return axios.get('/core/ufs/lista')
  },
  loadComboPrevidenciaSocial() {
    return axios.get('/core/previdencias_sociais/lista')
  },
  editAssocianteDadosPessoais(data) {
    return axios.put(`/administrativo/associados/dados_pessoais/${data.id}`, data)
  },
  editAssocianteDocumentation(data) {
    const objectId = data.id
    const object = data
    delete object.id
    return axios.put(`/administrativo/associados/documentacao/${objectId}`, object)
  },
  loadCards() {
    return axios.get('/administrativo/associados/card_situacao')
  },
  getEnderecoViaCep(cep) {
    return axiosViaCEp({
      method: 'get',
      url: `https://viacep.com.br/ws/${cep}/json/`,
    })
  },
  inserirNovoEndereco(data) {
    return axios.post('/core/enderecos/', data)
  },
  editAssocianteEndereco(data) {
    return axios.put(`/core/enderecos/${data.id}`, data)
  },
  loadComboTipoEndereco() {
    return axios.get('/core/tipo_enderecos/lista')
  },
  loadComboSituacaoRegistro() {
    return axios.get('/core/situacoes_registros/lista')
  },
  setHistoricoSituacao(data) {
    return axios.post('/core/historico_situacao', data)
  },
  getHistoricoSituacao(id) {
    return axios.get(`/core/historico_situacao/${id}`)
  },
  getListDepedentAssociante(id) {
    return axios.get(`/administrativo/associados/dependentes/${id}`)
  },
  loadDocumentations(id) {
    return axios.get(`/administrativo/documentos/registro/${id}`)
  },
  sendFiles(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/documentos/${object.id}`, dataForm)
  },
  loadEnderecosPorRegistro(id) {
    return axios.get(`/core/enderecos/registro/${id}`)
  },
  deleteEndereco(id) {
    return axios.delete(`/core/enderecos/${id}`)
  },
  sendNewPhoto(object) {
    const dataForm = new FormData()
    dataForm.append('file', object.file)
    return axios.post(`/administrativo/fotos/${object.id}`, dataForm)
  },
  deletePhoto(id) {
    return axios.get(`/administrativo/fotos/${id}`)
  },
  downloadDocument(id) {
    return axios.get(`/administrativo/documentos/download/${id}`)
  },
  deleteDocument(id) {
    return axios.delete(`/administrativo/documentos/${id}`)
  },
  insertNewConvidado(data) {
    return axios.post('/administrativo/associados/convidado', data)
  },
  getAssocianteForCpf(data) {
    return axios.get(`/administrativo/associados/cpf/${data}`)
  },
  getHistoricoTipoRegistro(id) {
    return axios.get(`/core/historico_tipo/${id}`)
  },
  setMudarTipoRegistro(data) {
    return axios.post('/core/historico_tipo', data)
  },
  getContagemTiposRegistros() {
    return axios.get('/administrativo/dashboard/tipos_registros?itipoId=0')
  },
  getHistoricoFaixaContribuicao(id) {
    return axios.get(`/core/historico_contribuicao/${id}`)
  },
  setMudarFaixaContribuica(data) {
    return axios.post('/core/historico_contribuicao', data)
  },
}
