export default {
  listaAtendimentos: [],
  atendimento: {
    numeroAtendimento: null,
    dataAtendimento: null,
    observacao: true,
    situacao: {
      id: null,
    },
    dataCancelamento: null,
    profissional: {
      id: null,
    },
    especialidade: {
      id: null,
    },
    registro: {
      id: null,
    },
    unidadeAtendimento: {
      id: null,
    },
    agenda: {
      id: null,
    },
    convenio: {
      id: null,
    },
  },
  comboEspecialidade: [],
}
