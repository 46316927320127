import axios from '@axios'

export default {
  load() {
    return axios.get('/core/cliente?page=0&size=10&sort=nome,asc')
  },
  edit(data) {
    return axios.put(`/core/cliente/${data.id}`, data)
  },
}
