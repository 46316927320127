<template>
  <b-row>
    <b-col cols="12" sm="12" md="12" lg="2">
      <div v-for="(opcao, index) in opcoes" :key="index">
        <b-button
          v-if="verificarPermissao(opcao.action)"
          class="mb-1"
          block
          variant="gradient-primary"
          :to="opcao.route"
          exact
          :data-cy="opcao.route.name"
        >
          {{ $t(opcao.title) }}
        </b-button>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10">
      <router-view />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton, BCard } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Campos Adicionais',
          route: { name: 'camposAdicionaisSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Conselhos',
          route: { name: 'conselhoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Doenças CID',
          route: { name: 'doencasCidSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Especialidades',
          route: { name: 'especialidadeSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Exames',
          route: { name: 'exameSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Feriados',
          route: { name: 'feriadoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Grupos',
          route: { name: 'grupoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Procedimentos',
          route: { name: 'procedimentoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Profissionais',
          route: { name: 'profissionalAtendimentoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Situações Atendimento',
          route: { name: 'situacaoAtendimentoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Tipos Atendimento',
          route: { name: 'tipoAtendimentoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Unidades Atendimento',
          route: { name: 'unidadeAtendimentoSettings' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Parametros',
          route: { name: 'parematerAtedimento' },
          action: 'ROLE_ATEND_CONSULTAR',
          resource: 'ACL'
        }
      ],
      permissionUser: undefined
    }
  }
}
</script>
