<template>
  <div>
    <form-insert
      v-if="showForm"
      :title-form="titleForm"
      :combo-type="comboTypeRegister"
      :cpf-associado="cpfAssociado"
      :show-cpf-associado="true"
      @cancelar="cancelar"
      @save="save"
    />
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button
          v-if="verificarPermissao('ROLE_ADMIM_INSERIR')"
          class="mr-1"
          variant="primary"
          @click="addDepedentes()"
        >
          Incluir Depedente
        </b-button>
        <b-button
          v-if="verificarPermissao('ROLE_ADMIM_INSERIR')"
          variant="primary"
          @click="addAggregate()"
        >
          Incluir Agregado
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="listaDepedentes"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="
              props.column.field === 'principal' ||
              props.column.field === 'whatsapp'
            "
          >
            <span>
              {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span v-show="!verificarTipo(props.row.tipo)">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editVinculo(props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="false">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Mostrando 1 a </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import FormInsert from '@/layouts/components/FormInsert.vue'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable,
    FormInsert
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('associantesAdministrativeModule', {
      fullAssociante: (state) => state.fullAssociante
    }),
    ...mapState('dependentAdministrativeModule', {
      comboTypesRegisterDepedent: (state) => state.comboTypesRegister
    }),
    ...mapState('aggregatesAdministrativeModule', {
      comboTypesRegisterAggregate: (state) => state.comboTypesRegister
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    this.cpfAssociado = ''
    this.$store
      .dispatch(
        'associantesAdministrativeModule/getListDepedentAssociante',
        this.$route.params.id
      )
      .then((response) => {
        this.listaDepedentes = response.data
      })
  },
  data() {
    return {
      cpfAssociado: '',
      comboTypeRegister: [],
      showForm: false,
      listaDepedentes: [],
      columns: [
        {
          label: 'Matricula',
          field: 'matricula'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'Vinculo',
          field: 'tipo',
          formatFn: this.formatVinculoName
        },
        {
          label: 'Tipo Vinculo',
          field: 'tipoRegistro'
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageLength: 10,
      dir: false,
      object: {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: null,
        associado: null,
        vinculo: true
      },
      titleForm: '',
      tipo: ''
    }
  },
  methods: {
    async addAggregate() {
      this.cancelar()
      this.tipo = 'AGGREGATE'
      this.comboTypeRegister = []
      this.titleForm = 'Cadastro de Agregados'
      this.object.associado = this.fullAssociante.cpf
      this.cpfAssociado = this.fullAssociante.cpf
      await this.$store.dispatch(
        'aggregatesAdministrativeModule/loadComboTypesRegister'
      )
      this.comboTypeRegister = this.comboTypesRegisterAggregate
      await this.$store.commit(
        'aggregatesAdministrativeModule/PREPARE_TO_EDIT_AGGREGATES',
        this.object
      )
      this.showForm = true
    },
    async addDepedentes() {
      this.cancelar()
      this.tipo = 'DEPEDENT'
      this.comboTypeRegister = []
      this.titleForm = 'Cadastro de Depedente'
      this.object.associado = this.fullAssociante.cpf
      this.cpfAssociado = this.fullAssociante.cpf
      await this.$store.dispatch(
        'dependentAdministrativeModule/loadComboTypesRegister'
      )
      this.comboTypeRegister = this.comboTypesRegisterDepedent
      await this.$store.commit(
        'dependentAdministrativeModule/PREPARE_TO_EDIT_DEPENDENT',
        this.object
      )
      this.showForm = true
    },
    editVinculo(data) {
      if (data.tipo.indexOf('Dependent') === 0) {
        this.$router.push({
          path: `/modules/administrative/phisicalPerson/editDependent/${data.id}`
        })
      } else if (data.tipo.indexOf('Agregado') === 0) {
        this.$router.push({
          path: `/modules/administrative/phisicalPerson/editAggregate/${data.id}`
        })
      } else if (data.tipo.indexOf('Associados (Convidou)') === 0) {
        this.$router.push({
          path: `/modules/administrative/phisicalPerson/editAssociante/${data.id}`
        })
        window.location.reload()
      }
    },
    save(data) {
      if (this.tipo === 'DEPEDENT') {
        data.associado = this.fullAssociante.id
        this.saveDepedent(data)
      } else if (this.tipo === 'AGGREGATE') {
        data.associado = this.fullAssociante.id
        this.saveAggregate(data)
      }
    },
    saveDepedent(data) {
      this.$store
        .dispatch('dependentAdministrativeModule/insertNewDependent', data)
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
          this.$router.push({
            path: `/modules/administrative/phisicalPerson/editDependent/${response.data.id}`
          })
        })
    },
    saveAggregate(data) {
      this.$store
        .dispatch('aggregatesAdministrativeModule/insertNewAggregates', data)
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
          this.$router.push({
            path: `/modules/administrative/phisicalPerson/editAggregate/${response.data.id}`
          })
        })
    },
    cancelar() {
      this.cpfAssociado = ''
      this.showForm = false
      this.$store.commit('dependentAdministrativeModule/RESET_DEPENDENT')
      this.object = {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: null,
        associado: null,
        vinculo: true
      }
    },
    formatVinculoName(val) {
      if (
        this.verificarTipo(val) ||
        val.indexOf('Associados (Convidou)') === 0
      ) {
        return 'Indicação'
      }
      return val
    },
    verificarTipo(val) {
      return val.indexOf('Associados (Convidado)') === 0
    }
  }
}
</script>
