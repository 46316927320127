<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listAccountTypes"
        :title-table="$t('Account Types')"
        module="accountTypeSettingsModule"
        sort-name="descricao"
        :show-btn-add="verificarPermissao('ROLE_FINAN_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_FINAN_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <FormAccountType v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormAccountType from './forms/FormAccountType.vue'

export default {
  name: 'Bank',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormAccountType
  },
  computed: {
    ...mapState('accountTypeSettingsModule', {
      listAccountTypes: (state) => state.listAccountTypes
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      columns: [
        {
          label: this.$t('id'),
          field: 'id'
        },
        {
          label: this.$t('Description'),
          field: 'descricao'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'descricao',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch(
        'accountTypeSettingsModule/prepareToEditAccountType',
        data
      )
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
