import fiscalNatureServices from '@/api/administrative/fiscalNatureServices'

export default {
  loadTable({ commit }, pageConfig) {
    return fiscalNatureServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_FISCAL_NATURE', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewFiscalNature({ commit }, data) {
    return fiscalNatureServices.insertNewfiscalNarure(data).then(result => result).catch(error => error)
  },
  prepareToEditFiscalNature({ commit }, data) {
    commit('PREPARE_TO_EDIT_FISCAL_NATURE', data)
  },
  editFiscalNature({ commit }, data) {
    return fiscalNatureServices.editFiscalNature(data).then(result => result).catch(error => error)
  },
  resetFiscalNature({ commit }) {
    commit('RESET_FISCAL_NATURE')
  },
  delete({ commit }, id) {
    return fiscalNatureServices.deleteFiscalNature(id).then(result => result).catch(error => error)
  },
}
