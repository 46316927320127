import associantesServices from '@/api/AdministrativeModule/associantesServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => associantesServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ASSOCIANTES', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewAssociante({ commit }, data) {
    return associantesServices.insertNewAssociante(data).then(result => result).catch(error => error)
  },
  prepareToEditFullAssociante({ commit }, data) {
    commit('PREPARE_TO_EDIT_FULL_ASSOCIANTE', data)
  },
  editAssociante({ commit }, data) {
    return associantesServices.editAssociante(data).then(result => result).catch(error => error)
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => associantesServices.deleteAssociante(id).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  resetAssociante({ commit }) {
    commit('RESET_ASSOCIANTE')
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadCombo().then(result => {
      commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  getAssocianteID({ commit }, id) {
    return new Promise((resolve, reject) => associantesServices.getAssocianteID(id).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboGenero().then(result => {
      commit('SET_LIST_COMBO_GENERO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboEstadoCivil().then(result => {
      commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboProfissoes().then(result => {
      commit('SET_LIST_COMBO_PROFISSOES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboNaturalidades().then(result => {
      commit('SET_LIST_COMBO_NATURALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboNacionalidades().then(result => {
      commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboUfs().then(result => {
      commit('SET_LIST_COMBO_UFS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboPrevidenciaSocial().then(result => {
      commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  editAssocianteDadosPessoais({ commit }, data) {
    return associantesServices.editAssocianteDadosPessoais(data).then(result => result).catch(error => error)
  },
  editAssocianteDocumentation({ commit }, data) {
    return associantesServices.editAssocianteDocumentation(data).then(result => result).catch(error => error)
  },
  loadCards({ commit }) {
    return associantesServices.loadCards().then(result => result).catch(error => error)
  },
  getEnderecoViaCep({ commit }, data) {
    return associantesServices.getEnderecoViaCep(data).then(result => result).catch(error => error)
  },
  inserirNovoEndereco({ commit }, data) {
    return associantesServices.inserirNovoEndereco(data).then(result => result).catch(error => error)
  },
  editAssocianteEndereco({ commit }, data) {
    return associantesServices.editAssocianteEndereco(data).then(result => result).catch(error => error)
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboTipoEndereco().then(result => {
      commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) => associantesServices.loadComboSituacaoRegistro().then(result => {
      commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  setHistoricoSituacao({ commit }, data) {
    return associantesServices.setHistoricoSituacao(data).then(result => result).catch(error => error)
  },
  getHistoricoSituacao({ commit }, id) {
    return associantesServices.getHistoricoSituacao(id).then(result => result).catch(error => error)
  },
  getListDepedentAssociante({ commit }, id) {
    return associantesServices.getListDepedentAssociante(id).then(result => result).catch(error => error)
  },
  sendFiles({ commit }, object) {
    return associantesServices.sendFiles(object).then(result => result).catch(error => error)
  },
  loadDocumentations({ commit }, id) {
    return associantesServices.loadDocumentations(id).then(result => result).catch(error => error)
  },
  loadEnderecosPorRegistro({ commit }, id) {
    return new Promise((resolve, reject) => associantesServices.loadEnderecosPorRegistro(id).then(result => {
      commit('SET_LISTA_ENDERECOS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  deleteEndereco({ commit }, id) {
    return associantesServices.deleteEndereco(id).then(result => result).catch(error => error)
  },
  prepareToEditEndereco({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECO', data)
  },
  sendNewPhoto({ commit }, object) {
    return associantesServices.sendNewPhoto(object).then(result => result).catch(error => error)
  },
  deletePhoto({ commit }, id) {
    return associantesServices.deletePhoto(id).then(result => result).catch(error => error)
  },
  downloadDocument({ commit }, id) {
    return associantesServices.downloadDocument(id).then(result => result).catch(error => error)
  },
  deleteDocument({ commit }, id) {
    return associantesServices.deleteDocument(id).then(result => result).catch(error => error)
  },
  insertNewConvidado({ commit }, data) {
    return associantesServices.insertNewConvidado(data).then(result => result).catch(error => error)
  },
  getAssocianteForCpf({ commit }, data) {
    return associantesServices.getAssocianteForCpf(data).then(result => result).catch(error => error)
  },
  getHistoricoTipoRegistro({ commit }, id) {
    return associantesServices.getHistoricoTipoRegistro(id).then(result => result).catch(error => error)
  },
  setMudarTipoRegistro({ commit }, data) {
    return associantesServices.setMudarTipoRegistro(data).then(result => result).catch(error => error)
  },
  getContagemTiposRegistros({ commit }) {
    return associantesServices.getContagemTiposRegistros().then(result => result).catch(error => error)
  },
  getHistoricoFaixaContribuicao({ commit }, id) {
    return associantesServices.getHistoricoFaixaContribuicao(id).then(result => result).catch(error => error)
  },
  setMudarFaixaContribuica({ commit }, data) {
    return associantesServices.setMudarFaixaContribuica(data).then(result => result).catch(error => error)
  },
}
