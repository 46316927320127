export default {
  data() {
    return {
      atendimentoAvulso: false,
      showHoraAvulso: false,
      vagasDisponiveis: 0
    }
  },
  methods: {
    minAndMaxDates() {
      const listaOrdenada = JSON.parse(JSON.stringify(this.agendaProfissional))

      // listaOrdenada.sort((a, b) => a.data.localeCompare(b.data))

      const [yearInicial, monthInicial, dayInicial] =
        listaOrdenada[0].split('-')

      const [yearFinal, monthFinal, dayFinal] =
        listaOrdenada[listaOrdenada.length - 1].split('-')

      this.minDate = new Date(
        yearInicial,
        parseInt(monthInicial) - 1,
        dayInicial
      )
      this.maxDate = new Date(yearFinal, parseInt(monthFinal) - 1, dayFinal)

      // if (listaOrdenada.length === 1) {
      //   this.maxDate = new Date(yearFinal, parseInt(monthFinal) - 1, dayFinal)
      // }
    },
    dateDisabled(ymd, date) {
      const enabledDates = []

      this.agendaProfissional.forEach((item) => {
        enabledDates.push(item)
      })

      // Formata a data atual no formato 'YYYY-MM-DD' para comparação
      const formattedDate = date.toISOString().split('T')[0]

      // Verifica se a data está no array de datas habilitadas
      return !enabledDates.includes(formattedDate)
    },

    dateClass() {
      if (this.minDate && this.maxDate) {
        return this.minDate <= this.maxDate ? 'table-info' : ''
      }
      return ''
    },
    formatDateEscolhida(dataString) {
      const data = new Date(`${dataString} 00:00:00`)

      const dia = data.getDate().toString().padStart(2, '0')
      const mes = (data.getMonth() + 1).toString().padStart(2, '0')
      const ano = data.getFullYear()

      const dataFormatada = `${dia}-${mes}-${ano}`
      return dataFormatada
    },
    async getHorarioSelecionado() {
      this.bloqueado = false
      this.atendimentoAvulso = false
      this.showHoraAvulso = false
      this.form.agendamento = null
      this.vagasDisponiveis = 0

      const dataEscolhida = this.formatDateEscolhida(this.form.dataAtendimento)

      const data = {
        id: this.form.profissional.id || this.form.profissional,
        data: dataEscolhida
      }

      try {
        const response = await this.$store.dispatch(
          'atendimentoModule/gerarHorarioPorIdProfissionalEData',
          data
        )

        this.comboProfissiocomboHorarioAgendanals = response.data || []

        if (this.comboProfissiocomboHorarioAgendanals.length === 0) {
          this.comboProfissiocomboHorarioAgendanalsFiltrado = []
          this.MensagemInfo(
            'Hora indisponível',
            'Nenhum horário encontrado para a data selecionada!'
          )
          this.atendimentoAvulso = true
          return
        }

        let controleQtd = { ...data, especialidade: this.form.especialidade }
        this.vagasDisponiveis = await this.$store.dispatch(
          'atendimentoModule/getQuantidadeVagasDisponiveis',
          controleQtd
        )
        // Filtra os horários disponíveis
        this.comboProfissiocomboHorarioAgendanalsFiltrado =
          this.comboProfissiocomboHorarioAgendanals
            .filter((item) => item.disponivel)
            .map((item) => ({
              value: item.id,
              label: item.hora
            }))
      } catch (error) {
        console.error('Erro ao buscar horários:', error)
        this.MensagemErro('Erro', 'Não foi possível buscar os horários.')
      }
    },

    async LoadComboHarario() {
      return new Promise(() => {
        this.comboProfissiocomboHorarioAgendanals = []
        this.agendaProfissional.forEach((agendamento) => {
          this.comboProfissiocomboHorarioAgendanals.push(agendamento)
        })
      })
    }
  }
}
