<template>
  <div>
    <b-card title="Relatórios">
      <b-col
        lg="12"
        class="my-6"
      >
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-2"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
              @input="myFilter"
            />

            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >Limpar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-table
        ref="selectableTable"
        :items="reports"
        :fields="columns"
        striped
        responsive
        class="mb-0"
        :select-mode="'single'"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :is-loading.sync="isLoading"
      >
        <template #cell(selecione)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="verificaParametro(row)"
          >
            {{ row.detailsShowing ? "Esconder Filtros" : "Mostrar Filtros" }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
            <b-row
              v-if="row.item.parametros.length > 0 && row.item.parametros[0]"
              class="mb-2"
            >
              <b-col
                v-if="report.Data_inicial !== undefined"
                md="6"
                class="mb-1"
              >
                <b-form-group
                  label="Data Inícial"
                  label-for="v-dataInicio"
                >
                  <flat-pickr
                    v-model="report.Data_inicial"
                    name="v-dataInicio"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'd/m/Y',
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="report.Data_final !== undefined"
                md="6"
                class="mb-1"
              >
                <b-form-group
                  label="Data Final"
                  label-for="v-dataFinal"
                >
                  <flat-pickr
                    v-model="report.Data_final"
                    name="v-dataFinal"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'd/m/Y',
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="report.ID_TIPOREGISTRO !== undefined"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Tipo Registro"
                  label-for="v-tipo"
                >
                  <v-select
                    v-model="report.ID_TIPOREGISTRO.id"
                    label="descricao"
                    :options="comboTypesRegister"
                    :reduce="(comboTypesRegister) => comboTypesRegister.id"
                    @input="
                      setValue('ID_TIPOREGISTRO', report.ID_TIPOREGISTRO.id)
                    "
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="report.ID_TIPOREGISTRO_AGREGADOS !== undefined"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Tipo Registro"
                  label-for="v-tipo"
                >
                  <v-select
                    v-model="report.ID_TIPOREGISTRO_AGREGADOS.id"
                    label="descricao"
                    :options="comboTypesRegister"
                    :reduce="(comboTypesRegister) => comboTypesRegister.id"
                    @input="
                      setValue(
                        'ID_TIPOREGISTRO_AGREGADOS',
                        report.ID_TIPOREGISTRO_AGREGADOS.id
                      )
                    "
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="report.ID_TIPOREGISTRO_DEPENDENTES !== undefined"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Tipo Registro"
                  label-for="v-tipo"
                >
                  <v-select
                    v-model="report.ID_TIPOREGISTRO_DEPENDENTES.id"
                    label="descricao"
                    :options="comboTypesRegister"
                    :reduce="(comboTypesRegister) => comboTypesRegister.id"
                    @input="
                      setValue(
                        'ID_TIPOREGISTRO_DEPENDENTES',
                        report.ID_TIPOREGISTRO_DEPENDENTES.id
                      )
                    "
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="report.CIDADE !== undefined"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="UF"
                  label-for="v-tipo"
                >
                  <v-select
                    v-model="uf"
                    label="descricao"
                    :options="comboUfs"
                    :reduce="(comboUfs) => comboUfs.id"
                    @input="loadComboCidadePorUf(uf)"
                  />
                </b-form-group>
              </b-col>

              <b-col
                v-if="report.CIDADE !== undefined"
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Cidade"
                  label-for="v-tipo"
                >
                  <v-select
                    v-model="report.CIDADE.id"
                    label="descricao"
                    :options="comboCidades"
                    :reduce="(comboCidades) => comboCidades.id"
                    @input="setValue('CIDADE', report.CIDADE.id)"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="p-1">
              <b-col cols="12">
                <b-overlay
                  :show="loading"
                  rounded="sm"
                >
                  <template #overlay>
                    <div class="d-flex align-items-center">
                      <b-spinner
                        small
                        type="grow"
                        variant="primary"
                      />
                      <b-spinner
                        type="grow"
                        variant="primary"
                      />
                      <b-spinner
                        small
                        type="grow"
                        variant="primary"
                      />
                      <span class="sr-only">Aguarde...</span>
                    </div>
                  </template>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="printReport(row.item)"
                  >
                    {{ loading ? "Aguarde..." : "Imprimir" }}
                  </b-button>
                </b-overlay>
              </b-col>
            </b-row>
          </b-card>
        </template>

        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>
      <div>
        <b-pagination
          :value="1"
          :total-rows="tableConfigs.totalElements"
          :per-page="tableConfigs.size"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @change="handleChangePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import {
  BModal,
  BButton,
  BCol,
  BRow,
  BFormGroup,
  BAlert,
  BCardText,
  BFormInput,
  BCollapse,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BOverlay,
  BSpinner,
  BTable,
  BFormCheckbox,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import mixinsGlobal from '@/mixins'
import _ from 'lodash'

export default {
  name: 'ReportAssociantes',
  components: {
    VueGoodTable,
    BCardCode,
    BButton,
    BModal,
    BAlert,
    BCardText,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BCollapse,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    vSelect,
    BSpinner,
    BOverlay,
    BTable,
    BFormCheckbox,
    flatPickr,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboTypesRegister: state => state.comboTypesRegister,
      comboUfs: state => state.comboUfs,
      comboCidades: state => state.comboCidades,
    }),
  },
  data() {
    return {
      filter: null,
      filterOn: [],
      uf: {
        id: 26,
        descricao: 'PE',
      },
      parametros: {
        dataInicial: {
          existeParametro: false,
          valor: '',
        },
        dataFinal: {
          existeParametro: false,
          valor: '',
        },
      },
      reports: [],
      columns: [
        'Selecione',
        {
          key: 'nomeAmigavel',
          label: 'Relatório',
          stickyColumn: true,
          isRowHeader: true,
        },
      ],
      report: {},
      loading: false,
      selected: [],
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0,
      },
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc',
      },
      pageLength: 5,
      dir: false,
      isLoading: false,
    }
  },
  methods: {
    setValue(field, value) {
      if (this.report[field].id) {
        this.report = {}
        this.report[field] = { id: value }
      }
    },
    desmarcaSelecionados(rows, item) {
      rows.forEach(row => {
        if (item.nome !== row.nome && row._showDetails) {
          row._showDetails = false
        }
      })
    },
    verificaParametro(row) {
      this.desmarcaSelecionados(this.$refs.selectableTable.localItems, row)
      row.toggleDetails()
      row.selectRow()
      row.item.parametros
      const that = this
      that.report = {}
      row.item.parametros.forEach(param => {
        if (param == 'Data_inicial') {
          that.report.Data_inicial = ''
        }
        if (param == 'Data_final') {
          that.report.Data_final = ''
        }

        if (param == 'ID_TIPOREGISTRO') {
          that.report.ID_TIPOREGISTRO = {}
          that.loadComboTipoRegistro(1)
        }

        if (param == 'ID_TIPOREGISTRO_DEPENDENTES') {
          that.report.ID_TIPOREGISTRO_DEPENDENTES = {}
          that.loadComboTipoRegistro(2)
        }

        if (param == 'ID_TIPOREGISTRO_AGREGADOS') {
          that.report.ID_TIPOREGISTRO_AGREGADOS = {}
          that.loadComboTipoRegistro(3)
        }

        if (param == 'CIDADE') {
          that.report.CIDADE = {}
          that.loadComboUfs(this.uf.id)
          that.loadComboCidadePorUf(this.uf.id)
        }
      })
    },
    printReport(reportSelecionado) {
      this.loading = true
      this.report.nome = reportSelecionado.nome
      this.report.valor = []
      if (reportSelecionado.id) {
        this.report.valor.push(parseInt(reportSelecionado.id))
      }
      reportSelecionado.parametros.forEach(valor => {
        let param = this.report[valor]
        if (param?.id) {
          param = param.id
        }
        this.report.valor.push(param)
      })
      this.report.valor = this.report.valor.sort()
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then(response => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },
    handleChangePage(page) {
      this.pageConfig.page = page
      this.carregarListaRelatorioPorModulo()
    },
    myFilter: _.debounce(function () {
      this.reports = []
      this.isLoading = true
      this.pageConfig.module = this.$route.name
      this.pageConfig.filter = this.filter
      this.$store
        .dispatch('reportModule/listagemGeral', this.pageConfig)
        .then(response => {
          this.pageConfig.page = response.data.pageable.pageNumber
          this.tableConfigs.last = response.data.last
          this.tableConfigs.totalPages = response.data.totalPages
          this.tableConfigs.totalElements = response.data.totalElements
          this.tableConfigs.size = response.data.size
          this.tableConfigs.number = response.data.number
          this.reports = response.data.content
          this.isLoading = false
        })
    }, 500),
    carregarListaRelatorioPorModulo() {
      this.reports = []
      this.isLoading = true
      this.pageConfig.module = this.$route.name
      this.$store
        .dispatch('reportModule/listagemGeral', this.pageConfig)
        .then(response => {
          this.pageConfig.page = response.data.pageable.pageNumber
          this.tableConfigs.last = response.data.last
          this.tableConfigs.totalPages = response.data.totalPages
          this.tableConfigs.totalElements = response.data.totalElements
          this.tableConfigs.size = response.data.size
          this.tableConfigs.number = response.data.number
          this.reports = response.data.content
          this.isLoading = false
        })
    },
    loadComboTipoRegistro(tipo) {
      this.$store
        .dispatch('combosModule/loadComboTypesRegister', tipo)
        .then(response => {})
    },
    loadComboCidadePorUf(uf) {
      if (this.report.CIDADE.id) {
        this.report.CIDADE.id = null
      }
      this.$store.dispatch('combosModule/loadMunicipiosPorUf', uf)
    },
    loadComboUfs() {
      this.$store.dispatch('combosModule/loadUf')
    },
  },
  watch: {
    $route() {
      this.carregarListaRelatorioPorModulo()
    },
  },
  mounted() {
    this.carregarListaRelatorioPorModulo()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
