export default {
  LOAD_LIST_TIPO_ATENDIMENTO(state, data) {
    state.listaTipoAtendimentos = data
  },
  PREPARE_TO_EDIT_TIPO_ATENDIMENTO(state, data) {
    state.tipoAtendimento = data
  },
  RESET_TIPO_ATENDIMENTO(state) {
    state.tipoAtendimento = {
      id: null,
      descricao: '',
      ativo: true,
    }
  },
}
