export default {
  listaAgendas: [],
  agenda: {
    dataInicial: '',
    dataFinal: '',
    horaInicial: '',
    horaFinal: '',
    profissional: '',
    ativo: true,
    qtoVagas: 0,
    repetir: '',
    domingo: false,
    segunda: true,
    terca: true,
    quarta: true,
    quinta: true,
    sexta: true,
    sabado: false,
    semana: 0,
  },
}
