import contributionRangeServices from '@/api/financial/contributionRangeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => contributionRangeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_CONTRIBUTION_RANGE', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewContributionRange({ commit }, data) {
    return contributionRangeServices.insertNewContributionRange(data).then(result => result).catch(error => error)
  },
  prepareToEditContributionRange({ commit }, data) {
    commit('PREPARE_TO_EDIT_CONTRIBUTION_RANGE', data)
  },
  editContributionRange({ commit }, data) {
    return contributionRangeServices.editContributionRange(data).then(result => result).catch(error => error)
  },
  resetContributionRange({ commit }) {
    commit('RESET_CONTRIBUTION_RANGE')
  },
  delete({ commit }, id) {
    return contributionRangeServices.deleteContributionRange(id).then(result => result).catch(error => error)
  },
}
