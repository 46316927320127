export default {
  listStatusOs: [],
  statusOs: {
    descricao: '',
    tipo: {
      id: null
    },
    ativo: true
  },
}
