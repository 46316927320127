<template>
  <b-row>
    <div v-if="loading" class="clearfix">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listProfiles"
        sort-name="nome"
        title-table="Perfils"
        module="profileSettingAccessModule"
        :show-btn-add="verificarPermissao('ROLE_PERFIL_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_PERFIL_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <form-profile v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'
import FormProfile from './forms/FormProfile.vue'

export default {
  name: 'Users',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormProfile,
    BSpinner
  },
  computed: {
    ...mapState('profileSettingAccessModule', {
      listProfiles: (state) => state.listProfiles
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      loading: false,
      columns: [
        {
          label: 'Id',
          field: 'id'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    async edit(data) {
      this.loading = true
      await this.$store.dispatch('profileSettingAccessModule/getById', data.id)
      this.showForm = true
      this.loading = false
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
