<template>
  <b-row>
    <b-col cols="12" sm="12" md="12" lg="2">
      <div v-for="(opcao, index) in opcoes" :key="index">
        <b-button
          v-if="verificarPermissao(opcao.action)"
          class="mb-1"
          block
          variant="gradient-primary"
          :to="opcao.route"
          exact
          :data-cy="opcao.route.name"
        >
          {{ $t(opcao.title) }}
        </b-button>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10">
      <router-view />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Banks',
          route: { name: 'banksSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Faixa Contribuição',
          route: { name: 'contributionRange' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Bank Accounts',
          route: { name: 'banksAccountSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Account Types',
          route: { name: 'accountTypeSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Account Plan',
          route: { name: 'accountPlanSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Taxas',
          route: { name: 'taxaSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Types Of Payment Receipt',
          route: { name: 'typeOfPaymentReceiptSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Payment Methods',
          route: { name: 'paymentMethodsSettings' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Parametros',
          route: { name: 'paremater' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        }
      ],
      permissionUser: undefined
    }
  }
}
</script>
