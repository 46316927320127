export default [
  {
    header: 'Settings',
  },
  {
    title: 'Register',
    icon: 'UserCheckIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Administrative',
        route: 'administrativeSettings',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Financial',
        route: 'financialSettings',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Atendimento',
        route: 'attendanceSettings',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Events',
        route: 'eventsSettings',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Parameters',
    icon: 'ToolIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Parameters',
        route: 'apps-invoice-list',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
  {
    title: 'Access',
    icon: 'UserIcon',
    action: 'read',
    resource: 'ACL',
    children: [
      {
        title: 'Users',
        route: 'usersSettings',
        action: 'read',
        resource: 'ACL',
      },
      {
        title: 'Profile',
        route: 'profileSettings',
        action: 'read',
        resource: 'ACL',
      },
    ],
  },
]
