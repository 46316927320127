import nacionalityServices from '@/api/administrative/nacionalityServices'

export default {
  loadTable({ commit }, pageConfig) {
    return nacionalityServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_NACIONALITIES', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewNacionality({ commit }, data) {
    return nacionalityServices.insertNewNacionality(data).then(result => result).catch(error => error)
  },
  prepareToEditNacionality({ commit }, data) {
    commit('PREPARE_TO_EDIT_NACIONALITY', data)
  },
  editNacionality({ commit }, data) {
    return nacionalityServices.editNacionality(data).then(result => result).catch(error => error)
  },
  resetNacionality({ commit }) {
    commit('RESET_NACIONALITY')
  },
  delete({ commit }, id) {
    return nacionalityServices.deleteNacionality(id).then(result => result).catch(error => error)
  },
}
