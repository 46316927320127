<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations"
  >
    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="AwardIcon"
      />
    </b-avatar>
    <h1
      data-cy="boaVendas"
      class="mb-1 mt-50 text-white"
    >
      Bem Vindo {{ data.nome }},
    </h1>
    <b-card-text class="m-auto w-75">
      Hoje é <strong>{{ getDataAtual() }}</strong>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BAvatar, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getDataAtual() {
      const data = new Date()
      const dia = data.getDate().toString().padStart(2, '0')
      const mes = (data.getMonth() + 1).toString().padStart(2, '0')
      const ano = data.getFullYear()
      return `${dia}/${mes}/${ano}`
    },
  },
}
</script>
