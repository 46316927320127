import bankService from '@/api/financial/bankServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => bankService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_BANK', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewBank({ commit }, data) {
    return bankService.insertNewBank(data).then(result => result).catch(error => error)
  },
  prepareToEditBank({ commit }, data) {
    commit('PREPARE_TO_EDIT_BANK', data)
  },
  editBank({ commit }, data) {
    return bankService.editBank(data).then(result => result).catch(error => error)
  },
  resetBank({ commit }) {
    commit('RESET_BANK')
  },
  delete({ commit }, id) {
    return bankService.deleteBank(id).then(result => result).catch(error => error)
  },
}
