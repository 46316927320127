export default {
  LOAD_LIST(state, data) {
    state.listStatusOs = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.statusOs = data
  },
  RESET(state) {
    state.statusOs = {
      descricao: '',
      ativo: true
    }
  }
}
