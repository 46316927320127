export default [
  {
    path: '/settings/access/users',
    name: 'usersSettings',
    component: () => import('@/views/settings/access/UsersSettings.vue'),
    meta: {
      action: 'ROLE_USER_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Configurações De Usuarios'
        }
      ]
    }
  }
]
