<template>
  <div v-if="loading" class="clearfix">
    <b-spinner class="float-right" label="Floated Right" />
  </div>
  <div v-else>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Atendimentos </span>
        </template>
        <app-collapse type="margin">
          <app-collapse-item title="Atendimentos" :isVisible="true">
            <div class="justify-content-end d-flex flex-wrap">
              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1 mb-1"
                variant="primary"
                @click="atestado"
                >Atestado</b-button
              >

              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1 mb-1"
                variant="primary"
                @click="receita"
                >Receita</b-button
              >

              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1 mb-1"
                variant="primary"
                @click="declaracao"
                >Declaração</b-button
              >

              <b-button
                v-if="parseInt(form.situacao.id) === 10 && disableForm"
                class="mr-1 mb-1"
                variant="primary"
                @click="exame"
                >Solicitação de Exames</b-button
              >

              <b-button
                v-if="disableForm"
                 class="mr-1 mb-1"
                variant="outline-success"
                @click="sair"
                >Sair</b-button
              >
            </div>
            <validation-observer ref="form">
              <b-form @submit.prevent>
                <b-row>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Número Atendimento"
                      label-for="v-numAtendimento"
                    >
                      <b-form-input
                        id="v-numAtendimento"
                        v-model="form.numeroAtendimento"
                        placeholder="numAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Data Atendimento"
                      label-for="v-dataAtendimento"
                    >
                      <flat-pickr
                        v-model="form.dataAtendimento"
                        name="fieldDateData"
                        class="form-control"
                        :disabled="true"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Data Cancelamento"
                      label-for="v-dataCancelamento"
                    >
                      <b-form-input
                        id="v-dataCancelamento"
                        v-model="form.dataCancelamento"
                        placeholder="dataCancelamento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Tipo de Atendimento"
                      label-for="v-tipoAtendimento"
                    >
                      <b-form-input
                        id="v-tipoAtendimento"
                        v-model="form.tipoAtendimento.descricao"
                        placeholder="tipoAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Especialidades"
                      label-for="v-especialidade"
                    >
                      <b-form-input
                        id="v-especialidade"
                        v-model="form.especialidade.descricao"
                        placeholder="especialidade"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <b-form-group
                      label="Profissional"
                      label-for="v-profissional"
                    >
                      <b-form-input
                        id="v-profissional"
                        v-model="form.profissional.nome"
                        placeholder="profissional"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Solicitante" label-for="v-registro">
                      <b-form-input
                        id="v-registro"
                        v-model="form.registro.nome"
                        placeholder="registro"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" lg="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Hora Inicial"
                      rules="required"
                    >
                      <b-form-group
                        label="Hora Inicial"
                        label-for="v-horaInicial"
                      >
                        <flat-pickr
                          v-model="form.horaInicial"
                          name="fieldDate"
                          :disabled="disableForm"
                          :config="{
                            altInput: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true
                          }"
                        />
                        <small
                          data-cy="v-horaInicial-validate"
                          class="text-danger"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" lg="4">
                    <validation-provider
                      #default="{ errors }"
                      name="Hora Final"
                      rules="required"
                    >
                      <b-form-group label="Hora Final" label-for="v-horaFinal">
                        <flat-pickr
                          v-model="form.horaFinal"
                          name="fieldDate"
                          :disabled="disableForm"
                          :config="{
                            altInput: true,
                            enableTime: true,
                            noCalendar: true,
                            dateFormat: 'H:i',
                            time_24hr: true
                          }"
                        />
                        <small
                          data-cy="v-horaFinal-validate"
                          class="text-danger"
                          >{{ errors[0] }}</small
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12" sm="12" lg="4">
                    <b-form-group label="CID" label-for="v-doencasCid">
                      <v-select
                        v-model="form.doencasCid"
                        :reduce="(comboDoencasCid) => comboDoencasCid.id"
                        label="nome"
                        :options="comboDoencasCid"
                        multiple
                        :readonly="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group
                      label="Unidade Atendimento"
                      label-for="v-unidadeAtendimento"
                    >
                      <b-form-input
                        id="v-unidadeAtendimento"
                        v-model="form.unidadeAtendimento.descricao"
                        placeholder="unidadeAtendimento"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group label="Situação" label-for="v-situacao">
                      <v-select
                        v-model="form.situacao.id"
                        :reduce="(comboSituacao) => comboSituacao.id"
                        label="nome"
                        :options="comboSituacao"
                        :disabled="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" sm="12" md="4" lg="4">
                    <b-form-group
                      label="Dias para Atestado"
                      label-for="v-atestado"
                    >
                      <b-input-group>
                        <b-form-input
                          id="v-atestado"
                          v-model="form.diasAtestado"
                          placeholder="dias atestado"
                          type="number"
                          :disabled="disableForm"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" sm="12" md="12" lg="12">
                    <b-form-group label="Observações" label-for="v-observacao">
                      <b-form-textarea
                        id="v-observacao"
                        v-model="form.observacao"
                        row="2"
                        placeholder="Digite aqui suas observações"
                        :disabled="disableForm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
            <ButtonsFormVue
              :disable-form="disableForm"
              :loading-button="loading"
              :permission-insert="verificarPermissao('ROLE_PROFISSIONAL')"
              :permission-edit="verificarPermissao('ROLE_PROFISSIONAL')"
              @save="salvar"
              @edit="editar"
              @cancel="beforeCancelar"
            />
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab @click="carregarHistorico">
        <template #title>
          <feather-icon icon="CheckIcon" />
          <span> Histórico </span>
          &nbsp;
          <b-spinner
            v-if="loadingHistorico"
            class="float-right"
            label="Floated Right"
            small
          />
        </template>

        <div v-if="loadingHistorico" class="text-center mt-5">
          <b-spinner></b-spinner>
          <p>Carregando histórico...</p>
        </div>

        <app-collapse
          v-if="!loadingHistorico && reportReady"
          v-for="historico in historicos"
          :key="historico.id"
          type="margin"
        >
          <app-collapse-item
            :isVisible="false"
            :title="formatarDataBr(historico.data)"
          >
            <b-row v-if="!historico.atendimento">
              <b-col> Nenhum Histórico </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12" sm="12" md="12" lg="12">
                <div class="medical-history-report">
                  <!-- Cabeçalho do Relatório -->
                  <b-card>
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h3>Histórico De Atendimento</h3>
                        <p class="mb-0"><strong>Paciente: </strong>{{ historico.atendimento.registro.nome }}</p>
                        <p class="mb-0"><strong>Atendimento Nº: </strong> {{ historico.atendimento.numeroAtendimento }}</p>
                      </div>
                      <div>
                        <p class="mb-0">Data: {{ formatarDataBr(historico.atendimento.dataAtendimento) }}</p>
                      </div>
                    </div>
                  </b-card>

                  <!-- Detalhes do Atendimento -->
                  <b-card class="mt-3">
                    <h4>Detalhes do Atendimento</h4>
                    <b-row>
                      <b-col cols="6">
                        <p><strong>Profissional:</strong> {{ historico.atendimento.profissional.nome }}</p>
                        <p><strong>Especialidades:</strong> 
                          <ul>
                            <li v-for="especialidade in historico.atendimento.profissional.especialidades" :key="especialidade.id">
                              {{ especialidade.descricao }} ({{ especialidade.tipoAtendimento.descricao }})
                            </li>
                          </ul>
                        </p>
                      </b-col>
                      <b-col cols="6">
                        <p><strong>Dias de Atestado:</strong> {{ historico.atendimento.diasAtestado }}</p>
                        <p><strong>Doenças CID:</strong> 
                          <span v-if="historico.atendimento.doencasCid.length">
                            {{ historico.atendimento.doencasCid.join(', ') }}
                          </span>
                          <span v-else>
                            Nenhum CID registrado
                          </span>
                        </p>
                      </b-col>
                    </b-row>
                  </b-card>

                  <!-- Observações -->
                  <b-card class="mt-3">
                    <h4>Observações</h4>
                    <p v-if="historico.atendimento.observacao">{{ historico.atendimento.observacao }}</p>
                    <p v-else>Sem observações adicionais registradas.</p>
                  </b-card>

                  <!-- Detalhes do Atendimento -->
                  <b-card v-for=" grupo in historico.atendimento.grupo_procedimento" :key="grupo.id" class="mt-3">
                    <h4>{{grupo.nome}}</h4>
                    <b-card v-for=" procedimento in grupo.procedimento" :key="procedimento.id" class="mt-1">
                      <h5 class="mb-1 text-muted">{{procedimento.nome}}</h5>
                      <b-row>
                        <b-col v-for="campo in procedimento.campo_adicional" :key="campo.id"  cols="12">
                          <p v-if="campo.campo_atendimento.length > 0"><strong>{{campo.nome}}:</strong> {{ exibeCamposValor(campo) }}</p>
                          </b-col>
                      </b-row>
                    </b-card>
                  </b-card>

                  <!-- Botão para Exportar ou Imprimir -->
                  <div class="text-center mt-4">
                    <!-- <b-button variant="primary" @click="printReport">Imprimir Relatório</b-button>
                    <b-button variant="success" @click="exportPDF">Exportar como PDF</b-button> -->
                  </div>
                </div>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-tab>
      <b-tab v-for="item in listaGrupos" :key="item.id">
        <template #title>
          <feather-icon icon="CheckIcon" />
          <span>{{ item.grupo }}</span>
          &nbsp;
          <b-spinner
            v-if="loadingCampos"
            class="float-right"
            label="Floated Right"
            small
          />
        </template>
        <app-collapse
          v-for="procedimento in item.procedimentos"
          :key="procedimento.id"
          type="margin"
        >
          <app-collapse-item :isVisible="true" :title="procedimento.nome">
            <b-row
              v-if="
                item.procedimentos.length === 0 ||
                procedimento.campo_adicional.length === 0
              "
            >
              <b-col> Nenhum campo configurado </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                v-for="campo in procedimento.campo_adicional"
                :key="campo.id"
              >
                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.STRING"
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="text"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.TEXTO"
                  :label="campo.nome"
                >
                 <wysiwyg
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    :class="{ disabled: disableForm }"
                  />
                </b-form-group>

                <b-form-group
                  v-if="
                    campo.tipo === TYPES_FIELDS.INTEGER ||
                    campo.tipo === TYPES_FIELDS.DECIMAL
                  "
                  :label="campo.nome"
                >
                  <b-form-input
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    type="number"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.DATA"
                  :label="campo.nome"
                >
                  <flat-pickr
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="fieldDate"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                    }"
                    :readonly="disableForm"
                    :disabled="disableForm"
                  />
                </b-form-group>

                <b-form-group
                  v-if="campo.tipo === TYPES_FIELDS.BOOLEAN"
                  :label="campo.nome"
                >
                  <b-form-checkbox
                    :id="'id_' + campo.id"
                    v-model="campo.valor"
                    name="check-button"
                    :value="'true'"
                    :unchecked-value="'false'"
                    :disabled="disableForm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loadingCampos"
          :permission-insert="verificarPermissao('ROLE_PROFISSIONAL')"
          :permission-edit="verificarPermissao('ROLE_PROFISSIONAL')"
          @save="salvarCamposAdicionais"
          @edit="editar"
          @cancel="beforeCancelar"
        />
      </b-tab>
    </b-tabs>
    <!-- modal -->
    <b-modal ref="my-modal" hide-footer title="Relatorios de Exames">
      <div class="d-block text-center">
        <h3>Sollicitção de Exame</h3>
        <b-row>
          <!-- situacaoRegistro -->
          <b-col ols="12" lg="12">
            <b-form-group
              :label="
                !idExame ? 'Selecione uma opção' : 'Exame selecionado'
              "
              label-for="v-situacao"
            >
              <v-select
                v-model="idExame"
                label="descricao"
                :options="comboExames"
                rule
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="col-12 mt-1"
          variant="outline-primary"
          block
          :disabled="!idExame"
          @click="printRoportExame"
        >
          Continua
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="col-12 mt-1"
          variant="outline-danger"
          block
          @click="hideModal"
        >
          Cancelar
        </b-button>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  BRow,
  BCol,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BCard
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import flatPickr from 'vue-flatpickr-component'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BSpinner,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    ButtonsFormVue,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,
    BInputGroup,
    BCard
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('atendimentoProfissionalModule', {
      atendimento: (state) => state.atendimento
    }),
    ...mapState('combosModule', {
      comboDoencasCid: (state) => state.comboDoencasCid
    })
  },  
  data() {
    return {
      required,
      collapseType: 'default',
      listaGrupos: [],
      loading: false,
      loadingCampos: false,
      loadingHistorico: false,
      reportReady: false,
      disableForm: true,
      form: {
        id: null,
        numeroAtendimento: '',
        dataAtendimento: '',
        observacao: '',
        situacao: {
          id: null,
          descricao: ''
        },
        dataCancelamento: null,
        profissional: {
          id: null,
          nome: '',
          especialidades: []
        },
        especialidade: {
          id: null,
          descricao: null
        },
        registro: {
          id: null,
          nome: ''
        },
        unidadeAtendimento: {
          id: null,
          descricao: null
        },
        agendamento: null,
        convenio: {
          id: null,
          descricao: null
        },
        tipoAtendimento: {
          id: null,
          descricao: ''
        },
        horaInicial: '',
        horaAgenda: null,
        horaFinal: null,
        campoAtendimento: [],
        hora: '',
        diasAtestado: 0,
        doencasCid: null
      },
      comboSituacao: [],
      report: {},
      TYPES_FIELDS: {
        STRING: 0,
        BOOLEAN: 1,
        DECIMAL: 2,
        INTEGER: 3,
        DATA: 4,
        TEXTO: 5
      },
      historicos: [],
      comboExames: [],
      idExame: null
    }
  },
  methods: {
    async editar() {
      this.disableForm = false
      this.aplicarEstiloDisabilitadoEmCampoData()
    },
    cancelar() {
      this.disableForm = true
      this.loadingCampos = false
      this.setObejetoLocalEdicao()
      this.aplicarEstiloDisabilitadoEmCampoData()
      const dateName = document.getElementsByName('fieldDateData')
      if (dateName.length > 0) {
        dateName.forEach((element) => {
          if (element.nextElementSibling) {
            element.nextElementSibling.style.backgroundColor = ''
            if (this.disableForm) {
              element.nextElementSibling.style.backgroundColor = '#efefef'
            }
          }
        })
      }
      this.$refs.form.reset()
    },
    beforeCancelar() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Deseja realmente sair do cadastro do atendimento?', {
          title: 'Confirmação',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true
        })
        .then((value) => {
          this.boxTwo = value
          if (value) {
            this.cancelar()
          }
        })
    },
    setObejetoLocalEdicao() {
      this.form = {
        id: this.atendimento.id,
        numeroAtendimento: this.atendimento.numeroAtendimento,
        dataAtendimento: this.atendimento.dataAtendimento,
        observacao: this.atendimento.observacao,
        situacao: this.atendimento.situacao,
        dataCancelamento: this.atendimento.dataCancelamento,
        profissional: this.atendimento.profissional,
        especialidade: this.atendimento.especialidade,
        registro: this.atendimento.registro,
        unidadeAtendimento: this.atendimento.unidadeAtendimento,
        agendamento: this.atendimento.agendamento,
        convenio: this.atendimento.convenio,
        tipoAtendimento: this.atendimento.tipoAtendimento,
        horaInicial: this.atendimento.horaInicial,
        horaAgenda: this.atendimento.horaAgenda,
        horaFinal: this.atendimento.horaFinal,
        campoAtendimento: this.atendimento.campoAtendimento,
        hora: this.atendimento.horaAgenda,
        diasAtestado: this.atendimento.diasAtestado,
        doencasCid: this.atendimento.doencasCid
      }
    },
    async loadComboSituacao() {
      this.$store
        .dispatch('atendimentoProfissionalModule/getComboSituacao')
        .then((response) => {
          this.comboSituacao = response.data.map((item) => ({
            id: item.id,
            nome: item.descricao
          }))
        })
    },
    async loadComboDoencasCid() {
      await this.$store.dispatch('combosModule/loadComboDoencasCid')
    },
    salvar() {
      this.$refs.form.validate().then((success) => {
        const objectSave = {
          id: this.form.id,
          observacao: this.form.observacao,
          situacao: this.form.situacao.id,
          horaInicial: this.form.horaInicial,
          horaFinal: this.form.horaFinal,
          diasAtestado: this.form.diasAtestado,
          doencasCid: this.form.doencasCid
        }
        if (success) {
          this.loading = true
          try {
            this.$store
              .dispatch('atendimentoProfissionalModule/edit', objectSave)
              .then((response) => {
                if (
                  response.response &&
                  response.response.data &&
                  response.response.data.error
                ) {
                  this.MensagemError('Editar', 'Falha ao editar!')
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.loadDadosForm()
                this.loading = false
              })
            return
          } catch (error) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loading = false
          }
        }
      })
    },
    loadDadosForm() {
      this.loading = true
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/getAtendimentoById',
          this.$route.params.id
        )
        .then(() => {
          this.cancelar()
        })
        .finally(() => {
          this.loading = false
        })
    },
    getGrupos() {
      this.loading = true
      this.montarAbas()
      this.loading = false
    },
    sair() {
      this.$router.go(-1)
    },
    exame() {
      this.report = {}
      this.report = {
        nome: 'solicitacao_exame',
        valor: [parseInt(this.form.id)]
      }
      if (this.report.nome === 'solicitacao_exame') {
        this.showModal()
        return
      }
      
    },
    printRoportExame() {
      this.report = {
        nome: 'solicitacao_exame',
        valor: []
      }
      this.report.valor.push(parseInt(this.form.id))
      this.report.valor.push(parseInt(this.idExame.id))
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    async showModal() {
      const response = await this.$store.dispatch(
        'combosModule/loadComboEspecialidadesExames',
        this.form.especialidade.id
      )
      this.comboExames = response.data
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    atestado() {
      this.report = {}
      this.report = {
        nome: 'atestado_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    receita() {
      this.report = {}
      this.report = {
        nome: 'receita_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    declaracao() {
      this.report = {}
      this.report = {
        nome: 'declaracao_atendimento',
        valor: [parseInt(this.form.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    montarAbas() {
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/montarTela',
          this.$route.params.id
        )
        .then((response) => {
          this.loadListaGrupos(response.data)
        })
    },
    loadListaGrupos(atendimento) {
      this.listaGrupos = []
      atendimento.atendimento.grupo_procedimento.forEach((grupo) => {
        if (grupo.id !== 0) {
          this.listaGrupos.push({
            id: grupo.id,
            grupo: grupo.nome ? grupo.nome : 'Padrão',
            procedimentos: grupo.procedimento
          })
        }
      })
      this.listaGrupos.forEach((item) => {
        item.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            campo.valor =
              campo.campo_atendimento.length > 0
                ? campo.campo_atendimento[0]?.valor
                : null
          })
        })
      })
    },
    salvarCamposAdicionais() {
      this.loadingCampos = true
      let listaSalvarCampos = []
      this.listaGrupos.forEach((grupo) => {
        grupo.procedimentos.forEach((procedimento) => {
          procedimento.campo_adicional.forEach((campo) => {
            listaSalvarCampos.push({
              atendimento: this.$route.params.id,
              campoAdicional: campo.id,
              valor: campo.valor ? campo.valor : null
            })
          })
        })
      })
      this.$store
        .dispatch(
          'atendimentoProfissionalModule/editCamposAdicionais',
          listaSalvarCampos
        )
        .then((response) => {
          if (
            response.response &&
            response.response.data &&
            response.response.data.error
          ) {
            this.MensagemError('Editar', 'Falha ao editar!')
            this.loadingCampos = false
            return
          }
          this.showMessageSuccess('Editar', 'Editado com sucesso!')
          this.cancelar()
        })
    },
    async carregarHistorico() {
      try {
        this.loadingHistorico = true;
        this.reportReady = false; // Evita exibição do relatório antes do carregamento

        const response = await this.$store.dispatch(
          "atendimentoProfissionalModule/carregarHistoricos",
          this.$route.params.id
        );
        this.historicos = response.data;

        // Mapeia cada histórico e faz as chamadas assíncronas para carregar os dados
        const historicosPromises = this.historicos.map(async (item) => {
          const historico = await this.openHistorico(item.id);
          if (historico) {
            item.atendimento = historico;
            // Chama a função para montar as sessões
            const grupoProcedimento = await this.montarSessoesHistoricos(item.id);
            item.atendimento = { ...item.atendimento, grupo_procedimento: grupoProcedimento };
          }
        });

        // Aguarda todas as promessas serem resolvidas
        await Promise.all(historicosPromises);

        console.log(this.historicos); // Exibe o array final no console
      } catch (error) {
        this.MensagemError("Carregar Histórico", "Falha ao carregar históricos!");
      } finally {
        this.loadingHistorico = false;
        this.reportReady = true; // Marca como pronto para exibição
      }
    },


   openHistorico(historicoAtendimentoId) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await this.$store.dispatch(
            "atendimentoProfissionalModule/getHistoricoById",
            historicoAtendimentoId
          );

          if (
            response.response &&
            response.response.data &&
            response.response.data.error
          ) {
            this.MensagemError("Historico", "Falha ao carregar!");
            return reject("Falha ao carregar histórico");
          }

          resolve(response.data); // Retorna os dados do histórico
        } catch (error) {
          this.MensagemError("Historico", "Erro ao buscar histórico!");
          reject("Erro ao buscar histórico");
        }
      });
    },


   montarSessoesHistoricos(id) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await this.$store.dispatch(
            "atendimentoProfissionalModule/montarTela",
            id
          );

          if (response && response.data && response.data.atendimento) {
            resolve(response.data.atendimento.grupo_procedimento);
          } else {
            reject("Grupo de procedimento não encontrado");
          }
        } catch (error) {
          this.MensagemError("Historico", "Erro ao buscar histórico!");
          reject("Erro ao buscar histórico");
        }
      });
    },

    formatarDataBr(data) {
      if (data) {
        const date = new Date(`${data}T00:00:00`)
        const formattedDate = date.toLocaleDateString('pt-BR')
        console.log(formattedDate) // Saída: 06/12/2024
        return formattedDate
      }
    },
    exibeCamposValor(campo) {
  // Verifica o tipo do campo e trata de acordo
  switch (campo.tipo) {
    case this.TYPES_FIELDS.STRING:
      return campo.campo_atendimento[0].valor; // Para tipo STRING, apenas retorna o valor

    case this.TYPES_FIELDS.BOOLEAN:
      // Para tipo BOOLEAN, retorna 'Sim' ou 'Não'
      return campo.campo_atendimento[0].valor ? 'Sim' : 'Não';

    case this.TYPES_FIELDS.DATA:
      // Para tipo DATA, formata a data (exemplo de formato: DD/MM/YYYY)
      const data = this.formatarDataBr(campo.campo_atendimento[0].valor);
      return data

    case this.TYPES_FIELDS.DECIMAL:
    case this.TYPES_FIELDS.INTEGER:
      // Para tipo DECIMAL ou INTEGER, retorna o valor numérico
      return campo.campo_atendimento[0].valor;

    case this.TYPES_FIELDS.TEXTO:
      // Para tipo TEXTO, exibe o texto diretamente
      return campo.campo_atendimento[0].valor;

    default:
      // Caso o tipo não seja reconhecido, retorna uma mensagem padrão
      return 'Valor não disponível';
  }
}

  },
  async mounted() {
    this.aplicarEstiloDisabilitadoEmCampoData()
    const dateName = document.getElementsByName('fieldDateData')
    if (dateName.length > 0) {
      dateName.forEach((element) => {
        if (element.nextElementSibling) {
          element.nextElementSibling.style.backgroundColor = ''
          if (this.disableForm) {
            element.nextElementSibling.style.backgroundColor = '#efefef'
          }
        }
      })
    }
    await this.loadComboSituacao()
    await this.loadComboDoencasCid()
    this.$store
      .dispatch(
        'atendimentoProfissionalModule/getAtendimentoById',
        this.$route.params.id
      )
      .then(() => {
        this.form = JSON.parse(JSON.stringify(this.atendimento))
      })
    this.getGrupos()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
<style>
.teeth {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 80px solid lightblue;
}

.medical-history {
  padding: 20px;
}

h4 {
  margin-top: 20px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5; /* Você pode adicionar um efeito visual para indicar que o editor está desabilitado */
}
</style>
