import addressTypeServices from '@/api/administrative/addressTypeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return addressTypeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ADDRESS_TYPE', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewAddressType({ commit }, data) {
    return addressTypeServices.insertNewAddressType(data).then(result => result).catch(error => error)
  },
  prepareToEditAddressType({ commit }, data) {
    commit('PREPARE_TO_EDIT_ADDRESS_TYPE', data)
  },
  editAddressType({ commit }, data) {
    return addressTypeServices.editAddressType(data).then(result => result).catch(error => error)
  },
  resetAddressType({ commit }) {
    commit('RESET_ADDRESS_TYPE')
  },
  delete({ commit }, id) {
    return addressTypeServices.deleteAddressType(id).then(result => result).catch(error => error)
  },
}
