var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"clearfix"},[_c('b-spinner',{staticClass:"float-right",attrs:{"label":"Floated Right"}})],1):_c('div',[_c('div',[_c('b-card',{attrs:{"title":"Simulação de emprestimos"}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"observacao","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Registro","label-for":"v-registro"}},[_c('v-select',{attrs:{"label":"nome","filterable":false,"options":_vm.comboRegistros,"selected":_vm.form.registro,"disabled":!!_vm.$route.params.id},on:{"search":_vm.onSearch},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.nome)+" ")])]}}],null,true),model:{value:(_vm.form.registro),callback:function ($$v) {_vm.$set(_vm.form, "registro", $$v)},expression:"form.registro"}},[_c('template',{slot:"no-options"},[_vm._v(" Pesquisa registro por nome, cpf ou matrícula ")])],2),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-registro"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"numero","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Número","label-for":"v-numero"}},[_c('b-form-input',{attrs:{"id":"v-numero","state":errors.length > 0 ? false : null,"placeholder":"numero","disabled":""},model:{value:(_vm.form.numero),callback:function ($$v) {_vm.$set(_vm.form, "numero", $$v)},expression:"form.numero"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"Descrição","disabled":!!_vm.$route.params.id},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"valor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Valor","label-for":"v-Valor"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-valor","raw":true,"options":_vm.options.number,"placeholder":"Valor","disabled":!!_vm.$route.params.id},model:{value:(_vm.form.valor),callback:function ($$v) {_vm.$set(_vm.form, "valor", _vm._n($$v))},expression:"form.valor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('validation-provider',{attrs:{"name":"valor total","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Valor Total","label-for":"v-valorTotal"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-valorTotal","raw":true,"options":_vm.options.number,"placeholder":"valor total","disabled":""},model:{value:(_vm.form.valorTotal),callback:function ($$v) {_vm.$set(_vm.form, "valorTotal", _vm._n($$v))},expression:"form.valorTotal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Quantidade Parcelas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Quantidade Parcelas","label-for":"v-qtoParcelas"}},[_c('b-form-input',{attrs:{"id":"v-qtoParcelas","state":errors.length > 0 ? false : null,"placeholder":"Quantidade Parcelas","type":"number","disabled":!!_vm.$route.params.id},model:{value:(_vm.form.qtoParcelas),callback:function ($$v) {_vm.$set(_vm.form, "qtoParcelas", $$v)},expression:"form.qtoParcelas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Taxas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Taxas","label-for":"v-taxa"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (comboTaxas) { return comboTaxas.id; },"label":"nome","options":_vm.comboTaxas,"state":errors.length > 0 ? false : null,"disabled":!!_vm.$route.params.id},model:{value:(_vm.form.taxa),callback:function ($$v) {_vm.$set(_vm.form, "taxa", $$v)},expression:"form.taxa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":!!_vm.$route.params.id ? '6' : '12'}},[_c('validation-provider',{attrs:{"name":"Tipo de Empréstimo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Empréstimo","label-for":"v-tipoPagamento"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"reduce":function (tipoFiltradoPorEmprestimos) { return tipoFiltradoPorEmprestimos.id; },"label":"nome","options":_vm.tipoFiltradoPorEmprestimos,"state":errors.length > 0 ? false : null,"disabled":!!_vm.$route.params.id},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(!!_vm.$route.params.id)?_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Data Lançamento","label-for":"v-dataLancamento"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"name":"fieldDate","disabled":!!_vm.$route.params.id,"config":{
                      altInput: true,
                      altFormat: 'd/m/Y',
                      enableTime: false,
                      dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                    }},model:{value:(_vm.form.dataLancamento),callback:function ($$v) {_vm.$set(_vm.form, "dataLancamento", $$v)},expression:"form.dataLancamento"}})],1)],1):_vm._e(),(!!_vm.form.itens)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":"Parcelas"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.form.itens,"rtl":_vm.direction,"pagination-options":{
                      enabled: true,
                      perPage: _vm.pageLength
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editParcela(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Edit")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteParcela(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Mostrando 1 a ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3', '5', '10']},on:{"input":function (value) { return props.perPageChanged({
                                  currentPerPage: value
                                }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" registros ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,3834501997)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[(!_vm.$route.params.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 mt-1",attrs:{"type":"submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.cancelar}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }