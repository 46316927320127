export default {
  LOAD_LIST(state, data) {
    state.listaCamposAdicionais = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.campoAdicional = data
  },
  RESET(state) {
    state.campoAdicional = {
      nome: '',
      descricao: '',
      procedimento: {
        id: null
      },
      ativo: true
    }
  }
}
