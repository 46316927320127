export default {
  LOAD_LIST_DEPENDENTS(state, data) {
    state.listDependents = data
  },
  PREPARE_TO_EDIT_DEPENDENT(state, data) {
    state.dependent = data
  },
  RESET_DEPENDENT(state) {
    state.dependent = {
      nome: '',
      cpf: '',
      email: '',
      tipoRegistro: null,
      associado: null,
    }
  },
  PREPARE_TO_EDIT_FULL_DEPENDENT(state, data) {
    state.fullDependent = data
  },
  SET_LIST_COMBO_GENERO(state, data) {
    state.comboGeneros = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_ESTADO_CIVIL(state, data) {
    state.comboEstadoCivil = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_PROFISSOES(state, data) {
    state.comboProfissoes = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_NATURALIDADES(state, data) {
    state.comboNaturalidades = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_NACIONALIDADES(state, data) {
    state.comboNacionalidades = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_TYPES_REGISTER(state, data) {
    state.comboTypesRegister = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_UFS(state, data) {
    state.comboUfs = data.map(item => ({
      id: item.id,
      nome: item.nome,
      sigla: item.sigla,
    }))
  },
  SET_LIST_COMBO_PREVIDENCIA_SOCIAL(state, data) {
    state.comboPrevidenciaSocial = data.map(item => ({
      id: item.id,
      nome: item.nome,
      label: `${item.codigo} - ${item.nome}`,
    }))
  },
  SET_LIST_COMBO_TIPO_ENDERECO(state, data) {
    state.comboTipoEndereco = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LIST_COMBO_SITUACAO_REGISTRO(state, data) {
    state.comboSituacaoRegistro = data.map(item => ({
      id: item.id,
      nome: item.nome,
    }))
  },
  SET_LISTA_ENDERECOS(state, data) {
    state.listaEnderecos = data
  },
  RESET_ENDERECO(state) {
    state.endereco = {
      cep: null,
      logradouro: null,
      numero: null,
      bairro: null,
      cidade: null,
      complemento: null,
      ativo: true,
      principal: false,
      registro: {
        id: null,
        nome: null,
      },
      uf: {
        id: null,
        nome: null,
        sigla: null,
      },
      tipoEndereco: {
        id: null,
        nome: null,
      },
    }
  },
  PREPARE_TO_EDIT_ENDERECO(state, data) {
    state.endereco = data
  },
  SET_LISTA_ASSOCIADO(state, data) {
    state.listaAssociado = []
    state.listaAssociado.push(data)
  },
  SET_USAR_ENDERECO_ASSOCIADO(state, data) {
    state.fullDependent.reutilizarEndereco = data
  },
}
