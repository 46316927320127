var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-description"}},[_c('b-form-input',{attrs:{"id":"v-description","state":errors.length > 0 ? false : null,"placeholder":"descrição","disabled":(!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') && _vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_CORE_INSERIR') && _vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo Protocolo","label-for":"v-tipo"}},[_c('v-select',{attrs:{"label":"descricao","state":errors.length > 0 ? false : null,"options":_vm.comboTipoProtocolo,"disabled":(!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') && _vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_CORE_INSERIR') && _vm.form.id)},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description-validate"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.id)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ativo","label-for":"v-check-button"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.form.ativo,"name":"check-button","switch":"","inline":"","disabled":(!_vm.verificarPermissao('ROLE_CORE_ATUALIZAR') && _vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_CORE_INSERIR') && _vm.form.id)},model:{value:(_vm.form.ativo),callback:function ($$v) {_vm.$set(_vm.form, "ativo", $$v)},expression:"form.ativo"}},[_vm._v(" "+_vm._s(_vm.form.ativo ? 'Sim' : 'Não')+" ")])],1)],1):_vm._e(),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_CORE_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_CORE_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }