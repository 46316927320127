import parameterServices from '@/api/administrative/parameterServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => parameterServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_PARAMETER', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  prepareToEditParameter({ commit }, data) {
    commit('PREPARE_TO_EDIT_PARAMETER', data)
  },
  editParameter({ commit }, data) {
    return parameterServices.editParameter(data).then(result => result).catch(error => error)
  },
  resetParameter({ commit }) {
    commit('RESET_PARAMETER')
  },
}
