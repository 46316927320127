export default {
  LOAD_LIST_NACIONALITIES(state, data) {
    state.listNationalities = data
  },
  PREPARE_TO_EDIT_NACIONALITY(state, data) {
    state.nationality = data
  },
  RESET_NACIONALITY(state) {
    state.nationality = {
      ativo: true,
      nome: '',
      sigla: '',
    }
  },
}
