<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listGenders"
        :title-table="$t('Gender')"
        module="genderSettingsModule"
        sort-name="nome"
        :show-btn-add="verificarPermissao('ROLE_ADMIM_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ADMIM_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <FormGender v-else @cancelar="cancelar" />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import { BRow, BCol } from 'bootstrap-vue'
import FormGender from './forms/FormGender.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    TableComponents,
    FormGender
  },
  computed: {
    ...mapState('genderSettingsModule', {
      listGenders: (state) => state.listGenders
    })
  },
  data() {
    return {
      verificarPermissao,
      showForm: false,
      columns: [
        {
          label: 'Id',
          field: 'id'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'Sigla',
          field: 'sigla'
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc'
      }
    }
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$store.dispatch('genderSettingsModule/prepareToEditGender', data)
      this.showForm = true
    },
    cancelar() {
      this.showForm = false
    }
  }
}
</script>
