export default {
  LOAD_LIST_ESPECIALIDADE(state, data) {
    state.listaEspecialidades = data
  },
  PREPARE_TO_EDIT_ESPECIALIDADE(state, data) {
    state.especialidade = data
  },
  RESET(state) {
    state.especialidade = {
      id: null,
      descricao: '',
      valor: 0,
      tipoAtendimento: {
        id: null
      },
      ativo: true
    }
  }
}
