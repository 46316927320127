import paymentMethodsService from '@/api/financial/PaymentMethodsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => paymentMethodsService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_PAYMENT_METHODS', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewPaymentMethods({ commit }, data) {
    return paymentMethodsService.insertNewPaymentMethods(data).then(result => result).catch(error => error)
  },
  prepareToEditPaymentMethods({ commit }, data) {
    commit('PREPARE_TO_EDIT_PAYMENT_METHODS', data)
  },
  editPaymentMethods({ commit }, data) {
    return paymentMethodsService.editPaymentMethods(data).then(result => result).catch(error => error)
  },
  resetPaymentMethods({ commit }) {
    commit('RESET_PAYMENT_METHODS')
  },
  delete({ commit }, id) {
    return paymentMethodsService.deletePaymentMethods(id).then(result => result).catch(error => error)
  },
}
