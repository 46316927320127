export default {
  LOAD_LIST_BANKS_ACCOUNT(state, data) {
    state.listBanksAccount = data
  },
  PREPARE_TO_EDIT_BANK_ACCOUNT(state, data) {
    state.bankAccount = data
  },
  RESET_BANK_ACCOUNT(state) {
    state.bankAccount = {
      descricao: '',
      planoDeConta: { id: null },
      tipoConta: { id: null },
      dataSaldoInicial: '',
      valorSaldoInicial: '',
      agencia: '',
      conta: '',
      banco: {
        id: null,
      },
      valorSaldoAtual: 0,
      ativo: true,
    }
  },
  SET_LIST_COMBO_PLANO_CONTAS(state, data) {
    state.planoDeContaCombo = data.map(item => ({
      id: item.id,
      nome: item.classe,
    }))
  },
  SET_LIST_COMBO_TIPO_CONTAS(state, data) {
    state.tipoContaCombo = data.map(item => ({
      id: item.id,
      nome: item.descricao,
    }))
  },
  SET_LIST_COMBO_BANCOS(state, data) {
    state.comboBanks = data.map(item => ({
      id: item.id,
      nome: item.descricao,
    }))
  },
}
