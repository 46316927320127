<template>
  <b-card :title="titleForm">
    <b-row>
      <b-col
        v-if="objectPlace.id"
        sm="4"
        offset-lg="10"
        offset-md="8"
        offset-sm="6"
      >
        <b-button variant="primary" class="mb-1" @click="print">
          Recibo
        </b-button>
      </b-col>
    </b-row>
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12" lg="12">
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group label="Registro" label-for="v-registro">
                <v-select
                  v-model="objectPlace.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboRegistros"
                  :selected="objectPlace.registro"
                  :disabled="
                    disabledSearch ||
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!objectPlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !objectPlace.id)
                  "
                  @search="onSearch"
                  ><template slot="no-options">
                    Pesquisa registro por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-registro" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- first descricao -->
          <b-col cols="12" sm="12" md="6" lg="6">
            <validation-provider
              #default="{ errors }"
              name="descricao"
              rules="required"
            >
              <b-form-group label="Descricao" label-for="v-descricao">
                <b-form-input
                  id="v-descricao"
                  v-model="objectPlace.descricao"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descricao"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!objectPlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !objectPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group
              label="Repetições do Lançamento"
              label-for="v-repeticoes"
            >
              <b-form-input
                id="v-repeticoes"
                v-model="objectPlace.qtoRepetido"
                type="number"
                placeholder="repeticoes"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- Data dataLancamento -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Data Lançamento" label-for="v-dataLancamento">
              <flat-pickr
                v-model="objectPlace.dataLancamento"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- Data dataVencimento -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Data Vencimento" label-for="v-dataVencimento">
              <flat-pickr
                v-model="objectPlace.dataVencimento"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- Data dataQuitacao -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Data Quitação" label-for="v-dataQuitacao">
              <flat-pickr
                v-model="objectPlace.dataQuitacao"
                name="fieldDate"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first valor -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Valor" label-for="v-Valor">
              <cleave
                id="v-valor"
                v-model.number="objectPlace.valor"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="Valor"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first desconto -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Desconto" label-for="v-desconto">
              <cleave
                id="v-desconto"
                v-model.number="objectPlace.desconto"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="desconto"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first juros -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Juros" label-for="v-juros">
              <cleave
                id="v-juros"
                v-model.number="objectPlace.juro"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="sjuros"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first multa -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Multa" label-for="v-multa">
              <cleave
                id="v-multa"
                v-model.number="objectPlace.multa"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="multa"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first valorTotal -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Valor Total" label-for="v-valorTotal">
              <cleave
                id="v-valorTotal"
                v-model.number="objectPlace.valorTotal"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="valor total"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first valor quitacao -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <b-form-group label="Valor Quitação" label-for="v-valorQuitacao">
              <cleave
                id="v-valorQuitacao"
                v-model.number="objectPlace.valorQuitado"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="valor quitação"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!--  TIPO -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="tipo de pagamento"
              rules="required"
            >
              <b-form-group
                label="Tipo de Pagamento"
                label-for="v-tipoPagamento"
              >
                <v-select
                  v-model="objectPlace.tipo"
                  :reduce="(comboType) => comboType.id"
                  label="nome"
                  :options="comboType"
                  :class="[errors.length > 0 ? isValid : '']"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!objectPlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !objectPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!--  FORMA PAGAMENTO -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="forma pagamento"
              rules="required"
            >
              <b-form-group
                label="Forma Pagamento"
                label-for="v-formaPagamento"
              >
                <v-select
                  v-model="objectPlace.formaPagamento"
                  :reduce="(comboPayment) => comboPayment.id"
                  label="nome"
                  :options="comboPayment"
                  :class="[errors.length > 0 ? isValid : '']"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!objectPlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !objectPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!--  SITUACAO -->
          <b-col cols="12" sm="12" md="6" lg="4">
            <validation-provider
              #default="{ errors }"
              name="sitiacao"
              rules="required"
            >
              <b-form-group label="Situação" label-for="v-situacao">
                <v-select
                  v-model="objectPlace.situacao"
                  :reduce="(comboSituation) => comboSituation.id"
                  label="nome"
                  :options="comboSituation"
                  :class="[errors.length > 0 ? isValid : '']"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!objectPlace.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') &&
                      !objectPlace.id)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- first obs -->
          <b-col cols="12" lg="12">
            <b-form-group label="Observação" label-for="v-obs">
              <b-form-input
                id="v-obs"
                v-model="objectPlace.observacao"
                placeholder="observação"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!objectPlace.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !objectPlace.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_FINAN_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_FINAN_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'
import _ from 'lodash'

export default {
  name: 'FormPaymentReceiptAccount',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  props: {
    titleForm: {
      type: String,
      default: 'FORMULARIO'
    },
    comboType: {
      type: Array,
      default: () => []
    },
    comboPayment: {
      type: Array,
      default: () => []
    },
    comboSituation: {
      type: Array,
      default: () => []
    },
    ehAssociado: {
      type: Boolean,
      default: true
    },
    ehPj: {
      type: Boolean,
      default: false
    },
    objectEdit: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isValid: 'is-invalid',
      required,
      disabledSearch: false,
      comboRegistros: [],
      objectPlace: {
        id: null,
        registro: {
          id: null
        },
        descricao: '',
        dataLancamento: '',
        dataVencimento: '',
        dataQuitacao: null,
        valor: 0.0,
        desconto: 0.0,
        juro: 0.0,
        multa: 0.0,
        valorTotal: 0.0,
        valorQuitado: 0.0,
        observacao: null,
        tipo: null,
        formaPagamento: null,
        situacao: null,
        ativo: true,
        qtoRepetido: 0
      },
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }
      }
    }
  },
  mounted() {
    this.cleanObjeto()
    if (this.$route.params.id) {
      this.disabledSearch = true
      this.objectPlace = JSON.parse(JSON.stringify(this.objectEdit))
    }
  },
  methods: {
    print() {
      this.report = {}
      this.report = {
        nome: 'recibo',
        valor: [parseInt(this.objectPlace.id)]
      }
      this.$store
        .dispatch('reportModule/printReport', this.report)
        .then((response) => {
          if (response.error) {
            this.showMessageSuccess('Limite Excedido', response.message)
          }
          this.openNewAbaBase64(response.data)
        })
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'pagamentoRecebimentoFinancialModule/searchRegistros',
        search
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`
        )
        return
      }

      if (registrosEncontrados.data.content.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data.content
      loading(false)
    }, 350),
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
    },
    cleanObjeto() {
      this.disabledSearch = false
      this.objectPlace = {
        id: null,
        registro: {
          id: null
        },
        descricao: '',
        dataLancamento: '',
        dataVencimento: '',
        dataQuitacao: null,
        valor: 0,
        desconto: null,
        juro: null,
        multa: null,
        valorTotal: null,
        valorQuitado: null,
        observacao: null,
        tipo: {
          id: null
        },
        formaPagamento: {
          id: null
        },
        situacao: null,
        ativo: true,
        qtoRepetido: 0
      }
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$emit('save', this.objectPlace)
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
