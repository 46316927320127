<template>
  <div>
    <div v-if="!disableForm">
      <validation-observer ref="form">
        <b-form @submit.prevent>
          <b-row>
            <!-- cep -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="cep"
                rules="required|regex:^[\d]{2}.[\d]{3}-[\d]{3}$"
              >
                <b-form-group
                  label="CEP"
                  label-for="cep"
                >
                  <b-input-group>
                    <cleave
                      id="cep"
                      v-model="endereco.cep"
                      type="text"
                      placeholder="CEP"
                      class="form-control"
                      :raw="false"
                      :options="options.maskCep"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="loadEnderecoViaCep()"
                      >
                        Buscar
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- tipo endereco -->
            <b-col md="6">
              <b-form-group
                label="Tipo de Endereço"
                label-for="tipoEndereco"
              >
                <v-select
                  v-model="endereco.tipoEndereco"
                  :disabled="enderecoNaoCarregado"
                  label="nome"
                  :options="comboTipoEnderecos"
                />
              </b-form-group>
            </b-col>

            <!-- logradouro -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="logradouro"
                rules="required"
              >
                <b-form-group
                  label="Logradouro"
                  label-for="logradouro"
                >
                  <b-form-input
                    id="logradouro"
                    v-model="endereco.logradouro"
                    :disabled="enderecoNaoCarregado"
                    type="text"
                    placeholder="logradouro"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- numero -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="numero"
                rules="required"
              >
                <b-form-group
                  label="Número"
                  label-for="numero"
                >
                  <b-form-input
                    id="numero"
                    v-model="endereco.numero"
                    :disabled="enderecoNaoCarregado"
                    type="text"
                    placeholder="numero"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- complemento -->
            <b-col md="6">
              <b-form-group
                label="Complemento"
                label-for="complemento"
              >
                <b-form-input
                  id="complemento"
                  v-model="endereco.complemento"
                  :disabled="enderecoNaoCarregado"
                  type="text"
                  placeholder="complemento"
                />
              </b-form-group>
            </b-col>

            <!-- bairro -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="bairro"
                rules="required"
              >
                <b-form-group
                  label="Bairro"
                  label-for="bairro"
                >
                  <b-form-input
                    id="bairro"
                    v-model="endereco.bairro"
                    :disabled="enderecoNaoCarregado"
                    type="text"
                    placeholder="bairro"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- cidade -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="cidade"
                rules="required"
              >
                <b-form-group
                  label="Cidade"
                  label-for="cidade"
                >
                  <b-form-input
                    id="cidade"
                    v-model="endereco.cidade"
                    :disabled="enderecoNaoCarregado"
                    type="text"
                    placeholder="cidade"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- uf -->
            <b-col md="6">
              <b-form-group
                label="UF"
                label-for="uf"
              >
                <v-select
                  v-model="endereco.uf"
                  :disabled="enderecoNaoCarregado"
                  label="nome"
                  :options="comboUfs"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="save()"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button
          variant="primary"
          @click="novoEndereco()"
        >
          Incluir
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="listaEnderecos"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="
              props.column.field === 'principal' ||
                props.column.field === 'whatsapp'
            "
          >
            <span>
              {{ props.formattedRow[props.column.field] ? "Sim" : "Não" }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editEndereco(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteEndereco(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Mostrando 1 a </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BInputGroup,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('employeesAdministrativeModule', {
      listaEnderecos: state => state.listaEnderecos,
      endereco: state => state.endereco,
      comboTipoEnderecos: state => state.comboTipoEndereco,
      comboUfs: state => state.comboUfs,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async mounted() {
    this.loadTable()
    await this.$store.dispatch(
      'employeesAdministrativeModule/loadComboTipoEndereco',
    )
  },
  methods: {
    novoEndereco() {
      this.enderecoNaoCarregado = true
      this.$store.commit('employeesAdministrativeModule/RESET_ENDERECO')
      this.disableForm = false
    },
    editEndereco(data) {
      this.$store.dispatch(
        'employeesAdministrativeModule/prepareToEditEndereco',
        data,
      )
      this.disableForm = false
      this.enderecoNaoCarregado = false
    },
    cancel() {
      this.enderecoNaoCarregado = true
      this.$store.commit('employeesAdministrativeModule/RESET_ENDERECO')
      this.disableForm = true
    },
    async loadTable() {
      await this.$store.dispatch(
        'employeesAdministrativeModule/loadEnderecosPorRegistro',
        this.$route.params.id,
      )
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.endereco.id) {
            this.$store
              .dispatch(
                'employeesAdministrativeModule/editEndereco',
                this.endereco,
              )
              .then(response => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error,
                  )
                  return
                }
                this.loadTable()
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.endereco.registro.id = this.$route.params.id
            this.$store
              .dispatch(
                'employeesAdministrativeModule/inserirNovoEndereco',
                this.endereco,
              )
              .then(response => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error,
                  )
                  return
                }
                this.loadTable()
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          }
        }
      })
    },
    deleteEndereco(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('employeesAdministrativeModule/deleteEndereco', data.id)
            .then(() => {
              this.loadTable()
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!',
              )
            })
            .catch(err => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.loadTable()
            })
        }
      })
    },
    loadEnderecoViaCep() {
      const cepSemMascara = this.endereco.cep.replace(/[().\s-]+/g, '')
      this.$store.commit('employeesAdministrativeModule/RESET_ENDERECO')
      this.$store
        .dispatch(
          'employeesAdministrativeModule/getEnderecoViaCep',
          cepSemMascara,
        )
        .then(response => {
          if (response.data.erro) {
            this.MensagemInfo(
              'CEP inválido ou não encontrado',
              'Preencha os campos de endereço!',
            )
            this.endereco.cep = cepSemMascara
            this.endereco.bairro = ''
            this.endereco.cidade = ''
            this.endereco.logradouro = ''
            this.endereco.uf = this.getUfPorSigla('PE')
            this.enderecoNaoCarregado = false
            this.endereco.principal = false
            return
          }
          const ufEncontrada = this.getUfPorSigla(response.data.uf)
          this.endereco.bairro = response.data.bairro
          this.endereco.cep = response.data.cep
          this.endereco.cidade = response.data.localidade
          this.endereco.logradouro = response.data.logradouro
          this.endereco.uf = ufEncontrada
          this.enderecoNaoCarregado = false
        })
    },
    getUfPorSigla(siglaUf) {
      return this.comboUfs.find(
        uf => uf.sigla.toUpperCase() === siglaUf.toUpperCase(),
      )
    },
  },
  data() {
    return {
      required,
      disableForm: true,
      columns: [
        {
          label: 'Logradouro',
          field: 'logradouro',
        },
        {
          label: 'Bairro',
          field: 'bairro',
        },
        {
          label: 'Cidade',
          field: 'cidade',
        },
        {
          label: 'Número',
          field: 'numero',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc',
      },
      pageLength: 10,
      dir: false,
      options: {
        maskCep: {
          delimiters: ['.', '-'],
          blocks: [2, 3, 3],
          uppercase: true,
        },
      },
      enderecoNaoCarregado: true,
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
