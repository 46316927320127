export default [
  {
    path: '/modules/ordemServicos',
    name: 'ordemServico',
    component: () => import('@/views/modules/ordemServico/OrdemServico.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Ordem de Serviço',
      breadcrumb: [
        {
          text: 'Ordem de Serviço'
        }
      ]
    }
  }
]
