import filesAccountsService from '@/api/financialModule/filesAccount'

export default {
  importNeos({ commit }, obj) {
    return filesAccountsService.importNeos(obj).then(result => result).catch(error => error)
  },
  getFileById({ commit }, id) {
    return filesAccountsService.getFileById(id).then(result => result).catch(error => error)
  },
  exportNeos({ commit }) {
    return filesAccountsService.exportNeos().then(result => result).catch(error => error)
  },
  loadHistoricoArquivos({ commit }, pageConfig) {
    return filesAccountsService.loadHistoricoArquivos(pageConfig).then(result => result).catch(error => error)
  },
  loadHistoricoArquivosInss({ commit }, pageConfig) {
    return filesAccountsService.loadHistoricoArquivosInss(pageConfig).then(result => result).catch(error => error)
  },
  importInss({ commit }, file) {
    return filesAccountsService.importInss(file).then(result => result).catch(error => error)
  },
  processarArquivo({ commit }, id) {
    return filesAccountsService.processarArquivo(id).then(result => result).catch(error => error)
  },
  carregarPorIdentificador({ commit }, pageConfig) {
    return filesAccountsService.carregarPorIdentificador(pageConfig).then(result => result).catch(error => error)
  },
}
