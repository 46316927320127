export default {
  listRegistrationsTypes: [],
  registrationType: {
    nome: '',
    valor: '',
    ativo: true,
    tipo: null,
  },
  comboTypes: [],
}
