import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/protocolo/protocolos${queryUrl}`)
  },
  insert(data) {
    return axios.post('/protocolo/protocolos', data)
  },
  edit(data) {
    return axios.put(`/protocolo/protocolos/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/protocolo/protocolos/${id}`)
  },
  getProtocolByIdRegister(id) {
    return axios.get(`/protocolo/protocolos/registro/${id}`)
  }
}
