import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/administrativo/parametros${queryUrl}`)
  },
  editParameter(data) {
    return axios.put(`/administrativo/parametros/${data.id}`, data)
  },
}
