<template>
  <div>
    <b-row>
      <b-col cols="12">
        <!-- <CardVue :cards="cards" /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="2">
        <div v-for="(opcao, index) in opcoes" :key="index">
          <b-button
            v-if="verificarPermissao(opcao.action)"
            class="mb-1"
            block
            variant="gradient-primary"
            :to="opcao.route"
            exact
          >
            {{ $t(opcao.title) }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="10">
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import CardVue from '@/layouts/components/Card.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CardVue
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Neos',
          route: { name: 'importPageNeos' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'INSS',
          route: { name: 'importPageInss' },
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL'
        }
      ],
      cards: []
    }
  }
}
</script>
