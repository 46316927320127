import socialSecurityGroupServices from '@/api/administrative/socialSecurityGroupServices'

export default {
  loadTable({ commit }, pageConfig) {
    return socialSecurityGroupServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_SOCIAL_SECURITY_GROUP', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewSocialSecurityGroup({ commit }, data) {
    return socialSecurityGroupServices.insertNewSocialSecurityGroup(data).then(result => result).catch(error => error)
  },
  prepareToEditSocialSecurityGroup({ commit }, data) {
    commit('PREPARE_TO_EDIT_SOCIAL_SECURITY_GROUP', data)
  },
  editSocialSecurityGroup({ commit }, data) {
    return socialSecurityGroupServices.editSocialSecurityGroup(data).then(result => result).catch(error => error)
  },
  resetSocialSecurityGroup({ commit }) {
    commit('RESET_SOCIAL_SECURITY_GROUP')
  },
  delete({ commit }, id) {
    return socialSecurityGroupServices.deleteSocialSecurityGroup(id).then(result => result).catch(error => error)
  },
}
