<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listaFornecedores"
        sort-name="nome"
        title-table="Fornecedor"
        module="fornecedorAdministrativeModule"
        @add="addNew"
        @edit="edit"
      />
      <form-insert
        v-else
        :eh-pj="true"
        :title-form="objectFormInsert.titleForm"
        :combo-type="comboTypesRegister"
        @cancelar="cancelar"
        @save="save"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import FormInsert from '@/layouts/components/FormInsert.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'

export default {
  name: 'Fornecedor',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormInsert,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('fornecedorAdministrativeModule', {
      listaFornecedores: state => state.listaFornecedores,
      fornecedor: state => state.fornecedor,
      comboTypesRegister: state => state.comboTypesRegister,
    }),
  },
  data() {
    return {
      showForm: false,
      columns: [
        {
          label: 'Nome',
          field: 'nome',
        },
        {
          label: 'CNPJ',
          field: 'cnpj',
        },
        {
          label: 'Fone',
          field: 'fone',
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Fornecedor',
      },
    }
  },

  mounted() {
    this.$store.dispatch('fornecedorAdministrativeModule/loadComboTypesRegister')
  },
  methods: {
    addNew() {
      this.showForm = true
    },
    edit(data) {
      this.$router.push({ path: `editFornecedor/${data.id}` })
    },
    cancelar() {
      this.$store.dispatch('fornecedorAdministrativeModule/resetFornecedor')
      this.showForm = false
    },
    async save(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const objetoPJSave = {
        nome: objectSave.nome,
        cnpj: objectSave.cpf,
        email: objectSave.email,
        tipoRegistro: objectSave.tipoRegistro,
      }
      this.$store.dispatch('fornecedorAdministrativeModule/insertNewFornecedor', objetoPJSave).then(response => {
        if (response.response) {
          this.MensagemError(`Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`, `${response.response.data.errors[0].message}`)
          this.showForm = true
          return
        }
        this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
        this.cancelar()
        this.edit(response.data)
      })
    },
  },
}
</script>
