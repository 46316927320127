export default {
  listaEspecialidades: [],
  especialidade: {
    id: null,
    descricao: '',
    valor: 0,
    tipoAtendimento: {
      id: null,
    },
    ativo: true,
  },
}
