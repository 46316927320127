import othersService from '@/api/AdministrativeModule/othersServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      othersService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_OTHERS', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insertNewOther({ commit }, data) {
    return othersService
      .insertNewOther(data)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditOther({ commit }, data) {
    commit('PREPARE_TO_EDIT_OTHER', data)
  },
  editOther({ commit }, data) {
    return othersService
      .editOther(data)
      .then((result) => result)
      .catch((error) => error)
  },
  resetOther({ commit }) {
    commit('RESET_OTHER')
  },
  getOtherID({ commit }, id) {
    return new Promise((resolve, reject) =>
      othersService
        .getOtherID(id)
        .then((result) => {
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  prepareToEditFullOther({ commit }, data) {
    commit('PREPARE_TO_EDIT_FULL_OTHER', data)
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadCombo()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTypeNotAssociate({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboTypeNotAssociate()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboGenero()
        .then((result) => {
          commit('SET_LIST_COMBO_GENERO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboEstadoCivil()
        .then((result) => {
          commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboProfissoes()
        .then((result) => {
          commit('SET_LIST_COMBO_PROFISSOES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboNaturalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NATURALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboNacionalidades()
        .then((result) => {
          commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboUfs()
        .then((result) => {
          commit('SET_LIST_COMBO_UFS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboPrevidenciaSocial()
        .then((result) => {
          commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboTipoEndereco()
        .then((result) => {
          commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboSituacaoRegistro()
        .then((result) => {
          commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  editOtherDadosPessoais({ commit }, data) {
    return othersService
      .editOtherDadosPessoais(data)
      .then((result) => result)
      .catch((error) => error)
  },
  editOtherDocumentation({ commit }, data) {
    return othersService
      .editOtherDocumentation(data)
      .then((result) => result)
      .catch((error) => error)
  },
  getEnderecoViaCep({ commit }, data) {
    return othersService
      .getEnderecoViaCep(data)
      .then((result) => result)
      .catch((error) => error)
  },
  inserirNovoEndereco({ commit }, data) {
    return othersService
      .inserirNovoEndereco(data)
      .then((result) => result)
      .catch((error) => error)
  },
  editEndereco({ commit }, data) {
    return othersService
      .editEndereco(data)
      .then((result) => result)
      .catch((error) => error)
  },
  // Novos
  sendNewPhoto({ commit }, object) {
    return othersService
      .sendNewPhoto(object)
      .then((result) => result)
      .catch((error) => error)
  },
  deletePhoto({ commit }, id) {
    return othersService
      .deletePhoto(id)
      .then((result) => result)
      .catch((error) => error)
  },
  setHistoricoSituacao({ commit }, data) {
    return othersService
      .setHistoricoSituacao(data)
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoSituacao({ commit }, id) {
    return othersService
      .getHistoricoSituacao(id)
      .then((result) => result)
      .catch((error) => error)
  },
  loadEnderecosPorRegistro({ commit }, id) {
    return new Promise((resolve, reject) =>
      othersService
        .loadEnderecosPorRegistro(id)
        .then((result) => {
          commit('SET_LISTA_ENDERECOS', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  deleteEndereco({ commit }, id) {
    return othersService
      .deleteEndereco(id)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditEndereco({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECO', data)
  },
  getListDepedentOutros({ commit }, id) {
    return othersService
      .getListDepedentOutros(id)
      .then((result) => result)
      .catch((error) => error)
  },
  sendFiles({ commit }, object) {
    return othersService
      .sendFiles(object)
      .then((result) => result)
      .catch((error) => error)
  },
  loadDocumentations({ commit }, id) {
    return othersService
      .loadDocumentations(id)
      .then((result) => result)
      .catch((error) => error)
  },
  downloadDocument({ commit }, id) {
    return othersService
      .downloadDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  deleteDocument({ commit }, id) {
    return othersService
      .deleteDocument(id)
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoTipoRegistro({ commit }, id) {
    return othersService
      .getHistoricoTipoRegistro(id)
      .then((result) => result)
      .catch((error) => error)
  },
  setMudarTipoRegistro({ commit }, data) {
    return othersService
      .setMudarTipoRegistro(data)
      .then((result) => result)
      .catch((error) => error)
  },
  loadCards({ commit }) {
    return othersService
      .loadCards()
      .then((result) => result)
      .catch((error) => error)
  },
  getHistoricoFaixaContribuicao({ commit }, id) {
    return othersService
      .getHistoricoFaixaContribuicao(id)
      .then((result) => result)
      .catch((error) => error)
  },
  setMudarFaixaContribuica({ commit }, data) {
    return othersService
      .setMudarFaixaContribuica(data)
      .then((result) => result)
      .catch((error) => error)
  },
  migrarAssociados({ commit }, data) {
    return othersService
      .migrarAssociados(data)
      .then((result) => result)
      .catch((error) => error)
  },
  loadComboTypesRegisterAssociate({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboTypesRegisterAssociate()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTypesRegisterDependent({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboTypesRegisterDependent()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  loadComboTypesRegisterAggregate({ commit }) {
    return new Promise((resolve, reject) =>
      othersService
        .loadComboTypesRegisterAggregate()
        .then((result) => {
          commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  setMudarTipoRegistroAssociado({ commit }, data) {
    return othersService
      .setMudarTipoRegistroAssociado(data)
      .then((result) => result)
      .catch((error) => error)
  },
  setMudarTipoRegistroDependenteAgregado({ commit }, data) {
    return othersService
      .setMudarTipoRegistroDependenteAgregado(data)
      .then((result) => result)
      .catch((error) => error)
  }
}
