var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"tipo de registro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Registro","label-for":"v-cbo"}},[_c('v-select',{class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"RegisterType","reduce":function (comboType) { return comboType.id; },"label":"nome","options":_vm.comboType},model:{value:(_vm.objectPlace.tipoRegistro),callback:function ($$v) {_vm.$set(_vm.objectPlace, "tipoRegistro", $$v)},expression:"objectPlace.tipoRegistro"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.showInputCpfAssociado())?_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"cpf Associado","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF do Associado","label-for":"v-cpf-associado"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-cpf-associado","raw":false,"placeholder":"cpf do associado","options":_vm.maskCpf,"readonly":_vm.ehAssociadoHabilitado()},model:{value:(_vm.cpfAssociadoPlace),callback:function ($$v) {_vm.cpfAssociadoPlace=$$v},expression:"cpfAssociadoPlace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2401809249)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.ehPj ? 'cnpj' : 'cpf',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.ehPj ? 'CNPJ' : 'CPF',"label-for":"v-cpf"}},[_c('cleave',{staticClass:"form-control",class:[errors.length > 0 ? _vm.isValid : ''],attrs:{"id":"v-cpf","raw":false,"placeholder":_vm.ehPj ? 'cmpj' : 'cpf',"options":_vm.ehPj ? _vm.maskCnpj : _vm.maskCpf},model:{value:(_vm.objectPlace.cpf),callback:function ($$v) {_vm.$set(_vm.objectPlace, "cpf", $$v)},expression:"objectPlace.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-for":"v-nome"}},[_c('b-form-input',{attrs:{"id":"v-nome","state":errors.length > 0 ? false : null,"placeholder":"nome"},model:{value:(_vm.objectPlace.nome),callback:function ($$v) {_vm.$set(_vm.objectPlace, "nome", $$v)},expression:"objectPlace.nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"v-email"}},[_c('b-form-input',{attrs:{"id":"v-email","type":"email","placeholder":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.objectPlace.email),callback:function ($$v) {_vm.$set(_vm.objectPlace, "email", $$v)},expression:"objectPlace.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"id":"btnSave","type":"submit","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Salvar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"id":"btnCancel","type":"reset","variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }