import axios from '@axios'

export default {
  importNeos(obj) {
    const dataForm = new FormData()
    dataForm.append('file', obj.file)
    if (obj.tipo === 9991) {
      return axios.post('integracao/excel/insconsistencias', dataForm)
    } if (obj.tipo === 9992) {
      return axios.post('integracao/excel', dataForm)
    }
    return axios.post(`/importacao_finan_txt/${obj.tipo}`, dataForm)
  },
  getFileById(id) {
    return axios.get(`importacao_finan_txt/${id}`)
  },
  loadHistoricoArquivos(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`importacao_finan_txt/${queryUrl}`)
  },
  loadHistoricoArquivosInss(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`importacao_inss${queryUrl}`)
  },
  exportNeos() {
    return axios.get('exportacao_neos/download')
  },
  importInss(file) {
    const dataForm = new FormData()
    dataForm.append('file', file)
    return axios.post('/importacao_inss/', dataForm)
  },
  processarArquivo(id) {
    return axios.post(`importacao_finan_txt/processar/${id}`)
  },
  carregarPorIdentificador(queryFilter) {
    let queryUrl = ''
    queryUrl = `${queryFilter.identificador}?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`importacao_inss/${queryUrl}`)
  },
}
