export default {
  listaFornecedores: [],
  fornecedor: {
    nome: '',
    cnpj: '',
    email: '',
    tipoRegistro: null,
  },
  fullFornecedor: {
    id: null,
    matricula: '',
    nome: '',
    email: '',
    tipoRegistro: {
      id: null,
      nome: '',
    },
    situacaoRegistro: {
      id: null,
      nome: '',
    },
    foto: null,
    observacao: null,
    enderecos: [],
    fones: [],
    nomeFantasia: null,
    cnpj: '',
    cpf: '',
    mei: false,
    cnae: null,
    inscricaoEstadual: null,
    inscricaoMunicipal: null,
    capitalSocial: null,
    numeroJuntaComercial: null,
    naturezaFiscal: {
      id: null,
      nome: null,
    },
  },
  comboTypesRegister: [{
    id: null,
    nome: null,
  }],
}
