export default {
  listTypesOfPaymentReceipts: [],
  typeOfPaymentReceipt: {
    descricao: '',
    conta: {
      id: null,
    },
    ativo: true,
  },
  comboConta: [],
}
