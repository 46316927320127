export default {
  listParameters: [],
  parameter: {
    tipo: '',
    lista: true,
    nome: '',
    valor: '',
    comentario: '',
  },
}
