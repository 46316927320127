import service from '@/api/attendance/procedimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => service.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNew({ commit }, data) {
    return service.insertNew(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return service.edit(data).then(result => result).catch(error => error)
  },
  reset({ commit }) {
    commit('RESET')
  },
  delete({ commit }, id) {
    return service.delete(id).then(result => result).catch(error => error)
  },
}
