export default {
  comboTypesRegister: [
    {
      id: null,
      nome: null
    }
  ],
  comboGeneros: [
    {
      id: null,
      nome: null
    }
  ],
  comboEstadoCivil: [
    {
      id: null,
      nome: null
    }
  ],
  comboProfissoes: [
    {
      id: null,
      nome: null
    }
  ],
  comboNaturalidades: [
    {
      id: null,
      nome: null
    }
  ],
  comboNacionalidades: [
    {
      id: null,
      nome: null
    }
  ],
  comboUfs: [
    {
      id: null,
      nome: null,
      sigla: null
    }
  ],
  comboPrevidenciaSocial: [
    {
      id: null,
      nome: null
    }
  ],
  comboTipoEndereco: [
    {
      id: null,
      nome: null
    }
  ],
  comboSituacaoRegistro: [
    {
      id: null,
      nome: null
    }
  ],
  comboNaturezaFiscals: [
    {
      id: null,
      nome: null
    }
  ],
  comboFormaPagamento: [
    {
      id: null,
      nome: null
    }
  ],
  comboTipoPagamento: [
    {
      id: null,
      nome: null
    }
  ],
  comboFaixaContribuicao: [
    {
      id: null,
      nome: null
    }
  ],
  comboDepedentesPorTipo: [
    {
      id: null,
      nome: null
    }
  ],
  comboTaxas: [
    {
      id: null,
      nome: null
    }
  ],
  comboEspecialidades: [
    {
      id: null,
      nome: null
    }
  ],
  comboTiposAtendimento: [
    {
      id: null,
      nome: null
    }
  ],
  comboTipoProtocolo: [
    {
      id: null,
      nome: null
    }
  ],
  comboMeioProtocolo: [
    {
      id: null,
      nome: null
    }
  ],
  comboConselhos: [
    {
      id: null,
      nome: null
    }
  ],
  comboCidades: [
    {
      id: null,
      nome: null
    }
  ],
  comboProcedimentos: [
    {
      id: null,
      nome: null
    }
  ],
  comboProtocolo: [
    {
      id: null,
      nome: null,
      tipo: null
    }
  ],
  comboItem: [
    {
      id: null,
      nome: null
    }
  ],
  comboStatusOs: [
    {
      id: null,
      nome: null
    }
  ],
  comboGrupos: [
    {
      id: null,
      nome: null
    }
  ],
  comboRoles: [
    {
      id: null,
      nome: null
    }
  ],
  comboPermissions: [
    {
      id: null,
      nome: null
    }
  ],
  comboPerfils: [
    {
      id: null,
      nome: null
    }
  ],
  comboPermissionsProfile: [
    {
      id: null,
      authority: null
    }
  ],
  comboDoencasCid: [
    {
      id: null,
      nome: null
    }
  ]
}
