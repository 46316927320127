import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import professionsSettingsModule from './settingsModules/admnistrative/professionsSettingsModule'
import fiscalNatureSettingsModule from './settingsModules/admnistrative/fiscalNatureSettingsModule'
import functionsSettingsModule from './settingsModules/admnistrative/functionsSettingsModule'
import registrationTypeSettingsModule from './settingsModules/admnistrative/registrationTypeSettingsModule'
import executivePositionsSettingsModule from './settingsModules/admnistrative/executivePositionsSettingsModule'
import bankSettingsModule from './settingsModules/finacial/bankSettingsModule'
import parameterFinancialSettingsModule from './settingsModules/finacial/parameterFinancialSettingsModule'
import bankAccountSettingsModule from './settingsModules/finacial/bankAccountSettingsModule'
import accountTypeSettingsModule from './settingsModules/finacial/accountTypeSettingsModule'
import accountPlanSettingsModule from './settingsModules/finacial/accountPlanSettingsModule'
import typeOfPaymentReceiptSettingsModule from './settingsModules/finacial/typeOfPaymentReceiptSettingsModule'
import paymentMethodsSettingsModule from './settingsModules/finacial/paymentMethodsSettingsModule'
import taxasSettingsModule from './settingsModules/finacial/taxasSettingsModule'
import contributionRangeSettingsModule from './settingsModules/finacial/contributionRangeSettingsModule'
import associantesAdministrativeModule from './associantesAdministrativeModule'
import usersSettingAccessModuleModule from './settingsModules/access/usersSettingAccessModule'
import profileSettingAccessModule from './settingsModules/access/profileSettingAccessModule'
import dependentAdministrativeModule from './dependentAdministrativeModule'
import civilStatusSettingsModule from './settingsModules/admnistrative/civilStatusSettingsModule'
import nationalitySettingsModule from './settingsModules/admnistrative/nationalitySettingsModule'
import naturalnessSettingsModule from './settingsModules/admnistrative/naturalnessSettingsModule'
import genderSettingsModule from './settingsModules/admnistrative/genderSettingsModule'
import addressTypeSettingsModule from './settingsModules/admnistrative/addressTypeSettingsModule'
import recordSituationsSettingsModule from './settingsModules/admnistrative/recordSituationsSettingsModule'
import socialSecurityGroupSettingsModule from './settingsModules/admnistrative/socialSecurityGroupSettingsModule'
import socialSecuritySettingsModule from './settingsModules/admnistrative/socialSecuritySettingsModule'
import aggregatesAdministrativeModule from './aggregatesAdministrativeModule'
import employeesAdministrativeModule from './employeesAdministrativeModule'
import othersAdministrativeModule from './othersAdministrativeModule'
import foneSettingsModule from './settingsModules/admnistrative/foneSettingsModule'
import phoneTypeSettingsModule from './settingsModules/admnistrative/phoneTypeSettingsModule'
import addressSettingsModule from './settingsModules/admnistrative/addressSettingsModule'
import sectorSettingsModule from './settingsModules/admnistrative/sectorSettingsModule'
import parameterAdministrativeSettingsModule from './settingsModules/admnistrative/parameterAdministrativeSettingsModule'
import fornecedorAdministrativeModule from './fornecedorAdministrativeModule'
// PROTOCOLO
import protocolTypeSettingsModule from './settingsModules/protocol/protocolTypeSettingsModule'
import mediumProtocolSettingsModule from './settingsModules/protocol/mediumProtocolSettingsModule'
import protocolSettingsModule from './settingsModules/protocol/protocolSettingsModule'
import itemProtocolSettingsModule from './settingsModules/protocol/itemProtocolSettingsModule'
import itemSettingsModule from './settingsModules/protocol/itemSettingsModule'
// MODULE FINANCIAL
import pagamentoRecebimentoFinancialModule from './pagamentoRecebimentoFinancialModule'
import filesFinancialModule from './filesFinancialModule'
import parcelamentoFinananceiroModule from './parcelamentoFinananceiroModule'
// ATENDIMENTO CONFIGURAÇÔES
import agendaSettingsModule from './settingsModules/atendimento/agendaSettingsModule'
import conselhoSettingsModule from './settingsModules/atendimento/conselhoSettingsModule'
import convenioSettingsModule from './settingsModules/atendimento/convenioSettingsModule'
import especialidadeSettingsModule from './settingsModules/atendimento/especialidadeSettingsModule'
import exameSettingsModule from './settingsModules/atendimento/exameSettingsModule'
import professionsAtendimentoSettingsModule from './settingsModules/atendimento/profissionalSettingsModule'
import situacaoAtendimentoSettingsModule from './settingsModules/atendimento/situacaoAtendimentoSettingsModule'
import tipoAtendimentoSettingsModule from './settingsModules/atendimento/tipoAtendimentoSettingsModule'
import unidadeAtendimentoSettingsModule from './settingsModules/atendimento/unidadeAtendimentoSettingsModule'
import parameterAttendeceSettingsModule from './settingsModules/atendimento/parameterAttendeceSettingsModule'
import doencasCidSettingsModule from './settingsModules/atendimento/doencasCidSettingsModule'
import feriadoSettingsModule from './settingsModules/atendimento/feriadoSettingsModule'
import camposAdicionaisSettingsModule from './settingsModules/atendimento/camposAdicionaisSettingsModule'
import procedimentosSettingsModule from './settingsModules/atendimento/procedimentosSettingsModule'
import grupoSettingsModule from './settingsModules/atendimento/grupoSettingsModule'

// CLIENTE
import clientSettingsModule from './settingsModules/client'
import diretoriaSettingsModule from './diretoriaSettingsModule'

// ATENDIMENTO
import atendimentoModule from './atendimentoModule'
import atendimentoProfissionalModule from './atendimentoProfissionalModule'
import agendamentoModule from './agendamentoModule'
import agendaModule from './agendaModule'

// CONVENIO

import convenioModule from './convenioModule'
// ORDEM SERVIÇOS
import ordemServicoModule from './ordemServicoModule'
import statusOsModule from './settingsModules/ordemServicoSettingModule/statusOsSettingsModule'

import reportModule from './reportModule'
import combosModule from './combos'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    combosModule,
    professionsSettingsModule,
    fiscalNatureSettingsModule,
    functionsSettingsModule,
    registrationTypeSettingsModule,
    executivePositionsSettingsModule,
    bankSettingsModule,
    bankAccountSettingsModule,
    accountTypeSettingsModule,
    accountPlanSettingsModule,
    typeOfPaymentReceiptSettingsModule,
    paymentMethodsSettingsModule,
    associantesAdministrativeModule,
    usersSettingAccessModuleModule,
    dependentAdministrativeModule,
    civilStatusSettingsModule,
    nationalitySettingsModule,
    naturalnessSettingsModule,
    genderSettingsModule,
    addressTypeSettingsModule,
    aggregatesAdministrativeModule,
    employeesAdministrativeModule,
    othersAdministrativeModule,
    foneSettingsModule,
    recordSituationsSettingsModule,
    socialSecurityGroupSettingsModule,
    socialSecuritySettingsModule,
    phoneTypeSettingsModule,
    addressSettingsModule,
    sectorSettingsModule,
    fornecedorAdministrativeModule,
    pagamentoRecebimentoFinancialModule,
    filesFinancialModule,
    contributionRangeSettingsModule,
    parameterFinancialSettingsModule,
    parameterAdministrativeSettingsModule,
    atendimentoModule,
    agendaSettingsModule,
    conselhoSettingsModule,
    convenioSettingsModule,
    especialidadeSettingsModule,
    exameSettingsModule,
    professionsAtendimentoSettingsModule,
    situacaoAtendimentoSettingsModule,
    tipoAtendimentoSettingsModule,
    unidadeAtendimentoSettingsModule,
    clientSettingsModule,
    diretoriaSettingsModule,
    parcelamentoFinananceiroModule,
    parameterAttendeceSettingsModule,
    reportModule,
    protocolTypeSettingsModule,
    mediumProtocolSettingsModule,
    protocolSettingsModule,
    itemProtocolSettingsModule,
    itemSettingsModule,
    doencasCidSettingsModule,
    agendaModule,
    agendamentoModule,
    feriadoSettingsModule,
    camposAdicionaisSettingsModule,
    procedimentosSettingsModule,
    taxasSettingsModule,
    convenioModule,
    ordemServicoModule,
    statusOsModule,
    grupoSettingsModule,
    profileSettingAccessModule,
    atendimentoProfissionalModule
  },
  strict: process.env.DEV
})
