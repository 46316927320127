export default {
  LOAD_LIST_EXECUTIVE_POSITION(state, data) {
    state.listExecutivePositions = data
  },
  PREPARE_TO_EDIT_EXECUTIVE_POSITION(state, data) {
    state.executivePosition = data
  },
  RESET_EXECUTIVE_POSITION(state) {
    state.executivePosition = {
      nome: '',
      ativo: true,
    }
  },
}
