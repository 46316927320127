export default [
  {
    header: 'Modules',
    action: 'ROLE_ADMIM_CONSULTAR',
    resource: 'ACL'
  },
  {
    title: 'Administrative',
    icon: 'UserCheckIcon',
    action: 'ROLE_ADMIM_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Phisical Person',
        route: 'phisicalPesonPage',
        action: 'ROLE_ADMIM_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Juridic Person',
        route: 'juridicPersonPage',
        action: 'ROLE_ADMIM_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Relatórios',
        route: 'reportPageAdm',
        action: 'ROLE_ADMIM_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Convênio',
    icon: 'GridIcon',
    action: 'ROLE_CONV_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Convênios',
        route: 'convenio',
        action: 'ROLE_CONV_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Financial',
    icon: 'DollarSignIcon',
    action: 'ROLE_FINAN_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Contas a Pagar',
        route: 'pagamento',
        action: 'ROLE_FINAN_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Contas a Receber',
        route: 'recebimento',
        action: 'ROLE_FINAN_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Emprestimos',
        route: 'emprestimo',
        action: 'ROLE_FINAN_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Arquivos',
        route: 'importPageNeos',
        action: 'ROLE_FINAN_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Relatórios',
        route: 'reportPageFinancial',
        action: 'ROLE_RELATORIO_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Atendimento',
    icon: 'ClockIcon',
    action: 'ROLE_ATEND_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Atendimentos',
        route: 'Attendance',
        action: 'ROLE_ATEND_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Agendamentos',
        route: 'agendamento',
        action: 'ROLE_ATEND_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Relatórios',
        route: 'reportPageAttendance',
        action: 'ROLE_ATEND_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  {
    title: 'Atendimentos',
    icon: 'ClockIcon',
    action: 'ROLE_PROFISSIONAL',
    resource: 'ACL',
    route: 'AttendanceProfissional'
  },
  {
    title: 'Ordem de Serviço',
    icon: 'FileTextIcon',
    action: 'ROLE_ORD_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Ordem de Serviços',
        route: 'ordemServico',
        action: 'ROLE_ORD_CONSULTAR',
        resource: 'ACL'
      }
    ]
  },
  // {
  //   title: 'Eventos',
  //   icon: 'CalendarIcon',
  //   action: 'read',
  //   resource: 'ACL',
  //   children: [
  //     {
  //       title: 'Eventos',
  //       route: 'Eventos',
  //       action: 'read',
  //       resource: 'ACL',
  //     },
  //   ],
  // },
  {
    title: 'Protocolo',
    icon: 'FileIcon',
    action: 'ROLE_PROT_CONSULTAR',
    resource: 'ACL',
    children: [
      {
        title: 'Protocolos',
        route: 'protocol',
        action: 'ROLE_PROT_CONSULTAR',
        resource: 'ACL'
      },
      {
        title: 'Relatórios',
        route: 'reportPageProtocol',
        action: 'ROLE_PROT_CONSULTAR',
        resource: 'ACL'
      }
    ]
  }
  // {
  //   title: 'Relatórios',
  //   icon: 'SendIcon',
  //   action: 'read',
  //   resource: 'ACL',
  //   children: [
  //     {
  //       title: 'Relatorios',
  //       route: 'reportPage',
  //       action: 'read',
  //       esource: 'ACL',
  //     },
  //   ],
  // },
]
