import doencaCidServices from '@/api/attendance/doencaCidServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => doencaCidServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_DOENCAS', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNew({ commit }, data) {
    return doencaCidServices.insertNew(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT_DOENCA', data)
  },
  edit({ commit }, data) {
    return doencaCidServices.edit(data).then(result => result).catch(error => error)
  },
  reset({ commit }) {
    commit('RESET_DOENCA')
  },
  delete({ commit }, id) {
    return doencaCidServices.delete(id).then(result => result).catch(error => error)
  },
}
