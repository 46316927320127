export default {
  listContributionRange: [],
  contributionRange: {
    descricao: '',
    ano: '',
    valor: '',
    vencimento: '',
    recorrencia: '',
    juros: '',
    multa: '',
    ativo: true,
  },
}
