export default {
  LOAD_LIST(state, data) {
    state.listaGrupos = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.grupo = data
  },
  RESET(state) {
    state.grupo = {
      id: null,
      nome: '',
      ativo: true
    }
  }
}
