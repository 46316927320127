<template>
  <div>
    <b-row>
      <b-col cols="12">
        <!-- <CardVue :cards="cards" /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import CardVue from '@/layouts/components/Card.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CardVue,
  },
  data() {
    return {
      verificarPermissao,
      cards: [
        {
          title: 'Card 1',
          tipo: [
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: '120',
              subtitle: 'Associado Ativos',
              customClass: 'mb-2 mb-xl-0',

            },
          ],
        },
        {
          title: 'Card contas',
          tipo: [
            {
              icon: 'TrendingUpIcon',
              color: 'light-primary',
              title: '120',
              subtitle: 'depedentes',
              customClass: 'mb-2 mb-xl-0',
            },
          ],
        },
        {
          title: 'Card 3',
          tipo: [
            {
              icon: 'TrendingUpIcon',
              color: 'light-primary',
              title: '12',
              subtitle: 'inativos',
              customClass: 'mb-2 mb-xl-0',
            },
          ],
        },
      ],
    }
  },
}
</script>
