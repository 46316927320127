import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser
} from '@/auth/utils'
import dashboard from './routes/dashboard'
import dashboardProfissional from './routes/prossissionalAtendimento'
import settings from './routes/settings'
import modules from './routes/modules'
import pages from './routes/pages'

Vue.use(VueRouter)

let abortController = new AbortController() // Define o AbortController

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: (to) => {
        return { name: 'dashboard-analytics' }
      }
    },
    ...dashboard,
    ...dashboardProfissional,
    ...pages,
    ...settings,
    ...modules,
    {
      path: '*',
      redirect: 'error-404'
    }
  ]
})

router.beforeEach((to, _, next) => {
  // Cancela as requisições anteriores quando a rota muda
  if (abortController) {
    abortController.abort() // Aborta todas as requisições pendentes
  }

  // Cria um novo AbortController para a próxima rota
  abortController = new AbortController()

  // Atualiza a chave meta
  to.meta.key = Date.now()
  const isLoggedIn = isUserLoggedIn()
  if (
    !isLoggedIn &&
    to.name !== 'auth-login' &&
    to.name !== 'auth-forgot-password' &&
    to.name.indexOf('reset-password') > -1
  ) {
    return next()
  }

  if (
    !isLoggedIn &&
    to.name !== 'auth-login' &&
    to.name === 'auth-forgot-password'
  ) {
    return next()
  }

  if (!isLoggedIn && to.name !== 'auth-login') {
    return next({ name: 'auth-login' })
  }
  if (
    !canNavigate(to) &&
    to.name !== 'misc-not-authorized' &&
    to.name !== 'auth-login'
  ) {
    if (!isLoggedIn && to.name !== 'auth-login') {
      return next({ name: 'auth-login' })
    }
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.ability : null))
  }
  next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
