import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/fone_tipos${queryUrl}`)
  },
  insertNewPhoneType(data) {
    return axios.post('/core/fone_tipos', data)
  },
  editPhoneType(data) {
    return axios.put(`/core/fone_tipos/${data.id}`, data)
  },
  deletePhoneType(id) {
    return axios.delete(`/core/fone_tipos/${id}`)
  },
}
