<template>
  <validation-observer ref="form">
    <b-form @submit.prevent>
      <fieldset :disabled="disableForm">
        <b-row>
          <!-- cpf -->
          <b-col cols="12" lg="4">
            <validation-provider
              #default="{ errors }"
              name="cpf"
              rules="required|regex:^[\d]{3}.[\d]{3}.[\d]{3}-[\d]{2}$"
            >
              <b-form-group label="CPF" label-for="v-cpf">
                <cleave
                  id="v-cpf"
                  v-model="documentationInforPersonal.cpf"
                  placeholder="cpf"
                  class="form-control"
                  :class="[errors.length > 0 ? 'is-invalid' : '']"
                  :raw="false"
                  :options="options.maskCpf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- rg -->
          <b-col cols="12" lg="4">
            <b-form-group label="RG" label-for="v-rg">
              <b-form-input
                id="v-rg"
                v-model="documentationInforPersonal.rg"
                placeholder="rg"
              />
            </b-form-group>
          </b-col>

          <!-- orgaoEmissor -->
          <b-col cols="12" lg="4">
            <b-form-group label="Orgão Emissor" label-for="v-orgaoEmissor">
              <b-form-input
                id="v-orgaoEmissor"
                v-model="documentationInforPersonal.orgaoEmissor"
                placeholder="orgaoEmissor"
              />
            </b-form-group>
          </b-col>

          <!-- dataExpedicao -->
          <b-col cols="12" lg="4">
            <b-form-group label="Data Expedição" label-for="v-dataExpedicao">
              <flat-pickr
                v-model="documentationInforPersonal.dataExpedicao"
                name="fieldDate"
                class="form-control"
                :readonly="disableForm"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
              />
            </b-form-group>
          </b-col>

          <!-- tituloEleitor -->
          <b-col cols="12" lg="4">
            <b-form-group label="Titulo Eleitor" label-for="v-tituloEleitor">
              <b-form-input
                id="v-tituloEleitor"
                v-model="documentationInforPersonal.tituloEleitor"
                placeholder="tituloEleitor"
              />
            </b-form-group>
          </b-col>

          <!-- secaoEleitoral -->
          <b-col cols="12" lg="4">
            <b-form-group label="Seção Eleitoral" label-for="v-secaoEleitoral">
              <b-form-input
                id="v-secaoEleitoral"
                v-model="documentationInforPersonal.secaoEleitoral"
                placeholder="secaoEleitoral"
              />
            </b-form-group>
          </b-col>

          <!-- ufEleitoral -->
          <b-col cols="12" lg="6">
            <b-form-group label="Uf Eleitoral" label-for="v-ufEleitoral">
              <v-select
                v-model="documentationInforPersonal.ufEleitoral"
                :disabled="disableForm"
                label="nome"
                :options="comboUfs"
              />
            </b-form-group>
          </b-col>

          <!-- reservista -->
          <b-col cols="12" lg="6">
            <b-form-group label="Reservista" label-for="v-reservista">
              <b-form-input
                id="v-reservista"
                v-model="documentationInforPersonal.reservista"
                placeholder="reservista"
              />
            </b-form-group>
          </b-col>

          <!-- numeroInss -->
          <b-col cols="12" lg="6">
            <b-form-group label="Número Inss" label-for="v-numeroInss">
              <b-form-input
                id="v-numeroInss"
                v-model="documentationInforPersonal.numeroInss"
                placeholder="numeroInss"
              />
            </b-form-group>
          </b-col>

          <!-- numeroSus -->
          <b-col cols="12" lg="6">
            <b-form-group label="Número SUS" label-for="v-numeroSus">
              <b-form-input
                id="v-numeroSus"
                v-model="documentationInforPersonal.numeroSus"
                placeholder="numero SUS"
              />
            </b-form-group>
          </b-col>

          <!-- previdenciaSocial -->
          <b-col cols="12" lg="12">
            <b-form-group
              label="Previdencia Sócial"
              label-for="v-previdenciaSocial"
            >
              <v-select
                v-model="documentationInforPersonal.previdenciaSocial"
                :disabled="disableForm"
                label="label"
                :options="comboPrevidenciaSocial"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </fieldset>
      <b-row>
        <!-- submit and reset -->
        <b-col v-if="disableForm" cols="12">
          <b-button
            v-if="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="edit"
          >
            Editar
          </b-button>
        </b-col>
        <b-col v-else cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="save"
          >
            Salvar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="cancel"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('associantesAdministrativeModule', {
      fullAssociante: (state) => state.fullAssociante,
      comboUfs: (state) => state.comboUfs,
      comboPrevidenciaSocial: (state) => state.comboPrevidenciaSocial
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    flatPickr,
    Cleave,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  watch: {
    disableForm() {
      this.aplicarEstiloDisabilitadoEmCampoData()
    }
  },
  data() {
    return {
      required,
      disableForm: true,
      documentationInforPersonal: {
        cpf: '',
        rg: '',
        orgaoEmissor: '',
        dataExpedicao: '',
        tituloEleitor: '',
        zonaEleitoral: '',
        secaoEleitoral: '',
        reservista: '',
        numeroInss: '',
        ufEleitoral: '',
        previdenciaSocial: ''
      },
      options: {
        maskCpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        }
      }
    }
  },
  mounted() {
    this.loadDocumetation(this.fullAssociante)
    this.aplicarEstiloDisabilitadoEmCampoData()
  },
  methods: {
    loadDocumetation(data) {
      this.documentationInforPersonal.id = data.id
      this.documentationInforPersonal.cpf = data.cpf
      this.documentationInforPersonal.rg = data.rg
      this.documentationInforPersonal.orgaoEmissor = data.orgaoEmissor
      this.documentationInforPersonal.numeroSus = data.numeroSus
      this.documentationInforPersonal.dataExpedicao = data.dataExpedicao
      this.documentationInforPersonal.tituloEleitor = data.tituloEleitor
      this.documentationInforPersonal.zonaEleitoral = data.zonaEleitoral
      this.documentationInforPersonal.secaoEleitoral = data.secaoEleitoral
      this.documentationInforPersonal.reservista = data.reservista
      this.documentationInforPersonal.numeroInss = data.numeroInss
      this.documentationInforPersonal.ufEleitoral = data.ufEleitoral
      this.documentationInforPersonal.previdenciaSocial = {
        id: data.previdenciaSocial.id,
        nome: data.previdenciaSocial.nome,
        label: data.previdenciaSocial.nome
          ? `${data.previdenciaSocial.codigo} - ${data.previdenciaSocial.nome}`
          : ''
      }
    },
    edit() {
      this.disableForm = false
    },
    cancel() {
      this.disableForm = true
      this.loadDocumetation(this.fullAssociante)
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const data = JSON.parse(
            JSON.stringify(this.documentationInforPersonal)
          )
          data.ufEleitoral = data.ufEleitoral ? data.ufEleitoral.id : null
          data.previdenciaSocial = data.previdenciaSocial
            ? data.previdenciaSocial.id
            : null
          this.$store
            .dispatch(
              'associantesAdministrativeModule/editAssocianteDocumentation',
              data
            )
            .then((response) => {
              if (response.response) {
                this.MensagemError(
                  'Falha ao Editar',
                  response.response.data.errors[0].message
                )
                return
              }
              this.$store
                .dispatch(
                  'associantesAdministrativeModule/prepareToEditFullAssociante',
                  response.data
                )
                .then(() => {
                  this.showMessageSuccess('Editar', 'Editado com sucesso!')
                  this.$refs.form.reset()
                  this.cancel()
                  this.loadDocumetation(response.data)
                })
            })
        }
      })
    }
  }
}
</script>
