import agendaService from '@/api/attendance/agendaServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => agendaService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_AGENDA', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insert({ commit }, data) {
    return agendaService.insert(data).then(result => result).catch(error => error)
  },
  edit({ commit }, data) {
    return Service.edit(data).then(result => result).catch(error => error)
  },
  delete({ commit }, id) {
    return Service.delete(id).then(result => result).catch(error => error)
  },
}
