<template>
  <div>
    <div>
      <vue-good-table
        :columns="columns"
        :rows="listaAssociado"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="(props.column.field === 'principal') || (props.column.field === 'whatsapp')">
            <span>
              {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editVinculo(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Mostrando 1 a
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  BDropdown, BDropdownItem, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable,
  },
  computed: {
    ...mapState('dependentAdministrativeModule', {
      listaAssociado: state => state.listaAssociado,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  data() {
    return {
      TIPO_FUNCIONARIO: 34,
      showForm: false,
      columns: [
        {
          label: 'Matricula',
          field: 'matricula',
        },
        {
          label: 'Nome',
          field: 'nome',
        },
        {
          label: 'Tipo Vinculo',
          field: 'tipoRegistro.nome',
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro.nome',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      pageLength: 10,
      dir: false,
      dependentObject: {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: null,
        associado: null,
        vinculo: true,
      },
    }
  },
  methods: {
    editVinculo(data) {
      if (data.tipoRegistro.id === this.TIPO_FUNCIONARIO) {
        this.$router.push({ path: `/modules/administrative/phisicalPerson/editEmployee/${data.id}` })
        return
      }
      this.$router.push({ path: `/modules/administrative/phisicalPerson/editAssociante/${data.id}` })
    },
    cancelar() {
      this.showForm = false
      this.$store.commit('dependentAdministrativeModule/RESET_DEPENDENT')
      this.dependentObject = {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: null,
        associado: null,
        vinculo: true,
      }
    },
  },
}
</script>
