export default {
  LOAD_LIST_GENDERS(state, data) {
    state.listGenders = data
  },
  PREPARE_TO_EDIT_GENDER(state, data) {
    state.gender = data
  },
  RESET_GENDER(state) {
    state.gender = {
      ativo: true,
      nome: '',
      sigla: '',
    }
  },
}
