export default {
  LOAD_LIST_ATENDIMENTO(state, data) {
    state.listaAtendimentos = data
  },
  PREPARE_TO_EDIT_ATENDIMENTO(state, data) {
    data.doencasCid = data.doencasCid.map((item) => {
      return { id: item.id, nome: item.cat + ' - ' + item.descricao }
    })
    state.atendimento = data
  },
  RESET_ATENDIMENTO(state) {
    state.atendimento = {
      numeroAtendimento: null,
      dataAtendimento: null,
      observacao: true,
      situacao: {
        id: null
      },
      dataCancelamento: null,
      profissional: {
        id: null
      },
      especialidade: {
        id: null
      },
      registro: {
        id: null
      },
      unidadeAtendimento: {
        id: null
      },
      agenda: {
        id: null
      },
      convenio: {
        id: null
      }
    }
  }
}
