<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="tipo de registro"
              rules="required"
            >
              <b-form-group label="Tipo de Registro" label-for="v-cbo">
                <v-select
                  id="RegisterType"
                  v-model="objectPlace.tipoRegistro"
                  :reduce="(comboType) => comboType.id"
                  label="nome"
                  :options="comboType"
                  :class="[errors.length > 0 ? isValid : '']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- CPF ASSOCIADO -->
          <b-col v-if="showInputCpfAssociado()" cols="12">
            <validation-provider
              #default="{ errors }"
              name="cpf Associado"
              rules=""
            >
              <b-form-group
                label="CPF do Associado"
                label-for="v-cpf-associado"
              >
                <cleave
                  id="v-cpf-associado"
                  v-model="cpfAssociadoPlace"
                  class="form-control"
                  :class="[errors.length > 0 ? isValid : '']"
                  :raw="false"
                  placeholder="cpf do associado"
                  :options="maskCpf"
                  :readonly="ehAssociadoHabilitado()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- CPF -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="ehPj ? 'cnpj' : 'cpf'"
              rules="required"
            >
              <b-form-group :label="ehPj ? 'CNPJ' : 'CPF'" label-for="v-cpf">
                <cleave
                  id="v-cpf"
                  v-model="objectPlace.cpf"
                  class="form-control"
                  :class="[errors.length > 0 ? isValid : '']"
                  :raw="false"
                  :placeholder="ehPj ? 'cmpj' : 'cpf'"
                  :options="ehPj ? maskCnpj : maskCpf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-group label="Nome" label-for="v-nome">
                <b-form-input
                  id="v-nome"
                  v-model="objectPlace.nome"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nome"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- email -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="email"
            >
              <b-form-group label="Email" label-for="v-email">
                <b-form-input
                  id="v-email"
                  v-model="objectPlace.email"
                  type="email"
                  placeholder="email"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              id="btnSave"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              id="btnCancel"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  name: 'FormInsert',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple
  },
  props: {
    titleForm: {
      type: String,
      default: 'FORMULARIO INSERT'
    },
    comboType: {
      type: Array,
      default: () => []
    },
    cpfAssociado: {
      type: String,
      default: ''
    },
    showCpfAssociado: {
      type: Boolean,
      default: false
    },
    ehAssociado: {
      type: Boolean,
      default: true
    },
    ehPj: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CONVIDADO_CODIGO: 49,
      required,
      isValid: 'is-invalid',
      cpfAssociadoPlace: '',
      objectPlace: {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: ''
      },
      maskCpf: {
        delimiters: ['.', '.', '-'],
        blocks: [3, 3, 3, 2],
        uppercase: true
      },
      maskCnpj: {
        delimiters: ['.', '.', '/', '-'],
        blocks: [2, 3, 3, 4, 2],
        uppercase: true
      }
    }
  },
  mounted() {
    this.cpfAssociadoPlace = this.cpfAssociado
  },
  methods: {
    ehAssociadoHabilitado() {
      if (this.objectPlace.tipoRegistro === this.CONVIDADO_CODIGO) {
        return false
      }
      return this.ehAssociado
    },
    showInputCpfAssociado() {
      this.cpfAssociadoPlace = ''
      delete this.objectPlace.associado
      if (
        this.showCpfAssociado ||
        this.objectPlace.tipoRegistro === this.CONVIDADO_CODIGO
      ) {
        return true
      }
      return false
    },
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
    },
    cleanObjeto() {
      this.objectPlace = {
        nome: '',
        cpf: '',
        email: '',
        tipoRegistro: ''
      }
    },
    save() {
      if (
        !this.ehAssociado ||
        this.objectPlace.tipoRegistro === this.CONVIDADO_CODIGO
      ) {
        this.objectPlace.associado = this.cpfAssociadoPlace
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$emit('save', this.objectPlace)
        }
      })
    }
  }
}
</script>
