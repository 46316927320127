<template>
  <div>
    <div v-if="disableForm">
      <div class="custom-search d-flex mb-2">
        <b-button
          class="justify-content-start"
          variant="primary"
          @click="inserirHistorico()"
        >
          Alterar Situação
        </b-button>
      </div>
      <div class="custom-search d-flex justify-content-end mb-2">
        <b-badge
          :variant="corSituacao()"
          style="font-size: 16px"
        >
          Situação Atual: {{ situacaoAtual }}
        </b-badge>
      </div>
      <b-row>
        <b-col md="12">
          <div class="justify-content-center mb-2">
            <h4>Histórico da Situação</h4>
          </div>
        </b-col>
      </b-row>
      <vue-good-table
        title="Teste"
        :columns="columns"
        :rows="listaHistorico"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      />
    </div>
    <div v-else>
      <validation-observer ref="form">
        <b-form @submit.prevent>
          <b-row>
            <!-- nome -->
            <b-col
              cols="12"
              lg="12"
            >
              <validation-provider
                #default="{ errors }"
                name="descricao"
                rules="required"
              >
                <b-form-group
                  label="Descrição"
                  label-for="v-descricao"
                >
                  <b-form-input
                    id="v-descricao"
                    v-model="situationPlace.descricao"
                    :state="errors.length > 0 ? false : null"
                    placeholder="descrição"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- situacaoRegistro -->
            <b-col
              ols="12"
              lg="12"
            >
              <b-form-group
                label="Situação"
                label-for="v-situacao"
              >
                <v-select
                  v-model="situationPlace.situacaoRegistro"
                  label="nome"
                  :options="comboSituacaoRegistro"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- submit and reset -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="save"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VueGoodTable,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('aggregatesAdministrativeModule', {
      fullAggregate: state => state.fullAggregate,
      comboSituacaoRegistro: state => state.comboSituacaoRegistro,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getHistoricoSituacao()
    this.situacaoAtual = this.fullAggregate.situacaoRegistro.nome
  },
  methods: {
    corSituacao() {
      if (this.situacaoAtual && this.situacaoAtual.toLowerCase() === 'ativo') {
        return 'primary'
      }

      if (
        this.situacaoAtual
        && this.situacaoAtual.toLowerCase() === 'bloqueio'
      ) {
        return 'danger'
      }

      if (
        this.situacaoAtual
        && this.situacaoAtual.toLowerCase() === 'cancelado'
      ) {
        return 'secondary'
      }

      if (
        this.situacaoAtual
        && this.situacaoAtual.toLowerCase() === 'falecido'
      ) {
        return 'dark'
      }
      return 'primary'
    },
    getHistoricoSituacao() {
      this.$store
        .dispatch(
          'aggregatesAdministrativeModule/getHistoricoSituacao',
          this.$route.params.id,
        )
        .then(response => {
          this.listaHistorico = response.data
        })
    },
    inserirHistorico() {
      this.situationPlace = {
        descricao: '',
        registro: {
          id: null,
        },
        situacaoRegistro: {
          id: null,
        },
      }
      this.situationPlace.registro.id = this.$route.params.id
      this.disableForm = false
    },
    cancel() {
      this.situationPlace = {
        descricao: '',
        registro: {
          id: null,
        },
        situacaoRegistro: {
          id: null,
        },
      }
      this.disableForm = true
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.$store
            .dispatch(
              'aggregatesAdministrativeModule/setHistoricoSituacao',
              this.situationPlace,
            )
            .then(response => {
              if (response.response) {
                this.MensagemError(
                  'Falha ao Editar',
                  response.response.data.error,
                )
                return
              }
              if (response.error) {
                this.MensagemError('Ação não Permitida', response.message)
                return
              }
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.situacaoAtual = response.data.situacaoRegistro.nome
              this.situationPlace.situacaoRegistro = response.data.situacaoRegistro
              this.getHistoricoSituacao()
              this.disableForm = true
            })
        }
      })
    },
    fieldFn(rowObj) {
      if (rowObj.dataCriacao) {
        let data = rowObj.dataCriacao.substring(0, 10)
        data = data.toLocaleString()
        return data
      }
      return rowObj.dataCriacao
    },
  },
  data() {
    return {
      situacaoAtual: null,
      listaHistorico: [],
      required,
      disableForm: true,
      situationPlace: {
        descricao: '',
        registro: {
          id: null,
        },
        situacaoRegistro: {
          id: null,
        },
      },
      columns: [
        {
          label: 'Descrição',
          field: 'descricao',
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro.nome',
        },
        {
          label: 'Data',
          field: this.fieldFn,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd/MM/yyyy',
        },
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc',
      },
      pageLength: 10,
      dir: false,
    }
  },
}
</script>
