<template>
  <div>
    <b-row>
      <b-col cols="12">
        <CardVue :cards="cards" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="2">
        <div v-for="(opcao, index) in opcoes" :key="index">
          <b-button
            v-if="verificarPermissao(opcao.action)"
            class="mb-1"
            block
            variant="gradient-primary"
            :to="opcao.route"
            exact
            @click="loadCard(opcao.route)"
          >
            {{ $t(opcao.title) }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="10">
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import CardVue from '@/layouts/components/Card.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CardVue
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Protocolos',
          route: { name: 'protocol' },
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL'
        }
      ],
      cards: []
    }
  },
  mounted() {
    //   this.$store.dispatch('associantesAdministrativeModule/loadCards').then(response => {
    //     response.data.forEach(element => {
    //       this.cards.push(element)
    //     })
    //   })
  },
  methods: {
    loadCard(route) {
      this.cards = [
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        }
      ]
      this.setCardsProtocolos(route.name)
    },
    setCardsProtocolos(name) {
      // if (name === 'protocol') {
      //   this.$store.dispatch('othersAdministrativeModule/loadCards').then(response => {
      //     this.cards = []
      //     response.data.forEach(element => {
      //       this.cards.push(element)
      //     })
      //   })
      // }
    }
  }
}
</script>
