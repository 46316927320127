import dependentService from '@/api/AdministrativeModule/dependentServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => dependentService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_DEPENDENTS', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewDependent({ commit }, data) {
    return dependentService.insertNewDependent(data).then(result => result).catch(error => error)
  },
  prepareToEditDependent({ commit }, data) {
    commit('PREPARE_TO_EDIT_DEPENDENT', data)
  },
  editDependent({ commit }, data) {
    return dependentService.editDependent(data).then(result => result).catch(error => error)
  },
  resetDependent({ commit }) {
    commit('RESET_DEPENDENT')
  },
  delete({ commit }, id) {
    return new Promise((resolve, reject) => dependentService.deleteDependent(id).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  getDependentID({ commit }, id) {
    return new Promise((resolve, reject) => dependentService.getDependentID(id).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  prepareToEditFullDependent({ commit }, data) {
    return new Promise(resolve => {
      commit('PREPARE_TO_EDIT_FULL_DEPENDENT', data)
      resolve(data)
    })
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadCombo().then(result => {
      commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboGenero().then(result => {
      commit('SET_LIST_COMBO_GENERO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboEstadoCivil().then(result => {
      commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboProfissoes().then(result => {
      commit('SET_LIST_COMBO_PROFISSOES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboNaturalidades().then(result => {
      commit('SET_LIST_COMBO_NATURALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboNacionalidades().then(result => {
      commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboUfs().then(result => {
      commit('SET_LIST_COMBO_UFS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboPrevidenciaSocial().then(result => {
      commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboTipoEndereco().then(result => {
      commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) => dependentService.loadComboSituacaoRegistro().then(result => {
      commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  editDependentDadosPessoais({ commit }, data) {
    return dependentService.editDependentDadosPessoais(data).then(result => result).catch(error => error)
  },
  editDependentDocumentation({ commit }, data) {
    return dependentService.editDependentDocumentation(data).then(result => result).catch(error => error)
  },
  getEnderecoViaCep({ commit }, data) {
    return dependentService.getEnderecoViaCep(data).then(result => result).catch(error => error)
  },
  inserirNovoEndereco({ commit }, data) {
    return dependentService.inserirNovoEndereco(data).then(result => result).catch(error => error)
  },
  editAssocianteEndereco({ commit }, data) {
    return dependentService.editEndereco(data).then(result => result).catch(error => error)
  },
  getAssocianteForCpf({ commit }, data) {
    return dependentService.getAssocianteForCpf(data).then(result => result).catch(error => error)
  },
  setAssociante({ commit }, data) {
    commit('SET_LISTA_ASSOCIADO', data)
  },
  setHistoricoSituacao({ commit }, data) {
    return dependentService.setHistoricoSituacao(data).then(result => result).catch(error => error)
  },
  getHistoricoSituacao({ commit }, id) {
    return dependentService.getHistoricoSituacao(id).then(result => result).catch(error => error)
  },
  loadEnderecosPorRegistro({ commit }, id) {
    return new Promise((resolve, reject) => dependentService.loadEnderecosPorRegistro(id).then(result => {
      const ordenandoProPrincipal = result.data.sort((a, b) => {
        if (a.principal < b.principal) return 1
        if (a.principal > b.principal) return -1
        return 0
      })
      commit('SET_LISTA_ENDERECOS', ordenandoProPrincipal)
      resolve(result)
    }).catch(err => reject(err)))
  },
  deleteEndereco({ commit }, id) {
    return dependentService.deleteEndereco(id).then(result => result).catch(error => error)
  },
  prepareToEditEndereco({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECO', data)
  },
  sendNewPhoto({ commit }, object) {
    return dependentService.sendNewPhoto(object).then(result => result).catch(error => error)
  },
  deletePhoto({ commit }, id) {
    return dependentService.deletePhoto(id).then(result => result).catch(error => error)
  },
  downloadDocument({ commit }, id) {
    return dependentService.downloadDocument(id).then(result => result).catch(error => error)
  },
  deleteDocument({ commit }, id) {
    return dependentService.deleteDocument(id).then(result => result).catch(error => error)
  },
  usarEnderecoAssociado({ commit }, id) {
    return dependentService.usarEnderecoAssociado(id).then(result => {
      commit('SET_USAR_ENDERECO_ASSOCIADO', true)
      return result
    }).catch(error => error)
  },
  getHistoricoTipoRegistro({ commit }, id) {
    return dependentService.getHistoricoTipoRegistro(id).then(result => result).catch(error => error)
  },
  setMudarTipoRegistro({ commit }, id) {
    return dependentService.setMudarTipoRegistro(id).then(result => result).catch(error => error)
  },
  migrarParaSocio({ commit }, data) {
    return dependentService.migrarParaSocio(data).then(result => result).catch(error => error)
  },
  loadCards({ commit }) {
    return dependentService.loadCards().then(result => result).catch(error => error)
  },
  getHistoricoFaixaContribuicao({ commit }, id) {
    return dependentService.getHistoricoFaixaContribuicao(id).then(result => result).catch(error => error)
  },
  setMudarFaixaContribuica({ commit }, data) {
    return dependentService.setMudarFaixaContribuica(data).then(result => result).catch(error => error)
  },
}
