<template>
  <div
    v-if="loading"
    class="clearfix"
  >
    <b-spinner
      class="float-right"
      label="Floated Right"
    />
  </div>
  <div v-else>
    <b-row>
      <b-col
        lg="12"
        md="12"
      >
        <app-collapse
          type="margin"
        >

          <app-collapse-item title="Informações Pessoais">
            <FormAggregateInfoVue />
          </app-collapse-item>

          <app-collapse-item title="Tipo de Registro">
            <FormPersonalRegistryTypeVue />
          </app-collapse-item>

          <app-collapse-item title="Situação">
            <FormAggregateSituationVue />
          </app-collapse-item>

          <app-collapse-item title="Faixa de Contribuição">
            <FormAggregateFaixaContribuicao />
          </app-collapse-item>

          <app-collapse-item title="Documentações">
            <FormAggregateDocumentsVue />
          </app-collapse-item>

          <app-collapse-item title="Endereços">
            <FormAggregateEnderecosVue />
          </app-collapse-item>

          <app-collapse-item title="Fones">
            <FormAggregateFoneVue />
          </app-collapse-item>

        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FormAggregateInfoVue from './FormAggregateInfo.vue'
import FormAggregateDocumentsVue from './FormAggregateDocuments.vue'
import FormAggregateSituationVue from './FormAggregateSituation.vue'
import FormAggregateEnderecosVue from './FormAggregateEnderecos.vue'
import FormAggregateFoneVue from './FormAggregateFone.vue'
import FormPersonalRegistryTypeVue from './FormAggregateTipoRegistro.vue'
import FormAggregateFaixaContribuicao from './FormAggregateFaixaContribuicao.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    FormAggregateInfoVue,
    FormAggregateDocumentsVue,
    FormAggregateSituationVue,
    FormAggregateEnderecosVue,
    FormAggregateFoneVue,
    FormPersonalRegistryTypeVue,
    FormAggregateFaixaContribuicao,
    BRow,
    BCol,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapseType: 'default',
    }
  },
}
</script>
