import registrationTypeServices from '@/api/administrative/registrationTypeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return registrationTypeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_REGISTRATION_TYPE', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewRegistrationType({ commit }, data) {
    return registrationTypeServices.insertNewRegistrationType(data).then(result => result).catch(error => error)
  },
  prepareToEditRegistrationType({ commit }, data) {
    commit('PREPARE_TO_EDIT_REGISTRATION_TYPE', data)
  },
  editRegistrationType({ commit }, data) {
    return registrationTypeServices.editRegistrationType(data).then(result => result).catch(error => error)
  },
  resetRegistrationType({ commit }) {
    commit('RESET_REGISTRATION_TYPE')
  },
  loadComboType({ commit }) {
    return new Promise((resolve, reject) => registrationTypeServices.loadCombo().then(result => {
      commit('SET_LIST_COMBO_TYPE', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  delete({ commit }, id) {
    return registrationTypeServices.deleteRegistrationType(id).then(result => result).catch(error => error)
  },
}
