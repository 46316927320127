var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrição","label-for":"v-descricao"}},[_c('b-form-input',{attrs:{"id":"v-descricao","state":errors.length > 0 ? false : null,"placeholder":"Descrição","data-cy":"description","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.descricao),callback:function ($$v) {_vm.$set(_vm.form, "descricao", $$v)},expression:"form.descricao"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-description"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Número da Conta Bancaria","label-for":"v-conta"}},[_c('b-form-input',{attrs:{"id":"v-conta","placeholder":"Conta","data-cy":"conta","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.conta),callback:function ($$v) {_vm.$set(_vm.form, "conta", $$v)},expression:"form.conta"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Agência","label-for":"v-agencia"}},[_c('b-form-input',{attrs:{"id":"v-agencia","placeholder":"Agência","data-cy":"agencia","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.agencia),callback:function ($$v) {_vm.$set(_vm.form, "agencia", $$v)},expression:"form.agencia"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"tipo de conta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de Conta","label-for":"v-tipoConta"}},[_c('v-select',{attrs:{"reduce":function (tipoContaCombo) { return tipoContaCombo.id; },"label":"nome","options":_vm.tipoContaCombo,"state":errors.length > 0 ? false : null,"data-cy":"tipoConta","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.tipoConta.id),callback:function ($$v) {_vm.$set(_vm.form.tipoConta, "id", $$v)},expression:"form.tipoConta.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-tipoConta"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"name":"plano de contas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Plano de Contas","label-for":"v-panoConta"}},[_c('v-select',{attrs:{"reduce":function (planoDeContaCombo) { return planoDeContaCombo.id; },"label":"nome","options":_vm.planoDeContaCombo,"state":errors.length > 0 ? false : null,"data-cy":"planoConta","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.planoDeConta.id),callback:function ($$v) {_vm.$set(_vm.form.planoDeConta, "id", $$v)},expression:"form.planoDeConta.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-planoConta"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"banco"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Banco","label-for":"v-bank"}},[_c('v-select',{attrs:{"reduce":function (comboBanks) { return comboBanks.id; },"label":"nome","options":_vm.comboBanks,"state":errors.length > 0 ? false : null,"data-cy":"bancos","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.banco.id),callback:function ($$v) {_vm.$set(_vm.form.banco, "id", $$v)},expression:"form.banco.id"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-bancos"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Data saldo inicial","label-for":"v-dataSaldoInicial"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                altInput: true,
                altFormat: 'd/m/Y',
                enableTime: false,
                dateFormat: 'Y-m-d\\TH:i:ss\\Z'
              },"data-cy":"dataSaldoInicial","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.dataSaldoInicial),callback:function ($$v) {_vm.$set(_vm.form, "dataSaldoInicial", $$v)},expression:"form.dataSaldoInicial"}})],1)],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-form-group',{attrs:{"label":"Saldo inicial","label-for":"v-valorSaldoInicial"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"v-valorSaldoInicial","raw":true,"options":_vm.options.number,"placeholder":"saldo incial","data-cy":"saldoInicial","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!_vm.form.id) ||
                (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.valorSaldoInicial),callback:function ($$v) {_vm.$set(_vm.form, "valorSaldoInicial", _vm._n($$v))},expression:"form.valorSaldoInicial"}})],1)],1),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }