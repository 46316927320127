import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/core/previdencias_sociais${queryUrl}`)
  },
  insertNewSocialSecurity(data) {
    return axios.post('/core/previdencias_sociais', data)
  },
  editSocialSecurity(data) {
    return axios.put(`/core/previdencias_sociais/${data.id}`, data)
  },
  deleteSocialSecurity(id) {
    return axios.delete(`/core/previdencias_sociais/${id}`)
  },
  loadComboSocialSecurityGroup() {
    return axios.get('/core/grupos_previdencias/lista')
  },
}
