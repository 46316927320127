<template>
  <b-row>
    <b-col cols="12" sm="12" md="12" lg="2">
      <div v-for="(opcao, index) in opcoes" :key="index">
        <b-button
          v-if="verificarPermissao(opcao.action)"
          class="mb-1"
          block
          variant="gradient-primary"
          :to="opcao.route"
          exact
          :data-cy="opcao.route.name"
        >
          {{ $t(opcao.title) }}
        </b-button>
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10">
      <router-view />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Tipos de Protocolo',
          route: { name: 'protocolTypeSettings' },
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Meios de Protocolo',
          route: { name: 'mediumProtocolSettings' },
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Items',
          route: { name: 'itemSettings' },
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL'
        }
      ],
      permissionUser: undefined
    }
  }
}
</script>

mensagem mudar botão
