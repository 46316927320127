import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  data() {
    return {
      verificarPermissao
    }
  },
  methods: {
    showMessageSuccess(title, msg) {
      const timeout = 8000
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title,
            icon: 'CheckIcon',
            variant: 'success',
            text: msg
          }
        },
        {
          timeout
        }
      )
    },
    MensagemError(title, msg) {
      const timeout = 8000
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title,
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: msg
          }
        },
        {
          timeout
        }
      )
    },
    MensagemInfo(title, msg) {
      const timeout = 8000
      this.$toast(
        {
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title,
            icon: 'InfoIcon',
            variant: 'info',
            text: msg
          }
        },
        {
          timeout
        }
      )
    },
    aplicarEstiloDisabilitadoEmCampoData() {
      const dateName = document.getElementsByName('fieldDate')
      if (dateName.length > 0) {
        dateName.forEach((element) => {
          if (element.nextElementSibling) {
            element.nextElementSibling.style.backgroundColor = ''
            if (this.disableForm) {
              element.nextElementSibling.style.backgroundColor = '#efefef'
            }
          }
        })
      }
    },
    formatCurrencyBrazil(value) {
      const formCurrency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      return formCurrency.format(value)
    },
    formatDateGrid(value) {
      if (value) {
        const locale = 'pt-br'
        const myArray = value.split('T')
        const data = new Date(`${myArray[0]} 00:00:01`)
        return data.toLocaleDateString(locale)
      }
      return value
    },
    formatColumBoolean(value) {
      return value ? 'Sim' : 'Não'
    },
    openNewAbaBase64(strBase64) {
      const base64ImageData = `data:application/pdf;base64,${strBase64}`
      const contentType = 'application/pdf'
      const byteCharacters = atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
      )
      const byteArrays = []
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024)
        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      const blobUrl = URL.createObjectURL(blob)
      window.open(blobUrl, '_blank')
    },
    preparaDataParaSalvarEdicao(data) {
      if (!data) {
        return ''
      }
      const dataRecebida = new Date(`${data}T00:00:00`)
      const dia = dataRecebida.getDate()
      const mes = dataRecebida.getMonth() + 1 // Os meses começam em zero, então adicionamos 1
      const ano = dataRecebida.getFullYear()
      // Formatando a data no formato desejado: DD-MM-YYYY
      const dataFormatada = `${dia < 10 ? `0${dia}` : dia}-${
        mes < 10 ? `0${mes}` : mes
      }-${ano}`
      return dataFormatada
    },
    dataParaFormulario(stringData) {
      if (!stringData) {
        return ''
      }
      const partes = stringData.split('-')
      const data = new Date(partes[2], partes[1] - 1, partes[0])
      return data
    }
  }
}
