<template>
  <div>
    <div v-if="loading" class="clearfix">
      <b-spinner class="float-right" label="Floated Right" />
    </div>
    <b-col v-else cols="12">
      <b-tabs>
        <b-tab data-cy="abaCliente">
          <template #title>
            <feather-icon icon="UserIcon" />
            <span data-cy="abaDadosCliente">Dados do CLiente</span>
          </template>
          <b-card title="Configurações de Cliente">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="4">
                <b-card title="Logo Marca">
                  <div class="profile-image-wrapper">
                    <div
                      class="profile-image p-0"
                      style="padding-top: 15px; margin-top: 15px"
                    >
                      <b-img
                        sizes="150"
                        center
                        :src="form.logo"
                        fluid
                        alt="Center image"
                      />
                    </div>
                    <div class="demo-inline-spacing">
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_ATUALIZAR')"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mt-1"
                        data-cy="uploadLogo"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          id="fotoInput"
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @input="updateLogo"
                        />
                        <feather-icon icon="EditIcon" /> Editar
                      </b-button>
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_DELETAR')"
                        variant="warning outline-secondary"
                        class="mt-1"
                        @click="deleteLogo"
                      >
                        <feather-icon icon="TrashIcon" /> Remover
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="4">
                <b-card title="Rodapé">
                  <div class="profile-image-wrapper">
                    <div
                      class="profile-image p-0"
                      style="padding-top: 15px; margin-top: 15px"
                    >
                      <b-img
                        sizes="150"
                        center
                        :src="form.rodape"
                        fluid
                        alt="Center image"
                      />
                    </div>
                    <div class="demo-inline-spacing">
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_ATUALIZAR')"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mt-1"
                        data-cy="uploadLogo"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          id="fotoInput"
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @input="updateRodape"
                        />
                        <feather-icon icon="EditIcon" /> Editar
                      </b-button>
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_DELETAR')"
                        variant="warning outline-secondary"
                        class="mt-1"
                        @click="deleteRodape"
                      >
                        <feather-icon icon="TrashIcon" /> Remover
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="4">
                <b-card title="Logo Marca">
                  <div class="profile-image-wrapper">
                    <div
                      class="profile-image p-0"
                      style="padding-top: 15px; margin-top: 15px"
                    >
                      <b-img
                        sizes="150"
                        center
                        :src="form.marcaDagua"
                        fluid
                        alt="Center image"
                      />
                    </div>
                    <div class="demo-inline-spacing">
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_ATUALIZAR')"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mt-1"
                        data-cy="uploadLogo"
                        @click="$refs.refInputEl.click()"
                      >
                        <input
                          id="fotoInput"
                          ref="refInputEl"
                          type="file"
                          class="d-none"
                          @input="updateMarcaDagua"
                        />
                        <feather-icon icon="EditIcon" /> Editar
                      </b-button>
                      <b-button
                        v-if="verificarPermissao('ROLE_CORE_DELETAR')"
                        variant="warning outline-secondary"
                        class="mt-1"
                        @click="deleteMarcaDagua"
                      >
                        <feather-icon icon="TrashIcon" /> Remover
                      </b-button>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
            <validation-observer ref="form">
              <b-form @submit.prevent>
                <b-row>
                  <!-- first cnpj -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="cnpj"
                      rules="required|regex:^[\d]{2}.[\d]{3}.[\d]{3}/[\d]{4}-[\d]{2}$"
                    >
                      <b-form-group
                        data-cy="labelCnpj"
                        label="CNPJ"
                        label-for="v-cnpj"
                      >
                        <cleave
                          id="v-cnpj"
                          v-model="form.cnpj"
                          placeholder="cnpj"
                          class="form-control"
                          :class="[errors.length > 0 ? 'is-invalid' : '']"
                          :raw="false"
                          :options="options.maskCnpj"
                          data-cy="cnpj"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first cnae -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="cnae"
                      rules=""
                    >
                      <b-form-group
                        data-cy="labelCnae"
                        label="CNAE"
                        label-for="v-cnae"
                      >
                        <b-form-input
                          id="v-cnae"
                          v-model="form.cnae"
                          :state="errors.length > 0 ? false : null"
                          placeholder="cnae"
                          data-cy="cnae"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first inscricaoEstadual -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="inscricaoEstadual"
                      rules=""
                    >
                      <b-form-group
                        label="Inscrição Estadual"
                        label-for="v-inscricaoEstadual"
                        data-cy="labelInscricaoEstadual"
                      >
                        <b-form-input
                          id="v-inscricaoEstadual"
                          v-model="form.inscricaoEstadual"
                          :state="errors.length > 0 ? false : null"
                          placeholder="inscricaoEstadual"
                          data-cy="inscricaoEstadual"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first inscricaoMunicipal -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="inscricaoMunicipal"
                      rules=""
                    >
                      <b-form-group
                        label="Inscrição Municipal"
                        label-for="v-inscricaoMunicipal"
                        data-cy="labelInscricaoMunicipal"
                      >
                        <b-form-input
                          id="v-inscricaoMunicipal"
                          v-model="form.inscricaoMunicipal"
                          :state="errors.length > 0 ? false : null"
                          placeholder="inscricaoMunicipal"
                          data-cy="inscricaoMunicipal"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first name -->
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="nome"
                      rules=""
                    >
                      <b-form-group
                        data-cy="labelNome"
                        label="Nome"
                        label-for="v-nome"
                      >
                        <b-form-input
                          id="v-nome"
                          v-model="form.nome"
                          :state="errors.length > 0 ? false : null"
                          placeholder="nome"
                          data-cy="nome"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first fantasia -->
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="nomeFantasia"
                      rules=""
                    >
                      <b-form-group
                        label="Nome Fantasia"
                        label-for="v-nomeFantasia"
                        data-cy="labelNomeFantasia"
                      >
                        <b-form-input
                          id="v-nomeFantasia"
                          v-model="form.fantasia"
                          :state="errors.length > 0 ? false : null"
                          placeholder="nome fantasia"
                          data-cy="nomeFantasia"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first email -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules=""
                    >
                      <b-form-group
                        data-cy="labelEmail"
                        label="Email"
                        label-for="v-email"
                      >
                        <b-form-input
                          id="v-email"
                          v-model="form.email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="email"
                          data-cy="email"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Data dataAdesao -->
                  <b-col cols="12" lg="6">
                    <b-form-group
                      data-cy="labelDataAdesao"
                      label="Data Adesão"
                      label-for="v-dataAdesao"
                    >
                      <flat-pickr
                        v-model="form.dataAdesao"
                        name="fieldDate"
                        class="form-control"
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                        data-cy="dataAdesao"
                        :disabled="
                          !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                          !verificarPermissao('ROLE_CORE_INSERIR')
                        "
                      />
                    </b-form-group>
                  </b-col>

                  <!-- first capitalSocial -->
                  <b-col cols="12" lg="6">
                    <b-form-group
                      data-cy="labelCapitalSocial"
                      label="Capital Social"
                      label-for="v-capitalSocial"
                    >
                      <cleave
                        id="v-capitalSocial"
                        v-model.number="form.capitalSocial"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="capitalSocial"
                        data-cy="capitalSocial"
                        :disabled="
                          !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                          !verificarPermissao('ROLE_CORE_INSERIR')
                        "
                      />
                    </b-form-group>
                  </b-col>

                  <!-- first numeroJuntaComercial -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="numeroJuntaComercial"
                      rules=""
                    >
                      <b-form-group
                        data-cy="labelNumeroJuntaComercial"
                        label="Número Junta Comércial"
                        label-for="v-numeroJuntaComercial"
                      >
                        <b-form-input
                          id="v-numeroJuntaComercial"
                          v-model="form.numeroJuntaComercial"
                          :state="errors.length > 0 ? false : null"
                          placeholder="numeroJuntaComercial"
                          data-cy="numeroJuntaComercial"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- first cobranca -->
                  <b-col cols="12" lg="6">
                    <validation-provider
                      #default="{ errors }"
                      name="cobranca"
                      rules=""
                    >
                      <b-form-group
                        data-cy="labelCobranca"
                        label="Cobrança"
                        label-for="v-cobranca"
                      >
                        <b-form-input
                          id="v-cobranca"
                          v-model="form.cobranca"
                          :state="errors.length > 0 ? false : null"
                          placeholder="cobranca"
                          data-cy="cobranca"
                          :disabled="
                            !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                            !verificarPermissao('ROLE_CORE_INSERIR')
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Data vencimentoContrato -->
                  <b-col cols="12" lg="6">
                    <b-form-group
                      disabled
                      data-cy="labelVencimentoContrato"
                      label="Data Vencimento Contrato"
                      label-for="v-vencimentoContrato"
                    >
                      <flat-pickr
                        :value="form.vencimentoContrato"
                        name="fieldDate"
                        class="form-control disabledData"
                        disabled
                        :config="{
                          altInput: true,
                          altFormat: 'd/m/Y',
                          enableTime: false,
                          dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                        }"
                        data-cy="vencimentoContrato"
                        :disabled="
                          !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                          !verificarPermissao('ROLE_CORE_INSERIR')
                        "
                      />
                    </b-form-group>
                  </b-col>
                  <!-- naturezaFiscal -->
                  <b-col ols="12" lg="6">
                    <b-form-group
                      data-cy="labelNaturezaFiscal"
                      label="Natureza Fiscal"
                      label-for="v-naturezaFiscal"
                    >
                      <v-select
                        v-model="form.naturezaFiscal"
                        label="nome"
                        :options="comboNaturezaFiscals"
                        data-cy="naturezaFiscal"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- first observacao -->
                  <b-col cols="12">
                    <label data-cy="labelObs" for="textarea-default"
                      >Observações</label
                    >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="form.observacao"
                      placeholder="Textarea"
                      rows="3"
                      data-cy="observacao"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                  </b-col>

                  <!-- submit and reset -->
                  <b-col cols="12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mt-1"
                      data-cy="save"
                      @click="handleEdit"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    >
                      Salvar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Endereço</span>
          </template>
          <b-alert variant="primary" :show="true">
            <h4 class="alert-heading">
              Para salvar os dados, volte para Dados do Cliente e clique em
              <strong>SALVAR!</strong>
            </h4>
          </b-alert>
          <b-card title="Endereço do Cliente">
            <b-row>
              <!-- first cep -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="cep"
                  rules="required|regex:^[\d]{2}.[\d]{3}-[\d]{3}$"
                >
                  <b-form-group label="CEP" label-for="cep">
                    <b-input-group>
                      <cleave
                        id="cep"
                        v-model="form.cep"
                        type="text"
                        placeholder="CEP"
                        class="form-control"
                        :raw="false"
                        :options="options.maskCep"
                        :disabled="
                          !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                          !verificarPermissao('ROLE_CORE_INSERIR')
                        "
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="loadEnderecoViaCep()"
                        >
                          Buscar
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!-- first logradouro -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="logradouro"
                  rules=""
                >
                  <b-form-group label="Logradouro" label-for="v-logradouro">
                    <b-form-input
                      id="v-logradouro"
                      v-model="form.logradouro"
                      :state="errors.length > 0 ? false : null"
                      placeholder="logradouro"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first numero -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="numero"
                  rules=""
                >
                  <b-form-group label="Número" label-for="v-numero">
                    <b-form-input
                      id="v-numero"
                      v-model="form.numero"
                      :state="errors.length > 0 ? false : null"
                      placeholder="numero"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first complemento -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="complemento"
                  rules=""
                >
                  <b-form-group label="Complemento" label-for="v-complemento">
                    <b-form-input
                      id="v-complemento"
                      v-model="form.complemento"
                      :state="errors.length > 0 ? false : null"
                      placeholder="complemento"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first bairro -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="bairro"
                  rules=""
                >
                  <b-form-group label="Bairro" label-for="v-bairro">
                    <b-form-input
                      id="v-bairro"
                      v-model="form.bairro"
                      :state="errors.length > 0 ? false : null"
                      placeholder="bairro"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first cidade -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="cidade"
                  rules=""
                >
                  <b-form-group label="Cidade" label-for="v-cidade">
                    <b-form-input
                      id="v-cidade"
                      v-model="form.cidade"
                      :state="errors.length > 0 ? false : null"
                      placeholder="cidade"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- uf -->
              <b-col ols="12" lg="6">
                <b-form-group label="UF" label-for="v-uf">
                  <v-select
                    v-model="form.uf"
                    label="nome"
                    :options="comboUfs"
                    :disabled="
                      !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                      !verificarPermissao('ROLE_CORE_INSERIR')
                    "
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="MailIcon" />
            <span>Configurações Email</span>
          </template>
          <b-alert variant="primary" :show="true">
            <h4 class="alert-heading">
              Para salvar os dados, volte para Dados do Cliente e clique em
              <strong>SALVAR!</strong>
            </h4>
          </b-alert>
          <b-card title="Configurações Email">
            <b-row>
              <!-- first servidorEmail -->
              <b-col cols="12" lg="12">
                <validation-provider
                  #default="{ errors }"
                  name="Servidor Email"
                  rules=""
                >
                  <b-form-group
                    label="Servidor Email"
                    label-for="v-servidorEmail"
                  >
                    <b-form-input
                      id="v-servidorEmail"
                      v-model="form.servidorEmail"
                      :state="errors.length > 0 ? false : null"
                      placeholder="servidorEmail"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first usuario -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="usuario"
                  rules=""
                >
                  <b-form-group label="Usuário" label-for="v-usuario">
                    <b-form-input
                      id="v-usuario"
                      v-model="form.usuario"
                      :state="errors.length > 0 ? false : null"
                      placeholder="usuario"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first senha -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="senha"
                  rules=""
                >
                  <b-form-group label="Senha" label-for="v-senha">
                    <b-form-input
                      id="v-senha"
                      v-model="form.senha"
                      type="password"
                      :state="errors.length > 0 ? false : null"
                      placeholder="senha"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first porta -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="porta"
                  rules=""
                >
                  <b-form-group label="porta" label-for="v-porta">
                    <b-form-input
                      id="v-porta"
                      v-model="form.porta"
                      :state="errors.length > 0 ? false : null"
                      placeholder="porta"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first remetente -->
              <b-col cols="12" lg="6">
                <validation-provider
                  #default="{ errors }"
                  name="remetente"
                  rules=""
                >
                  <b-form-group label="remetente" label-for="v-remetente">
                    <b-form-input
                      id="v-remetente"
                      v-model="form.remetente"
                      :state="errors.length > 0 ? false : null"
                      placeholder="remetente"
                      :disabled="
                        !verificarPermissao('ROLE_CORE_ATUALIZAR') ||
                        !verificarPermissao('ROLE_CORE_INSERIR')
                      "
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BTabs,
  BTab,
  BFormTextarea,
  BSpinner,
  BAvatar,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BAlert
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import mixinsGlobal from '@/mixins'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormTextarea,
    BSpinner,
    BAvatar,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BAlert,
    flatPickr,
    Cleave,
    vSelect
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('combosModule', {
      comboNaturezaFiscals: (state) => state.comboNaturezaFiscals,
      comboUfs: (state) => state.comboUfs
    })
  },
  data() {
    return {
      verificarPermissao,
      loading: false,
      required,
      form: {
        nome: '',
        fantasia: '',
        email: '',
        logo: '',
        rodape: '',
        marcaDagua: '',
        observacao: '',
        dataAdesao: '',
        cnpj: '',
        cnae: null,
        inscricaoEstadual: '',
        inscricaoMunicipal: '',
        capitalSocial: 0,
        numeroJuntaComercial: '',
        naturezaFiscal: {
          id: ''
        },
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        complemento: '',
        uf: {
          id: null,
          nome: '',
          sigla: ''
        },
        cobranca: '',
        vencimentoContrato: '',
        servidorEmail: '',
        usuario: '',
        senha: '',
        porta: '',
        remetente: ''
      },
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        },
        maskCnpj: {
          delimiters: ['.', '.', '/', '-'],
          blocks: [2, 3, 3, 4, 2],
          uppercase: true
        },
        maskCep: {
          delimiters: ['.', '-'],
          blocks: [2, 3, 3],
          uppercase: true
        }
      }
    }
  },
  async mounted() {
    this.loading = true
    this.handleResetObject()
    await this.$store.dispatch('combosModule/loadComboNaturezaFiscal')
    await this.$store.dispatch('combosModule/loadComboUfs')
    this.$store.dispatch('clientSettingsModule/load').then((result) => {
      this.form = result.data.content[0]
      if (!this.form.logo.includes('data:image/jpeg;base64,')) {
        this.form.logo = `data:image/jpeg;base64,${this.form.logo}`
      }
      if (!this.form.rodape.includes('data:image/jpeg;base64,')) {
        this.form.rodape = `data:image/jpeg;base64,${this.form.rodape}`
      }
      if (this.form.marcaDagua) {
        if (!this.form.marcaDagua.includes('data:image/jpeg;base64,')) {
          this.form.marcaDagua = `data:image/jpeg;base64,${this.form.marcaDagua}`
        }
      }
      this.loading = false
    })
  },
  methods: {
    handleResetObject() {
      this.form = {
        nome: '',
        fantasia: '',
        email: '',
        logo: '',
        rodape: '',
        marcaDagua: '',
        observacao: '',
        dataAdesao: '',
        cnpj: '',
        cnae: null,
        inscricaoEstadual: '',
        inscricaoMunicipal: '',
        capitalSocial: 0,
        numeroJuntaComercial: '',
        naturezaFiscal: {
          id: ''
        },
        cep: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        complemento: '',
        uf: {
          id: null,
          nome: '',
          sigla: ''
        },
        cobranca: '',
        vencimentoContrato: '',
        servidorEmail: '',
        usuario: '',
        senha: '',
        porta: '',
        remetente: ''
      }
    },
    handleEdit() {
      this.$store
        .dispatch('clientSettingsModule/edit', this.form)
        .then((response) => {
          if (response.response) {
            this.MensagemError('Falha ao Editar', response.response.data.error)
            return
          }
          this.showMessageSuccess('Editar', 'Editado com sucesso!')
        })
    },
    async updateLogo(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const file = input.files[0]
        this.form.logo = await this.getBase64(file)
        this.form.logo = `data:image/jpeg;base64,${this.form.logo}`
      }
    },
    async updateRodape(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const file = input.files[0]
        this.form.rodape = await this.getBase64(file)
        this.form.rodape = `data:image/jpeg;base64,${this.form.logo}`
      }
    },
    async updateMarcaDagua(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const file = input.files[0]
        this.form.marcaDagua = await this.getBase64(file)
        this.form.marcaDagua = `data:image/jpeg;base64,${this.form.marcaDagua}`
      }
    },
    deleteLogo() {
      this.form.logo = ''
    },
    deleteRodape() {
      this.form.rodape = ''
    },
    deleteMarcaDagua() {
      this.form.marcaDagua = ''
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
          if (encoded.length % 4 > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          resolve(encoded)
        }
        reader.onerror = (error) => reject(error)
      })
    },
    loadEnderecoViaCep() {
      const cepSemMascara = this.form.cep.replace(/[().\s-]+/g, '')
      this.$store
        .dispatch(
          'associantesAdministrativeModule/getEnderecoViaCep',
          cepSemMascara
        )
        .then((response) => {
          if (response.data.erro) {
            this.MensagemError('CEP inválido ou não encontrado')
            return
          }
          const ufEncontrada = this.getUfPorSigla(response.data.uf)
          this.form.bairro = response.data.bairro
          this.form.cep = response.data.cep
          this.form.cidade = response.data.localidade
          this.form.logradouro = response.data.logradouro
          this.form.uf = ufEncontrada
        })
    },
    getUfPorSigla(siglaUf) {
      return this.comboUfs.find(
        (uf) => uf.sigla.toUpperCase() === siglaUf.toUpperCase()
      )
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.disabledData {
  background-color: rgb(239, 239, 239) !important;
}
</style>
