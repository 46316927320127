<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first descricao -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descrição"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="v-descricao">
                <b-form-input
                  id="v-descricao"
                  v-model="form.descricao"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Descrição"
                  data-cy="description"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-description" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- first conta -->
          <b-col cols="12" lg="6">
            <b-form-group label="Número da Conta Bancaria" label-for="v-conta">
              <b-form-input
                id="v-conta"
                v-model="form.conta"
                placeholder="Conta"
                data-cy="conta"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first name -->
          <b-col cols="12" lg="6">
            <b-form-group label="Agência" label-for="v-agencia">
              <b-form-input
                id="v-agencia"
                v-model="form.agencia"
                placeholder="Agência"
                data-cy="agencia"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="tipo de conta"
              rules="required"
            >
              <b-form-group label="Tipo de Conta" label-for="v-tipoConta">
                <v-select
                  v-model="form.tipoConta.id"
                  :reduce="(tipoContaCombo) => tipoContaCombo.id"
                  label="nome"
                  :options="tipoContaCombo"
                  :state="errors.length > 0 ? false : null"
                  data-cy="tipoConta"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-tipoConta" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" lg="6">
            <validation-provider
              #default="{ errors }"
              name="plano de contas"
              rules="required"
            >
              <b-form-group label="Plano de Contas" label-for="v-panoConta">
                <v-select
                  v-model="form.planoDeConta.id"
                  :reduce="(planoDeContaCombo) => planoDeContaCombo.id"
                  label="nome"
                  :options="planoDeContaCombo"
                  :state="errors.length > 0 ? false : null"
                  data-cy="planoConta"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-planoConta" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider #default="{ errors }" name="banco">
              <b-form-group label="Banco" label-for="v-bank">
                <v-select
                  v-model="form.banco.id"
                  :reduce="(comboBanks) => comboBanks.id"
                  label="nome"
                  :options="comboBanks"
                  :state="errors.length > 0 ? false : null"
                  data-cy="bancos"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-bancos" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Data Saldo Inicial -->
          <b-col md="6">
            <b-form-group
              label="Data saldo inicial"
              label-for="v-dataSaldoInicial"
            >
              <flat-pickr
                v-model="form.dataSaldoInicial"
                class="form-control"
                :config="{
                  altInput: true,
                  altFormat: 'd/m/Y',
                  enableTime: false,
                  dateFormat: 'Y-m-d\\TH:i:ss\\Z'
                }"
                data-cy="dataSaldoInicial"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first valor -->
          <b-col cols="12" lg="6">
            <b-form-group label="Saldo inicial" label-for="v-valorSaldoInicial">
              <cleave
                id="v-valorSaldoInicial"
                v-model.number="form.valorSaldoInicial"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="saldo incial"
                data-cy="saldoInicial"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_FINAN_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_FINAN_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    flatPickr,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Contas',
      required,
      form: {
        descricao: '',
        planoDeConta: { id: null },
        tipoConta: { id: null },
        dataSaldoInicial: '',
        valorSaldoInicial: '',
        agencia: '',
        conta: '',
        banco: {
          id: null
        },
        valorSaldoAtual: 0
      },
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        }
      }
    }
  },
  computed: {
    ...mapState('bankAccountSettingsModule', {
      bankAccount: (state) => state.bankAccount,
      planoDeContaCombo: (state) => state.planoDeContaCombo,
      tipoContaCombo: (state) => state.tipoContaCombo,
      comboBanks: (state) => state.comboBanks
    })
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.bankAccount))
    this.form.banco = !this.form.banco
      ? { id: null }
      : { id: this.form.banco.id }
    this.$store.dispatch('bankAccountSettingsModule/loadComboPlanoContas')
    this.$store.dispatch('bankAccountSettingsModule/loadComboTipoConta')
    this.$store.dispatch('bankAccountSettingsModule/loadComboBanks')
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('bankAccountSettingsModule/resetBankAccount')
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          const obj = JSON.parse(JSON.stringify(this.form))
          obj.banco = !obj.banco.id ? null : { id: obj.banco.id }
          if (this.form.id) {
            this.$store
              .dispatch('bankAccountSettingsModule/editBankAccount', obj)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch('bankAccountSettingsModule/insertNewBankAccount', obj)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        descricao: '',
        planoDeConta: { id: null },
        tipoConta: { id: null },
        dataSaldoInicial: '',
        valorSaldoInicial: '',
        agencia: '',
        conta: '',
        banco: {
          id: null
        },
        valorSaldoAtual: 0
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
