import professionServices from '@/api/administrative/professionsServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => professionServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_PROFESSION', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewProfession({ commit }, data) {
    return professionServices.insertNewProfession(data).then(result => result).catch(error => error)
  },
  prepareToEditProfession({ commit }, data) {
    commit('PREPARE_TO_EDIT_PROFESSION', data)
  },
  editProfession({ commit }, data) {
    return professionServices.editProfession(data).then(result => result).catch(error => error)
  },
  resetProfession({ commit }) {
    commit('RESET_PROFESSION')
  },
  delete({ commit }, id) {
    return professionServices.deleteProfession(id).then(result => result).catch(error => error)
  },
}
