export default [
  {
    path: '/modules/financial/recebimento',
    redirect: '/modules/financial/recebimento',
    name: 'recebimento',
    component: () =>
      import('@/views/modules/financial/recebimento/Recebimento.vue'),
    meta: {
      action: 'ROLE_FINAN_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Contas a Receber',
      breadcrumb: [
        {
          text: 'Administrative'
        }
      ]
    },
    children: [
      {
        path: 'recebimento',
        name: 'recebimento',
        component: () =>
          import(
            '@/views/modules/financial/recebimento/grid/RecebimentoGrid.vue'
          ),
        meta: {
          pageTitle: 'Contas a Receber',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Financeiro | Contas a Receber'
            }
          ]
        }
      },
      {
        path: 'editrecebimento/:id',
        name: 'editrecebimento',
        component: () =>
          import(
            '@/views/modules/financial/recebimento/form/RecebimentoForm.vue'
          ),
        meta: {
          pageTitle: 'Editando Contas a Receber',
          action: 'ROLE_FINAN_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Modulo Financeiro | Editando Contas a Receber'
            }
          ]
        }
      }
    ]
  }
]
