import diretoriaService from '@/api/diretoria'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => diretoriaService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insert({ commit }, data) {
    return diretoriaService.insert(data).then(result => result).catch(error => error)
  },
  prepareToEdit({ commit }, data) {
    commit('PREPARE_TO_EDIT', data)
  },
  edit({ commit }, data) {
    return diretoriaService.edit(data).then(result => result).catch(error => error)
  },
}
