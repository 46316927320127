import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/profissionais${queryUrl}`)
  },
  insertNew(data) {
    return axios.post('/atendimento/profissionais', data)
  },
  edit(data) {
    return axios.put(`/atendimento/profissionais/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/atendimento/profissionais/${id}`)
  },
  searchProfessional(name) {
    const endpoint = `/atendimento/profissionais/registro?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  getById(id) {
    return axios.get(`/atendimento/profissionais/${id}`)
  }
}
