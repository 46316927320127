import accountPlanService from '@/api/financial/accountPlanServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => accountPlanService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ACCOUNT_PLANS', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewAccountPlan({ commit }, data) {
    return accountPlanService.insertNewAccountPlan(data).then(result => result).catch(error => error)
  },
  prepareToEditAccountPlan({ commit }, data) {
    commit('PREPARE_TO_EDIT_ACCOUNT_PLANS', data)
  },
  editAccountPlan({ commit }, data) {
    return accountPlanService.editAccountPlan(data).then(result => result).catch(error => error)
  },
  resetAccountPlan({ commit }) {
    commit('RESET_ACCOUNT_PLANS')
  },
  delete({ commit }, id) {
    return accountPlanService.deleteAccountPlan(id).then(result => result).catch(error => error)
  },
}
