<template>
  <div>
    <b-row>
      <b-col cols="12">
        <CardVue :cards="cards" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="12" lg="2">
        <div v-for="(opcao, index) in opcoes" :key="index">
          <b-button
            v-if="verificarPermissao(opcao.action)"
            class="mb-1"
            block
            variant="gradient-primary"
            :to="opcao.route"
            exact
            @click="loadCard(opcao.route)"
          >
            {{ $t(opcao.title) }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="10">
        <router-view />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import CardVue from '@/layouts/components/Card.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    CardVue
  },
  data() {
    return {
      verificarPermissao,
      opcoes: [
        {
          title: 'Associados',
          route: { name: 'associantes' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Depedentes',
          route: { name: 'dependent' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Agregados',
          route: { name: 'aggregates' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Funcionários',
          route: { name: 'employees' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        },
        {
          title: 'Outros',
          route: { name: 'others' },
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL'
        }
      ],
      cards: []
    }
  },
  mounted() {
    this.$store
      .dispatch('associantesAdministrativeModule/loadCards')
      .then((response) => {
        response.data.forEach((element) => {
          this.cards.push(element)
        })
      })
  },
  methods: {
    loadCard(route) {
      this.cards = [
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        },
        {
          descricao: 'Carregando...',
          valor: 0
        }
      ]
      this.setCardsAssociados(route.name)
      this.setCardsDependentes(route.name)
      this.setCardsAgregados(route.name)
      this.setCardsFuncionarios(route.name)
      this.setCardsOutros(route.name)
    },
    setCardsAssociados(name) {
      if (name === 'associantes') {
        this.$store
          .dispatch('associantesAdministrativeModule/loadCards')
          .then((response) => {
            this.cards = []
            response.data.forEach((element) => {
              this.cards.push(element)
            })
          })
      }
    },
    setCardsDependentes(name) {
      if (name === 'dependent') {
        this.$store
          .dispatch('dependentAdministrativeModule/loadCards')
          .then((response) => {
            this.cards = []
            response.data.forEach((element) => {
              this.cards.push(element)
            })
          })
      }
    },
    setCardsAgregados(name) {
      if (name === 'aggregates') {
        this.$store
          .dispatch('aggregatesAdministrativeModule/loadCards')
          .then((response) => {
            this.cards = []
            response.data.forEach((element) => {
              this.cards.push(element)
            })
          })
      }
    },
    setCardsFuncionarios(name) {
      if (name === 'employees') {
        this.$store
          .dispatch('employeesAdministrativeModule/loadCards')
          .then((response) => {
            this.cards = []
            response.data.forEach((element) => {
              this.cards.push(element)
            })
          })
      }
    },
    setCardsOutros(name) {
      if (name === 'others') {
        this.$store
          .dispatch('othersAdministrativeModule/loadCards')
          .then((response) => {
            this.cards = []
            response.data.forEach((element) => {
              this.cards.push(element)
            })
          })
      }
    }
  }
}
</script>
