export default {
  LOAD_LIST_SOCIAL_SECURITY_GROUP(state, data) {
    state.listSocialSecurityGroup = data
  },
  PREPARE_TO_EDIT_SOCIAL_SECURITY_GROUP(state, data) {
    state.socialSecurityGroup = data
  },
  RESET_SOCIAL_SECURITY_GROUP(state) {
    state.socialSecurityGroup = {
      id: 0,
      ativo: true,
      nome: '',
    }
  },
}
