import parameterAtendimentoServices from '@/api/administrative/parameterAtendimentoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => parameterAtendimentoServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_PARAMETER', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  prepareToEditParameter({ commit }, data) {
    commit('PREPARE_TO_EDIT_PARAMETER', data)
  },
  editParameter({ commit }, data) {
    return parameterAtendimentoServices.editParameter(data).then(result => result).catch(error => error)
  },
  resetParameter({ commit }) {
    commit('RESET_PARAMETER')
  },
}
