export default {
  LOAD_LIST_PAYMENT_METHODS(state, data) {
    state.listPaymentMethods = data
  },
  PREPARE_TO_EDIT_PAYMENT_METHODS(state, data) {
    state.paymentMethods = data
  },
  RESET_PAYMENT_METHODS(state) {
    state.paymentMethods = {
      descricao: '',
      ativo: true,
    }
  },
}
