export default {
  LOAD_LIST_DOENCAS(state, data) {
    state.listaDoencas = data
  },
  PREPARE_TO_EDIT_DOENCA(state, data) {
    state.doenca = data
  },
  RESET_DOENCA(state) {
    state.doenca = {
      id: null,
      descricao: '',
      especialidade: {
        id: null,
      },
      cat: '',
      ativo: true,
    }
  },
}
