import fonesService from '@/api/administrative/foneServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => fonesService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_FONES', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewFone({ commit }, data) {
    return fonesService.insertNewFone(data).then(result => result).catch(error => error)
  },
  prepareToEditFone({ commit }, data) {
    commit('PREPARE_TO_EDIT_FONE', data)
  },
  editFone({ commit }, data) {
    return fonesService.editFone(data).then(result => result).catch(error => error)
  },
  resetFone({ commit }) {
    commit('RESET_FONE')
  },
  delete({ commit }, id) {
    return fonesService.deleteFone(id).then(result => result).catch(error => error)
  },
  getFoneByIdRegister({ commit }, id) {
    return new Promise((resolve, reject) => fonesService.getFoneByIdRegister(id).then(result => {
      commit('LOAD_LIST_FONES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboTipoFone({ commit }) {
    return new Promise((resolve, reject) => fonesService.loadComboTipoFone().then(result => {
      commit('SET_LIST_COMBO_TIPO_FONE', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
}
