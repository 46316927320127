<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first descricao -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="descricao"
              rules="required"
            >
              <b-form-group label="Descrição" label-for="v-descricao">
                <b-form-input
                  id="v-descricao"
                  v-model="form.descricao"
                  :state="errors.length > 0 ? false : null"
                  placeholder="descrição"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- tipo Protocolo -->
          <b-col cols="12" sm="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Tipo Protocolo"
              rules="required"
            >
              <b-form-group label="Tipo Protocolo" label-for="v-tipoProtocolo">
                <v-select
                  v-model="form.tipoProtocolo"
                  :reduce="(comboTipoProtocolo) => comboTipoProtocolo.id"
                  label="descricao"
                  :options="comboTipoProtocolo"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- tipo Status -->
          <b-col cols="12" sm="12" md="6">
            <validation-provider
              #default="{ errors }"
              name="Status"
              rules="required"
            >
              <b-form-group label="Status" label-for="v-status">
                <v-select
                  v-model="form.status"
                  :reduce="(comboStatus) => comboStatus.id"
                  label="nome"
                  :options="comboStatus"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="6" md="6">
            <validation-provider
              #default="{ errors }"
              name="Data Início"
              rules=""
            >
              <b-form-group label="Data Início" label-for="v-dataInicio">
                <flat-pickr
                  v-model="form.dataInicio"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small data-cy="v-dataInicio-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="6" md="6">
            <validation-provider
              #default="{ errors }"
              name="Data Término"
              rules=""
            >
              <b-form-group label="Data Término" label-for="v-dataFim">
                <flat-pickr
                  v-model="form.dataTermino"
                  name="fieldDate"
                  :config="{
                    altInput: true,
                    altFormat: 'd/m/Y',
                    enableTime: false,
                    dateFormat: 'Y-m-d',
                  }"
                />
                <small data-cy="v-dataFim-validate" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="10">
            <validation-provider
              #default="{ errors }"
              name="observacao"
              rules="required"
            >
              <b-form-group label="Solicitante" label-for="v-registro">
                <v-select
                  v-model="form.registro"
                  label="nome"
                  :filterable="false"
                  :options="comboRegistros"
                  :selected="form.registro"
                  @search="onSearch"
                  ><template slot="no-options">
                    Pesquisa registro por nome, cpf ou matrícula
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </v-select>
                <small data-cy="v-registro" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="2">
            <b-form-group label="Garantia">
              <b-form-checkbox
                v-model="form.garantia"
                :checked="form.garantia"
                name="check-button"
                switch
                inline
              >
                {{ form.garantia ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6">
            <validation-provider #default="{ errors }" name="valor" rules="">
              <b-form-group label="Valor" label-for="v-valor">
                <cleave
                  id="valor"
                  v-model.number="form.valor"
                  class="form-control"
                  :raw="true"
                  :options="options.number"
                  placeholder="valor"
                  :class="[errors.length > 0 ? isValid : '']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" sm="12" md="6" lg="6">
            <b-form-group label="Valor Desconto" label-for="v-valorDesconto">
              <cleave
                id="valorDesconto"
                v-model.number="form.valorDesconto"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="valor Desconto"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="12">
            <b-form-group label="Observação" label-for="v-observacao">
              <b-form-textarea
                id="v-observacao"
                v-model="form.observacao"
                placeholder="Observação"
                rows="2"
                max-rows="6"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="cancel"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import Cleave from 'vue-cleave-component'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('ordemServicoModule', {
      ordemServico: (state) => state.ordemServico,
    }),
    ...mapState('combosModule', {
      comboTipoProtocolo: (state) => state.comboTipoProtocolo,
      comboStatus: (state) => state.comboStatusOs,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    vSelect,
    flatPickr,
    Cleave,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      titleForm: 'Cadastro de Ordem de Serviço',
      required,
      form: {
        id: null,
        descricao: '',
        dataInicial: null,
        dataTermino: null,
        registro: {
          id: 1,
        },
        garantia: null,
        observacao: null,
        status: {
          id: null,
        },
        valor: null,
        valorDesconto: null,
        tipoProtocolo: null,
      },
      comboRegistros: [],
      loading: false,
      showForm: false,
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.$store
        .dispatch('ordemServicoModule/getById', this.$route.params.id)
        .then(() => {
          this.form = JSON.parse(JSON.stringify(this.ordemServico))
          this.form.dataInicio = this.dataParaFormulario(this.form.dataInicio)
          this.form.dataTermino = this.dataParaFormulario(this.form.dataTermino)
        })
    }
    await this.$store.dispatch('combosModule/loadComboTipoProtocolo')
    await this.$store.dispatch('combosModule/loadComboStatusOs')
  },
  methods: {
    cancel() {
      if (this.$route.params.id) {
        this.$router.go(-1)
        return
      }
      this.$emit('cancelar')
      this.$store.dispatch('ordemServicoModule/reset')
      this.cleanObjeto()
    },
    prepareDadosParaSalvar(obj) {
      const objSave = JSON.parse(JSON.stringify(obj))
      objSave.registro = { id: objSave.registro.id }
      objSave.status = { id: objSave.status }
      objSave.dataInicio = this.preparaDataParaSalvarEdicao(objSave.dataInicio)
      objSave.dataTermino = this.preparaDataParaSalvarEdicao(
        objSave.dataTermino,
      )
      return objSave
    },
    save() {
      this.$refs.form.validate().then((success) => {
        const obj = this.prepareDadosParaSalvar(this.form)
        if (success) {
          if (this.form.id) {
            this.$store
              .dispatch('ordemServicoModule/edit', obj)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error,
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch('ordemServicoModule/insert', obj)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error,
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        id: null,
        descricao: '',
        dataInicial: null,
        dataTermino: null,
        registro: {
          id: 1,
        },
        garantia: null,
        observacao: null,
        status: {
          id: null,
        },
        valor: null,
        valorDesconto: null,
        tipoProtocolo: null,
      }
    },
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce(async (loading, search, vm) => {
      const registrosEncontrados = await vm.$store.dispatch(
        'ordemServicoModule/searchRegistros',
        search,
      )
      if (registrosEncontrados.response) {
        vm.MensagemError(
          'Falha na busca',
          `${registrosEncontrados.response.data.message}`,
        )
        return
      }

      if (registrosEncontrados.data.content.length === 0) {
        vm.MensagemError('Zero registros', 'Nenhum registro encontrado')
        return
      }
      vm.comboRegistros = registrosEncontrados.data.content
      loading(false)
    }, 350),
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
