export default {
  LOAD_LIST_CONSELHO(state, data) {
    state.listaConselhos = data
  },
  PREPARE_TO_EDIT_CONSELHO(state, data) {
    state.conselho = data
  },
  RESET_CONSELHO(state) {
    state.conselho = {
      id: null,
      descricao: '',
      sigla: '',
      ativo: true,
    }
  },
}
