import addressService from '@/api/administrative/enderecoServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => addressService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_ENDERECOS', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewAddress({ commit }, data) {
    return addressService.insertNewAddress(data).then(result => result).catch(error => error)
  },
  prepareToEditAddress({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECOS', data)
  },
  editAddress({ commit }, data) {
    return addressService.editAddress(data).then(result => result).catch(error => error)
  },
  resetAddress({ commit }) {
    commit('RESET_ENDERECOS')
  },
  delete({ commit }, id) {
    return addressService.deleteAddress(id).then(result => result).catch(error => error)
  },
  getAddressByIdRegister({ commit }, id) {
    return new Promise((resolve, reject) => addressService.getAddressByIdRegister(id).then(result => {
      commit('LOAD_LIST_ENDERECOS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  getEnderecoViaCep({ commit }, data) {
    return addressService.getEnderecoViaCep(data).then(result => result).catch(error => error)
  },
}
