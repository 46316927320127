<template>
  <div>
    <div v-if="!disableForm">
      <validation-observer ref="form">
        <b-form @submit.prevent>
          <b-row>
            <!--descricao -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="descricao"
                rules="required"
              >
                <b-form-group
                  label="Descrição"
                  label-for="descricao"
                >
                  <b-form-input
                    id="descricao"
                    v-model="fone.descricao"
                    :disabled="disableForm"
                    type="text"
                    placeholder="descrição"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--numero -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="numero"
                rules="required"
              >
                <b-form-group
                  label="Número"
                  label-for="numero"
                >
                  <b-input-group>
                    <cleave
                      id="numero"
                      v-model="fone.numero"
                      :disabled="disableForm"
                      class="form-control"
                      :raw="false"
                      :options="options.phone"
                      placeholder="número"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Tipo Fone -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="tipo"
                rules="required"
              >
                <b-form-group
                  label="Tipo"
                  label-for="tipo"
                >

                  <v-select
                    id="tipo"
                    v-model="fone.tipo"
                    :disabled="disableForm"
                    label="nome"
                    :options="comboTipoFone"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <div style="margin-top: 30px;">
                <b-form-checkbox
                  v-model="fone.principal"
                  :value="true"
                  class="custom-control-primary"
                >
                  Principal
                </b-form-checkbox>
              </div>
            </b-col>

            <b-col md="3">
              <div style="margin-top: 30px;">
                <b-form-checkbox
                  v-model="fone.whatsapp"
                  :value="true"
                  class="custom-control-primary"
                >
                  Whatsapp
                </b-form-checkbox>
              </div>
            </b-col>

            <b-col
              cols="12"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="save()"
              >
                Salvar
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="cancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button
          variant="primary"
          @click="novoTelefone()"
        >
          Incluir
        </b-button>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="listaFones"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="(props.column.field === 'principal') || (props.column.field === 'whatsapp')">
            <span>
              {{ props.formattedRow[props.column.field] ? 'Sim' : 'Não' }}
            </span>
          </span>
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editTelefone(props.row)">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteFone(props.row)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Mostrando 1 a
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> de {{ props.total }} registros </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixinsGlobal from '@/mixins'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormCheckbox,
  BDropdown, BDropdownItem, BFormSelect, BPagination, BInputGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.br'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BInputGroup,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('foneSettingsModule', {
      listaFones: state => state.listaFones,
      fone: state => state.fone,
      comboTipoFone: state => state.comboTipoFone,
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async mounted() {
    this.loadTable()
    await this.$store.dispatch('foneSettingsModule/loadComboTipoFone')
  },
  methods: {
    novoTelefone() {
      this.$store.commit('foneSettingsModule/RESET_FONE')
      this.disableForm = false
    },
    editTelefone(data) {
      this.$store.dispatch('foneSettingsModule/prepareToEditFone', data)
      this.disableForm = false
    },
    cancel() {
      this.$store.commit('foneSettingsModule/RESET_FONE')
      this.disableForm = true
    },
    async loadTable() {
      await this.$store.dispatch('foneSettingsModule/getFoneByIdRegister', this.$route.params.id)
    },
    save() {
      this.$refs.form.validate().then(success => {
        if (success) {
          if (this.fone.id) {
            const objectEdit = {
              id: this.fone.id,
              descricao: this.fone.descricao,
              numero: this.fone.numero,
              tipo: {
                id: this.fone.tipo.id,
                nome: this.fone.tipo.nome,
              },
              principal: this.fone.principal,
              whatsapp: this.fone.whatsapp,
              ativo: this.fone.ativo,
              registro: {
                id: this.fone.registro.id,
                nome: this.fone.registro.nome,
              },
            }
            this.$store.dispatch('foneSettingsModule/editFone', objectEdit).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Editar', response.response.data.error)
                return
              }
              this.loadTable()
              this.showMessageSuccess('Editar', 'Editado com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          } else {
            this.fone.registro.id = this.$route.params.id
            this.$store.dispatch('foneSettingsModule/insertNewFone', this.fone).then(response => {
              if (response.response) {
                this.MensagemError('Falha ao Inserir', response.response.data.error)
                return
              }
              this.loadTable()
              this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
              this.$refs.form.reset()
              this.cancel()
            })
          }
        }
      })
    },
    deleteFone(data) {
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('foneSettingsModule/delete', data.id).then(() => {
            this.loadTable()
            this.showMessageSuccess('Remover', 'Registro removido com sucesso!')
          }).catch(err => {
            this.MensagemError('Falha ao remover o registro', err.message)
            this.loadTable()
          })
        }
      })
    },
  },
  data() {
    return {
      required,
      disableForm: true,
      columns: [
        {
          label: 'Descrição',
          field: 'descricao',
        },
        {
          label: 'Número',
          field: 'numero',
        },
        {
          label: 'Principal',
          field: 'principal',
        },
        {
          label: 'Whatsapp',
          field: 'whatsapp',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      pageConfig: {
        page: 1,
        size: 10,
        sort: 'nome',
        order: 'asc',
      },
      pageLength: 10,
      dir: false,
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BR',
        },
      },
    }
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
