import aggregatesService from '@/api/AdministrativeModule/aggregatesServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) => aggregatesService.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_AGGREGATES', result.data.content)
      resolve(result)
    }).catch(err => reject(err)))
  },
  insertNewAggregates({ commit }, data) {
    return aggregatesService.insertNewAggregates(data).then(result => result).catch(error => error)
  },
  prepareToEditAggregates({ commit }, data) {
    commit('PREPARE_TO_EDIT_AGGREGATES', data)
  },
  editAggregates({ commit }, data) {
    return aggregatesService.editAggregates(data).then(result => result).catch(error => error)
  },
  resetAggregates({ commit }) {
    commit('RESET_AGGREGATES')
  },
  getAggregateID({ commit }, id) {
    return new Promise((resolve, reject) => aggregatesService.getAggregateID(id).then(result => {
      resolve(result)
    }).catch(err => reject(err)))
  },
  prepareToEditFullAggregate({ commit }, data) {
    commit('PREPARE_TO_EDIT_FULL_AGGREGATE', data)
  },
  loadComboTypesRegister({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadCombo().then(result => {
      commit('SET_LIST_COMBO_TYPES_REGISTER', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboGenero({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboGenero().then(result => {
      commit('SET_LIST_COMBO_GENERO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboEstadoCivil({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboEstadoCivil().then(result => {
      commit('SET_LIST_COMBO_ESTADO_CIVIL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboProfissoes({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboProfissoes().then(result => {
      commit('SET_LIST_COMBO_PROFISSOES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNaturalidades({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboNaturalidades().then(result => {
      commit('SET_LIST_COMBO_NATURALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboNacionalidades({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboNacionalidades().then(result => {
      commit('SET_LIST_COMBO_NACIONALIDADES', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboUfs({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboUfs().then(result => {
      commit('SET_LIST_COMBO_UFS', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboPrevidenciaSocial({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboPrevidenciaSocial().then(result => {
      commit('SET_LIST_COMBO_PREVIDENCIA_SOCIAL', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboTipoEndereco({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboTipoEndereco().then(result => {
      commit('SET_LIST_COMBO_TIPO_ENDERECO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  loadComboSituacaoRegistro({ commit }) {
    return new Promise((resolve, reject) => aggregatesService.loadComboSituacaoRegistro().then(result => {
      commit('SET_LIST_COMBO_SITUACAO_REGISTRO', result.data)
      resolve(result)
    }).catch(err => reject(err)))
  },
  editAggregateDadosPessoais({ commit }, data) {
    return aggregatesService.editAggregateDadosPessoais(data).then(result => result).catch(error => error)
  },
  editAggregateDocumentation({ commit }, data) {
    return aggregatesService.editAggregateDocumentation(data).then(result => result).catch(error => error)
  },
  getEnderecoViaCep({ commit }, data) {
    return aggregatesService.getEnderecoViaCep(data).then(result => result).catch(error => error)
  },
  inserirNovoEndereco({ commit }, data) {
    return aggregatesService.inserirNovoEndereco(data).then(result => result).catch(error => error)
  },
  editEndereco({ commit }, data) {
    return aggregatesService.editEndereco(data).then(result => result).catch(error => error)
  },
  getAssocianteForCpf({ commit }, data) {
    return aggregatesService.getAssocianteForCpf(data).then(result => result).catch(error => error)
  },
  setAssociante({ commit }, data) {
    commit('SET_LISTA_ASSOCIADO', data)
  },
  setHistoricoSituacao({ commit }, data) {
    return aggregatesService.setHistoricoSituacao(data).then(result => result).catch(error => error)
  },
  getHistoricoSituacao({ commit }, id) {
    return aggregatesService.getHistoricoSituacao(id).then(result => result).catch(error => error)
  },
  loadEnderecosPorRegistro({ commit }, id) {
    return new Promise((resolve, reject) => aggregatesService.loadEnderecosPorRegistro(id).then(result => {
      const ordenandoProPrincipal = result.data.sort((a, b) => {
        if (a.principal < b.principal) return 1
        if (a.principal > b.principal) return -1
        return 0
      })
      commit('SET_LISTA_ENDERECOS', ordenandoProPrincipal)
      resolve(result)
    }).catch(err => reject(err)))
  },
  deleteEndereco({ commit }, id) {
    return aggregatesService.deleteEndereco(id).then(result => result).catch(error => error)
  },
  prepareToEditEndereco({ commit }, data) {
    commit('PREPARE_TO_EDIT_ENDERECO', data)
  },
  editAggregateEndereco({ commit }, data) {
    return aggregatesService.editEndereco(data).then(result => result).catch(error => error)
  },
  sendNewPhoto({ commit }, object) {
    return aggregatesService.sendNewPhoto(object).then(result => result).catch(error => error)
  },
  deletePhoto({ commit }, id) {
    return aggregatesService.deletePhoto(id).then(result => result).catch(error => error)
  },
  downloadDocument({ commit }, id) {
    return aggregatesService.downloadDocument(id).then(result => result).catch(error => error)
  },
  deleteDocument({ commit }, id) {
    return aggregatesService.deleteDocument(id).then(result => result).catch(error => error)
  },
  usarEnderecoAssociado({ commit }, id) {
    return aggregatesService.usarEnderecoAssociado(id).then(result => {
      commit('SET_USAR_ENDERECO_ASSOCIADO', true)
      return result
    }).catch(error => error)
  },
  getHistoricoTipoRegistro({ commit }, id) {
    return aggregatesService.getHistoricoTipoRegistro(id).then(result => result).catch(error => error)
  },
  setMudarTipoRegistro({ commit }, data) {
    return aggregatesService.setMudarTipoRegistro(data).then(result => result).catch(error => error)
  },
  loadCards({ commit }) {
    return aggregatesService.loadCards().then(result => result).catch(error => error)
  },
  getHistoricoFaixaContribuicao({ commit }, id) {
    return aggregatesService.getHistoricoFaixaContribuicao(id).then(result => result).catch(error => error)
  },
  setMudarFaixaContribuica({ commit }, data) {
    return aggregatesService.setMudarFaixaContribuica(data).then(result => result).catch(error => error)
  },
  listagemRelatorios() {
    return aggregatesService.listagemRelatorios().then(result => result).catch(error => error)
  },
  migrarParaSocio({ commit }, data) {
    return aggregatesService.migrarParaSocio(data).then(result => result).catch(error => error)
  },
}
