import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/exames${queryUrl}`)
  },
  insertNewExame(data) {
    return axios.post('/atendimento/exames', data)
  },
  editExame(data) {
    return axios.put(`/atendimento/exames/${data.id}`, data)
  },
  deleteExame(id) {
    return axios.delete(`/atendimento/exames/${id}`)
  },
}
