export default {
  LOAD_LIST_RECORD_SITUATIONS(state, data) {
    state.listRecordSituations = data
  },
  PREPARE_TO_EDIT_RECORD_SITUATIONS(state, data) {
    state.recordSituation = data
  },
  RESET_RECORD_SITUATIONS(state) {
    state.recordSituation = {
      ativo: true,
      id: 0,
      nome: '',
      padrao: true,
    }
  },
}
