import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/taxas${queryUrl}`)
  },
  insertNew(data) {
    return axios.post('/financeiro/taxas', data)
  },
  edit(data) {
    return axios.put(`/financeiro/taxas/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/financeiro/taxas/${id}`)
  },
}
