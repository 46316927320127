<template>
  <b-row>
    <b-col cols="12">
      <TableComponents
        v-if="!showForm"
        :columns="columns"
        :rows="listAggregates"
        sort-name="nome"
        title-table="Agregados"
        module="aggregatesAdministrativeModule"
        :show-btn-add="verificarPermissao('ROLE_ADMIM_INSERIR')"
        :show-action-delete="verificarPermissao('ROLE_ADMIM_DELETAR')"
        @add="addNew"
        @edit="edit"
      />
      <form-insert
        v-else
        :title-form="objectFormInsert.titleForm"
        :combo-type="comboTypesRegister"
        :show-cpf-associado="showCpfAssociado"
        :eh-associado="ehAssociado"
        @cancelar="cancelar"
        @save="save"
      />
    </b-col>
  </b-row>
</template>
<script>
import { mapState } from 'vuex'
import TableComponents from '@/layouts/components/TableComponents.vue'
import FormInsert from '@/layouts/components/FormInsert.vue'
import { BRow, BCol } from 'bootstrap-vue'
import mixinsGlobal from '@/mixins'

export default {
  name: 'Aggregates',
  components: {
    BRow,
    BCol,
    TableComponents,
    FormInsert
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('aggregatesAdministrativeModule', {
      listAggregates: (state) => state.listAggregates,
      aggregate: (state) => state.aggregate,
      comboTypesRegister: (state) => state.comboTypesRegister
    })
  },
  data() {
    return {
      ehAssociado: false,
      showCpfAssociado: true,
      showForm: false,
      columns: [
        {
          label: 'Matricula',
          field: 'matricula'
        },
        {
          label: 'Nome',
          field: 'nome'
        },
        {
          label: 'Tipo Registro',
          field: 'tipoRegisto'
        },
        {
          label: 'Data Nascimento',
          field: 'dataNascimento',
          type: 'date',
          dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss'Z'",
          dateOutputFormat: 'dd/MM/yyyy'
        },
        {
          label: 'CPF',
          field: 'cpf'
        },
        {
          label: 'Vinculo',
          field: 'nomeAssociado'
        },
        {
          label: 'Situação',
          field: 'situacaoRegistro'
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      objectFormInsert: {
        titleForm: 'Cadastro de Agregados'
      }
    }
  },

  mounted() {
    this.$store.dispatch(
      'aggregatesAdministrativeModule/loadComboTypesRegister'
    )
  },
  methods: {
    addNew() {
      this.showForm = true
      this.showCpfAssociado = true
      this.ehAssociado = false
    },
    edit(data) {
      this.$router.push({ path: `editAggregate/${data.id}` })
    },
    cancelar() {
      this.$store.dispatch('aggregatesAdministrativeModule/resetAggregate')
      this.showForm = false
    },
    async save(data) {
      const associadoVinculo = await this.$store.dispatch(
        'aggregatesAdministrativeModule/getAssocianteForCpf',
        data.associado
      )
      const objectSave = JSON.parse(JSON.stringify(data))
      objectSave.associado = associadoVinculo.data.id
      this.$store
        .dispatch(
          'aggregatesAdministrativeModule/insertNewAggregates',
          objectSave
        )
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
          this.edit(response.data)
        })
    }
  }
}
</script>
