export default {
  listAggregates: [],
  aggregate: {
    nome: '',
    cpf: '',
    email: '',
    tipoRegistro: null,
    associado: null,
  },
  fullAggregate: {
    id: null,
    matricula: null,
    nome: '',
    email: '',
    tipoRegistro: null,
    situacaoRegistro: null,
    foto: null,
    observacao: null,
    enderecos: [],
    fones: [],
    cpf: null,
    rg: null,
    sexo: null,
    dataNascimento: null,
    dataAdesao: null,
    orgaoEmissor: '',
    dataExpedicao: '',
    nomePai: null,
    nomeMae: null,
    tituloEleitor: null,
    zonaEleitoral: null,
    secaoEleitoral: null,
    reservista: null,
    numeroInss: null,
    ufEleitoral: null,
    estadoCivil: null,
    nacionalidade: null,
    naturalidade: null,
    profissao: null,
    previdenciaSocial: null,
    reutilizarEndereco: false,
    faixaContribuicao: null,
  },
  comboTypesRegister: [{
    id: null,
    nome: null,
  }],
  comboGeneros: [{
    id: null,
    nome: null,
  }],
  comboEstadoCivil: [{
    id: null,
    nome: null,
  }],
  comboProfissoes: [{
    id: null,
    nome: null,
  }],
  comboNaturalidades: [{
    id: null,
    nome: null,
  }],
  comboNacionalidades: [{
    id: null,
    nome: null,
  }],
  comboUfs: [{
    id: null,
    nome: null,
    sigla: null,
  }],
  comboPrevidenciaSocial: [{
    id: null,
    nome: null,
  }],
  comboTipoEndereco: [{
    id: null,
    nome: null,
  }],
  comboSituacaoRegistro: [{
    id: null,
    nome: null,
  }],
  listaEnderecos: [],
  endereco: {
    cep: null,
    logradouro: null,
    numero: null,
    bairro: null,
    cidade: null,
    complemento: null,
    ativo: true,
    principal: false,
    registro: {
      id: null,
      nome: null,
    },
    uf: {
      id: null,
      nome: null,
      sigla: null,
    },
    tipoEndereco: {
      id: null,
      nome: null,
    },
  },
  listaAssociado: [],
}
