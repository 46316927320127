import phoneTypeServices from '@/api/administrative/phoneTypeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return phoneTypeServices.loadTable(pageConfig).then(result => {
      commit('LOAD_LIST_PHONE_TYPE', result.data.content)
      return result
    }).catch(err => err)
  },
  insertNewPhoneType({ commit }, data) {
    return phoneTypeServices.insertNewPhoneType(data).then(result => result).catch(error => error)
  },
  prepareToEditPhoneType({ commit }, data) {
    commit('PREPARE_TO_EDIT_PHONE_TYPE', data)
  },
  editPhoneType({ commit }, data) {
    return phoneTypeServices.editPhoneType(data).then(result => result).catch(error => error)
  },
  resetPhoneType({ commit }) {
    commit('RESET_PHONE_TYPE')
  },
  delete({ commit }, id) {
    return phoneTypeServices.deletePhoneType(id).then(result => result).catch(error => error)
  },
}
