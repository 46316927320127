import axios from '@axios'

export default {
  load(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/financeiro/parcelamentos${queryUrl}`)
  },
  getParametrosParcelamento() {
    return axios.get('/financeiro/parametros/1')
  },
  insert(data) {
    return axios.post('/financeiro/parcelamentos/', data)
  },
  getParcelamentoPorId(id) {
    return axios.get(`/financeiro/parcelamentos/${id}`)
  },
  filter(pageConfig, filter) {
    let queryUrl = ''
    queryUrl = `?page=${pageConfig.page}&size=${pageConfig.size}&${pageConfig.sort},${pageConfig.order}`
    return axios.post(`financeiro/parcelamentos/filtro${queryUrl}`, filter)
  },
}
