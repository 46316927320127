<template>
  <div v-if="loading" class="clearfix">
    <b-spinner class="float-right" label="Floated Right" />
  </div>
  <div v-else>
    <b-row>
      <b-col lg="12" md="12">
        <app-collapse type="margin">
          <app-collapse-item title="Informações Pessoais">
            <FormPersonalInfoVue />
          </app-collapse-item>

          <app-collapse-item title="Tipo de Registro">
            <FormPersonalRegistryTypeVue />
          </app-collapse-item>

          <app-collapse-item title="Situação">
            <FormPersonalNotesVue />
          </app-collapse-item>

          <app-collapse-item title="Faixa Contribuição">
            <FormPersonalFaixaContribuicaoVue />
          </app-collapse-item>

          <app-collapse-item title="Documentações">
            <FormPersonalDocumentsVue />
          </app-collapse-item>

          <app-collapse-item title="Endereços">
            <FormPersonalEnderecosVue />
          </app-collapse-item>

          <app-collapse-item title="Fones">
            <FormAssocianteFonesVue />
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FormPersonalInfoVue from './FormAssociantePersonalInfo.vue'
import FormPersonalDocumentsVue from './FormAssocianteDocuments.vue'
import FormPersonalNotesVue from './FormAssocianteSituation.vue'
import FormPersonalEnderecosVue from './FormAssocianteEnderecos.vue'
import FormAssocianteFonesVue from './FormAssocianteFone.vue'
import FormPersonalRegistryTypeVue from './FormAssocianteTipoRegistro.vue'
import FormPersonalFaixaContribuicaoVue from './FormAssocianteFaixaContribuicao.vue'
import { verificarPermissao } from '@/auth/utils'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    FormPersonalInfoVue,
    FormPersonalDocumentsVue,
    FormPersonalNotesVue,
    FormPersonalEnderecosVue,
    FormAssocianteFonesVue,
    FormPersonalRegistryTypeVue,
    FormPersonalFaixaContribuicaoVue,
    BRow,
    BCol,
    BSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      verificarPermissao,
      collapseType: 'default'
    }
  }
}
</script>
