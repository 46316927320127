export default {
  listaFones: [],
  fone: {
    descricao: '',
    numero: '',
    tipo: null,
    principal: false,
    whatsapp: false,
    ativo: true,
    registro: {
      id: null,
      nome: '',
    },
  },
  comboTipoFone: [],
}
