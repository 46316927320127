import especialidadeService from '@/api/attendance/especialidadeServices'

export default {
  loadTable({ commit }, pageConfig) {
    return new Promise((resolve, reject) =>
      especialidadeService
        .loadTable(pageConfig)
        .then((result) => {
          commit('LOAD_LIST_ESPECIALIDADE', result.data.content)
          resolve(result)
        })
        .catch((err) => reject(err))
    )
  },
  insert({ commit }, data) {
    return especialidadeService
      .insert(data)
      .then((result) => result)
      .catch((error) => error)
  },
  edit({ commit }, data) {
    return especialidadeService
      .edit(data)
      .then((result) => result)
      .catch((error) => error)
  },
  delete({ commit }, id) {
    return especialidadeService
      .delete(id)
      .then((result) => result)
      .catch((error) => error)
  },
  prepareToEditEspecialidade({ commit }, data) {
    commit('PREPARE_TO_EDIT_ESPECIALIDADE', data)
  },
  reset({ commit }) {
    commit('RESET')
  }
}
