var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":_vm.titleForm}},[_c('validation-observer',{ref:"form"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"classe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Classe","label-for":"v-classe"}},[_c('b-form-input',{attrs:{"id":"v-classe","state":errors.length > 0 ? false : null,"placeholder":"Classe","data-cy":"classe","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.addressTypePlace.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') &&
                    !_vm.addressTypePlace.id)},model:{value:(_vm.form.classe),callback:function ($$v) {_vm.$set(_vm.form, "classe", $$v)},expression:"form.classe"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-classe"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Natureza","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Natureza","label-for":"v-codNatureza"}},[_c('b-form-input',{attrs:{"id":"v-codNatureza","state":errors.length > 0 ? false : null,"placeholder":"Natureza","data-cy":"natureza","disabled":(!_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                    !!_vm.form.id) ||
                  (!_vm.verificarPermissao('ROLE_FINAN_INSERIR') && !_vm.form.id)},model:{value:(_vm.form.codNatureza),callback:function ($$v) {_vm.$set(_vm.form, "codNatureza", $$v)},expression:"form.codNatureza"}}),_c('small',{staticClass:"text-danger",attrs:{"data-cy":"v-natureza"}},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('ButtonsFormVue',{attrs:{"permission-insert":_vm.verificarPermissao('ROLE_FINAN_INSERIR'),"permission-edit":_vm.verificarPermissao('ROLE_FINAN_ATUALIZAR'),"disable-form":false},on:{"save":function($event){return _vm.save()},"cancel":function($event){return _vm.cancel()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }