<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="reports"
      @on-row-click="printReport"
    />
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import mixinsGlobal from '@/mixins'

export default {
  name: 'ReportAssociantes',
  components: {
    VueGoodTable,
  },
  mixins: [mixinsGlobal],
  data() {
    return {
      reports: [],
      columns: [
        {
          label: 'Relatório',
          field: 'nomeAmigavel',
        },
      ],
      pdfTeste: 'YOUR_PDF',
    }
  },
  mounted() {
    this.$store.dispatch('reportModule/listagemRelatorios', this.$route.params.id).then(response => {
      this.reports = response.data
    })
  },
  methods: {
    printReport(params) {
      const report = {
        nome: params.row.nome,
        valor: [
          parseInt(this.$route.params.id),
        ],
      }
      this.$store.dispatch('reportModule/printReport', report).then(response => {
        this.openNewAbaBase64(response.data)
      })
    },
  },
}
</script>
