export default {
  LOAD_LIST_ACCOUNT_TYPES(state, data) {
    state.listAccountTypes = data
  },
  PREPARE_TO_EDIT_ACCOUNT_TYPE(state, data) {
    state.accountType = data
  },
  RESET_ACCOUNT_TYPE(state) {
    state.accountType = {
      nome: '',
      ativo: true,
    }
  },
}
