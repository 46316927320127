export default [
  {
    path: '/modules/administrative/phisicalPerson',
    redirect: '/modules/administrative/phisicalPerson/associantes',
    name: 'phisicalPesonPage',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/PhisicalPerson.vue'
      ),
    meta: {
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Pessoa Fisíca',
      breadcrumb: [
        {
          text: 'Administrative'
        }
      ]
    },
    children: [
      {
        path: 'associantes',
        name: 'associantes',
        component: () =>
          import(
            '@/views/modules/administrative/phisicalPerson/associantes/grid/Associantes.vue'
          ),
        meta: {
          pageTitle: 'Associados',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Associados'
            }
          ]
        }
      },
      {
        path: 'dependent',
        name: 'dependent',
        component: () =>
          import(
            '@/views/modules/administrative/phisicalPerson/dependents/grid/Dependent.vue'
          ),
        meta: {
          pageTitle: 'Depedentes',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Depedentes'
            }
          ]
        }
      },
      {
        path: 'aggregates',
        name: 'aggregates',
        component: () =>
          import(
            '@/views/modules/administrative/phisicalPerson/aggregates/grid/Aggregates.vue'
          ),
        meta: {
          pageTitle: 'Agregados',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Agregados'
            }
          ]
        }
      },
      {
        path: 'employees',
        name: 'employees',
        component: () =>
          import(
            '@/views/modules/administrative/phisicalPerson/employees/grid/Employees.vue'
          ),
        meta: {
          pageTitle: 'Funcionários',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Funcionários'
            }
          ]
        }
      },
      {
        path: 'others',
        name: 'others',
        component: () =>
          import(
            '@/views/modules/administrative/phisicalPerson/others/grid/Others.vue'
          ),
        meta: {
          pageTitle: 'Outros',
          action: 'ROLE_ADMIM_CONSULTAR',
          resource: 'ACL',
          breadcrumb: [
            {
              text: 'Módulo Administrativo | Outros'
            }
          ]
        }
      }
    ]
  }
]
