import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${queryFilter.sort},${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/atendimento/unidade_atendimentos${queryUrl}`)
  },
  insertNewUnidadeAtendimento(data) {
    return axios.post('/atendimento/unidade_atendimentos', data)
  },
  editUnidadeAtendimento(data) {
    return axios.put(`/atendimento/unidade_atendimentos/${data.id}`, data)
  },
  deleteUnidadeAtendimento(id) {
    return axios.delete(`/atendimento/unidade_atendimentos/${id}`)
  },
}
