<template>
  <b-card :title="titleForm">
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <b-row>
          <!-- first name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-group label="Nome" label-for="v-nome">
                <b-form-input
                  id="v-nome"
                  v-model="form.nome"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nome"
                  data-cy="nome"
                  :disabled="
                    (!verificarPermissao('ROLE_FINAN_ATUALIZAR') &&
                      !!form.id) ||
                    (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                  "
                />
                <small data-cy="v-nome" class="text-danger">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- first name -->
          <b-col cols="12" md="4">
            <b-form-group label="Percentual de Juros" label-for="v-percentual">
              <b-form-input
                id="v-percentual"
                v-model="form.percentualJuros"
                placeholder="percentual"
                data-cy="percentual"
                type="number"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first name -->
          <b-col cols="12" md="4">
            <b-form-group label="Adesão" label-for="v-adesao">
              <b-form-input
                id="v-adesao"
                v-model="form.adesao"
                placeholder="adesao"
                data-cy="adesao"
                type="number"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>

          <!-- first name -->
          <b-col cols="12" md="4">
            <b-form-group label="Margem" label-for="v-margem">
              <b-form-input
                id="v-margem"
                v-model="form.margem"
                placeholder="margem"
                data-cy="margem"
                type="number"
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              />
            </b-form-group>
          </b-col>
          <b-col v-if="form.id" cols="12">
            <b-form-group label="Ativo ?">
              <b-form-checkbox
                v-model="form.ativo"
                :checked="form.ativo"
                name="check-button"
                switch
                inline
                :disabled="
                  (!verificarPermissao('ROLE_FINAN_ATUALIZAR') && !!form.id) ||
                  (!verificarPermissao('ROLE_FINAN_INSERIR') && !form.id)
                "
              >
                {{ form.ativo ? 'Sim' : 'Não' }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <ButtonsFormVue
            :permission-insert="verificarPermissao('ROLE_FINAN_INSERIR')"
            :permission-edit="verificarPermissao('ROLE_FINAN_ATUALIZAR')"
            :disable-form="false"
            @save="save()"
            @cancel="cancel()"
          />
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import { verificarPermissao } from '@/auth/utils'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'

export default {
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('taxasSettingsModule', {
      taxa: (state) => state.taxa
    })
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  data() {
    return {
      verificarPermissao,
      titleForm: 'Cadastro de Taxas',
      required,
      form: {
        nome: '',
        percentualJuros: null,
        adesao: null,
        margem: null,
        ativo: true
      }
    }
  },
  mounted() {
    this.form = JSON.parse(JSON.stringify(this.taxa))
  },
  methods: {
    cancel() {
      this.$emit('cancelar')
      this.cleanObjeto()
      this.$store.dispatch('taxasSettingsModule/reset')
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          if (this.form.id) {
            this.$store
              .dispatch('taxasSettingsModule/edit', this.form)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Editar',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Editar', 'Editado com sucesso!')
                this.$refs.form.reset()
                this.cancel()
              })
          } else {
            this.$store
              .dispatch('taxasSettingsModule/insertNew', this.form)
              .then((response) => {
                if (response.response) {
                  this.MensagemError(
                    'Falha ao Inserir',
                    response.response.data.error
                  )
                  return
                }
                this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
                this.cleanObjeto()
                this.$refs.form.reset()
              })
          }
        }
      })
    },
    cleanObjeto() {
      this.form = {
        nome: '',
        percentualJuros: null,
        adesao: null,
        margem: null,
        ativo: true
      }
    }
  }
}
</script>
