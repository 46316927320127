import administrativeModule from './administrativeModule'
import financialModule from './financialModule'
import attendanceModule from './attendanceModule'
import protocolModule from './protocolModule'
import convenioModule from './convenioModule'
import ordemSercicoModule from './ordemServicoModule'

export default [
  ...administrativeModule,
  ...financialModule,
  ...attendanceModule,
  ...protocolModule,
  ...convenioModule,
  ...ordemSercicoModule,
  {
    path: '/modules/administrative/phisicalPerson/editAssociante/:id',
    name: 'editAssociante',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/form/FormPrincipalAssociante.vue'
      ),
    meta: {
      pageTitle: 'Editando Associado',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Associado'
        }
      ]
    }
  },
  {
    path: '/modules/administrative/phisicalPerson/editAggregate/:id',
    name: 'editAggregate',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/form/FormPrincipalAggregate.vue'
      ),
    meta: {
      pageTitle: 'Editando Agregados',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Agregados'
        }
      ]
    }
  },
  {
    path: '/modules/administrative/phisicalPerson/editDependent/:id',
    name: 'editDependent',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/form/FormPrincipalDependente.vue'
      ),
    meta: {
      pageTitle: 'Editando Dependentes',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Dependentes'
        }
      ]
    }
  },
  {
    path: '/modules/administrative/phisicalPerson/editEmployee/:id',
    name: 'editEmployee',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/form/FormPrincipalEmployee.vue'
      ),
    meta: {
      pageTitle: 'Editando Funcionário',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Funcionário'
        }
      ]
    }
  },
  {
    path: '/modules/administrative/phisicalPerson/editOther/:id',
    name: 'editOther',
    component: () =>
      import(
        '@/views/modules/administrative/phisicalPerson/form/FormPrincipalOther.vue'
      ),
    meta: {
      pageTitle: 'Editando Outro',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Outro'
        }
      ]
    }
  },
  {
    path: '/modules/administrative/juridicPerson/editFornecedor/:id',
    name: 'editFornecedor',
    component: () =>
      import(
        '@/views/modules/administrative/juridicPerson/fornecedor/FormPrincipalEditarFornecedor.vue'
      ),
    meta: {
      pageTitle: 'Editando Fornecedor',
      action: 'ROLE_ADMIM_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Administrativo | Editando Fornecedor'
        }
      ]
    }
  },
  {
    path: '/modules/attendance/attendance/editAtendimento/:id',
    name: 'editAtendimento',
    component: () =>
      import(
        '@/views/modules/attendance/attendance/form/AttendanceEditForm.vue'
      ),
    meta: {
      pageTitle: 'Editando Atendimento',
      action: 'ROLE_ATEND_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Atendimento | Editando Atendimento'
        }
      ]
    }
  },
  {
    path: '/modules/convenios/edit/:id',
    name: 'editConvenio',
    component: () => import('@/views/modules/convenio/form/FormConvenio.vue'),
    meta: {
      pageTitle: 'Editando Convênio',
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Convênio | Editando Convênio'
        }
      ]
    }
  },
  {
    path: '/modules/Attendance/attendance/AttendanceProfissional/editAtendimentoProfissional/:id',
    name: 'editAtendimentoProfissional',
    component: () =>
      import('@/views/modules/profissional/form/ProfissionalForm.vue'),
    meta: {
      pageTitle: 'Editando Atendimento',
      action: 'ROLE_PROFISSIONAL',
      resource: 'ACL',
      breadcrumb: [
        {
          text: 'Modulo Atendimento | Editando Atendimento'
        }
      ]
    }
  }
]
