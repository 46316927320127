export default {
  LOAD_LIST(state, data) {
    state.listaOrdemServico = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.ordemServico = data
  },
  RESET(state) {
    state.ordemServico = {
      id: null,
      descricao: '',
      dataInicial: '',
      dataTermino: '',
      registro: {
        id: null,
      },
      garantia: true,
      observacao: '',
      status: {
        id: null,
      },
      valor: null,
      valorDesconto: null,
      tipoProtocolo: null,
    }
  },
}
