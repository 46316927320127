export default {
  LOAD_LIST_CIVIL_STATUS(state, data) {
    state.listCivilStatuss = data
  },
  PREPARE_TO_EDIT_CIVIL_STATUS(state, data) {
    state.civilStatus = data
  },
  RESET_CIVIL_STATUS(state) {
    state.civilStatus = {
      ativo: true,
      nome: '',
    }
  },
}
